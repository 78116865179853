import { getApolloClient } from '../../../App/config/apollo/ApolloClient';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { UNKNOWN_ERROR } from '../../../consts';
import {
  GET_FULL_TOUR_QUERY,
  Data,
  Variables,
} from '../../../graphql/queries/GetFullTourQuery';

export async function fetchTour(tourID: string) {
  let isNetworkError = false;

  const variables = { tourID };

  const client = await getApolloClient();

  try {
    // important: this needs to be same as the one in TourRoutesView.tsx
    const response = await client.query<Data, Variables>({
      query: GET_FULL_TOUR_QUERY,
      fetchPolicy: 'network-only',
      variables,
    });

    return response?.data?.result;
  } catch (error: any) {
    captureInSentry(error?.message || UNKNOWN_ERROR, { tourID });

    if (error.networkError) {
      isNetworkError = true;
    }
  }

  if (isNetworkError) {
    try {
      const cacheResponse = await client.query<Data, Variables>({
        query: GET_FULL_TOUR_QUERY,
        fetchPolicy: 'cache-only',
        variables,
      });

      return cacheResponse?.data?.result;
    } catch (error: any) {}
  }

  return null;
}
