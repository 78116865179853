import * as Sentry from '@sentry/react';
import { matchPath } from 'react-router-dom';
import { History } from 'history';
import { env } from '../../App/config/env';
import { ROUTES } from '../../Routes/routes';

export function configureSentry(history: History<any>) {
  const routes = Object.values(ROUTES).map((route) => ({ path: route }));

  Sentry.init({
    dsn: env.SENTRY_DSN,

    release: env.GIT_HASH,
    environment: env.ENVIRONMENT,

    debug: !env.IS_RTM,

    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/

      // for v5 we are using, https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v4v5
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath,
      }),

      Sentry.replayIntegration(),
    ],

    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),

    transportOptions: {
      /**
       * Name of IndexedDb database to store events
       * Default: 'sentry-offline'
       */
      // dbName: string;
      /**
       * Name of IndexedDb object store to store events
       * Default: 'queue'
       */
      // storeName: string;
      /**
       * Maximum number of events to store
       * Default: 30
       */
      // @ts-expect-error - not typed correctly
      maxQueueSize: 200,
      /**
       * Flush the store shortly after startup.
       * Default: false
       */
      // flushAtStartup: boolean;
      /**
       * Called before an event is stored.
       * Return false to drop the envelope rather than store it.
       *
       * @param envelope The envelope that failed to send.
       * @param error The error that occurred.
       * @param retryDelay The current retry delay in milliseconds.
       */
      // shouldStore: (envelope: Envelope, error: Error, retryDelay: number) => boolean | Promise<boolean>;
    },
  });
}
