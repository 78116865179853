import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { createOrGetSession } from '../analytics/createOrGetSession';
import { env } from '../../App/config/env';
import indexedDBStorage from '../../utils/indexedDBStorage';

export type LogEntry = {
  message: string;
  timestamp: string;
};

const SESSION_END_MESSAGE = 'Session end';
const MAX_LOG_SIZE_KB = 1024; // Define your maximum log size in KB

export function logMessage(...args: any) {
  if (!env.IS_RTM) {
    console.log('LVR:DEBUG', ...args);

    persistLogMessage(...args).catch((error) => {
      Sentry.captureException(error);
    });
  }
}

async function persistLogMessage(...args: any): Promise<void> {
  try {
    const key = getLogKey();
    const message = `${args.map((arg: any) => JSON.stringify(arg)).join(' ')}`;
    const timestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS');
    const logEntry: LogEntry = { message, timestamp };

    let logMessages: Array<LogEntry> =
      (await indexedDBStorage.getItem(key)) || [];

    // Check if the last message is a session end message
    if (
      logMessages.length > 0 &&
      logMessages[logMessages.length - 1].message === SESSION_END_MESSAGE
    ) {
      logMessages.pop();
    }

    // Add the new log entry
    logMessages.push(logEntry);

    // Add the session end message
    const sessionEndEntry: LogEntry = {
      message: SESSION_END_MESSAGE,
      timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS'),
    };
    logMessages.push(sessionEndEntry);

    await indexedDBStorage.setItem(key, logMessages);

    // Perform cleanup if needed
    await performCleanup();
  } catch (error) {
    Sentry.captureException(error);
  }
}

// Function to periodically update the session end message
export async function updateSessionEndLog(): Promise<void> {
  try {
    const key = getLogKey();

    let logMessages: Array<LogEntry> =
      (await indexedDBStorage.getItem(key)) || [];

    if (
      logMessages.length > 0 &&
      logMessages[logMessages.length - 1].message === SESSION_END_MESSAGE
    ) {
      logMessages[logMessages.length - 1].timestamp = format(
        new Date(),
        'yyyy-MM-dd HH:mm:ss.SSS'
      );
      await indexedDBStorage.setItem(key, logMessages);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function logGroupDateFormatter(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

// Function to retrieve log messages for a specific date and session
export async function getLogMessages(
  date: string,
  sessionID: string
): Promise<Array<LogEntry>> {
  try {
    const key = `log-${date}-${sessionID}`;
    const logMessages: Array<LogEntry> = await indexedDBStorage.getItem(key);
    return (logMessages || []).sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
}

// Function to clear all log messages for a specific date and session
export async function clearLogMessages(
  date: string,
  sessionID: string
): Promise<void> {
  try {
    const key = `log-${date}-${sessionID}`;

    await indexedDBStorage.removeItem(key);
  } catch (error) {
    Sentry.captureException(error);
  }
}

// Function to retrieve all log messages for a specific date
export async function getLogMessagesForDate(
  date: string
): Promise<Array<LogEntry>> {
  try {
    const keys = await indexedDBStorage.keys();
    const logKeys = keys.filter(
      (key) => typeof key === 'string' && key.startsWith(`log-${date}-`)
    );

    const logMessages: Array<LogEntry> = (
      await Promise.all(logKeys.map((key) => indexedDBStorage.getItem(key)))
    ).flat();

    return logMessages.sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
}

// Function to clear all log messages for a specific date
export async function clearLogMessagesForDate(date: string): Promise<void> {
  try {
    const keys = await indexedDBStorage.keys();
    const logKeys = keys.filter(
      (key) => typeof key === 'string' && key.startsWith(`log-${date}-`)
    );

    await Promise.all(logKeys.map((key) => indexedDBStorage.removeItem(key)));
  } catch (error) {
    Sentry.captureException(error);
  }
}

function getLogKey(): string {
  const sessionID = createOrGetSession();
  return `log-${logGroupDateFormatter(new Date())}-${sessionID}`;
}

async function performCleanup(): Promise<void> {
  try {
    const keys = await indexedDBStorage.keys();
    let totalSize = 0;
    const logEntries = [];

    // Calculate total size and collect all log entries
    for (const key of keys) {
      if (typeof key === 'string' && key.startsWith('log-')) {
        const logMessages: Array<LogEntry> = await indexedDBStorage.getItem(
          key
        );
        if (logMessages) {
          const size = new Blob([JSON.stringify(logMessages)]).size / 1024; // Size in KB
          totalSize += size;
          logEntries.push({ key, logMessages, size });
        }
      }
    }

    // If total size exceeds the limit, perform cleanup
    if (totalSize > MAX_LOG_SIZE_KB) {
      // Sort log entries by timestamp - oldest first
      logEntries.sort(
        (a, b) =>
          new Date(a.logMessages[0].timestamp).getTime() -
          new Date(b.logMessages[0].timestamp).getTime()
      );

      // Remove oldest log entries until total size is within the limit
      for (const entry of logEntries) {
        if (totalSize <= MAX_LOG_SIZE_KB) break;
        await indexedDBStorage.removeItem(entry.key);
        totalSize -= entry.size;
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
