import React from 'react';
import styled from '../../../style/styled';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../Routes/routes';
import { captureButtonPress } from '../../../features/analytics';
import { useTranslation } from 'react-i18next';

import { Button } from '../../Button';
import {
  ContentSectionWidgetButtonContainer,
  ContentSectionWidgetContainer,
} from '../styledComponents';
import { env } from '../../../App/config/env';
import { useSelector } from 'react-redux';
import { selectVariant } from '../../../store/googleOptimize/selectors';

const THUMBNAIL_SPACER = '/7a350f63a17cf55a378f7906b225b6c0.png';

interface Props {
  thumbnailURIs?: string[];
  caption?: string;
}

export const ShopMoreToursWidget: React.FC<Props> = ({
  thumbnailURIs = [],
  caption,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const variant = useSelector(selectVariant(env.GO_EXP_ID_FOR_LIBRARY_PAGE));

  let thumnailsCount = 0;

  if (thumbnailURIs.length >= 9) {
    thumnailsCount = 9;
  } else if (thumbnailURIs.length >= 4) {
    thumnailsCount = 4;
  }

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Show More Tours',
    });

    if (variant === '0') {
      history.push({ pathname: ROUTES.index, hash: '#tours_section' });
    } else if (variant === '1') {
      history.push(ROUTES.library);
    } else {
      history.push(ROUTES.index);
    }
  };

  return (
    <ContentSectionWidgetContainer interactive>
      <ImageContainer>
        {/* spacer: without this, you get a layout shift */}
        <img src={THUMBNAIL_SPACER} alt="" width="100%" />

        {thumnailsCount > 0 && (
          <ThumbnailGridContainer
            onClick={handleClick}
            repeat={Math.sqrt(thumnailsCount)}
          >
            {thumbnailURIs.slice(0, thumnailsCount).map((src) => (
              <img
                key={src}
                src={src}
                alt=""
                width="100%"
                // safari doesn't like this, so commented out
                // height="100%"
              />
            ))}
          </ThumbnailGridContainer>
        )}
      </ImageContainer>

      <ContentSectionWidgetButtonContainer>
        <Button width="100%" onClick={handleClick}>
          {caption || t('More tours')}
        </Button>
      </ContentSectionWidgetButtonContainer>
    </ContentSectionWidgetContainer>
  );
};

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const ThumbnailGridContainer = styled.div<{ repeat: number }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* z-index: 2; */
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(${({ repeat }) => repeat}, 1fr);
  padding-bottom: 4px;
`;
