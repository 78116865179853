import { getStore } from '../../../store';
import { Asset } from '../../../store/offline/types';

export async function getAssetIDsToDelete(): Promise<Array<Asset>> {
  const store = getStore();

  if (!store) {
    return [];
  }

  const state = store.getState();

  const files = state.offline.files || {};

  const assetsToDelete: Asset[] = [];

  Object.keys(files).forEach((assetID) => {
    const asset = files[assetID];

    if (asset) {
      const playlistIDs = asset.playlistIDs || [];

      if (playlistIDs.length === 0) {
        assetsToDelete.push({ id: assetID, ...asset });
      }
    }
  });

  return assetsToDelete;
}
