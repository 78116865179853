import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import styled from '../../../style/styled';
import * as Sentry from '@sentry/react';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { useDispatch } from 'react-redux';
import {
  captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import { loginSuccess } from '../../../store/app/actions';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { env } from '../../../App/config/env';
import {
  EXPLORE_WEB_AuthWithGoogleUsingCode as Data,
  EXPLORE_WEB_AuthWithGoogleUsingCodeVariables as Variables,
} from './__generated__/EXPLORE_WEB_AuthWithGoogleUsingCode';
import { Button } from '../../../components/Button';
// import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';

const AUTH_WITH_GOOGLE_USING_CODE_MUTATION = gql`
  mutation EXPLORE_WEB_AuthWithGoogleUsingCode(
    $input: AuthWithGoogleUsingCodeInput!
  ) {
    result: authWithGoogleUsingCode(input: $input) {
      jwt
      newUser
      error {
        id
        message
        type
      }
    }
  }
`;

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  redirectedHref?: string | null;
  setBusy: (busy: boolean) => void;
  busy: boolean;
}

export const ContinueWithGoogle3: React.FC<Props> = ({
  onFailure,
  onSuccess,
  setBusy,
  redirectedHref,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [oauthRequested, setOauthRequested] = useState<boolean>(false);
  const [authWithGoogleUsingCode, { loading }] = useMutation<Data, Variables>(
    AUTH_WITH_GOOGLE_USING_CODE_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        onFailure
          ? onFailure(error)
          : toast.error(error?.message || UNKNOWN_ERROR);
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry(
            'ContinueWithGoogle.tsx result is null or undefined',
            { variables: getVariables() }
          );

          return;
        }

        const { jwt, error, newUser } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          dispatch(loginSuccess({ userId: userID, jwt }));

          if (newUser) {
            captureEvent({
              name: EventNames.USER_REGISTERED,
              data: [{ key: DataKeys.SIGNUP_METHOD, value: 'Google' }],
            });
          } else {
            captureEvent({
              name: EventNames.LOGGED_IN,
              data: [{ key: DataKeys.LOGIN_METHOD, value: 'Google' }],
            });
          }

          const toastSuccessMessage = t(
            'You have successfully logged in with Google'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          onFailure
            ? onFailure(new Error(errorMessage))
            : toast.error(errorMessage);
        }
      },
    }
  );

  useEffect(() => {
    setBusy(loading || oauthRequested);
  }, [loading, oauthRequested, setBusy]);

  const onGoogleLoginSuccess = (response: any) => {
    if (response.code) {
      const variables: Variables = {
        input: {
          code: response.code,
          clientID: env.GOOGLE_CLIENT_ID,
          referralAppID: env.APP_ID,
        },
      };

      const affiliateCode = getStoredAffiliateCode();

      if (affiliateCode) {
        variables.input.affiliateCode = affiliateCode;
      }

      setVariables(variables);

      authWithGoogleUsingCode({ variables });
    } else {
      captureInSentry(
        'ContinueWithGoogle2.tsx Did not receive an access token from Google auth',
        { response: JSON.stringify(response) }
      );
    }

    setOauthRequested(false);
  };

  const onGoogleLoginError = () => {
    const message =
      'Authentication with Google failed. Please try again later, or try another way to log in.';

    toast.error(t(message));
    setOauthRequested(false);
  };

  const onNonOAuthError = () => {
    setOauthRequested(false);
  };

  const login = useGoogleLogin({
    onError: onGoogleLoginError,
    onSuccess: onGoogleLoginSuccess,
    onNonOAuthError,
    flow: 'auth-code',
  });

  // based on local busy state
  const caption = t(
    loading || oauthRequested ? 'Please Wait...' : 'Continue with Google'
  );

  // https://github.com/MomenSherif/react-oauth#googlelogin
  return (
    <GoogleButton
      disabled={loading || oauthRequested}
      onClick={() => {
        captureButtonPress({
          buttonName: 'ContinueWithGoogle',
          page: window.location.pathname,
        });

        setOauthRequested(true);

        login();
      }}
    >
      {caption}
    </GoogleButton>
  );
};

const GoogleButton = styled(Button)`
  background-color: #db3236;
  color: #fff;
  width: 100%;
  margin-bottom: 16px;
  border: none;
`;
