import React, { useEffect, useState } from 'react';
import styled from '../../../style/styled';
import VectorMap from '../../../features/offlineMap/VectorMap';
import { InfoPanel } from './InfoPanel';
import {
  MarkerType,
  SelectedFeature,
} from '../../../features/offlineMap/types';
import { Extent } from 'ol/extent';
import { EXPLORE_WEB_GetFullTour_result_map_geoJSONFiles } from '../../../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';

interface Tour {
  viewpoints: Array<{
    id: string;
    nameI18n: {
      en_US: string | null;
    };
    taglineI18n: {
      en_US: string | null;
    };
    location: {
      coordinates: {
        latitude: number;
        longitude: number;
      };
    } | null;
    thumbnail: {
      uri: string;
    } | null;
  }>;
  map: {
    id: string;
    projectionCode: string;
    extXMin: number;
    extYMin: number;
    extXMax: number;
    extYMax: number;
    defaultViewBoxXMin: number | null;
    defaultViewBoxYMin: number | null;
    defaultViewBoxXMax: number | null;
    defaultViewBoxYMax: number | null;
    geoJSONFiles: EXPLORE_WEB_GetFullTour_result_map_geoJSONFiles[];
  } | null;
}

interface Props {
  currentViewpointIndex: number;
  tour: Tour;
  isMapsVisible: boolean;
  showUserPosition: boolean;
  withViewpointVisiting: boolean;
  withZoomControls?: boolean;
  showGoButtonOnCurrentMarker?: boolean;
}

export const OfflineTourMap: React.FC<Props> = ({
  tour,
  isMapsVisible,
  showUserPosition,
  withViewpointVisiting,
  withZoomControls = false,
  showGoButtonOnCurrentMarker = false,
}: Props) => {
  const [
    selectedFeature,
    setSelectedFeature,
  ] = useState<SelectedFeature | null>(null);

  useEffect(() => {
    if (!isMapsVisible) {
      setSelectedFeature(null);
    }
  }, [isMapsVisible]);

  const { map } = tour;

  if (!map) {
    return null;
  }

  const markers = getMarkerData(tour);

  const defaultViewboxExtent =
    typeof map.defaultViewBoxXMin === 'number' &&
    typeof map.defaultViewBoxYMin === 'number' &&
    typeof map.defaultViewBoxXMax === 'number' &&
    typeof map.defaultViewBoxYMax === 'number'
      ? ([
          map.defaultViewBoxXMin,
          map.defaultViewBoxYMin,
          map.defaultViewBoxXMax,
          map.defaultViewBoxYMax,
        ] as Extent)
      : null;

  return (
    <Container>
      <VectorMap
        defaultViewboxExtent={defaultViewboxExtent}
        imageExtent={[map.extXMin, map.extYMin, map.extXMax, map.extYMax]}
        projectionCode={map.projectionCode}
        geoJSONFiles={map.geoJSONFiles}
        enableRotation={false}
        markers={markers}
        setSelectedFeature={setSelectedFeature}
        selectedFeature={selectedFeature}
        showUserPosition={showUserPosition}
        withZoomControls={withZoomControls}
      />

      {selectedFeature && (
        <InfoPanel
          selectedFeature={selectedFeature}
          onClose={() => setSelectedFeature(null)}
          withViewpointVisiting={withViewpointVisiting}
          showGoButtonOnCurrentMarker={showGoButtonOnCurrentMarker}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

function getMarkerData(tour: Tour) {
  return tour.viewpoints.map((vp, idx) => {
    const { latitude = 0, longitude = 0 } = vp.location?.coordinates || {};

    const name = vp.nameI18n?.en_US || '';
    const description = vp.taglineI18n?.en_US || '';

    return {
      id: vp.id,
      idx,
      position: [longitude, latitude],
      type: MarkerType.VIEWPOINT,
      thumbnailURL: vp.thumbnail?.uri || '',
      name,
      description,
    };
  });
}
