import styled from '../../../style/styled';

export const ContentSectionWidgetContainer = styled.div<{
  interactive: boolean;
}>`
  text-align: center;
  position: relative;
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  overflow: hidden;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
`;
