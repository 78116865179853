import { useState } from 'react';
import styled from '../../../../style/styled';
import { mdiHelp, mdiPlay } from '@mdi/js';
import { env } from '../../../../App/config/env';
import { fetchTour } from '../../../../features/offline/utils/fetchTour';
import { findAllAssets } from '../../../../features/offline/utils/findAllAssets';
import MdiIcon from '../../../MdiIcon';
import { logMessage } from '../../../../features/logging/logMessage';

interface Props {
  playlistID: string;
}

export const TouWidgetCheckDownloadStatus = ({ playlistID }: Props) => {
  const [loading, setLoading] = useState(false);

  const sentToSW = async () => {
    setLoading(true);

    if (
      env.ENABLE_SERVICE_WORKER_BACKGROUND_CACHING &&
      navigator.serviceWorker.controller
    ) {
      logMessage(
        '[sentToSW] DEBUG TourWidgetOfflineStatusIndicator has SW controller'
      );

      const tour = await fetchTour(playlistID);

      const assets = findAllAssets(tour);

      logMessage(
        '[sentToSW] DEBUG TourWidgetOfflineStatusIndicator going to call postMessage'
      );

      navigator.serviceWorker.controller.postMessage({
        action: 'cacheTourAssets',
        assets: assets.map(({ uri }) => uri),
      });
    } else {
      logMessage(
        '[sentToSW] DEBUG SW navigator.serviceWorker.controller is ',
        navigator.serviceWorker.controller
      );
    }

    setLoading(false);
  };

  const checkStatus = async () => {
    logMessage('[sentToSW] DEBUG Checking status');

    const tour = await fetchTour(playlistID);

    const assets = findAllAssets(tour);

    const cache = await caches.open('tour-assets-v1');

    let cachedCount = 0;

    for (let i = 0; i < assets.length; i++) {
      const { uri } = assets[i];

      try {
        const cached = await cache.match(uri);

        if (cached) {
          cachedCount++;
        }
      } catch (error) {
        // do nothing
      }
    }

    logMessage(
      '[checkStatus] DEBUG',
      cachedCount,
      'out of',
      assets.length,
      'assets cached'
    );
  };

  return (
    <>
      <ContainerOne interactive onClick={sentToSW} disabled={loading}>
        <MdiIcon path={mdiPlay} color="white" />
      </ContainerOne>

      <ContainerTwo interactive onClick={checkStatus} disabled={loading}>
        <MdiIcon path={mdiHelp} color="white" />
      </ContainerTwo>
    </>
  );
};

const ContainerOne = styled.button.attrs<{
  interactive: boolean;
}>(({ interactive }) => ({
  style: {
    cursor: interactive ? 'pointer' : 'auto',
  },
}))<{ interactive: boolean }>`
  position: absolute;
  left: 4px;
  bottom: 12px;
  z-index: 4;
  display: flex;
  align-items: center;
  color: #fff;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
`;

const ContainerTwo = styled.button.attrs<{
  interactive: boolean;
}>(({ interactive }) => ({
  style: {
    cursor: interactive ? 'pointer' : 'auto',
  },
}))<{ interactive: boolean }>`
  position: absolute;
  left: 40px;
  bottom: 12px;
  z-index: 4;
  display: flex;
  align-items: center;
  color: #fff;

  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
`;
