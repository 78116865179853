import { env } from '../../App/config/env';
import {
  CurrencyCode,
  LanguageCode,
  ProductSKU,
} from '../../graphql/globalTypes';
import { isGuestUser } from '../../utils/isGuestUser';
import { RootState } from '../redux/types';
import {
  CartState,
  PinnedTour,
  PromoCodeState,
  initialState,
  AppSettingsState,
  CurrencySetMode,
  ActivationStatus,
} from './types';
import isTokenExpired from '../../utils/isTokenExpired';
import { IEnv } from '../../features/whitelabeling/branding/types';
import { getDefaultSupportedLanguages } from '../../consts';

enum PurchaseMode {
  GIFT = 'GIFT',
  PERSONAL = 'PERSONAL',
}

type ProductMapInCart = {
  [productID: string]: number;
};

type ActivationCache = {
  tourIDs: Array<string>;
  expiryDate: number;
};

export function selectSuccessfulActivations(
  state: RootState
): Record<string, ActivationCache> {
  return state.app.successfulActivations;
}

export function selectIsAuthenticated(state: RootState): boolean {
  const jwt = selectJWT(state);

  return !isTokenExpired(jwt);
}

export function selectUnexpiredToken(state: RootState): string | null {
  const jwt = selectJWT(state);

  if (typeof jwt === 'string' && !isTokenExpired(jwt)) {
    return jwt;
  }

  return null;
}

export function selectUserID(state: RootState): string | null {
  return state.app.user?.id || null;
}

export function selectJWT(state: RootState) {
  return state.app.user?.jwt;
}

export function selectIsGuestUser(state: RootState): boolean {
  const jwt = state.app.user?.jwt;

  if (!jwt) {
    return false;
  }

  return isGuestUser(jwt);
}

export function selectCart(state: RootState): CartState {
  const { cart = [] } = state.app;

  return cart;
}

export function selectHasCartItems(state: RootState): boolean {
  const cart = selectCart(state);
  return cart.length > 0;
}

export function selectCartProductsCount(state: RootState): number {
  return selectCart(state).length;
}

export function selectPlaylistsMapInCart(state: RootState): ProductMapInCart {
  const { cart = [] } = state.app;

  return cart
    .filter((c) => c.productSKU === ProductSKU.PLAYLIST)
    .reduce((accum, cartItem) => {
      // @ts-ignore
      accum[cartItem.productID] = cartItem.count || 1;

      return accum;
    }, {});
}

export function selectPlaylistBundlesMapInCart(
  state: RootState
): ProductMapInCart {
  const { cart = [] } = state.app;

  return cart
    .filter((c) => c.productSKU === ProductSKU.PLAYLIST_BUNDLE)
    .reduce((accum, cartItem) => {
      // @ts-ignore
      accum[cartItem.productID] = cartItem.count || 1;

      return accum;
    }, {});
}

export function selectPlaylistCount(playlistID: string) {
  return function (state: RootState): number {
    const playlistsMapInCart = selectPlaylistsMapInCart(state);

    return playlistsMapInCart[playlistID] || 0;
  };
}

export function selectProductCount(productID: string, productSKU: string) {
  return function (state: RootState): number {
    const cart = selectCart(state);

    const item = cart.find(
      (i) => i.productID === productID && i.productSKU === productSKU
    );

    return item?.count || 0;
  };
}

export function selectPurchaseMode(state: RootState): PurchaseMode {
  return state.app.purchaseMode || PurchaseMode.PERSONAL;
}

export function selectLandscapeMessageDisplayed(state: RootState): boolean {
  return Boolean(state.app.landscapeMessageDisplayed);
}

export function selectAddToHomeScreenPopupDisplayedTimestamp(
  state: RootState
): null | Date {
  const timestamp = state.app.AddToHomeScreenPopupDisplayedTimestamp;

  return typeof timestamp === 'number' ? new Date() : null;
}

export function selectShowDisclaimer(state: RootState): boolean {
  return state.app.showDisclaimer;
}

export function selectProtected(state: RootState): boolean {
  return Boolean(state.app.protected);
}

export function selectPromoCode(state: RootState): PromoCodeState {
  return state.app.promoCode;
}

export function selectPromoCodeDiscountRate(state: RootState): number {
  return state.app.promoCode?.discountRate || 0;
}

export function selectPinnedTours(state: RootState): PinnedTour[] {
  return state.app.pinnedTours;
}

export function selectLanguage(state: RootState): LanguageCode {
  return env.ENABLE_I18N ? state.app.language : initialState.language;
}

export function selectSupportedLanguages(state: RootState): LanguageCode[] {
  if (env.IS_STOCK) {
    return getDefaultSupportedLanguages();
  }

  return (
    state.app.whitelabelAppSettings?.features?.supportedLanguages || [
      LanguageCode.en_US,
    ]
  );
}

export function selectCurrencyCode(state: RootState): CurrencyCode {
  return state.app.currencyCode;
}

export function selectCurrencyCodeSetMode(state: RootState): CurrencySetMode {
  return state.app.currencyCodeSetMode;
}

export function selectShouldAutoUpdateCurrencyCode(state: RootState): boolean {
  const { currencyCodeSetMode } = state.app;

  return currencyCodeSetMode !== CurrencySetMode.USER;
}

export function selectTourSettingsMaintainAudioPausedStateWhenSwitchingVPs(
  state: RootState
): boolean {
  return state.app.tourSettings.maintainAudioPausedStateWhenSwitchingVPs;
}

export function selectTourSettingsAutoLoadViewpointWhenNearby(
  state: RootState
): boolean {
  return state.app.tourSettings.autoLoadViewpointWhenNearby;
}

export function selectAppSettings(state: RootState): AppSettingsState {
  return state.app.appSettings;
}

export function selectClosestViewpointDetectionThresholdDistance(
  state: RootState
): number {
  return state.app.appSettings.closestViewpointDetectionThresholdDistance;
}

export function selectClosestViewpointDetectionThresholdAccuracy(
  state: RootState
): number {
  return state.app.appSettings.closetViewpointDetectionThresholdAccuracy;
}

export function selectMessageToShow(state: RootState): string | null {
  const { messages } = state.app;

  // get the final message
  return messages[messages.length - 1] || null;
}

export function selectEnableGoogleAnalytics(state: RootState): boolean {
  return state.app.enableGoogleAnalytics;
}

export function selectDisplayFullScreenLoadingOverlay(
  state: RootState
): boolean {
  return state.app.fulscreenLoadingOverlay;
}

export function selectWhitelabelAppSettings(state: RootState): IEnv {
  return state.app.whitelabelAppSettings;
}

export function selectActivationStatus(state: RootState): ActivationStatus {
  return state.app.activationStatus;
}

export function selectActivationError(state: RootState): string | null {
  return state.app.activationError;
}

export function selectEndOfTourSurveySentForTourIDs(
  state: RootState
): Array<string> {
  return state.app.endOfTourSurveySentForTourIDs;
}

export function selectEndOfTourPopupClosedForTourIDs(
  state: RootState
): Array<string> {
  return state.app.endOfTourPopupClosedForTourIDs;
}
