import { LanguageCode } from '../../graphql/globalTypes';
import { logMessage } from '../logging/logMessage';
import { captureEvent } from './captureEvent';
import { DataKeys, EventNames } from './types';

type CaptureButtonPressArgs = {
  page: string;
  buttonName: string;
  // toggle buttons
  action?: 'On' | 'Off';
  // buttons inside the tour
  imageID?: string;
  tourID?: string;
  tourBundleID?: string;
  viewpointID?: string;
  // app buttons
  app?: string;
  store?: string;
  // time slider
  audioPosition?: number;
  // subtitles
  languageCode?: LanguageCode;
};

export function captureButtonPress(args: CaptureButtonPressArgs) {
  const data = [
    {
      key: DataKeys.PAGE,
      value: args.page,
    },
    {
      key: DataKeys.BUTTON_NAME,
      value: args.buttonName,
    },
  ];

  if (args.action) {
    data.push({
      key: DataKeys.ACTION,
      value: args.action,
    });
  }

  if (args.tourID) {
    data.push({
      key: DataKeys.TOUR_ID,
      value: args.tourID,
    });
  }

  if (args.imageID) {
    data.push({
      key: DataKeys.IMAGE_ID,
      value: args.imageID,
    });
  }

  if (args.viewpointID) {
    data.push({
      key: DataKeys.VIEWPOINT_ID,
      value: args.viewpointID,
    });
  }

  if (args.app) {
    data.push({
      key: DataKeys.APP,
      value: args.app,
    });
  }

  if (args.store) {
    data.push({
      key: DataKeys.STORE,
      value: args.store,
    });
  }

  if (args.audioPosition) {
    data.push({
      key: DataKeys.AUDIO_POSITION,
      value: args.audioPosition.toString(),
    });
  }

  captureEvent({
    name: EventNames.ACTION_BUTTON_PRESS,
    data,
  });

  logMessage(`Button press: ${args.buttonName}`);
}
