import { useSelector } from 'react-redux';
import { selectDisplayFullScreenLoadingOverlay } from '../../store/app/selectors';
import styled from '../../style/styled';
import { LoadingIndicator } from '../../Routes/components/LoadingScreen/LoadingIndicator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999;
  background: white;

  > div {
    animation: 1.8s infinite heartbeat;
  }
`;

export const FullScreenLoadingOverlay = () => {
  const display = useSelector(selectDisplayFullScreenLoadingOverlay);

  if (!display) {
    return null;
  }

  return (
    <Wrapper>
      <LoadingIndicator black height={150} width={150} />
    </Wrapper>
  );
};
