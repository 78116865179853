import { logMessage } from '../features/logging/logMessage';

export function isServiceWorkerRegistered(): Promise<boolean> {
  return navigator.serviceWorker
    .getRegistration()
    .then((registration) => {
      if (registration && registration.active) {
        logMessage('Service Worker is active');
        return true; // Service Worker is registered and active
      } else {
        logMessage('Service Worker is not active yet');
        return false; // Service Worker is not active
      }
    })
    .catch((error) => {
      logMessage('Error checking Service Worker registration:', error);
      return false;
    });
}
