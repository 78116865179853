import { RouteComponentProps } from 'react-router-dom';
import { usePrevious } from '../../../hooks/usePrevious';
import { CloseTourIfNotOnFreeRoamTourRoute } from './CloseTourIfNotOnFreeRoamTourRoute';
import { RouteChangeCapturer } from './RouteChangeCapturer';
import { CloseModalManager } from './CloseModalManager';
import { ScrollToPageTopManagerWithItineraryPages } from './ScrollToPageTopManagerWithItineraryPages';

interface Props extends RouteComponentProps {}

// Set of side effects fired on route change.
export const RouteListener = ({ location }: Props) => {
  const curPathname = location.pathname;
  const prevPathname = usePrevious(curPathname);

  return (
    <>
      <CloseTourIfNotOnFreeRoamTourRoute
        curPathname={curPathname}
        prevPathname={prevPathname}
      />
      {/* Capture path in analytics */}
      <RouteChangeCapturer
        curPathname={curPathname}
        prevPathname={prevPathname}
      />
      <ScrollToPageTopManagerWithItineraryPages
        curPathname={curPathname}
        prevPathname={prevPathname}
      />
      <CloseModalManager
        curPathname={curPathname}
        prevPathname={prevPathname}
      />
    </>
  );
};
