import React, { useState } from 'react';
import InfoIcon from 'mdi-react/InfoCircleIcon';
import styled from '../../../style/styled';
import { ContentBetweenTwoBars } from '../../../components/ContentBetweenTwoBars';
// import { ReCaptchaDisclaimer } from '../../../components/ReCaptchaDisclaimer';
import { COLORS } from '../../../style/colors';
import { AuthPageTitle } from '../../../styledComponents/Title';
import { ContinueWithFacebook } from './ContinueWithFacebook';
import { ContinueWithGoogle } from './ContinueWithGoogle';
// import { ContinueWithGoogle2 } from './ContinueWithGoogle2';
import { ContinueWithGoogle3 } from './ContinueWithGoogle3';
import { LogInWithEmailForm } from './LogInWithEmailForm';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SignUpScreen } from '../signup/SignUpScreen';
import { setModalType } from '../../../store/modal/actions';
import { ModalType } from '../../../store/modal/types';
import { ForgotPasswordScreen } from '../forgotPassword/ForgotPasswordScreen';
import { ForgotPasswordSuccessScreen } from '../forgotPassword/ForgotPasswordSuccessScreen';
import { LinkLikeButton } from '../../../styledComponents/LinkLikeButton';
import { captureButtonPress } from '../../../features/analytics';
import { useTranslation } from 'react-i18next';
import { ContinueAsGuest } from './ContinueAsGuest';
import { darken } from 'polished';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { env } from '../../../App/config/env';

enum ModalContent {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
}

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  reason?: string | null;
  redirectedHref?: string | null;
  originalHref?: string | null;
}

export const LogInViewDesktop: React.FC<Props> = ({
  onSuccess,
  onFailure,
  reason,
  originalHref,
  redirectedHref,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [busy, setBusy] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ModalContent>(
    ModalContent.SIGNUP
  );

  const onAuthSuccess = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      dispatch(setModalType({ modalType: ModalType.NONE }));

      if (redirectedHref) {
        const redirectedURL = new URL(redirectedHref);

        history.push({
          pathname: redirectedURL.pathname,
          search: redirectedURL.search,
        });
      }
    }
  };

  let title = 'Log in';

  if (modalContent === ModalContent.SIGNUP) {
    title = 'Create account';
  } else if (modalContent === ModalContent.FORGOT_PASSWORD) {
    title = 'Reset password';
  } else if (modalContent === ModalContent.FORGOT_PASSWORD_SUCCESS) {
    title = 'Reset Password Email Sent';
  }

  const showSocialButtons =
    modalContent === ModalContent.SIGNUP || modalContent === ModalContent.LOGIN;

  return (
    <LoginScreenContainer data-testid="auth-form">
      <AuthPageTitle>{t(title)}</AuthPageTitle>

      {showSocialButtons && reason && (
        <ReasonContainer>
          <InfoIcon />
          <ReasonTextContainer>
            <p>{t(reason)}</p>
          </ReasonTextContainer>
        </ReasonContainer>
      )}

      {modalContent === ModalContent.SIGNUP && (
        <SignUpScreen
          redirectedHref={redirectedHref}
          onSuccess={onAuthSuccess}
          onLogIn={() => setModalContent(ModalContent.LOGIN)}
          onForgotPassword={() => setModalContent(ModalContent.FORGOT_PASSWORD)}
          onFailure={onFailure}
        />
      )}

      {modalContent === ModalContent.LOGIN && (
        <LogInWithEmailForm
          busy={busy}
          setBusy={setBusy}
          onSuccess={onAuthSuccess}
          onForgotPassword={() => setModalContent(ModalContent.FORGOT_PASSWORD)}
          onCreateAccount={() => setModalContent(ModalContent.SIGNUP)}
          redirectedHref={redirectedHref}
          onFailure={onFailure}
        />
      )}

      {modalContent === ModalContent.FORGOT_PASSWORD && (
        <ForgotPasswordScreen
          onSuccess={() => {
            setModalContent(ModalContent.FORGOT_PASSWORD_SUCCESS);
          }}
          onFailure={onFailure}
          onLogIn={() => {
            setModalContent(ModalContent.LOGIN);
          }}
        />
      )}

      {modalContent === ModalContent.FORGOT_PASSWORD_SUCCESS && (
        <ForgotPasswordSuccessScreen
          onExit={() => {
            dispatch(dispatch(setModalType({ modalType: ModalType.NONE })));
          }}
        />
      )}

      {showSocialButtons && (
        <>
          <OrContainer>
            <ContentBetweenTwoBars barColor={COLORS.offwhiteDarker}>
              <OrText>{t('Or')}</OrText>
            </ContentBetweenTwoBars>
          </OrContainer>
          <ContinueAsGuest
            onSuccess={onAuthSuccess}
            onFailure={onFailure}
            setBusy={setBusy}
            busy={busy}
            originalHref={originalHref}
            redirectedHref={redirectedHref}
          />
          <ContinueWithFacebook
            onSuccess={onAuthSuccess}
            onFailure={onFailure}
            setBusy={setBusy}
            busy={busy}
            originalHref={originalHref}
            redirectedHref={redirectedHref}
          />

          {env.ENABLE_NEW_GOOGLE_AUTH ? (
            <GoogleOAuthProvider clientId={env.GOOGLE_CLIENT_ID}>
              <ContinueWithGoogle3
                onSuccess={onAuthSuccess}
                onFailure={onFailure}
                setBusy={setBusy}
                busy={busy}
              />
            </GoogleOAuthProvider>
          ) : (
            <ContinueWithGoogle
              onSuccess={onAuthSuccess}
              onFailure={onFailure}
              setBusy={setBusy}
              busy={busy}
            />
          )}
        </>
      )}

      <SecondaryActionsContainer>
        {(modalContent === ModalContent.SIGNUP ||
          modalContent === ModalContent.FORGOT_PASSWORD) && (
          <LinkLikeButton
            disabled={busy}
            onClick={() => {
              captureButtonPress({
                buttonName: 'LogInWithEmail',
                page: window.location.pathname,
              });

              setModalContent(ModalContent.LOGIN);
            }}
          >
            <strong>{t('Already have an account?')}</strong>
          </LinkLikeButton>
        )}

        {(modalContent === ModalContent.LOGIN ||
          modalContent === ModalContent.FORGOT_PASSWORD) && (
          <LinkLikeButton
            disabled={busy}
            onClick={() => {
              captureButtonPress({
                buttonName: 'CreateAccount',
                page: window.location.pathname,
              });

              setModalContent(ModalContent.SIGNUP);
            }}
          >
            <strong>{t('Create an account')}</strong>
          </LinkLikeButton>
        )}

        {(modalContent === ModalContent.LOGIN ||
          modalContent === ModalContent.SIGNUP) && (
          <LinkLikeButton
            disabled={busy}
            onClick={() => {
              captureButtonPress({
                buttonName: 'ForgotPassword',
                page: window.location.pathname,
              });

              setModalContent(ModalContent.FORGOT_PASSWORD);
            }}
          >
            <strong>{t('Forgot password?')}</strong>
          </LinkLikeButton>
        )}
      </SecondaryActionsContainer>

      {/* <ReCaptchaDisclaimer /> */}
    </LoginScreenContainer>
  );
};

const OrContainer = styled.div`
  width: 100%;
  margin: 16px 0;
`;

const OrText = styled.p`
  margin: 0 10px;
  text-transform: capitalize;
`;

const ReasonTextContainer = styled.div`
  margin-left: 8px;
`;

const LoginScreenContainer = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const ReasonContainer = styled.div`
  padding: 4px;
  border: 1px solid ${darken(0.1, '#eaf8fc')};
  border-radius: 2px;
  background: #eaf8fc;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
`;

const SecondaryActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  padding-top: 16px;
  border-top: 2px solid #ddd;
`;
