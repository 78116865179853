import React from 'react';
import styled from '../../../../style/styled';
import { Section } from './styledComponents/Section';
import { SectionTitle } from './styledComponents/SectionTitle';
import { DownloadWidget } from './DownloadWidget';
import { env } from '../../../../App/config/env';
import { useTranslation } from 'react-i18next';

interface Props {
  tourID: string;
  // i18n text
  tourName: string;
}

export const DownloadSection: React.FC<Props> = ({ tourID, tourName }) => {
  const { t } = useTranslation();

  if (!env.ENABLE_SERVICE_WORKER) {
    return null;
  }

  const title = env.IS_STOCK ? 'Tour data' : 'Guide data';

  return (
    <Section>
      <SectionTitle>{t(title)}</SectionTitle>

      <Container>
        <DownloadWidget playlistID={tourID} playlistName={tourName} />
      </Container>
    </Section>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* text-align: justify; */
`;
