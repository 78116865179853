import * as Sentry from '@sentry/react';
import { LanguageCode } from './../../graphql/globalTypes';
import { getStore } from '../../store';
import { selectLanguage } from '../../store/app/selectors';
import { setLanguage } from './i18n';
import { setLanguage as setLanguageAction } from '../../store/app/actions';
import { DEFAULT_LANGUAGE } from '../../consts';
import { RootState } from '../../store/redux/types';
import { logMessage } from '../logging/logMessage';

// read the language set in redux store and use it in i18next.
// on error, set the default language.
export async function setInitialLanguage(): Promise<LanguageCode | null> {
  const store = getStore();

  let language = DEFAULT_LANGUAGE;

  if (store) {
    const state = store.getState() as RootState;
    const currentLanguage = selectLanguage(state);

    if (currentLanguage) {
      language = currentLanguage;
    }
  } else {
    Sentry.captureException(
      new Error('setInitialLanguage - Store is not available')
    );
  }

  try {
    await setLanguage(language);

    logMessage('Language set to:', language);
  } catch (error) {
    logMessage('Error setting language:', error);

    Sentry.captureException(error);

    // if there was an error loading the language file, change the language back to default
    if (store) {
      store.dispatch(setLanguageAction(DEFAULT_LANGUAGE));
    }
  }

  return language;
}
