import { gql } from '@apollo/client';
import { createContext } from 'react';
import {
  EXPLORE_WEB_CurrentUserForUserContext_currentUser,
  EXPLORE_WEB_CurrentUserForUserContext_currentUser_purchases as Purchase,
} from './__generated__/EXPLORE_WEB_CurrentUserForUserContext';

export const CURRENT_USER_QUERY = gql`
  query EXPLORE_WEB_CurrentUserForUserContext {
    currentUser {
      id
      email
      name
      purchases {
        productSKU
        productID
        expiresAt
      }
      purchasedGifts {
        productSKU
        productID
        redeemCode
        redeemedAt
      }
    }
  }
`;

export type User = EXPLORE_WEB_CurrentUserForUserContext_currentUser;

export type UserContextValue = {
  user: User | null;
  refetch: () => void;
  tourPurchasesMap: Record<string, Purchase>;
  tourBundlePurchasesMap: Record<string, Purchase>;
  isLoggedIn: boolean;
  isGuestUser: boolean;
};

const UserContext = createContext<UserContextValue>({
  user: null,
  refetch: () => {},
  tourPurchasesMap: {},
  tourBundlePurchasesMap: {},
  isLoggedIn: false,
  isGuestUser: false,
});

export default UserContext;
