import styled from '../style/styled';
import { COLORS } from '../style/colors';

export const ErrorText = styled.p`
  color: ${COLORS.errorColor};
  font-size: 75%;
`;

export const ErrorTextInLineBLock = styled(ErrorText)`
  display: inline-block;
`;
