import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../App/config/env';
import { Section } from './styledComponents/Section';
import { SectionTitle } from './styledComponents/SectionTitle';
import { IconWithTextContainer } from './styledComponents/IconWithTextContainer';
import { BodyTextWithIcon } from './styledComponents/BodyTextWithIcon';
import { Grid } from '../../../../Layouts/primitives/Grid';

const TOUR_INFO_DIGITAL_RECONSTRUCTION_ICON =
  '/f997ff73ad4c1fe5af5d799675503c16.png';
const TOUR_INFO_TRAIL_DURATION_ICON = '/9a9f295feb49fec35ae3550f34748ed6.png';
const TOUR_INFO_TIME_PERIOD_ICON = '/802f6c71fa368c900d0d326b5da2b687.png';
const TOUR_INFO_AUDIO_DURATION_ICON = '/135bc1912315b799d31f01ee2a1b1c14.png';
const TOUR_INFO_IMAGES_ICON = '/e61737afa2d4968c8b1cdc16dff8181b.png';
const TOUR_INFO_STREET_VIEW_ICON = '/90cfca60bae6786b7983a3b85c886b4c.png';

export type TourInfoLotsToDoSectionTour = {
  viewpoints: Array<{
    imagesCount: number;
  }>;
  trailLength: number | null;
  timePeriod: string | null;
  audioDurationMinutes: number | null;
};

interface Props {
  tour: TourInfoLotsToDoSectionTour;
}

export const TourInfoLotsToDoSection: React.FC<Props> = React.memo(
  ({ tour }) => {
    const { t } = useTranslation();
    const {
      tourInfo: {
        lotsToDoSection: {
          displayTrailLength,
          displayStreetView,
          displayTimePeriod,
        },
      },
      banner: { title },
    } = env.APP_ENV;

    const imagesCount = getImagesCount(tour.viewpoints);

    return (
      <Section id="lots-to-do">
        <SectionTitle>{t('Lots to do...')}</SectionTitle>

        <Grid gap={5} cols={3} colsSmall={1}>
          <IconWithTextContainer>
            <LazyLoadImage
              width={32}
              src={TOUR_INFO_DIGITAL_RECONSTRUCTION_ICON}
            />
            <BodyTextWithIcon>
              {tour.viewpoints.length}
              {title === 'A Journey Through Faith'
                ? ' Stops'
                : t('digital reconstructions')}
            </BodyTextWithIcon>
          </IconWithTextContainer>

          {displayTrailLength && tour.trailLength !== null && (
            <IconWithTextContainer>
              <LazyLoadImage width={32} src={TOUR_INFO_TRAIL_DURATION_ICON} />
              <BodyTextWithIcon>
                {/* TODO: i18n switch to m or km */}
                {tour.trailLength}m
              </BodyTextWithIcon>
            </IconWithTextContainer>
          )}

          {displayTimePeriod && tour.timePeriod && (
            <IconWithTextContainer>
              <LazyLoadImage width={32} src={TOUR_INFO_TIME_PERIOD_ICON} />
              <BodyTextWithIcon>
                {/* TODO: i18n handle AD etc. */}
                {tour.timePeriod}
              </BodyTextWithIcon>
            </IconWithTextContainer>
          )}

          {tour.audioDurationMinutes && (
            <IconWithTextContainer>
              <LazyLoadImage width={32} src={TOUR_INFO_AUDIO_DURATION_ICON} />
              <BodyTextWithIcon>
                {/* TODO: i18n automatically return in seconds and then convert to hrs, mins, secs */}
                {/* TODO: [LVR-3121] As a developer, I have imeplemented automatic music duration calculation so that an admin does not have to set it manually from the Console */}
                {tour.audioDurationMinutes} {t('minutes')}
              </BodyTextWithIcon>
            </IconWithTextContainer>
          )}

          <IconWithTextContainer>
            <LazyLoadImage width={32} src={TOUR_INFO_IMAGES_ICON} />
            <BodyTextWithIcon>
              {imagesCount} {t('images')}
            </BodyTextWithIcon>
          </IconWithTextContainer>

          {displayStreetView && (
            <IconWithTextContainer>
              <LazyLoadImage width={32} src={TOUR_INFO_STREET_VIEW_ICON} />
              <BodyTextWithIcon>{t('Street view')}</BodyTextWithIcon>
            </IconWithTextContainer>
          )}
        </Grid>
      </Section>
    );
  }
);

export function getImagesCount(
  viewpoints: Array<{ imagesCount: number }>
): number {
  return viewpoints.reduce(
    (accum, { imagesCount = 0 }) => accum + imagesCount,
    0
  );
}
