import Dinero from 'dinero.js';
import { CurrencyCode } from '../graphql/globalTypes';

type Product = {
  currencyCode: CurrencyCode;
  // in cents
  unitPrice: number;
  count: number;
  discountRate: number;
};

// this applies to both client and consumer purchases.
// the difference between client and consumer purchases is that
// there could be different discount rates per product for clients,
// where as it is same across all products for a user
export function getTotalAmount(products: Product[]): number {
  const total = products.reduce((accum, p) => {
    const fraction = (100 - p.discountRate) / 100;

    // prices are ints and in multiples of 100s, so we don't need to massage them.
    // const amount = getAmountInCents(p.price);
    const amount = p.unitPrice;
    const count = p.count || 1;

    // eslint-disable-next-line new-cap
    let amountTimesCount = Dinero({ amount })
      .multiply(fraction)
      .multiply(count);

    // refer to notes at the bottom of the page here:
    // https://developer.paypal.com/docs/reports/reference/paypal-supported-currencies/
    if (
      p.currencyCode === CurrencyCode.JPY ||
      p.currencyCode === CurrencyCode.HUF ||
      p.currencyCode === CurrencyCode.TWD
    ) {
      const roundedAmount =
        Math.floor(amountTimesCount.getAmount() / 100) * 100;

      // eslint-disable-next-line new-cap
      amountTimesCount = Dinero({ amount: roundedAmount });
    }

    // eslint-disable-next-line new-cap
    return Dinero({ amount: accum }).add(amountTimesCount).getAmount();
  }, 0);

  // PRICE IS NOT IN CENTS.
  // eslint-disable-next-line new-cap
  return Dinero({ amount: total }).toUnit();
}
