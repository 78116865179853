import isURL from 'validator/lib/isURL';
import { IEnv } from '../features/whitelabeling/branding/types';

export const getRedirectionURLOnTourExit = (appEnv: IEnv): string | null => {
  const redirectionURLOneTourExit = appEnv?.features?.redirectionURLOneTourExit;

  if (
    typeof redirectionURLOneTourExit === 'string' &&
    isURL(redirectionURLOneTourExit)
  ) {
    return redirectionURLOneTourExit;
  }

  return null;
};
