import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from './Asset';
import { I18NSTRING } from './I18nString';
import { PRE_TOUR_VIEWPOINT_FRAGMENT } from './PreTourViewpoint';

export const TOUR_VIEWPOINT_FRAGMENT = gql`
  fragment TourViewpointFragment on Viewpoint {
    ...PreTourViewpointFragment

    descriptionI18n {
      ...I18nStringFragment
    }
    descriptionLongI18n {
      ...I18nStringFragment
    }
    taglineI18n {
      ...I18nStringFragment
    }
    country {
      id
      name
    }
    rotation {
      y
    }
    timePeriod {
      id
      startYearUIFriendly
      endYearUIFriendly
    }
    timePeriodSpecific
    projectionLeft {
      ...AssetFragment
    }
    restriction
    thumbnail {
      ...AssetFragment
    }
    narrations(input: {}) {
      id
      language {
        code
        displayTextEnglish
      }
      gender
      narrationSource
      voiceTrackLengthInSeconds
      voiceTrack {
        ...AssetFragment
      }
      subtitlesI18n {
        en_US {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        ar {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        el_GR {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        es_ES {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        fr_FR {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        he_IL {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        hi_IN {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        it_IT {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        ja_JP {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        pt_PT {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        pl_PL {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        ru_RU {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        zh {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
      }
    }
    music {
      id
      length
      asset {
        ...AssetFragment
      }
    }
    images {
      id
      asset {
        ...AssetFragment
      }
      nameI18n {
        ...I18nStringFragment
      }
    }
    imagesCount
    geofence {
      ...AssetFragment
    }
    fallbackGeofenceRadius
  }

  ${ASSET_FRAGMENT}
  ${PRE_TOUR_VIEWPOINT_FRAGMENT}
  ${I18NSTRING}
`;
