import DownloadAppAssetsSWOnServiceWorkerActivation from './DownloadAppAssetsSWOnServiceWorkerActivation';
import useIsServiceWorkerActive from '../../../hooks/useIsServiceWorkerActive';

// Download the app assets using Serivice Worker postMessage
// if Service Worker is active.
export const DownloadAppAssetsSW = () => {
  const isServiceWorkerActive = useIsServiceWorkerActive();

  if (!isServiceWorkerActive) {
    return null;
  }

  // Download the app assets
  return <DownloadAppAssetsSWOnServiceWorkerActivation />;
};
