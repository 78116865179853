import React from 'react';
import AccountIcon from 'mdi-react/AccountIcon';
import GuestIcon from 'mdi-react/IncognitoIcon';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../Routes/routes';
import styled, { useTheme } from '../../style/styled';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { useTranslation } from 'react-i18next';
import { env } from '../../App/config/env';
import { useUser } from '../../hooks/useUser';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  showTextInMobile?: boolean;
}

export const MyTripsButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const title = env.IS_STOCK ? 'My tours' : 'My guides';

  const { isGuestUser } = useUser();

  const Icon = isGuestUser ? GuestIcon : AccountIcon;

  const TextWrapper = props.showTextInMobile
    ? AppHeaderButtonText
    : AppHeaderButtonTextMobileHidden;

  return (
    <AppHeaderButtonContainer
      fullWidth={fullWidth}
      onClick={() => {
        captureButtonPress({
          page: window.location.pathname,
          buttonName: 'MyTrips',
        });

        history.push(ROUTES.myTrips);
      }}
    >
      <Icon color={color} size={size * 1.4} />
      <TextWrapper
        size={size}
        color={color}
        bold={window.location.pathname === ROUTES.myTrips}
      >
        {t(title)}
      </TextWrapper>
    </AppHeaderButtonContainer>
  );
};

const AppHeaderButtonTextMobileHidden = styled(AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
