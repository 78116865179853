import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { CurrencyCode } from '../../../graphql/globalTypes';
import { setCurrencyCode } from '../../../store/app/actions';
import { selectShouldAutoUpdateCurrencyCode } from '../../../store/app/selectors';
import { CurrencySetMode } from '../../../store/app/types';
import { SUPPORTED_CURRENCIES } from '../consts';

const supportedCurrencyCodes = SUPPORTED_CURRENCIES.map(({ value }) => value);

export const CurrencyAutoSetter = () => {
  const dispatch = useDispatch();

  const shouldAutoUpdateCurrencyCode = useSelector(
    selectShouldAutoUpdateCurrencyCode
  );

  useEffect(() => {
    async function getIpInfo() {
      try {
        const response = await fetch('https://ipapi.co/json/');

        const data = await response.json();

        // extract the currency code from the payload
        const currencyCode = data.currency as CurrencyCode;

        // check if it is a supported currency code
        const supported = supportedCurrencyCodes.includes(currencyCode);

        // if supported, set it
        if (supported) {
          dispatch(
            setCurrencyCode({
              currencyCode,
              currencyCodeSetMode: CurrencySetMode.AUTOMATIC,
            })
          );
        }
      } catch (error: any) {
        captureInSentry('Could not auto fetch currencyCode', {
          error: error.message,
        });
      }
    }

    if (shouldAutoUpdateCurrencyCode) {
      getIpInfo();
    }
  }, [shouldAutoUpdateCurrencyCode, dispatch]);

  return null;
};
