import { useEffect } from 'react';
import { getQueryVariable } from '../../utils/getQueryVariable';
import { CodeKeys, PromoCode } from '../../types';
import { captureEvent, DataKeys, EventNames } from '../../features/analytics';
import { storeAffiliateCode } from '../../features/affiliateCodes/storeAffiliateCode';
import { useDispatch } from 'react-redux';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import { setPromoCode } from '../../store/app/actions';

export const AffiliateCodeExtractor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // IMPORTANT!
    // There are some related logic in the AutoLoginAsAnonymous component as well.
    const affiliateCode = getQueryVariable(CodeKeys.AFFILIATE_CODE);

    if (typeof affiliateCode === 'string') {
      storeAffiliateCode(affiliateCode);

      captureEvent({
        name: EventNames.AFFILIATE_CODE_EXTRACTED,
        data: [
          {
            key: DataKeys.AFFILIATE_CODE,
            value: affiliateCode,
          },
        ],
      });

      // temp handling of AHE
      if (affiliateCode === 'ZORJ9YT7') {
        const promoCode: PromoCode = {
          id: '6107a9e3fe1a10000638e167',
          code: 'WHE25',
          discountRate: 25,
          expired: false,
        };

        dispatch(setPromoCode(promoCode));

        dispatch(
          setModalType({
            modalType: ModalType.WHE_PROMO_CODE,
            modalData: {
              discountRate: promoCode.discountRate,
            },
          })
        );
      }
    }
  }, [dispatch]);

  return null;
};
