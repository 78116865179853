import { useEffect } from 'react';
import qs from 'query-string';
import { CodeKeys } from '../types';

import {
  EXPLORE_WEB_AuthAsAnonymous as Data,
  EXPLORE_WEB_AuthAsAnonymousVariables as Variables,
} from '../Routes/Auth/login/__generated__/EXPLORE_WEB_AuthAsAnonymous';
import { getStoredAffiliateCode } from '../features/affiliateCodes/getStoredAffiliateCode';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { UNKNOWN_ERROR } from '../consts';
import { toast } from 'react-toastify';
import { captureInSentry } from './config/reporting/captureInSentry';
import { getUserIDFromJWT } from '../utils/getUserIDFromJWT';
import { removeStoredAffiliateCode } from '../features/affiliateCodes/removeStoredAffiliateCode';
import { loginSuccess } from '../store/app/actions';
import { ROUTES } from '../Routes/routes';
import { AUTH_AS_ANONYMOUS_MUTATION } from '../Routes/Auth/login/ContinueAsGuest';
import { captureEvent, DataKeys, EventNames } from '../features/analytics';
import { selectIsAuthenticated } from '../store/app/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { env } from './config/env';
import { useHistory } from 'react-router-dom';
import { getQueryVariable } from '../utils/getQueryVariable';

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

// The reason why this is a component and not a hook is because we want to be able to conditionally render this.
const AutoLoginAsAnonymous = () => {
  const isAuthed = useSelector(selectIsAuthenticated);
  const history = useHistory();
  const dispatch = useDispatch();

  const parsedQs = qs.parse(history.location.search);

  // only used for AK - move to a hook
  const [authAsAnonymous] = useMutation<Data, Variables>(
    AUTH_AS_ANONYMOUS_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        const errorMessage = error?.message || UNKNOWN_ERROR;

        toast.error(errorMessage);
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry(
            'App.tsx AUTH_AS_ANONYMOUS_MUTATION result is null or undefined',
            { variables: getVariables() }
          );

          return;
        }

        const { jwt, error } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({
            name: EventNames.USER_REGISTERED,
            data: [{ key: DataKeys.SIGNUP_METHOD, value: 'Anonymous' }],
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          history.replace(
            `${ROUTES.myTrips}?${CodeKeys.REDEEM_CODE}=${
              parsedQs[CodeKeys.REDEEM_CODE]
            }`
          );
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          toast.error(errorMessage);
        }
      },
    }
  );

  const authenticateAsAnonymous = () => {
    const variables: Variables = {
      input: {},
    };

    const affiliateCode =
      getStoredAffiliateCode() || getQueryVariable(CodeKeys.AFFILIATE_CODE);

    if (affiliateCode) {
      variables.input.affiliateCode = affiliateCode;
    }

    setVariables(variables);

    authAsAnonymous({ variables });
  };

  useEffect(
    () => {
      if (env.IS_AK && !isAuthed && parsedQs[CodeKeys.REDEEM_CODE]) {
        authenticateAsAnonymous();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
};

export default AutoLoginAsAnonymous;
