import Cookies from 'js-cookie';
import { AFFILIATE_CODE_KEY } from '../../App/config/enums';

export function getStoredAffiliateCode() {
  const affiliateCode = window.localStorage
    ? localStorage.getItem(AFFILIATE_CODE_KEY)
    : Cookies.get(AFFILIATE_CODE_KEY);

  return affiliateCode;
}
