import { useEffect, useState } from 'react';
import qs from 'query-string';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ITheme } from '../features/whitelabeling/branding/types';
import { useBeforeFirstRender } from '../hooks/useBeforeFirstRender';
import AppWithTheme from './AppWithTheme';
import { CodeKeys } from '../types';
import {
  enableFulscreenLoadingOverlay,
  setWhitelabelAppSettings,
} from '../store/app/actions';
import { getAppConfig } from '../utils/getAppConfig';
import { setAppID } from '../utils/setAppID';
import { setAppEnv } from '../utils/setAppEnv';
import { getStoredAppID } from '../utils/getStoredAppID';
import RemoteAppContext, {
  Data,
  Variables,
  GET_APP_QUERY,
} from '../context/remoteAppContext';
import { logMessage } from '../features/logging/logMessage';

type Config = {
  theme: ITheme;
  stylesheet: string;
};

type Props = {
  appID: string;
};

const AppWithRemoteConfig = ({ appID }: Props) => {
  const dispatch = useDispatch();

  // Display fullscreen loading overlay on top the entire app,
  // when we are entering with a viewpoint activation link.
  // The activation will occur under the hood,
  // and as soon as we enter the viewpoint,
  // we dispatch disableFulscreenLoadingOverlay()
  useBeforeFirstRender(() => {
    const parsedSearch = qs.parse(window.location.search);
    if (
      parsedSearch?.[CodeKeys.REDEEM_CODE] &&
      window.location.pathname.includes('viewpoint')
    ) {
      logMessage('Detected viewpoint activation link');

      dispatch(enableFulscreenLoadingOverlay());
    }
  });

  const [config, setConfig] = useState<Config | null>(null);
  const { data, loading } = useQuery<Data, Variables>(GET_APP_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: { input: { appID } },
  });

  const appData = data?.result;

  // TODO: [LVR-2585]
  useEffect(() => {
    // if the app is invalid, restore the app using stored appOD
    if (!loading && !appData) {
      const storedAppID = getStoredAppID();

      if (storedAppID) {
        const urlObj = new URL(window.location.href);
        const newUrl = `${urlObj.protocol}//${urlObj.host}/${getStoredAppID()}`;

        logMessage('Invalid app detected, loading stored app:', newUrl);

        window.location.href = newUrl;
      } else {
        logMessage(
          'Invalid app detected, no stored app found. No app to load.'
        );
      }
    }

    // doesn't matter if it's still fetching from the network or not,
    // if there is appData, load the config, setAppID and setAppEnv
    else if (appData) {
      getAppConfig(appData).then(({ stylesheet, theme, settings }) => {
        setAppID(appData.appID);

        // #DEPRECATED
        setAppEnv(settings);

        dispatch(setWhitelabelAppSettings(settings));

        // Make sure this is the last call in this hook because otherwise the language reset logic doesn't work
        setConfig({ stylesheet, theme });
      });
    }
  }, [appData, dispatch, loading]);

  // loading spinner similar to the one in the index.html (bouncing logo)
  if (!config) {
    return (
      <div className="full-page-loader-container">
        <div>
          <img src="/apple-touch-icon.png" alt="" />
        </div>
      </div>
    );
  }

  return (
    <RemoteAppContext.Provider value={{ appData }}>
      <AppWithTheme stylesheetURL={config.stylesheet} theme={config.theme} />
    </RemoteAppContext.Provider>
  );
};

export default AppWithRemoteConfig;
