import React, { useState } from 'react';
import styled from '../style/styled';
import { Z_INDEX } from '../style/Z_INDEX';
import { SCREEN_WIDTH_BREAK_SMALL } from '../style/SIZES';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowDisclaimer } from '../store/app/selectors';
import {
  enableGoogleAnalytics,
  setCookieDisclaimerVisibility,
} from '../store/app/actions';
import { captureButtonPress } from '../features/analytics';
import { COLORS } from '../style/colors';
import { Trans, useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { getTrackingID } from '../App/config/googleAnalytics/setupGoogleAnalytics';
import { env } from '../App/config/env';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { Link } from 'react-router-dom';
import { ToggleSwitch } from './ToggleSwitch';

// Alert the users we are using cookies
export const CookieDisclaimer = () => {
  const showDisclaimer = useSelector(selectShowDisclaimer);
  const dispatch = useDispatch();

  if (!showDisclaimer) {
    return null;
  }

  const learnMore = () => {
    captureButtonPress({
      buttonName: 'CookieDisclaimer_LearnMore',
      page: window.location.pathname,
    });
  };

  const accept = () => {
    captureButtonPress({
      buttonName: 'CookieDisclaimer_AcceptCookies',
      page: window.location.pathname,
    });

    // Currently Google Analytics are enabled right from the start, so this does not have any impact.
    dispatch(enableGoogleAnalytics());

    dispatch(setCookieDisclaimerVisibility({ visibility: false }));

    if (env.ENABLE_LOG_ROCKET_REPORTING) {
      LogRocket.init(env.LOG_ROCKET_ID, { release: env.GIT_HASH });

      setupLogRocketReact(LogRocket);
    }

    // if (env.ENABLE_SENTRY_REPORTING) {
    //   init({
    //     dsn: env.SENTRY_DSN,
    //     release: env.GIT_HASH,
    //     environment: env.ENVIRONMENT,
    //     debug: !env.IS_RTM,
    //     ignoreErrors: [
    //       'TypeError: Failed to fetch',
    //       'TypeError: NetworkError when attempting to fetch resource.',
    //     ],
    //     integrations: [
    //       // new Integrations.BrowserTracing()
    //     ],
    //     tracesSampleRate: 1.0,

    //     // TODO: sanitize private info?
    //     // https://docs.sentry.io/platforms/javascript/configuration/filtering/
    //   });

    //   if (env.ENABLE_LOG_ROCKET_REPORTING) {
    //     // Integrate LogRocket with Sentry
    //     // refer: https://blog.logrocket.com/extending-sentry-with-logrocket-52e2f5b67d5a/
    //     configureScope((scope) => {
    //       scope.setExtra('sessionURL', LogRocket.sessionURL);
    //     });
    //   }
    // }
  };

  const decline = () => {
    Cookies.remove('_ga', { path: '/', domain: '.ancient-world.co' });
    Cookies.remove('_gid', { path: '/', domain: '.ancient-world.co' });
    Cookies.remove('_marketing', { path: '/', domain: '.ancient-world.co' });
    Cookies.remove(`_gat`, { path: '/', domain: '.ancient-world.co' });
    Cookies.remove(`_gat_${getTrackingID()}`, {
      path: '/',
      domain: '.ancient-world.co',
    });
    Cookies.remove(`_gat_UA-82330519-3`, {
      path: '/',
      domain: '.ancient-world.co',
    });

    Cookies.remove('_ga');
    Cookies.remove('_gid');
    Cookies.remove('_marketing');
    Cookies.remove('_gat');
    Cookies.remove(`_gat_${getTrackingID()}`);
    Cookies.remove(`_gat_UA-82330519-3`);

    dispatch(setCookieDisclaimerVisibility({ visibility: false }));
  };
  return (
    <CookieDisclaimerContent
      learnMore={learnMore}
      accept={accept}
      decline={decline}
    />
  );
};

export interface CookieDisclaimerContentProps {
  learnMore: () => void;
  accept: () => void;
  decline: () => void;
}

export const CookieDisclaimerContent: React.FC<CookieDisclaimerContentProps> = ({
  learnMore,
  accept,
  decline,
}) => {
  const { t } = useTranslation();
  const [optionsIsShown, setOptionsIsShown] = useState(false);
  const [statisticsChecked, setStatisticsChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const handleStatisticsChange = (
    nextChecked: React.SetStateAction<boolean>
  ) => {
    setStatisticsChecked(nextChecked);
  };

  const handleMarketingChange = (
    nextChecked: React.SetStateAction<boolean>
  ) => {
    setMarketingChecked(nextChecked);
  };
  const dispatch = useDispatch();

  return (
    <>
      {!optionsIsShown && (
        <Container data-testid="cookie-disclaimer-initial">
          <Label>
            {t(
              'We use cookies on this site to provide you with the best browser experience, analyse its traffic and show you relevant ads.'
            )}
            &nbsp;&nbsp;
            <Trans i18nKey={'See our privacy policy for more information.'}>
              See our
              <StyledLink to="/privacy_policy" target="_blank">
                privacy policy
              </StyledLink>
              for more information.
            </Trans>
            {/*{t('See our privacy policy for more information.')}*/}
          </Label>

          <Actions>
            <div
              role="button"
              onClick={() => {
                learnMore();
                setOptionsIsShown(true);
              }}
            >
              <ButtonText>{t('More options')}</ButtonText>
            </div>

            <div
              role="button"
              onClick={() => {
                accept();
              }}
            >
              <ButtonText>{t('OK')}</ButtonText>
            </div>
          </Actions>
        </Container>
      )}

      {optionsIsShown && (
        <Container data-testid="cookie-disclaimer-options">
          <LabelTitle>
            {t('Statistics')}
            <ToggleSwitch
              handleCheckChange={handleStatisticsChange}
              selected={statisticsChecked}
              toggleSwitchName="Cookie-Statistics-Toggle"
            />
          </LabelTitle>
          <Label>
            {t(
              'I want to help you make this site better so I will provide you with anonymous data about my use of this site.'
            )}
          </Label>

          <LabelTitle>
            {t('Marketing')}
            <ToggleSwitch
              handleCheckChange={handleMarketingChange}
              selected={marketingChecked}
              toggleSwitchName="Cookie-Marketing-Toggle"
            />
          </LabelTitle>
          <Label>
            {t(
              'I want to see ads with your offers, coupons and exclusive deals rather than random ads from other advertisers.'
            )}
          </Label>

          <Actions>
            <div style={{ color: '#c4c4c4' }} onClick={decline}>
              {t('Decline')}
            </div>
            <div
              onClick={() => {
                if (statisticsChecked) {
                  dispatch(enableGoogleAnalytics());
                  if (env.ENABLE_LOG_ROCKET_REPORTING) {
                    LogRocket.init(env.LOG_ROCKET_ID, {
                      release: env.GIT_HASH,
                    });
                    setupLogRocketReact(LogRocket);
                  }
                }

                if (marketingChecked) {
                  Cookies.set('_marketing', 'subscribed');
                }
                dispatch(setCookieDisclaimerVisibility({ visibility: false }));
              }}
            >
              <ButtonText>{t('Agree to selected')}</ButtonText>
            </div>
          </Actions>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  z-index: ${Z_INDEX.REACT_TOAST_CONTAINER};
  position: fixed;
  bottom: 10px;
  left: 10px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  border-radius: 3px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  min-width: 344px;
  background-color: #202124;
  justify-content: flex-start;
  text-rendering: optimizeSpeed;
  padding-bottom: 8px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    max-width: 100%;
    min-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
`;

const Label = styled.div`
  color: ${COLORS.lightTextColor};
  padding: 14px 16px;
  line-height: 150%;
`;

const LabelTitle = styled.div`
  color: ${COLORS.lightTextColor};
  padding: 14px 16px;
  line-height: 150%;
  font-size: 20px;

  > label {
    vertical-align: middle;
    margin-left: 20px;
  }
`;

const Actions = styled.div`
  align-self: flex-end;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #3fc4ff;
  letter-spacing: 1px;

  div {
    cursor: pointer;
    padding: 4px 12px;
    margin-left: 8px;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s ease-in-out;
    background: #77777700;

    &:hover {
      background: #77777750;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #3fc4ff;
`;

const ButtonText = styled.span`
  color: #3fc4ff;
`;
