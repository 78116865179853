import { LanguageCode } from './../../../graphql/globalTypes';
import {
  EXPLORE_WEB_GetFullTour_result_narrationConfig,
  EXPLORE_WEB_GetFullTour_result_viewpoints_narrations,
} from '../../../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';
import { FullViewpoint } from '../../../graphql/queries/GetFullTourQuery';
import { languages } from '../../../consts';

interface Args {
  viewpoint: FullViewpoint;
  overridingNarrationConfig:
    | EXPLORE_WEB_GetFullTour_result_narrationConfig
    | null
    | undefined;
  narrationLanguage: LanguageCode;
}

export function resolvePreferredNarration({
  viewpoint,
  overridingNarrationConfig,
  narrationLanguage,
}: Args):
  | EXPLORE_WEB_GetFullTour_result_viewpoints_narrations
  | null
  | undefined {
  if (
    !viewpoint ||
    !Array.isArray(viewpoint.narrations) ||
    viewpoint.narrations.length === 0
  ) {
    return null;
  }

  const code = languages[narrationLanguage]?.code;

  // by default, pick the English track in this order
  const narration = viewpoint.narrations.find(
    (n) =>
      n.language.code === code &&
      n.narrationSource === 'PROFESSIONALLY_RECORDED'
  );

  // if the playlist doesn't override the default narration config, return whatever was returned by the find call. it can be null.
  if (!overridingNarrationConfig) {
    return narration;
  }

  const { languageCode, narrationSource, gender } = overridingNarrationConfig;

  let options = viewpoint.narrations.slice(0);

  if (languageCode) {
    const languageCodeFromGQLEnum = languageCode.replace('_', '-');
    options = options.filter(
      (narration: any) => narration.language.code === languageCodeFromGQLEnum
    );
  }

  if (narrationSource) {
    options = options.filter(
      (narration: any) => narration.narrationSource === narrationSource
    );
  }

  if (gender) {
    options.filter((narration: any) => narration.gender === gender);
  }

  if (options.length > 0) {
    return options[0];
  }

  return narration;
}
