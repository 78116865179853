import { loadStripe } from '@stripe/stripe-js/pure';
import { env } from '../App/config/env';

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY!);

function getStripePromise() {
  return stripePromise;
}

export default getStripePromise;
