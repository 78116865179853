import { CurrencyCode } from '../../graphql/globalTypes';

export const CURRENCY_SYMBOLS = {
  [CurrencyCode.USD]: 'US$',
  [CurrencyCode.AUD]: 'AU$',
  [CurrencyCode.BRL]: 'R$',
  [CurrencyCode.CAD]: 'CA$',
  [CurrencyCode.CHF]: 'CHF',
  [CurrencyCode.CNY]: '¥',
  [CurrencyCode.CZK]: 'Kč',
  [CurrencyCode.DKK]: 'kr',
  [CurrencyCode.EUR]: '€',
  [CurrencyCode.GBP]: '£',
  [CurrencyCode.HKD]: 'HK$',
  [CurrencyCode.HUF]: 'Ft',
  [CurrencyCode.ILS]: '₪',
  [CurrencyCode.JPY]: '¥',
  [CurrencyCode.MXN]: '$',
  [CurrencyCode.MYR]: 'RM',
  [CurrencyCode.NOK]: 'kr',
  [CurrencyCode.NZD]: 'NZ$',
  [CurrencyCode.PHP]: '₱',
  [CurrencyCode.PLN]: 'zł',
  [CurrencyCode.RUB]: '₽',
  [CurrencyCode.SEK]: 'kr',
  [CurrencyCode.SGD]: 'S$',
  [CurrencyCode.THB]: '฿',
  [CurrencyCode.TWD]: 'NT$',
  [CurrencyCode.USD]: 'US$',
};

// If updating this, might need to update getDifferenceAmount function to support currencies without minor units.
export const SUPPORTED_CURRENCIES = [
  {
    value: CurrencyCode.AUD,
    label: 'Australian Dollar',
    symbol: CURRENCY_SYMBOLS[CurrencyCode.AUD],
  },
  {
    value: CurrencyCode.EUR,
    label: 'Euro',
    symbol: CURRENCY_SYMBOLS[CurrencyCode.EUR],
  },
  {
    value: CurrencyCode.GBP,
    label: 'Sterling Pounds',
    symbol: CURRENCY_SYMBOLS[CurrencyCode.GBP],
  },
  {
    value: CurrencyCode.USD,
    label: 'United State Dollar',
    symbol: CURRENCY_SYMBOLS[CurrencyCode.USD],
  },
];
