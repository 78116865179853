import * as Sentry from '@sentry/react';
import { env } from '../env';
import Cookies from 'js-cookie';
import { from, ApolloClient, InMemoryCache } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { HttpLink } from '@apollo/client/link/http';
import { createHeadersLink } from './links/createHeadersLink';
import { handleApolloErrorLink } from './links/handleApolloErrorLink';
import { CachePersistor } from 'apollo3-cache-persist';
import indexedDBStorage from '../../../utils/indexedDBStorage';
import { captureInSentry } from '../reporting/captureInSentry';
import { logMessage } from '../../../features/logging/logMessage';

export let _client: ApolloClient<any>;
export let _apolloCachePersitor: CachePersistor<any>;

const createApolloClient = async (): Promise<ApolloClient<any>> => {
  const cache = new InMemoryCache();

  // restore apollo cache from disk - start

  if (env.ENABLE_APOLLO_PERSIST) {
    _apolloCachePersitor = new CachePersistor({
      cache,
      storage: indexedDBStorage,
      key: env.APOLLO_PERSIST_KEY,
      maxSize: false,
      debug: true,
    });

    const currentVersion = window.localStorage
      ? window.localStorage.getItem(
          env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY
        )
      : Cookies.get(env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY);

    try {
      if (currentVersion === env.APOLLO_PERSIST_VERSION) {
        logMessage('Apollo cache restored from disk');

        await _apolloCachePersitor.restore();
      } else {
        logMessage('Deprecated apollo cache found - purged from disk');

        await _apolloCachePersitor.purge();

        if (window.localStorage) {
          window.localStorage.setItem(
            env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY,
            env.APOLLO_PERSIST_VERSION
          );
        } else {
          Cookies.set(
            env.APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY,
            env.APOLLO_PERSIST_VERSION
          );
        }
      }
    } catch (error: any) {
      Sentry.captureException(error);
    }
  }
  // restore apollo cache from disk - end

  // been getting a ncaught Error: The error you provided does not contain a stack trace.
  // https://github.com/apollographql/apollo-client/issues/6769
  const fetchOptions: any = {};

  if (window.AbortController) {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchOptions.signal = signal;
  }

  const httpLink = new HttpLink({
    uri: env.API_URI,
    fetchOptions,
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => {
        // We don't want to retry if there is there is an authentication error.
        // handleApolloErrorLink will log the user out if there is an authentication error on the first occurrence.
        return error?.statusCode !== 401;
      },
    },
  });

  const authLink = createHeadersLink();

  _client = new ApolloClient<any>({
    link: from([handleApolloErrorLink, retryLink, authLink, httpLink]),
    cache,
    connectToDevTools: true,
  });

  return _client;
};

export const getApolloClient = async (): Promise<ApolloClient<any>> => {
  if (_client) {
    return _client;
  }

  return createApolloClient();
};

export const resetApolloCache = async () => {
  if (_apolloCachePersitor) {
    _apolloCachePersitor.pause();

    try {
      await _apolloCachePersitor.purge();
    } catch (error: any) {
      captureInSentry(
        `ApolloClient.ts resetApolloCache() _apolloCachePersitor.purge() error: ${
          error?.message || 'Unkown'
        }`
      );
    }
  }

  try {
    // DO NOT use _client.clearStore. resetStore will refetch the pending queries.
    // Difference: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.resetStore
    // vs. https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.clearStore
    await _client.resetStore();
    // await _apolloCachePersitor.purge();
  } catch (error: any) {
    captureInSentry(
      `ApolloClient.ts resetApolloCache() _client.clearStore() error: ${
        error?.message || 'Unknown'
      }`
    );
  }

  if (_apolloCachePersitor) {
    try {
      await _apolloCachePersitor.persist();
    } catch (error: any) {
      captureInSentry(
        `ApolloClient.ts resetApolloCache() _apolloCachePersitor.persist() error: ${
          error?.message || 'Unknown'
        }`
      );
    }

    _apolloCachePersitor.resume();
  }
};
