import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_SignUpWIthEmailMutation,
  EXPLORE_WEB_SignUpWIthEmailMutationVariables,
} from './__generated__/EXPLORE_WEB_SignUpWIthEmailMutation';

export type Data = EXPLORE_WEB_SignUpWIthEmailMutation;
export type Variables = EXPLORE_WEB_SignUpWIthEmailMutationVariables;

export const SIGNUP_MUTATION = gql`
  mutation EXPLORE_WEB_SignUpWIthEmailMutation($input: SignUpWithEmailInput!) {
    result: signUpWithEmail(input: $input) {
      created
      jwt
      error {
        id
        message
        type
      }
    }
  }
`;
