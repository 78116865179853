import { v4 as uuidv4 } from 'uuid';
import { DataKeys, EventNames } from './types';
import { captureEvent } from './captureEvent';
import {
  browserName,
  browserVersion,
  deviceType,
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';

let _sessionID: string | null = null;
let _sessionEventSubmitted = false;

export function createOrGetSession(submitEvent = false) {
  _sessionID = _sessionID || uuidv4();

  if (_sessionEventSubmitted) {
    return _sessionID;
  }

  if (submitEvent) {
    _sessionEventSubmitted = true;

    const data = [];

    if (mobileVendor) {
      data.push({
        key: DataKeys.DEVICE_VENDOR,
        value: mobileVendor,
      });
    }

    if (mobileModel) {
      data.push({
        key: DataKeys.DEVICE_MODEL,
        value: mobileModel,
      });
    }

    if (deviceType) {
      data.push({
        key: DataKeys.DEVICE_TYPE,
        value: deviceType,
      });
    }

    if (osName) {
      data.push({
        key: DataKeys.OS,
        value: osName,
      });
    }

    if (osVersion) {
      data.push({
        key: DataKeys.OS_VERSION,
        value: osVersion,
      });
    }

    if (browserName) {
      data.push({
        key: DataKeys.BROWSWE_NAME,
        value: browserName,
      });
    }

    if (browserVersion) {
      data.push({
        key: DataKeys.BROWSWE_VERSION,
        value: browserVersion,
      });
    }

    captureEvent({
      name: EventNames.SESSION_START,
      data,
    });
  }

  return _sessionID;
}
