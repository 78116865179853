import {
  updateNarrationSeekPos,
  setAudioStatePartial,
} from '../../store/viewer/actions';
import { logMessage } from '../logging/logMessage';

interface Sound {
  url: string;
}

interface PlayNarrationArgs {
  sound: Sound;
  length: number;
  play?: boolean;
  loop?: boolean;
  html5?: boolean;
}

type SeekArgs = {
  value: number;
};

interface SoundInstance {
  audio: HTMLAudioElement;
  sound: Sound;
}

type Dispatcher = (payload: any) => any;

type Options = {
  getDispatch: (() => Dispatcher) | null;
};

export class AudioPlayerNative {
  private _currentSound: SoundInstance | null = null;
  private _queuedPlayNarrationArgs: PlayNarrationArgs | null = null;
  private _getDispatch: (() => Dispatcher) | null;
  private _stepTimerID: number = -1;
  private _loadedCheckTimerID: number = -1;
  private _systemPaused = false;
  private _isLoadingNewSound = false;
  private _length = 0;

  constructor({ getDispatch }: Options) {
    this._getDispatch = getDispatch;
  }

  get dispatch(): Dispatcher {
    let dispatcher;

    if (this._getDispatch) {
      dispatcher = this._getDispatch();
    } else {
      dispatcher = () => {};
    }

    return dispatcher;
  }

  isPlaying = () =>
    this._currentSound ? !this._currentSound.audio.paused : false;

  playNarration = ({ sound, play, loop, length, html5 }: PlayNarrationArgs) => {
    if (this._queuedPlayNarrationArgs?.sound.url === sound.url) {
      logMessage('AudioPlayerNative: Audio is already queued');
      return;
    }

    if (
      this._currentSound &&
      this._currentSound.sound.url === sound.url &&
      this.isPlaying() === play
    ) {
      logMessage('AudioPlayerNative: Audio is already playing');
      return;
    }

    if (this._currentSound && this._currentSound.sound.url === sound.url) {
      logMessage('AudioPlayerNative: Pausing or resuming audio');
      play ? this.resumeNarration() : this.pauseNarration();
      return;
    }

    if (this._isLoadingNewSound) {
      logMessage(
        'AudioPlayerNative: Audio is already loading, so queueing the new audio'
      );
      this._queuedPlayNarrationArgs = { sound, play, length, loop, html5 };
      return;
    }

    logMessage(
      'AudioPlayerNative: Attempting to stop any currently playing audio'
    );
    this.stop();

    this._isLoadingNewSound = true;
    logMessage('AudioPlayerNative: Attempting to play new audio');
    this.playNewSound({ sound, play, length, loop, html5 });
  };

  playNewSound = ({
    sound,
    play = true,
    length,
    loop = false,
    html5 = true,
  }: PlayNarrationArgs) => {
    this._length = length;

    this.dispatch(
      setAudioStatePartial({
        seekPos: 0,
        length,
        error: null,
        loading: true,
        ready: false,
        playing: false,
        started: false,
        completed: false,
      })
    );

    const audio = new Audio(sound.url);
    audio.loop = loop;

    audio.addEventListener('loadedmetadata', this.handleLoaded);

    audio.addEventListener('ended', this.handleEnded);

    audio.addEventListener('pause', this.handlePause);

    audio.addEventListener('play', this.handlePlay);

    audio.addEventListener('error', this.handleError);

    this._currentSound = { audio, sound };
    logMessage('AudioPlayerNative: Current sound set:', !!this._currentSound);
    logMessage('AudioPlayerNative: Play:', play);

    if (play) {
      logMessage('AudioPlayerNative: Attempting to play audio');
      audio.play().catch((error) => {
        console.error('Play error:', error);
      });
    }

    logMessage('AudioPlayerNative: Removing the lock');
    this._isLoadingNewSound = false;

    if (this._queuedPlayNarrationArgs) {
      logMessage('AudioPlayerNative: Attempting to play queued audio');
      this.playNewSound(this._queuedPlayNarrationArgs);
      this._queuedPlayNarrationArgs = null;
    }
  };

  handleLoaded = () => {
    logMessage('AudioPlayerNative: Audio loaded');
    window.clearTimeout(this._loadedCheckTimerID);

    if (this._currentSound) {
      let duration = this._currentSound.audio.duration;
      if (duration === Infinity) {
        duration = this._length;
      }
      logMessage('AudioPlayerNative: Duration:', duration);
      this.dispatch(
        setAudioStatePartial({
          loading: false,
          length: Math.floor(duration),
          ready: true,
        })
      );
      logMessage('AudioPlayerNative: Audio loading complete state updated');
    } else {
      logMessage('AudioPlayerNative: No current audio to handle loaded event');
    }
  };

  pauseNarration = (bySystem = false) => {
    if (this._currentSound) {
      this._systemPaused = bySystem;
      const { audio } = this._currentSound;
      logMessage('AudioPlayerNative: Attempting to pause audio on API');
      audio.pause();
    } else {
      logMessage('AudioPlayerNative: No current audio to pause');
    }
  };

  resumeNarration = () => {
    if (!this._currentSound) {
      logMessage('AudioPlayerNative: No current audio to resume');
      return;
    }

    const { audio } = this._currentSound;
    logMessage('AudioPlayerNative: Attempting to resume audio on API');
    audio.play().catch((error) => {
      console.error('Play error:', error);
    });
    logMessage('AudioPlayerNative: Audio should have resumed');
  };

  restartNarration = () => {
    if (!this._currentSound) {
      logMessage('AudioPlayerNative: No current audio to restart');
      return;
    }

    const { audio } = this._currentSound;
    logMessage('AudioPlayerNative: Attempting to resume audio on API');
    audio.currentTime = 0;
    audio.play().catch((error) => {
      console.error('Play error:', error);
    });
    logMessage('AudioPlayerNative: Audio should have restarted');
  };

  stop = () => {
    logMessage('AudioPlayerNative: Attempting to stop audio');
    const current = this._currentSound;
    this._currentSound = null;
    window.clearTimeout(this._loadedCheckTimerID);
    window.clearTimeout(this._stepTimerID);

    if (!current) {
      logMessage('AudioPlayerNative: No current audio to stop');
      return;
    }

    // Remove event listeners
    current.audio.removeEventListener('loadedmetadata', this.handleLoaded);
    current.audio.removeEventListener('ended', this.handleEnded);
    current.audio.removeEventListener('pause', this.handlePause);
    current.audio.removeEventListener('play', this.handlePlay);
    current.audio.removeEventListener('error', this.handleError);

    // Stop and reset the audio
    current.audio.pause();
    current.audio.currentTime = 0;
  };

  step = () => {
    if (!this._currentSound) {
      logMessage('AudioPlayerNative: No current audio to step');
      return;
    }

    const seekPos = this._currentSound.audio.currentTime;
    this.dispatch(updateNarrationSeekPos({ seek: seekPos }));
    const isPlaying = !this._currentSound.audio.paused;

    if (isPlaying) {
      this._stepTimerID = window.setTimeout(this.step, 500);
    }
  };

  seek = ({ value }: SeekArgs) => {
    logMessage('AudioPlayerNative: Seeking to:', value);

    if (!this._currentSound) {
      return;
    }

    this._currentSound.audio.currentTime = value;
    this.dispatch(updateNarrationSeekPos({ seek: value }));
  };

  handleEnded = () => {
    logMessage('AudioPlayerNative: Audio ended');

    // This is set the seek position to 0 when the audio ends.
    if (this._currentSound) {
      this._currentSound.audio.currentTime = 0;
    }

    this.dispatch(
      setAudioStatePartial({
        playing: false,
        completed: true,
        started: false,
        seekPos: 0,
      })
    );

    logMessage('AudioPlayerNative: Audio ended state updated');
  };

  handlePause = () => {
    logMessage('AudioPlayerNative: Audio paused event');
    const payload = {
      playing: false,
      pausedByTheSystem: this._systemPaused,
      // This is causing issues!
      // pausedByUser: !this._systemPaused,
    };
    this.dispatch(setAudioStatePartial(payload));
    logMessage('AudioPlayerNative: Pause audio action dispatched');
  };

  handlePlay = () => {
    logMessage('AudioPlayerNative: Audio play event');
    this.dispatch(
      setAudioStatePartial({
        loading: false,
        started: true,
        playing: true,
        pausedByUser: false,
        pausedByTheSystem: false,
        completed: false,
        error: null,
      })
    );

    window.clearTimeout(this._stepTimerID);
    this._stepTimerID = window.setTimeout(() => {
      this.step();
    }, 500);
  };

  handleError = (event: Event) => {
    logMessage('AudioPlayerNative: playerror event:', event);
    this.dispatch(
      setAudioStatePartial({
        loading: false,
        playing: false,
        started: false,
        pausedByUser: false,
        pausedByTheSystem: false,
        completed: false,
        error: event,
      })
    );
  };
}
