import React from 'react';
import { DeviceFingerprintContext } from '../context/deviceFingerprintContext';

type Props = {
  children: React.ReactNode;
  fingerprint: string | null;
  fingerprintError: any;
};

const DeviceFingerprintProvider = ({
  children,
  fingerprint,
  fingerprintError,
}: Props) => {
  return (
    <DeviceFingerprintContext.Provider
      value={{
        fingerprint,
        fingerprintError,
      }}
    >
      {children}
    </DeviceFingerprintContext.Provider>
  );
};

export default DeviceFingerprintProvider;
