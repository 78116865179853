import queryString from 'query-string';

// remove the specified query variable from query string
export function removeQueryVariables(
  variables: Array<string>,
  url: boolean = false
): string {
  const parsed = queryString.parse(window.location.search);

  // remove the specified variables from the query string
  variables.forEach((variable) => {
    delete parsed[variable];
  });

  // return it combined with the current pathname
  if (url) {
    return queryString.stringifyUrl({
      url: window.location.toString().replace(window.location.search, ''),
      query: parsed,
    });
  }

  // return just the modified query string
  return queryString.stringify(parsed);
}
