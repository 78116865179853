import { Helmet } from 'react-helmet';
import React from 'react';
import { env } from '../App/config/env';
import { getCDNURI } from '../utils/getCDNURI';
import { BANNER_IMAGE } from '../features/offline/appAssets';

interface Props {
  stylesheetURL?: string;
}

export const LithodomosGuideHelmet: React.FC<Props> = ({ stylesheetURL }) => {
  const title = 'Lithodomos Guide';
  const description = 'Lithodomos Guide is the ';
  const url = `https://guide.lithodomos.com/${env.APP_ID}`;
  const thumbnail = getCDNURI(BANNER_IMAGE);

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Virtual, Tourism, Tours, VR, AR, 360 prominence, Heritage, Cultural Tourism"
      />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:url" content={url} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={thumbnail} />

      {stylesheetURL && (
        <link rel="stylesheet" type="text/css" href={stylesheetURL} />
      )}
    </Helmet>
  );
};
