import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RotateOrbitIcon from 'mdi-react/RotateOrbitIcon';
import styled from '../../../style/styled';
import { selectViewerOptionIsGyroEnabled } from '../../../store/viewer/selectors';
import { CentreH } from '../../../styledComponents/CentreH';
import { Button } from '../../../components/Button';
import { setViewerOptions } from '../../../store/viewer/actions';
import { HOLD_PHONE } from '../../../features/offline/appAssets';
import { getCDNURI } from '../../../utils/getCDNURI';

// Alert to the user
// - if the gyroscope is enabled, they need to tilt the phone correctly to get an accurate reading
// - if the gyroscope is not enabled, how to enable it
const OrientationAlert = () => {
  const dispatch = useDispatch();
  const isGyroEnabled = useSelector(selectViewerOptionIsGyroEnabled);
  const { t } = useTranslation();

  if (isGyroEnabled) {
    return (
      <ModalContent>
        <Title>{t('Improve compass reading')}</Title>

        <Content>
          <ImageContainer>
            <img
              src={getCDNURI(HOLD_PHONE)}
              alt=""
              width="200px"
              height="200px"
            />
          </ImageContainer>

          <TextContainer>
            <Text>
              {t(
                'Slightly tilt your device backwards to get the best compass reading.'
              )}
            </Text>

            <Text>
              {t(
                'You can recalibrate your device at any time by tilting and moving it in a figure-8 pattern to improve accuracy.'
              )}
            </Text>
          </TextContainer>
        </Content>
      </ModalContent>
    );
  }

  return (
    <ModalContent>
      <Title>YOU ARE NOT USING THE GYROSCOPE</Title>

      <Content>
        <ImageContainer>
          {/* <img src={HOLD_PHONE} alt="" width="200px" height="200px" /> */}

          <RotateOrbitIcon size={200} />
        </ImageContainer>

        <TextContainer>
          <Text>
            The compass indicator only works when the gyroscope function is
            enabled.
          </Text>

          <Text>
            Press the <strong>Enable gyrscope</strong> button below to enable
            it. You can toggle the gyroscope function from the tour menu as
            well.
          </Text>

          <Button
            onClick={() => {
              dispatch(
                setViewerOptions({
                  options: { isGyroEnabled: true, isAutoRotating: false },
                })
              );
            }}
          >
            Enable gyroscope
          </Button>
        </TextContainer>
      </Content>
    </ModalContent>
  );
};

export default OrientationAlert;

const ModalContent = styled(CentreH)`
  max-width: 600px;
  padding-top: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media (orientation: landscape) {
    flex-direction: row;
  }
`;

const ImageContainer = styled(CentreH)`
  margin-bottom: 12px;
  width: 50%;
`;

const TextContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (orientation: landscape) {
    text-align: left;
    width: 50%;
  }
`;

const Title = styled.h3`
  margin-bottom: 12px;
  text-align: center;
  text-transform: uppercase;
`;

const Text = styled.p`
  margin-bottom: 16px;
`;
