import { env } from '../../App/config/env';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { logMessage } from '../../features/logging/logMessage';
import getErrorMessage from '../../utils/getErrorMessage';
import { getOldReduxPersistStorage } from './getOldReduxPersistStorage';
import { getReduxPersistStorage } from './getReduxPersistStorage';

export async function migratePersistedStateToNewStorage() {
  logMessage('Attempting to migrate persisted state to new storage.');

  const srcStorage = getOldReduxPersistStorage();
  const destStorage = getReduxPersistStorage();

  // Assuming you are using redux-persist with a single key 'root'
  const key = `persist:${env.REDUX_PERSIST_KEY}`;

  try {
    // Read from the old storage
    const existingState = await srcStorage.getItem(key);

    logMessage('Existing state found:', !!existingState);

    if (existingState) {
      // Write to the new storage
      await destStorage.setItem(key, existingState);

      logMessage('Migration to new storage successful.');

      // Clear the old storage
      await srcStorage.removeItem(key);
    }
  } catch (error: any) {
    logMessage('Migration to new storage failed:', error);

    captureInSentry(`Migration to new storage failed`, {
      error: getErrorMessage(error),
    });
  }
}
