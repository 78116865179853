import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { Button } from '../../../components/Button';
import { TextContainer } from '../../../styledComponents/TextContainer';
import { Paragraph } from '../../../styledComponents/Paragraph';
import { useTranslation } from 'react-i18next';

interface Props {
  onExit?: () => void;
}

export const ForgotPasswordSuccessScreen = ({ onExit }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <TextContainer>
        <Paragraph>
          {t(
            'Please check your inbox for instructions on how to reset your password.'
          )}
        </Paragraph>

        <Paragraph>
          {t(
            "If you don't receive an email in the next 10 minutes, please check your spam box."
          )}
        </Paragraph>
      </TextContainer>

      <Button
        data-testid="password-reset-success-close-button"
        width="100%"
        onClick={() => {
          onExit ? onExit() : history.replace(ROUTES.index);
        }}
      >
        {t('Close')}
      </Button>
    </div>
  );
};
