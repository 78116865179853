import 'ol/ol.css';
import './OLMap/Map.css';
import React from 'react';
import memoizeOne from 'memoize-one';
import equals from 'fast-deep-equal';
import { Extent, getCenter } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import Projection from 'ol/proj/Projection';
import { Coordinate } from 'ol/coordinate';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { FeatureLike } from 'ol/Feature';
import Style, { StyleLike } from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import { Options } from 'ol/layer/BaseVector';
import Map from './OLMap/Map';
import Controls from './OLMap/Controls/Controls';
import Layers from './OLMap/Layers/Layers';
// import ImageLayer from './OLMap/Layers/ImageLayer';
// import imageStatic from './OLMap/Source/imageStatic';
import UserPosition from './OLMap/Geolocation/UserPosition';
import VectorLayer from './OLMap/Layers/VectorLayer';
import vector from './OLMap/Source/vector';
import Markers from './OLMap/Marker/Markers';
import { SelectedFeature } from './types';
import { GeoJSONFileType } from '../../graphql/globalTypes';
// import Overlay from './OLMap/Overlay/Overlay';
import { calculateBoundOfPoints } from '../map/utils/calculateBoundOfPoints';
import { fromLonLat } from 'ol/proj';
import ZoomControl from './OLMap/Controls/ZoomControl';
// import { useRerendered } from '../../hooks/useRerendered';
import { METERS_PER_UNIT } from './consts';

type GeoJSONFile = {
  asset: {
    uri: string;
  };
  geoJSONFileType: GeoJSONFileType;
};

interface Props {
  defaultViewboxExtent: Extent | null;
  imageExtent: Extent;
  geoJSONFiles: GeoJSONFile[];
  enableRotation?: boolean;
  projectionCode: string;
  markers?: Array<SelectedFeature>;
  setSelectedFeature: (args: SelectedFeature | null) => void;
  selectedFeature: SelectedFeature | null;
  showUserPosition: boolean;
  withZoomControls?: boolean;
}

// TODO: [LVR-4729] As an offline map user, I should be shown how to zoom the map when I scroll over the map so that I know how to zoom the map
const VectorMap: React.FC<Props> = ({
  defaultViewboxExtent,
  imageExtent,
  projectionCode,
  geoJSONFiles,
  enableRotation = false,
  markers = [],
  setSelectedFeature,
  selectedFeature,
  showUserPosition,
  withZoomControls = false,
}) => {
  // useRerendered('VectorMap');

  const viewboxExtent =
    // use the default specified one from the backend
    defaultViewboxExtent ||
    // deteremine from markers
    memoizedGetMarkerBoundingExtents(
      overrideMarkers(markers),
      projectionCode
    ) ||
    // use whole site
    imageExtent;

  const center = getCenter(viewboxExtent);
  const zoom = 2;

  const projection = new Projection({
    code: projectionCode,
    extent: imageExtent,
    metersPerUnit: METERS_PER_UNIT[projectionCode],
  });

  const handleSingleClick = (event: MapBrowserEvent<UIEvent>) => {
    const { map, pixel } = event;

    let nextSelectedFeature: SelectedFeature | null = null;

    setSelectedFeature(null);

    map.forEachFeatureAtPixel(pixel, function (feature: any /*, layer: any */) {
      // if we've already found the correct feature
      if (nextSelectedFeature) {
        return;
      }

      const type = feature.get('type');
      const id = feature.get('id');
      const idx = feature.get('idx');
      const name = feature.get('name');
      const description = feature.get('description');
      const thumbnailURL = feature.get('thumbnailURL');
      const latitude = feature.get('latitude');
      const longitude = feature.get('longitude');

      if (
        type !== undefined &&
        id !== undefined &&
        idx !== undefined &&
        name !== undefined &&
        description !== undefined &&
        thumbnailURL !== undefined
      ) {
        nextSelectedFeature = {
          id,
          type,
          name,
          idx,
          description,
          thumbnailURL,
          position: [longitude, latitude],
        };
      }
    });

    setSelectedFeature(nextSelectedFeature);
  };

  return (
    <Map
      viewOptions={{
        center,
        zoom,
        projection,
        // maxZoom: 4,
        // minZoom: 2,

        // set extent to restrict to bounds
        extent: imageExtent,

        // prevent rotation
        enableRotation,
      }}
      fitExtent={viewboxExtent}
      onSingleClick={handleSingleClick}
    >
      <Layers>
        {memoizeGetGeoJSONLayers(geoJSONFiles).map(
          (layerOptions: Options<any>, idx: number) => (
            <VectorLayer key={idx} layerOptions={layerOptions} />
          )
        )}

        {/* We attmpted to create one big image, but the quality waasn't good so we ditched it and moved to GeoJSON vector layers */}
        {/* <ImageLayer
          source={imageStatic({
            url: imageURL,
            imageSize,
            projection,
            imageExtent,
            imageSmoothing: true,
          })}
        /> */}
      </Layers>

      <Controls>
        {/* <FullScreenControl /> */}
        {withZoomControls && <ZoomControl />}
      </Controls>

      {showUserPosition && <UserPosition />}

      <Markers
        markers={overrideMarkers(markers)}
        selectedFeature={selectedFeature}
      />
    </Map>
  );
};

export default VectorMap;

const memoizedGetMarkerBoundingExtents = memoizeOne(
  getMarkerBoundingExtents,
  equals
);

function getMarkerBoundingExtents(
  markers: Array<SelectedFeature>,
  projection: string
): Extent | null {
  if (markers.length < 0) {
    return null;
  }

  const points = markers.map((m) => ({
    lng: m.position[0],
    lat: m.position[1],
  }));

  const [topLeft, bottomRight] = calculateBoundOfPoints(points, 1.25);

  const [xMin, yMin] = fromLonLat([topLeft.lng, topLeft.lat], projection);
  const [xMax, yMax] = fromLonLat(
    [bottomRight.lng, bottomRight.lat],
    projection
  );

  const extent = [xMin, yMin, xMax, yMax] as Extent;

  return extent;
}

const memoizeGetGeoJSONLayers = memoizeOne(getGeoJSONLayers, equals);

type LayerMap = {
  [GeoJSONFileType.SITE_AREA]: Options<any>[];
  [GeoJSONFileType.PARK]: Options<any>[];
  [GeoJSONFileType.WATER]: Options<any>[];
  [GeoJSONFileType.BUILDINGS]: Options<any>[];
  [GeoJSONFileType.ROADS]: Options<any>[];
  [GeoJSONFileType.EXTERNAL_WALLS]: Options<any>[];
  [GeoJSONFileType.INTERNAL_WALLS]: Options<any>[];
  [GeoJSONFileType.BOUNDARY]: Options<any>[];
  [GeoJSONFileType.TRAIL]: Options<any>[];
};

// this order is important
const DEFAULT_LAYER_MAP: LayerMap = {
  [GeoJSONFileType.SITE_AREA]: [],
  [GeoJSONFileType.PARK]: [],
  [GeoJSONFileType.WATER]: [],
  [GeoJSONFileType.BUILDINGS]: [],
  [GeoJSONFileType.ROADS]: [],
  [GeoJSONFileType.EXTERNAL_WALLS]: [],
  [GeoJSONFileType.INTERNAL_WALLS]: [],
  [GeoJSONFileType.BOUNDARY]: [],
  [GeoJSONFileType.TRAIL]: [],
};

function getGeoJSONLayers(geoJSONFiles: GeoJSONFile[]) {
  const layerMap = geoJSONFiles.reduce((accum, geoJSONFile) => {
    const url = geoJSONFile?.asset.uri;

    if (url) {
      const { geoJSONFileType } = geoJSONFile;

      const options: Options<any> = {
        declutter: false,
        source: vector({
          // this "new" object is causing the vector layer to update every time
          format: new GeoJSON(),
          url,
        }),
        style: getStyles(geoJSONFileType),
      };

      const existing = accum[geoJSONFileType] || [];

      accum[geoJSONFile.geoJSONFileType] = [...existing, options];
    }

    return accum;
  }, DEFAULT_LAYER_MAP);

  const layers = Object.keys(layerMap)
    // @ts-ignore
    .map((key) => layerMap[key])
    .reduce((accum, layer) => {
      return [...accum, ...layer];
    }, []);

  return layers;
}

function getStyles(geoJSONFileType: GeoJSONFileType): StyleLike {
  switch (geoJSONFileType) {
    case GeoJSONFileType.SITE_AREA:
      return new Style({
        // stroke: new Stroke({
        //   color: '#333',
        //   width: 1,
        // }),
        fill: new Fill({
          color: '#E0DFDF',
        }),
      });
    case GeoJSONFileType.ROADS:
      return function (feature: FeatureLike) {
        const newStyle = new Style({
          stroke: new Stroke({
            color: '#B4B4B4',
            width: 4,
          }),
          fill: new Fill({
            color: 'white',
          }),
          text: new Text({
            font:
              '11px "Roboto", "Open Sans", "Arial Unicode MS", "sans-serif"',
            placement: 'line',
            offsetY: 10,
            fill: new Fill({
              color: '#000',
            }),
          }),
        });

        // @ts-expect-error
        feature.setStyle(newStyle);

        const label = feature.get('name');

        if (label) {
          const text = newStyle.getText();

          text && text.setText(label);
        }

        return newStyle;
      };
    case GeoJSONFileType.BUILDINGS:
      return new Style({
        stroke: new Stroke({
          color: '#333',
          width: 1,
        }),
        fill: new Fill({
          color: '#ddd',
        }),
      });
    case GeoJSONFileType.PARK:
      return new Style({
        fill: new Fill({
          color: '#ADD19E',
        }),
      });
    case GeoJSONFileType.WATER:
      return new Style({
        fill: new Fill({
          color: '#AAD3DF',
        }),
      });
    case GeoJSONFileType.BOUNDARY:
      return new Style({
        stroke: new Stroke({
          color: '#515151',
          width: 2,
          lineDash: [2, 6],
        }),
      });
    case GeoJSONFileType.EXTERNAL_WALLS:
      return new Style({
        stroke: new Stroke({
          color: '#333',
          width: 4,
        }),
        fill: new Fill({
          color: '#eee',
        }),
      });
    case GeoJSONFileType.INTERNAL_WALLS:
      return new Style({
        stroke: new Stroke({
          color: '#333',
          width: 2,
        }),
        fill: new Fill({
          color: '#eee',
        }),
      });
    case GeoJSONFileType.TRAIL:
      return new Style({
        stroke: new Stroke({
          color: '#0066FF',
          width: 4,
        }),
      });
  }
}

// delete this after copying these coordinates to Hellenic Museum viewpoints from the Console
const overrideMap: { [key: string]: Coordinate } = {
  '60067bf8e283330007f8c47a': [144.957046719, -37.8121361383],
  '6008b1d34274c0d90fdf84d5': [144.9569957487, -37.8121619818],
  '6008b27c4274c0d90fdf8cb0': [144.9569904235, -37.8121605794],
  '6008b2be4274c0d90fdf8ff4': [144.9569681081, -37.8121241181],
  '6008b31f4274c0d90fdf9466': [144.9569769836, -37.8120936668],
  '6008b3864274c0d90fdf98ee': [144.9570137531, -37.8120770388],
  '6008b3ce4274c0d90fdf9c61': [144.9569853518, -37.8120353687],
  '6008b40e4274c0d90fdf9f4f': [144.9570441831, -37.8120646179],
  '6008c9686a1d380007bcc167': [144.957094139, -37.8121070894],
  '6008b4584274c0d90fdfa31c': [144.9571316693, -37.8121122982],
};

function overrideMarkers(
  markers: Array<SelectedFeature>
): Array<SelectedFeature> {
  return markers.map(({ position, ...rest }) => ({
    ...rest,
    position: overrideMap[rest.id] || position,
  }));
}
