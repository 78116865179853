import {
  AK_APP_CONFIGURATION,
  AO_APP_CONFIGURATION,
  DEFAULT_APP_CONFIGURATION,
} from '../features/whitelabeling/branding/consts';
import AppWithTheme from './AppWithTheme';
import { env } from './config/env';

const AppWithDefaultConfig = () => {
  if (env.IS_AK) {
    return (
      <AppWithTheme
        theme={AK_APP_CONFIGURATION.theme}
        stylesheetURL={AK_APP_CONFIGURATION.stylesheet}
      />
    );
  }

  if (env.IS_AO) {
    return (
      <AppWithTheme
        theme={AO_APP_CONFIGURATION.theme}
        stylesheetURL={AO_APP_CONFIGURATION.stylesheet}
      />
    );
  }

  return (
    <AppWithTheme
      theme={DEFAULT_APP_CONFIGURATION.theme}
      stylesheetURL={DEFAULT_APP_CONFIGURATION.stylesheet}
    />
  );
};

export default AppWithDefaultConfig;
