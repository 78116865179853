import Icon from 'mdi-react/PanoramaIcon';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_MEDIUM,
} from '../../style/SIZES';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../Routes/routes';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  showTextInMobile?: boolean;
}

const ProductLink = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const productLink = `${ROUTES.itinerary.replace(
    ':internalReference',
    'ancient_masada'
  )}?utm_source=app_header&utm_medium=product_link`;

  const isOnProductPage = useIsOnProductPage();

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Header / Product',
    });

    history.push(productLink);
  };

  const TextWrapper = props.showTextInMobile
    ? AppHeaderButtonText
    : AppHeaderButtonTextMobileHidden;

  return (
    <AppHeaderButtonContainer
      fullWidth={fullWidth}
      onClick={handleClick}
      borderColor={isOnProductPage ? color : ''}
    >
      <Icon color={color} size={size * 1.4} />
      <TextWrapper size={size} color={color}>
        {t('Product')}
      </TextWrapper>
    </AppHeaderButtonContainer>
  );
};

export default ProductLink;

const AppHeaderButtonTextMobileHidden = styled(AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    display: none;
  }
`;

function useIsOnProductPage() {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);

  // Define the target pathname and required query parameters
  const targetPathname = ROUTES.itinerary.replace(
    ':internalReference',
    'ancient_masada'
  );
  const requiredParams = {
    utm_source: 'app_header',
    utm_medium: 'product_link',
  };

  // Check if pathname matches
  const isPathnameMatch = pathname === targetPathname;

  // Check if all required query parameters are present and correct
  const areQueryParamsMatch = Object.keys(requiredParams).every(
    (key) =>
      searchParams.has(key) && searchParams.get(key) === requiredParams[key]
  );

  return isPathnameMatch && areQueryParamsMatch;
}
