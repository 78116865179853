import { env } from '../../App/config/env';
import { Stack } from '../../Layouts/primitives/Stack';
import styled from '../../style/styled';

const LocationPermissionsDeniedMessage = () => {
  const appname = env.IS_STOCK
    ? 'Ancient World website'
    : 'Lithodomos Audio-Visual Guide';

  const messages = [
    `It looks like you have previously denied location permissions for ${appname}.`,
    `To track your current location, please enable location permissions in your browser settings.`,
  ];

  return (
    <Stack gutter={8}>
      {messages.map((message, index) => (
        <TextRow key={index}>{message}</TextRow>
      ))}
    </Stack>
  );
};

const TextRow = styled.span`
  color: #fff;
  display: block;
  font-size: 14px;
`;

export default LocationPermissionsDeniedMessage;
