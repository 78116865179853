import { useTranslation } from 'react-i18next';
import { ToursSelect } from '../../../../components/dropdowns/ToursSelect';

type Props = {
  tourActivationText: string;
  selectedTours: Array<{ label: string; value: string }>;
  isDisabled: boolean;
  handleToursSelectChange: (
    tours: Array<{ label: string; value: string }>
  ) => void;
  redeemableTours: Array<{ productName: string; productID: string }>;
};

export const SelectMultupleToursToActivate = ({
  tourActivationText,
  selectedTours,
  isDisabled,
  handleToursSelectChange,
  redeemableTours,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <p>
        <strong>{tourActivationText}</strong>
      </p>

      <p>
        {t(
          'Please select the tours you would like to redeem, and click the REDEEM SELECTED TOURS button. The tours will be available from the My Tours page after redemption.'
        )}
      </p>

      <ToursSelect
        value={selectedTours}
        isDisabled={isDisabled}
        onChange={handleToursSelectChange}
        closeMenuOnSelect={false}
        options={redeemableTours.map((t) => ({
          label: t.productName,
          value: t.productID,
        }))}
      />
    </>
  );
};
