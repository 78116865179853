import { useCallback } from 'react';
import { captureButtonPress } from '../../../../features/analytics';
import { Button } from '../../../Button';
import { useTranslation } from 'react-i18next';
import getRedirectCartButtonToExternalURL from '../../../../features/whitelabeling/branding/utils/getRedirectCartButtonToExternalURL';

interface Props {
  tourID: string;
  buy: () => void;
}

export const TourWidgetBookNowButton = ({ tourID, buy }: Props) => {
  const { t } = useTranslation();
  const page = window.location.pathname;

  const handleButtonClick = useCallback(() => {
    captureButtonPress({
      buttonName: 'BuyTour',
      tourID,
      page,
    });

    buy();
  }, [tourID, buy, page]);

  const disabled = !!getRedirectCartButtonToExternalURL();

  return (
    <Button black width="100%" onClick={handleButtonClick} disabled={disabled}>
      {t('Add to cart')}
    </Button>
  );
};
