import { useContext } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { env } from '../../../App/config/env';
import { getCDNURI } from '../../../utils/getCDNURI';
import { findAllAssetsInSettings } from '../../whitelabeling/branding/utils/findAllAssetsInSettings';
import { COMMON_APP_ASSETS } from '../appAssets';
import RemoteAppContext from '../../../context/remoteAppContext';
import { findAllAssets } from '../utils/findAllAssets';

// Download the app assets
const DownloadAppAssetsSWOnServiceWorkerActivation = () => {
  const { appData } = useContext(RemoteAppContext);

  useDeepCompareEffect(() => {
    if (navigator.serviceWorker?.controller) {
      const urls = [
        // whitelabel app assets
        ...findAllAssets(appData).map(({ uri }) => uri),
        // app specific assets (i.e. only relevant whitelabel assets)
        ...findAllAssetsInSettings(env.APP_ENV),
        ...COMMON_APP_ASSETS.map(getCDNURI),
      ];

      const uniqueUrls = [...new Set(urls)];

      navigator.serviceWorker.controller.postMessage({
        action: 'cacheAppAssets',
        assets: uniqueUrls,
      });

      navigator.serviceWorker.controller.postMessage({
        action: 'removeAppAssetsNotInList',
        assets: uniqueUrls,
      });
    }
  }, [appData]);

  return null;
};

export default DownloadAppAssetsSWOnServiceWorkerActivation;
