import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import LogRocket from 'logrocket';
import { env } from '../../App/config/env';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import { RootState, SagasContext } from './types';
import { transforms } from './transforms';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { getReduxPersistStorage } from './getReduxPersistStorage';
import getErrorMessage from '../../utils/getErrorMessage';
import { logMessage } from '../../features/logging/logMessage';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

let _store: any;
let _persistor: any;
let _context: SagasContext;

export function createReduxStore(context: SagasContext) {
  _context = context;

  const persistedReducer = persistReducer(
    {
      version: env.REDUX_PERSIST_VERSION,
      key: env.REDUX_PERSIST_KEY,
      storage: getReduxPersistStorage(),
      debug: env.REDUX_PERSIST_ENABLE_DEBUGGING,
      // transform the shape of the state persisted and read back
      transforms,
      throttle: 1000,
    },
    rootReducer
  );

  const sagaMiddleware = createSagaMiddleware({
    context: _context,
    onError: onSagaError,
  });

  // Refer to: https://extension.remotedev.io/#usage
  const reduxDevToolsExtensionCompose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const composeEnhancers = reduxDevToolsExtensionCompose
    ? reduxDevToolsExtensionCompose({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

  const middleware = [
    sagaMiddleware,
    // Note: LogRocket should be the final middleware
    env.ENABLE_LOG_ROCKET_REPORTING &&
      LogRocket.reduxMiddleware({
        stateSanitizer: function (state: RootState) {
          // sanitise state
          const appState = state.app;

          let user = appState?.user;

          if (user) {
            user = { ...user, jwt: 'removed' };
          }

          return {
            ...state,
            appState: { ...appState, user },
          };
        },
      }),
  ].filter(Boolean);

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  _store = createStore(persistedReducer, enhancer);

  _persistor = persistStore(_store);

  sagaMiddleware.run(rootSaga);

  return {
    store: _store,
    persistor: _persistor,
  };
}

function onSagaError(error: any) {
  captureInSentry(
    `createReduxeStore.ts onSagaError() error: ${getErrorMessage(error)}`
  );
}

export function persistImmediately() {
  if (!_persistor) {
    return;
  }

  _persistor
    .flush()
    .then(() => {
      logMessage('State has been persisted immediately!');
    })
    .catch((error: any) => {
      Sentry.captureException(error);

      logMessage('Failed to persist state immediately:', error);
    });
}
