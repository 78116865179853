import {
  RTM_STOCK_URL,
  ALPHA_STOCK_URL,
  BETA_STOCK_URL,
  DEV_URL,
} from './../consts';
import { env } from '../App/config/env';
import { captureInSentry } from '../App/config/reporting/captureInSentry';
import { ROUTES } from '../Routes/routes';
import urlJoin from './urlJoin';

export function getVerificationURL(): string | null {
  if (env.IS_STOCK) {
    let hostname = RTM_STOCK_URL;
    const pageRoute = ROUTES.verify;

    if (env.IS_ALPHA) {
      hostname = ALPHA_STOCK_URL;
    } else if (env.IS_BETA) {
      hostname = BETA_STOCK_URL;
    } else if (env.isDevelopment) {
      hostname = DEV_URL;
    }

    return urlJoin(hostname, pageRoute);
  }

  // whitelabel apps currently should not be calling this fn
  captureInSentry('A whitelabeled app is calling getVerificationURL');

  return null;
}
