import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { ROUTES } from '../../routes';
import { useMutation } from '@apollo/client';
import {
  SEND_RESET_PASSWORD_LINK_MUTATION,
  Data,
  Variables,
} from '../../../graphql/mutations/SendResetPasswordLinkMutation';
import * as Sentry from '@sentry/react';
import { Input } from '../../../styledComponents/Input';
import { Label } from '../../../styledComponents/Label';
// import { ButtonContainer } from '../../../components/ButtonContainer';
// import { ButtonGroupContainer } from '../../../components/ButtonGroupContainer';
// import { AuthPageTitle } from '../../../styledComponents/Title';
import { HelpText } from '../../../styledComponents/HelpText';
import { ErrorText } from '../../../styledComponents/ErrorText';
// import styled from '../../../style/styled';
import { AuthButtonWithRecaptcha3 } from '../../../components/AuthButtonWithRecaptcha3';
// import { ReCaptchaDisclaimer } from '../../../components/ReCaptchaDisclaimer';
import { Grid } from '../../../Layouts/primitives/Grid';
import { isMobile } from 'react-device-detect';
// import styled from '../../../style/styled';
// import { Button } from '../../../components/Button';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';

type FormState = {
  email: string;
};

type ErrorState = {
  email: string;
};

function getInitialFormState() {
  return { email: '' };
}

function getInitialErrorState() {
  return { email: '' };
}

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

const RECAPTCHA_CONTEXT = 'ForgotPassword';

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  onLogIn?: () => void;
}

export const ForgotPasswordScreen: React.FC<Props> = ({
  onSuccess,
  onFailure,
  onLogIn,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [formState, setFormState] = useState<FormState>(getInitialFormState());
  const [formError, setFormError] = useState<ErrorState>(
    getInitialErrorState()
  );
  const [error, setError] = useState<Error | null>(null);

  const [sendResetPasswordLink, { loading }] = useMutation<Data, Variables>(
    SEND_RESET_PASSWORD_LINK_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        setError(error);

        if (onFailure) {
          onFailure(error);
        }
      },
      onCompleted: ({ result }) => {
        const { sent, error } = result;

        if (sent) {
          toast.success(
            t(
              'You have successfully requested to reset your password. Please check your email for the next steps.'
            ),
            {
              autoClose: 10000,
              pauseOnHover: true,
              hideProgressBar: true,
              toastId: 'SUCCESSFUL_PASSWORD_RESET_REQUEST',
            }
          );

          if (onSuccess) {
            onSuccess();
          } else {
            history.push(ROUTES.authForgotSuccess);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          if (onFailure) {
            onFailure(new Error(errorMessage));
          } else {
            setError(new Error(errorMessage));
          }
        }
      },
    }
  );

  // const onKeyDown = async (event: any) => {
  //   if (event.keyCode !== 13) {
  //     return;
  //   }

  //   let token: string | null = null;

  //   if (executeRecaptcha) {
  //     try {
  //       token = await executeRecaptcha(RECAPTCHA_CONTEXT);
  //     } catch (error) {
  //       // don't do anything
  //     }
  //   }

  //   handleSubmit(token);
  // };

  const submitForm = async (e: any) => {
    e.preventDefault();

    let token: string | null = null;

    // if (executeRecaptcha) {
    //   try {
    //     token = await executeRecaptcha(RECAPTCHA_CONTEXT);
    //   } catch (error) {
    //     captureInSentry(error?.message || 'Recaptcha error', {
    //       recaptchaContext: RECAPTCHA_CONTEXT,
    //     });
    //   }
    // }

    handleSubmit(token);
  };

  const handleSubmit = (recaptchaToken: string | null) => {
    const email = formState.email.trim();

    const errorState = getInitialErrorState();

    if (!email) {
      errorState.email = 'Please enter your valid Email.';
      setFormError(errorState);
    } else {
      const variables: Variables = {
        input: {
          email,
          passwordResetURL: `https://${window.location.hostname}${ROUTES.authChangePassword}`,
        },
      };

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      sendResetPasswordLink({ variables });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setError(null);
    setFormError({ ...formError, [name]: '' });

    setFormState({ ...formState, [name]: value });
  };

  return (
    <div>
      <HelpText>
        {t(
          "Enter your email address below and we'll send you instructions to reset it."
        )}
      </HelpText>

      <form onSubmit={submitForm}>
        <Grid colsMedium={1} cols={1} gap={12} marginTopSmall={20}>
          <section>
            <Label htmlFor="email">{t('Email address')}</Label>
            <Input
              autoFocus={!isMobile}
              disabled={loading}
              name="email"
              type="email"
              id="email"
              autoComplete="on"
              value={formState.email}
              onChange={handleInputChange}
              data-testid="email-input-field"
              // onKeyDown={onKeyDown}
            />
            <ErrorText>{formError.email || ''}</ErrorText>
          </section>

          <ErrorText>{error?.message || ''}</ErrorText>

          <AuthButtonWithRecaptcha3
            context={RECAPTCHA_CONTEXT}
            disabled={loading}
            buttonName="ForgotPasswordButton"
          >
            {t(loading ? 'Submitting request...' : 'Request password reset')}
          </AuthButtonWithRecaptcha3>
        </Grid>
      </form>
    </div>
  );
};
