import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import styled from '../../style/styled';
import { LanguageCode } from '../../graphql/globalTypes';
import { setLanguage } from '../../store/app/actions';
import { selectLanguage } from '../../store/app/selectors';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { DEFAULT_LANGUAGE, getSupportedLanguageConfigs } from '../../consts';
import {
  captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../features/analytics';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { env } from '../../App/config/env';

export const LanguageSelector = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { features } = env.APP_ENV;

  const handleToursSelectChange = (value: LanguageCode) => {
    captureEvent({
      name: EventNames.LANGUAGE_CHANGED,
      data: [
        {
          key: DataKeys.LANGUAGE,
          value,
        },
      ],
    });

    dispatch(setLanguage(value));
    setShowMenu(false);
  };

  const supportedLanguageConfigs = getSupportedLanguageConfigs(
    features.supportedLanguages
  );

  const selectedLanguageEntry = supportedLanguageConfigs.find(
    (i) => i.value === language
  );

  // if the language in state does not match a supported language, reset to default language code
  useEffect(() => {
    if (!selectedLanguageEntry) {
      dispatch(setLanguage(DEFAULT_LANGUAGE));
    }
  }, [selectedLanguageEntry, dispatch]);

  if (!selectedLanguageEntry) {
    captureInSentry('selectedLanguageEntry is undefined', {
      language,
    });

    return null;
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setShowMenu(false)}>
      <Container data-testid="header-language-dropdown">
        <AppHeaderButtonContainer>
          <FlagWithDropDownIconContainer
            onClick={() => {
              captureButtonPress({
                buttonName: 'Language',
                page: window.location.pathname,
              });

              setShowMenu(!showMenu);
            }}
          >
            <AppHeaderButtonText color="#fff" size={HEADER_BUTTON_SIZE}>
              <img
                src={selectedLanguageEntry.uri || ''}
                alt={selectedLanguageEntry.display}
                width="24px"
                height="18px"
              />
            </AppHeaderButtonText>

            {/* Need to rethink this because we are running out of space in the header on mobile */}
            {/* <DownIcon color="#fff" /> */}
          </FlagWithDropDownIconContainer>
        </AppHeaderButtonContainer>

        {showMenu && (
          <MenuContainer data-testid="language-selector-languages">
            {supportedLanguageConfigs
              .filter((i) => i.value !== selectedLanguageEntry.value)
              .map(({ label, value, uri }) => (
                <Item
                  key={label}
                  onClick={() => handleToursSelectChange(value)}
                  caption={label}
                  uri={uri}
                />
              ))}
          </MenuContainer>
        )}
      </Container>
    </OutsideClickHandler>
  );
};

const FlagWithDropDownIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ItemProps {
  onClick: () => void;
  caption: string;
  uri: string;
}

const Item: React.FC<ItemProps> = ({ caption, onClick, uri }) => {
  return (
    <ItemContainer
      onClick={onClick}
      data-testid={`language-selector-language-${caption}`}
    >
      <img src={uri} alt="" width="24px" height="18px" />
      <Caption>{caption}</Caption>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  padding: 4px 4px 8px 4px;
  cursor: pointer;
  border-bottom: 1px solid #333;
  margin: 4px 4px 0 4px;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }
`;

const Caption = styled.p`
  margin-left: 8px;
`;

const Container = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  position: absolute;
  background: #000;
  color: #fff;
  z-index: 2;
  right: 0;
  margin-top: 8px;
  box-shadow: 0px 0 3px 1px #00000082;
`;
