import { AppSettingsState } from './../app/types';
import { FullTour } from './../../graphql/queries/GetFullTourQuery';
import { setClosestViewpointIndex } from './../viewer/actions';
import { SagaIterator } from 'redux-saga';
import { call, select, put, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPES, UpdateUserPositionAction } from './actions';
// import { capturePosition } from '../../features/analytics';
import { selectTour } from '../viewer/selectors';
import { getClosestViewpoint } from '../../utils/getClosestViewpoint';
import { ClosestViewpointDetectionMode } from './types';
import { tourSupportsViewpointProximitySensing } from '../../utils/tourSupportsViewpointProximitySensing';
import { selectAppSettings } from '../app/selectors';

export const sagas = [locationSagas];

export function* locationSagas(): any {
  yield takeLatest(ACTION_TYPES.UPDATE_USER_POSITION, updateUserPositionSaga);
}

function* updateUserPositionSaga(
  action: UpdateUserPositionAction
): SagaIterator {
  const { currentLocation } = action.payload;

  if (!currentLocation) {
    yield put(
      setClosestViewpointIndex({
        index: -1,
        distance: Infinity,
        mode: ClosestViewpointDetectionMode.RADIUS,
      })
    );

    return;
  }

  // disabled for GDPR for now
  // capture the current user position in LVR analytics
  // yield call(capturePosition);

  const tour: FullTour | null = yield select(selectTour);
  const appSettings: AppSettingsState = yield select(selectAppSettings);

  if (
    appSettings.detectClosestViewpoints &&
    tour &&
    tourSupportsViewpointProximitySensing(tour)
  ) {
    const { viewpoint, distance, mode } = yield call(
      getClosestViewpoint,
      {
        lat: currentLocation.coords.latitude,
        lng: currentLocation.coords.longitude,
      },
      tour.viewpoints,
      appSettings.closestViewpointDetectionThresholdDistance
    );

    if (!viewpoint) {
      return;
    }

    const index = tour.viewpoints.findIndex((vp) => viewpoint.id === vp.id);

    yield put(setClosestViewpointIndex({ index, distance, mode }));
  }
}
