import React, { useRef, useState, useEffect, useCallback } from 'react';
import memoizeOne from 'memoize-one';
import styled, { useTheme } from '../../style/styled';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TopRightContainer } from '../../styledComponents/TopRightContainer';
import { env } from '../../App/config/env';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { ActivateProductButton } from './ActivateProductButton';
import { Z_INDEX } from '../../style/Z_INDEX';
import {
  selectIsAuthenticated,
  selectIsGuestUser,
} from '../../store/app/selectors';
import { ROUTES } from '../../Routes/routes';
import { CartButton } from './CartButton';
import { SignOutLink } from './SignOutLink';
import { SignInLink } from './SignInLink';
import { MyTripsButton } from './MyTripsButton';
import { HomeButton } from './HomeButton';
import { DevButton } from './DevButton';
import { MobileMenu } from './MobileMenu';
import { AppLogoAndText } from './AppLogoAndText';
import { nonNullable } from '../../utils/nonNullable';
import { ToursLibraryLink } from './ToursLibraryLink';
import { LanguageSelector } from './LanguageSelector';
import { ConvertGuestAccountLink } from './ConvertGuestAccountLink';
import { NAVBAR_HEIGHT } from '../../consts';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';

const AppHeader = () => {
  const history = useHistory();
  const isAuthed = useSelector(selectIsAuthenticated);
  const isGuestUser = useSelector(selectIsGuestUser);
  const location = useLocation();
  const theme = useTheme();
  const headerRef = useRef<HTMLHeadElement>(null);
  const [atTop, setAtTop] = useState<boolean>(window.scrollY <= 0);

  const { pathname } = location;
  const color = theme.palette.appHeader.color;
  const bgColor = theme.palette.appHeader.background;
  const nonMenuCommonProps = { color, size: HEADER_BUTTON_SIZE };
  const inMenuCommonProps = { ...nonMenuCommonProps, fullWidth: true };

  const updateAtTop = useCallback(() => {
    const header = headerRef.current;

    if (header) {
      const isAtTop = window.scrollY < header.offsetTop + header.offsetHeight;

      if (isAtTop !== atTop) {
        setAtTop(isAtTop);
      }
    }
  }, [atTop]);

  // Scroll event listener.
  // Updates whether we've scrolled to the top or not, to be used for toggling the box shadow of the header.
  useEffect(() => {
    window.addEventListener('scroll', updateAtTop);

    return () => {
      window.removeEventListener('scroll', updateAtTop);
    };
  }, [updateAtTop]);

  const {
    dev,
    version,
    cart,
    home,
    redeem,
    myTrips,
    // myTourPlans,
    signOut,
    signIn,
    library,
    languageSelector,
    convertGuestAccount,
    // currencySelector,
  } = getButtonVisibilityMemoized(pathname, isAuthed, isGuestUser);

  const menuItems = [
    library ? (
      <MenuItem key="toursLibrary" bgColor={bgColor}>
        <ToursLibraryLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    convertGuestAccount && !env.IS_AO ? (
      <MenuItem key="convertGuestAccount" bgColor={bgColor}>
        <ConvertGuestAccountLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    signOut && !env.IS_AO ? (
      <MenuItem key="signOut" bgColor={bgColor}>
        <SignOutLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    dev ? (
      <MenuItem key="dev" bgColor={bgColor}>
        <DevButton {...inMenuCommonProps} fullWidth />
      </MenuItem>
    ) : null,

    version ? (
      <MenuItem key="version" bgColor={bgColor} nonInteractive>
        <Version {...inMenuCommonProps} />
      </MenuItem>
    ) : null,
  ].filter(nonNullable);

  const backgroundColor =
    env.APP_ENV.banner.title === 'Fatima'
      ? atTop &&
        ['/cityrama_fatima', '/cityrama_fatima-staging', '/'].indexOf(
          history.location.pathname
        ) !== -1
        ? bgColor
        : '#000'
      : bgColor;

  return (
    <AppHeaderContainer
      ref={headerRef}
      id="header"
      bgColor={backgroundColor}
      atTop={atTop}
      // height={NAVBAR_HEIGHT}
    >
      <NavBar>
        <AppLogoAndText
          size={
            env.APP_ENV.banner.title === 'Ancient World'
              ? 18
              : env.APP_ENV.banner.title === 'A Journey Through Faith'
              ? 24
              : 28
          }
        />

        <nav>
          <TopRightContainer>
            {languageSelector && <LanguageSelector />}

            {/* {currencySelector && <CurrencySelector />} */}

            {home && <HomeButton {...nonMenuCommonProps} />}

            {cart &&
              !env.IS_AK &&
              env.APP_ENV.banner.title !== 'A Journey Through Faith' &&
              !env.IS_AO && <CartButton {...nonMenuCommonProps} />}

            {redeem && <ActivateProductButton {...nonMenuCommonProps} />}

            {/* There is a reference to the location of MyTripsButton in the FAQ page. Make sure it is updated if this button is moved. */}
            {myTrips && <MyTripsButton {...inMenuCommonProps} />}

            {signIn && <SignInLink {...inMenuCommonProps} />}

            {menuItems.length > 0 && (
              <MobileMenu isAuthed={isAuthed} items={menuItems} />
            )}
          </TopRightContainer>
        </nav>
      </NavBar>
    </AppHeaderContainer>
  );
};

export default AppHeader;

const AppHeaderContainer = styled.header.attrs<{
  atTop: boolean;
}>(({ atTop }) => ({
  style: {
    boxShadow: atTop ? 'none' : '0px 1px 20px 0px #000000',
  },
}))<{
  bgColor: string;
  atTop: boolean;
  // height: number;
}>`
  background: ${({ bgColor }) => bgColor};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: ${Z_INDEX.HEADER};
  transition: all 0.2s ease-in-out;
`;

const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${NAVBAR_HEIGHT}px;
  padding: 10px;
`;

const getButtonVisibilityMemoized = memoizeOne(getButtonVisibility);

function getButtonVisibility(
  pathname: string,
  isAuthed: boolean,
  isGuestUser: boolean
) {
  return {
    dev: env.ENABLE_DEV_MENU_ITEM,
    version: env.ENABLE_DEV_MENU_ITEM,
    cart: true,
    home: pathname !== ROUTES.index,
    redeem: pathname !== ROUTES.tour && !pathname.startsWith(ROUTES.auth),
    myTrips: isAuthed,
    signOut: isAuthed,
    signIn: !isAuthed && pathname !== ROUTES.authLogin,
    library: true,
    languageSelector: env.IS_STOCK
      ? env.ENABLE_I18N
      : env.APP_ENV.header.showLanguages,
    // currencySelector: env.APP_ENV.features.multiCurrency,
    convertGuestAccount: isAuthed && isGuestUser,
  };
}

interface DevVersionProps {
  color: string;
}

const Version = (props: DevVersionProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const color = props.color || theme.palette.appHeader.color;

  const version = `${env.APP_VERSION} ${env.GIT_HASH || ''} (${
    env.IS_ALPHA ? 'Alpha' : 'Beta'
  })`;

  return (
    <VersionText
      color={color}
      onClick={() => {
        dispatch(setModalType({ modalType: ModalType.LOG_VIEWER }));
      }}
    >
      {version}
    </VersionText>
  );
};

const MenuItem = styled.div<{ nonInteractive?: boolean; bgColor: string }>`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 10px;

  svg {
    transition: transform 0.2s;
  }

  p {
    transition: transform 0.2s;
  }
`;

const VersionText = styled.p<{ color: string }>`
  margin: 0 10px;
  color: ${(props) => props.color};
`;
