import queryString from 'query-string';

export function getQueryVariable(variable: string): string | null {
  const vars = queryString.parse(window.location.search);

  const value = vars[variable];

  if (typeof value === 'string') {
    return value;
  }

  if (Array.isArray(value) && typeof value[0] === 'string') {
    return value[0];
  }

  return null;
}
