import { useEffect, useState } from 'react';
import { lighten } from 'polished';
import { useHistory } from 'react-router-dom';
import { env } from '../../App/config/env';
import { ROUTES } from '../../Routes/routes';
import { COLORS } from '../../style/colors';
import styled from '../../style/styled';

export const Version = () => {
  const [count, setCount] = useState<number>(0);
  const history = useHistory();
  const isAK = env.IS_AK;

  useEffect(() => {
    if (count === 5) {
      setCount(0);
      history.push(ROUTES.setup);
    }
  }, [count, history]);

  return (
    <VersionText
      isAK={isAK}
      role="button"
      onClick={() => {
        setCount((c) => c + 1);
      }}
    >
      v{env.APP_VERSION}
    </VersionText>
  );
};

const VersionText = styled.span<{ isAK: boolean }>`
  font-size: 12px;
  color: ${(props) =>
    props.isAK ? COLORS.offwhiteDarker : lighten(0.2, COLORS.darkTextColor)};
`;
