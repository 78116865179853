import { isMobile } from 'react-device-detect';
import { env } from '../../App/config/env';
import { CurrencyCode, LanguageCode } from '../../graphql/globalTypes';
import { PromoCode } from '../../types';
import { DEFAULT_LANGUAGE, DEFAULT_CURRENCY_CODE } from './../../consts';
import { IEnv } from '../../features/whitelabeling/branding/types';
import { DEFAULT_APP_CONFIGURATION } from '../../features/whitelabeling/branding/consts';

enum PurchaseMode {
  GIFT = 'GIFT',
  PERSONAL = 'PERSONAL',
}

export type CartState = Array<{
  productSKU: string;
  productID: string;
  count?: number;
}>;

export type PromoCodeState = PromoCode | null;

export type PinnedTour = {
  tourID: string;
  viewpointID?: string;
};

export type TourSettingsState = {
  maintainAudioPausedStateWhenSwitchingVPs: boolean;
  autoLoadViewpointWhenNearby: boolean;
};

export const DEFAULT_TOUR_SETTINGS_STATE: TourSettingsState = {
  maintainAudioPausedStateWhenSwitchingVPs: true,
  autoLoadViewpointWhenNearby: true,
};

export type AppSettingsState = {
  detectClosestViewpoints: boolean;
  closestViewpointDetectionThresholdDistance: number;
  closetViewpointDetectionThresholdAccuracy: number;
};

export const DEFAULT_APP_SETTINGS_STATE: AppSettingsState = {
  detectClosestViewpoints: isMobile,
  closestViewpointDetectionThresholdDistance: env.isDevelopment ? 100 : 20,
  closetViewpointDetectionThresholdAccuracy: env.isDevelopment ? 200 : 10,
};

export enum CurrencySetMode {
  DEFAULT = 0,
  AUTOMATIC = 1,
  USER = 2,
}

export enum ActivationStatus {
  IDLE = 'IDLE',
  OFFLINE = 'OFFLINE',
  AUTHENTICATING = 'AUTHENTICATING',
  REQUESTING = 'REQUESTING',
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

type ActivationCache = {
  tourIDs: Array<string>;
  expiryDate: number;
};

export type AppState = {
  user: UserState | null;

  // show the cookie disclaimer
  showDisclaimer: boolean;

  // shop related state
  cart: CartState;
  purchaseMode: PurchaseMode;
  currencyCode: CurrencyCode;
  currencyCodeSetMode: CurrencySetMode;

  redirectToCart: boolean;
  AddToHomeScreenPopupDisplayedTimestamp: number | null;

  // protect staging crawlers
  protected: boolean;

  pinnedTours: PinnedTour[];

  // the following states aren't persisted. they are removed from the outbound transform (transforms.ts)
  landscapeMessageDisplayed: boolean;
  promoCode: PromoCodeState;

  // language
  language: LanguageCode;

  tourSettings: TourSettingsState;
  appSettings: AppSettingsState;

  messages: string[];

  enableGoogleAnalytics: boolean;

  // shows a fullscreen loading overlay on top of the app hiding everything
  fulscreenLoadingOverlay: boolean;

  whitelabelAppSettings: IEnv;

  // code -> tourIDs
  // DEPRECATED
  // successfulActivations: Record<string, Array<string>>;
  successfulActivations: Record<string, ActivationCache>;
  activationStatus: ActivationStatus;
  activationError: string | null;

  endOfTourSurveySentForTourIDs: Array<string>;
  endOfTourPopupClosedForTourIDs: Array<string>;
};

export type UserState = {
  id: string;
  jwt: string;
};

export const initialState: AppState = {
  user: null,

  cart: [],
  purchaseMode: PurchaseMode.PERSONAL,
  currencyCode: DEFAULT_CURRENCY_CODE,
  currencyCodeSetMode: CurrencySetMode.DEFAULT,

  redirectToCart: false,
  landscapeMessageDisplayed: false,
  AddToHomeScreenPopupDisplayedTimestamp: null,

  showDisclaimer: true,
  protected: true,

  promoCode: null,

  pinnedTours: [],

  language: DEFAULT_LANGUAGE,

  appSettings: DEFAULT_APP_SETTINGS_STATE,
  tourSettings: DEFAULT_TOUR_SETTINGS_STATE,

  messages: [],

  enableGoogleAnalytics: false,

  fulscreenLoadingOverlay: false,

  whitelabelAppSettings: DEFAULT_APP_CONFIGURATION.settings,

  successfulActivations: {},
  activationStatus: ActivationStatus.IDLE,
  activationError: null,

  endOfTourSurveySentForTourIDs: [],
  endOfTourPopupClosedForTourIDs: [],
};
