import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from '../../../style/styled';
import { useScreenSize } from '../../../hooks/useScreenSize';

const DEFAULT_ASPECT_RATIO = 0.5625; // w: 16, h: 9
const MAX_WIDTH = 880;

interface Props {
  images: Array<{
    id: string;
    url: string;
    caption: string;
    num: number;
  }>;
  selectedIndex: number;
  aspectRatio?: number;
}

// Image Carousal
const MultiImageViewer: React.FC<Props> = ({
  images,
  selectedIndex,
  aspectRatio = DEFAULT_ASPECT_RATIO,
}) => {
  const size = useScreenSize();

  const isPortrait = size.height > size.width;

  let width;
  let height;

  if (isPortrait) {
    // height is determined by the width
    width = Math.min(MAX_WIDTH, size.width);
    height = width * aspectRatio;
  } else {
    height = Math.min(MAX_WIDTH * aspectRatio, size.height);
    width = height / aspectRatio;
  }

  return (
    <Container width={width} height={height}>
      <Carousel
        showArrows
        swipeable
        useKeyboardArrows
        infiniteLoop
        showIndicators
        showThumbs={false}
        autoPlay={false}
        showStatus={false}
        selectedItem={selectedIndex}
        // this is a hack for now. 3.2.9 to 3.2.19 has a bug where swiping will trigger autoplay despite setting false
        interval={1000000000}
      >
        {images.map((image) => (
          <div key={image.id} style={{ userSelect: 'none' }}>
            <img src={image.url} alt="" />

            <p className="legend">
              {image.num}. {image.caption}
            </p>
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default MultiImageViewer;

const Container = styled.div<{ width: number; height: number }>`
  margin: auto;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  background: #000;
`;
