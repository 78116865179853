import { CURRENCY_SYMBOLS } from './../features/multiCurrency/consts';
import { CurrencyCode } from './../graphql/globalTypes';
import Dinero from 'dinero.js';

type Args = {
  // if currency code is not specified, it won't be returned
  currencyCode?: CurrencyCode;
  price: number;
  priceInCents?: boolean;
};

export function getPriceFormatted({
  currencyCode,
  price,
  priceInCents = false,
}: Args): string {
  // Dinero only works with cents
  const amount = priceInCents ? price : Math.floor(price * 100);

  const amountFormatted = Dinero({
    // When you multiply by 100, there is a chance it would add .000000001 etc to the end
    amount,
    precision: 2,
  }).toFormat('0,0.00');

  if (currencyCode) {
    const symbol = CURRENCY_SYMBOLS[currencyCode];

    return `${symbol} ${amountFormatted}`;
  }

  return amountFormatted;
}
