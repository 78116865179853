import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../../style/styled';
import { Button } from '../../../../Button';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../../style/SIZES';

interface Props {
  selected: boolean;
  onClick: () => void;
  caption: string;
  iconURL: string;
  selectedIconURL: string;
}

export const TourInfoWhenAndWhereSectionMapSwitcherButton: React.FC<Props> = ({
  selected,
  onClick,
  iconURL,
  selectedIconURL,
  caption,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ButtonContainer>
        <Button
          width="120px"
          primary={selected}
          secondary={!selected}
          onClick={onClick}
        >
          {t(caption)}
        </Button>
      </ButtonContainer>
      <IconContainer>
        <Img
          src={selected ? selectedIconURL : iconURL}
          alt=""
          onClick={onClick}
        />
      </IconContainer>
    </>
  );
};

const Img = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 1px solid #000;
`;

const ButtonContainer = styled.div`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;

const IconContainer = styled.div`
  display: none;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: inline-block;
  }
`;
