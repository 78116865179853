import { useEffect } from 'react';
import { processQueue } from '../../features/analytics/eventQueue';

export const SubmitQueuedAnayticsEvents = () => {
  useEffect(() => {
    setInterval(processQueue, 30000);
  }, []);

  return null;
};
