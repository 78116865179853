import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../Routes/routes';
import { captureButtonPress } from '../../../../features/analytics';
import { Button } from '../../../Button';
import { useTranslation } from 'react-i18next';

interface Props {
  tourID: string;
}

export const TourWidgetDefaultCheckInButton: React.FC<Props> = ({ tourID }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = window.location.pathname;

  const handleCheckInButtonClick = () => {
    captureButtonPress({
      tourID,
      buttonName: 'CheckIn',
      page,
    });

    history.push(ROUTES.cart);
  };

  return (
    <Button black width="100%" onClick={handleCheckInButtonClick}>
      {t('View in cart')}
    </Button>
  );
};
