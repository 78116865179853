import { useEffect, useState } from 'react';
import { isServiceWorkerRegistered } from '../utils/isServiceWorkerRegistered';

const useIsServiceWorkerActive = () => {
  const [isServiceWorkerActive, setIsServiceWorkerActive] = useState(false);

  useEffect(() => {
    let timerID: NodeJS.Timeout;

    timerID = setInterval(() => {
      isServiceWorkerRegistered().then((registered) => {
        if (registered) {
          clearInterval(timerID);
          setIsServiceWorkerActive(true);
        }
      });
    }, 5000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  return isServiceWorkerActive;
};

export default useIsServiceWorkerActive;
