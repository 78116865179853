import { COLORS } from '../../..//style/colors';
import styled from '../../../style/styled';
// import { invertColor } from '../../../utils/invertColor';
// import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';

export const ContentSectionWidgetPrice = styled.p<{
  strikeOut?: boolean;
  color?: string;
  marginRight?: number;
  marginLeft?: number;
}>`
  color: ${({ color }) => (color ? color : COLORS.lightTextColor)};
  font-weight: bold;
  text-decoration: ${({ strikeOut }) => (strikeOut ? 'line-through' : 'none')};
  text-decoration-thickness: ${({ strikeOut }) => (strikeOut ? 2 : 0)}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
`;
