import Icon from 'mdi-react/EnvelopeIcon';
import { useTranslation } from 'react-i18next';
import { captureButtonPress } from '../../features/analytics';
import {
  AppHeaderButtonText,
  AppHeaderButtonContainer,
} from './styledComponents';

import { ROUTES } from '../../Routes/routes';
import ButtonLikeLink from '../ButtonLikeLink';
import styled, { useTheme } from '../../style/styled';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  showTextInMobile?: boolean;
}

const GetInTouchLink = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const size = props.size || HEADER_BUTTON_SIZE;
  const color = props.color || theme.palette.appHeader.color;

  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Header / Get in touch',
    });
  };

  const TextWrapper = props.showTextInMobile
    ? AppHeaderButtonText
    : AppHeaderButtonTextMobileHidden;

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleClick}>
      <ButtonLikeLink
        to={ROUTES.businessContact}
        highlight
        uppercase
        padding="4px 8px"
        small
      >
        <LinkContent>
          <Icon color={color} size={size * 1.4} />
          <TextWrapper color={color} size={size}>
            {t('Get in touch')}
          </TextWrapper>
        </LinkContent>
      </ButtonLikeLink>
    </AppHeaderButtonContainer>
  );
};

export default GetInTouchLink;

const AppHeaderButtonTextMobileHidden = styled(AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;

const LinkContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
