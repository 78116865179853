import * as styledComponents from 'styled-components';
// import { ITheme } from '../features/whitelabeling/branding/types';

// We have to do this get theme types to work.

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
} = styledComponents; /* as styledComponents.ThemedStyledComponentsModule<ITheme> */

export { css, createGlobalStyle, keyframes, ThemeProvider, useTheme };

export default styled;
