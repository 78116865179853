import React from 'react';
import { env } from '../App/config/env';
import { AncientWorldHelmet } from './AncientWorldHelmet';
import { LithodomosGuideHelmet } from './LithodomosGuideHelmet';

interface Props {
  stylesheetURL?: string;
}

// conditionally render a helmet component for whitelabel app or stock app
export const Helmet: React.FC<Props> = ({ stylesheetURL }) => {
  if (env.IS_STOCK) {
    return (
      <AncientWorldHelmet
        stylesheetURL={stylesheetURL}
        isAK={env.IS_AK}
        isAO={env.IS_AO}
      />
    );
  }

  return <LithodomosGuideHelmet stylesheetURL={stylesheetURL} />;
};
