import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/app/selectors';

interface Props {
  children: any;
}

export const RenderIfAuthed: React.FC<Props> = ({ children }) => {
  const isAuthed = useSelector(selectIsAuthenticated);

  if (isAuthed) {
    return children;
  }

  return null;
};
