import Cookies from 'js-cookie';
import { AFFILIATE_CODE_KEY } from '../../App/config/enums';

export function storeAffiliateCode(affiliateCode: string) {
  if (window.localStorage) {
    localStorage.setItem(AFFILIATE_CODE_KEY, affiliateCode);
  } else {
    Cookies.set(AFFILIATE_CODE_KEY, affiliateCode);
  }
}
