import { env } from '../../../App/config/env';
import styled from '../../../style/styled';

export const ContentSectionWidgetTitle = styled.h4<{
  isAK?: boolean;
  isAO?: boolean;
}>`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 26px;
  font-weight: bold;
  z-index: 4;
  pointer-events: none;
  text-shadow: 0 0 4px #000;
  user-select: none;
  position: absolute;
  text-align: center;
  font-family: ${({ isAK, isAO }) =>
    isAK ? 'Lato Regular' : isAO ? 'Titillium Web Bold' : 'inherit'};
`;

ContentSectionWidgetTitle.defaultProps = {
  isAK: env.IS_AK,
  isAO: env.IS_AO,
};
