import React, { useCallback, useState } from 'react';
// import { useTransition, animated, useTrail } from 'react-spring';
import OutsideClickHandler from 'react-outside-click-handler';
import { mdiClose } from '@mdi/js';
import { mdiMenu } from '@mdi/js';
import styled, { createGlobalStyle, useTheme } from '../../style/styled';
import { IconButton } from '../IconButton';
import { AppLogoAndText } from './AppLogoAndText';
// import { isMobile } from 'react-device-detect';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { env } from '../../App/config/env';
import MdiIcon from '../MdiIcon';
// import { useRerendered } from '../../hooks/useRerendered';

interface Props {
  isAuthed: boolean;
  items: React.ReactElement[];
  size?: number;
}

export const MobileMenu = React.memo((props: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const theme = useTheme();
  // having the colors change is distracting
  const color = theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;

  const hideMenu = useCallback(() => {
    setMenuVisible(false);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuVisible((visible) => !visible);
  }, []);

  const iconPath = menuVisible ? mdiClose : mdiMenu;

  return (
    <OutsideClickHandler onOutsideClick={hideMenu}>
      {menuVisible && <GlobalStyleForOpenMenu />}

      <Container>
        <MenuButton data-testid="hamburger-button" onClick={toggleMenu}>
          <MdiIcon path={iconPath} color={color} size={size * 2} />
        </MenuButton>

        {menuVisible && (
          <Menu
            data-testid="mobile-menu"
            // close menu when an item is clicked upon
            onClick={toggleMenu}
            title={env.APP_ENV.banner.title}
          >
            <MenuContent>
              <MenuTitleBar color={color}>
                <AppLogoAndText size={size} color={color} />

                <IconButton
                  path={mdiClose}
                  iconSize={size * 1.4}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    hideMenu();
                  }}
                  iconBgColor={color}
                />
              </MenuTitleBar>

              <MenuItems>{props.items}</MenuItems>
            </MenuContent>
          </Menu>
        )}
      </Container>
    </OutsideClickHandler>
  );
});

const GlobalStyleForOpenMenu = createGlobalStyle`
  body {
    @media (pointer:none), (pointer:coarse) {
      overflow: hidden;
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
`;

const Menu = styled.div<{ title: string }>`
  background-color: ${({ theme, title }) =>
    title === 'Fatima' ? '#000' : theme.palette.appHeader.background};
  display: flex;
  flex-direction: column;
  right: 0;
  left: auto;
  width: max-content;
  position: absolute;
  height: auto;

  box-shadow: 0px 0 3px 1px #00000082;

  @media (pointer: none), (pointer: coarse) {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    height: 100vh;
    box-shadow: none;
  }
`;

const MenuContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MenuTitleBar = styled.div<{ color: string }>`
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.brand.contrastColor};

  @media (pointer: none), (pointer: coarse) {
    display: flex;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding-top: 0;

  @media (pointer: none), (pointer: coarse) {
    padding-top: 56px;
  }
`;
