import distance from '@turf/distance';
import { point } from '@turf/helpers';

type Coordinate = {
  lat: number;
  lng: number;
};

export function getDistanceInMetersBetweenTwoCoords(
  a: Coordinate,
  b: Coordinate
) {
  const ptA = point([a.lng, a.lat]);
  const ptB = point([b.lng, b.lat]);

  return distance(ptA, ptB, { units: 'meters' });
}
