import { DEFAULT_MAPS_TRAIL_STROKE_WIDTH } from '../../../consts';
import { COLORS } from '../../../style/colors';

export function setGeoJSONStyle(feature: any) {
  const style: google.maps.Data.StyleOptions = {
    strokeColor: COLORS.defaultMapsTrailColor,
    strokeWeight: DEFAULT_MAPS_TRAIL_STROKE_WIDTH,
    strokeOpacity: 1,
    fillOpacity: 0,
  };

  // attempt to override the values from the geojson file
  if (typeof feature.getProperty === 'function') {
    style.strokeColor = feature.getProperty('stroke') || style.strokeColor;
    style.strokeWeight =
      feature.getProperty('stroke-width') || style.strokeWeight;
    style.strokeOpacity =
      feature.getProperty('stroke-opacity') || style.strokeOpacity;
  }

  return style;
}
