import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../Routes/routes';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../../features/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuccessfulActivations } from '../../../../store/app/selectors';
import { removeQueryVariables } from '../../../../utils/removeQueryVariables';
import { CodeKeys } from '../../../../types';
import { setModalType } from '../../../../store/modal/actions';
import { ModalType } from '../../../../store/modal/types';
import {
  addPinnedTours,
  disableFulscreenLoadingOverlay,
  addSuccessfulActivation,
  setActivationStatus,
  setActivationError,
} from '../../../../store/app/actions';
import { downloadPlaylistMetadata } from '../../../../store/offline/actions';
import { LogInScreen } from '../../../../Routes/Auth/login/LogInScreen';
import { env } from '../../../config/env';
import { ActivateProductView, OnSuccessArgs } from './ActivateProductView';
import qs from 'query-string';
import { unlock } from '../../../../features/audio/howlerSetup';
import styled from '../../../../style/styled';
import { useUser } from '../../../../hooks/useUser';
import { useDeepCompareEffect } from 'react-use';
import useAuthing from './useAuthing';
import { ActivationStatus } from '../../../../store/app/types';
import { isFuture } from 'date-fns';
import { logMessage } from '../../../../features/logging/logMessage';

interface Props {
  code?: string | null;
  setActivateError: React.Dispatch<React.SetStateAction<boolean>>;
}

// Activates a product (i.e. a tour) using a redeem code.
export const ActivateProduct = ({ code, setActivateError }: Props) => {
  const history = useHistory();
  const parsedQs = qs.parse(history.location.search);
  const dispatch = useDispatch();
  const { refetch: refetchUser } = useUser();
  const successfulActivations = useSelector(selectSuccessfulActivations);

  // IMPORTANT! This is required to prevent double calling of onActivateSuccess.
  // It is a ref because we don't want any side effects to be triggered as a result of changing this value.
  // This is used like a lock.
  const isActivationSuccessBeingHandled = useRef<boolean>(false);

  // get the cached activation data for the code
  const successfulActivationCache =
    (code && successfulActivations[code]) || null;

  // if there is a successful activation cache for the code, get the tour IDs IF the code is not expired
  const successfullyActivatedTourIDs = successfulActivationCache
    ? isFuture(successfulActivationCache.expiryDate)
      ? successfulActivationCache.tourIDs
      : []
    : [];

  const successfullyActivatedCodeExpiry =
    successfulActivationCache?.expiryDate || 0;

  const viewpointIDToLaunchPostActivation =
    history.location.pathname.includes('viewpoint') &&
    history.location.pathname.split('/').filter(Boolean).pop();

  const { authing, setAuthing } = useAuthing();

  const isActivatingOffline =
    env.ENABLE_OFFLINE_REDEMPTIONS &&
    !authing &&
    successfullyActivatedTourIDs.length > 0;

  // We don't want this side effect to trigger as a result of dispatching `addSuccessfulActivation` action.
  useDeepCompareEffect(() => {
    if (
      code &&
      successfullyActivatedTourIDs.length > 0 &&
      successfullyActivatedCodeExpiry
    ) {
      onActivateSuccess({
        redeemCodeUsed: code,
        activatedTourIDs: successfullyActivatedTourIDs,
        expiryDate: successfullyActivatedCodeExpiry,
      });
    }
  }, [successfullyActivatedTourIDs, successfullyActivatedCodeExpiry, code]);

  useEffect(() => {
    if (isActivatingOffline) {
      logMessage('Activation: Activating offline');
      dispatch(setActivationStatus(ActivationStatus.OFFLINE));
    } else if (authing) {
      logMessage('Activation: Authing');
      dispatch(setActivationStatus(ActivationStatus.AUTHENTICATING));
    } else {
      logMessage('Activation: Requesting online activation');
      dispatch(setActivationStatus(ActivationStatus.REQUESTING));
    }
  }, [dispatch, authing, isActivatingOffline]);

  const downloadTours = (tourIDs: Array<string>) => {
    const sanitisedTourIDs =
      env.IS_AK || env.IS_AO
        ? // I don't know what the agreement was for AK and AO, so I will keep it as it was.
          tourIDs
        : // But for all other apps, we will only download the first 4 tours automatically.
          // This was decided between Simon and Anusha on 20/10/2023.
          tourIDs.slice(0, 4);

    sanitisedTourIDs.forEach((tourID) => {
      dispatch(
        downloadPlaylistMetadata({
          playlistID: tourID,
          automatic: true,
        })
      );

      logMessage('Activation: Downloading tour', tourID);
    });
  };

  const launchViewpoint = (tourID: string, viewpointID: string) => {
    unlock();

    dispatch(disableFulscreenLoadingOverlay());

    history.push(ROUTES.tour, {
      playlistID: tourID,
      viewpointID,
    });
  };

  // This is called on a successful activation
  const onActivateSuccess = async ({
    redeemCodeUsed,
    activatedTourIDs,
    expiryDate,
  }: OnSuccessArgs) => {
    // This is required to prevent double calling of onActivateSuccess
    if (isActivationSuccessBeingHandled.current) {
      return;
    }

    logMessage('Activation: Success');

    isActivationSuccessBeingHandled.current = true;

    // We would refetch the tours so that the widgets for the tours that we just
    // activated will be rendered correctly.
    await refetchUser();

    logMessage('Activation: Refetching user');

    // Pin the last activated tours to the pinned tours
    dispatch(addPinnedTours(activatedTourIDs.map((tourID) => ({ tourID }))));

    // Download the activated tours automatically.
    downloadTours(activatedTourIDs);

    // capture analytics
    captureEvent({
      name: EventNames.ACTIVATED_PRODUCT,
      data: [{ key: DataKeys.REDEEM_CODE, value: redeemCodeUsed }],
    });

    // Store the successfully activated redeem codes in local storage.
    // The state change caused by this action will trigger the useEffect above,
    // which will call this function again. That's why we need the lock.
    dispatch(
      addSuccessfulActivation({
        code: redeemCodeUsed,
        tourIDs: activatedTourIDs,
        expiryDate,
      })
    );

    logMessage(
      'Activation: Storing successful activation for offline activation',
      {
        code: redeemCodeUsed,
        tourIDs: activatedTourIDs,
        expiryDate,
      }
    );

    // Close the activate modal
    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));

    // Redirections
    if (
      history.location.pathname.includes('itinerary') &&
      parsedQs[CodeKeys.REDEEM_CODE]
    ) {
      // redirect after activation

      const nextPathname = env.IS_STOCK
        ? history.location.pathname
        : ROUTES.index;

      history.push({
        pathname: nextPathname,
        search: removeQueryVariables([CodeKeys.REDEEM_CODE]),
      });

      logMessage('Activation: Redirecting to', nextPathname);
    } else if (
      viewpointIDToLaunchPostActivation &&
      parsedQs[CodeKeys.REDEEM_CODE] &&
      activatedTourIDs.length === 1
    ) {
      launchViewpoint(activatedTourIDs[0], viewpointIDToLaunchPostActivation);

      logMessage('Activation: Launching viewpoint', {
        tourID: activatedTourIDs[0],
        viewpointID: viewpointIDToLaunchPostActivation,
      });
    } else if (env.IS_STOCK) {
      // redirect after activation
      history.push({
        pathname: ROUTES.myTrips,
        search: '',
      });

      logMessage('Activation: Redirecting to', ROUTES.myTrips);
    } else {
      history.replace({
        pathname: ROUTES.index,
        search: '',
      });

      logMessage('Activation: Redirecting to', ROUTES.index);
    }

    dispatch(setActivationStatus(ActivationStatus.SUCCESS));
  };

  if (isActivatingOffline) {
    logMessage('Activation: Activating offline. No UI.');

    return null;
  }

  if (authing) {
    return (
      <Wrapper>
        <LogInScreen
          onSuccess={() => setAuthing(false)}
          // will be converted to i18n in the LogInScreen component
          reason="Please sign up or log in to redeem a voucher code."
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ActivateProductView
        codeFromDeeplink={code}
        onSuccess={onActivateSuccess}
        setActivateErrorMessage={(errorMessage: string) => {
          dispatch(setActivationStatus(ActivationStatus.FAILURE));
          dispatch(setActivationError(errorMessage));
          setActivateError(true);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
`;
