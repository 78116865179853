import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryVariable } from '../../utils/getQueryVariable';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import { CodeKeys } from '../../types';
import { unbox } from '../../utils/unbox';
import { captureEvent, DataKeys, EventNames } from '../../features/analytics';

// if there is a redeem code in the query string, extract it
// then open the modal, fill it and try to activate
export const AutoRedeemFromDeeplink = () => {
  const redeemCodeFromLink =
    unbox(getQueryVariable(CodeKeys.REDEEM_CODE)) || null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (redeemCodeFromLink) {
      captureEvent({
        name: EventNames.REDEMPTION_CODE_EXTRACTED,
        data: [
          {
            key: DataKeys.REDEEM_CODE,
            value: redeemCodeFromLink,
          },
        ],
      });

      dispatch(
        setModalType({
          modalType: ModalType.ACTIVATE_PRODUCT_MODAL,
          modalData: {
            code: redeemCodeFromLink,
          },
        })
      );
    }
  }, [dispatch, redeemCodeFromLink]);

  return null;
};
