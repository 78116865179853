import { useTranslation } from 'react-i18next';
import RdeemIcon from 'mdi-react/RedeemIcon';
import TickIcon from 'mdi-react/TickIcon';
import ErrorIcon from 'mdi-react/AlertCircleIcon';
import styled, { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import { useDispatch, useSelector } from 'react-redux';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import { selectActivationStatus } from '../../store/app/selectors';
import { ActivationStatus } from '../../store/app/types';
import { useEffect } from 'react';
import { setActivationStatus } from '../../store/app/actions';
import LoadingSpinnerSimple from '../../Routes/components/LoadingScreen/LoadingSpinnerSimple';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  showTextInMobile?: boolean;
}

export const ActivateProductButton = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const activationStatus = useSelector(selectActivationStatus);

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleButtonClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'ActivateProduct',
    });

    dispatch(
      setModalType({
        modalType: ModalType.ACTIVATE_PRODUCT_MODAL,
        modalData: { code: null },
      })
    );
  };

  // change the icon back to default after 5 seconds
  useEffect(() => {
    let timerID = -1;

    if (
      activationStatus === ActivationStatus.SUCCESS ||
      activationStatus === ActivationStatus.FAILURE
    ) {
      timerID = window.setTimeout(() => {
        dispatch(setActivationStatus(ActivationStatus.IDLE));
      }, 5000);
    }

    return () => {
      window.clearTimeout(timerID);
    };
  }, [activationStatus, dispatch]);

  const TextWrapper = props.showTextInMobile
    ? AppHeaderButtonText
    : AppHeaderButtonTextMobileHidden;

  return (
    <AppHeaderButtonContainer
      fullWidth={fullWidth}
      onClick={handleButtonClick}
      data-testid="header-redeem-button"
    >
      {activationStatus === ActivationStatus.AUTHENTICATING ||
      activationStatus === ActivationStatus.REQUESTING ? (
        <LoadingSpinnerSimple size={`${size * 1.4}px`} color={color} />
      ) : activationStatus === ActivationStatus.SUCCESS ? (
        <TickIcon color={color} size={size * 1.4} />
      ) : activationStatus === ActivationStatus.FAILURE ? (
        <ErrorIcon color={color} size={size * 1.4} />
      ) : (
        <RdeemIcon color={color} size={size * 1.4} />
      )}
      <TextWrapper size={size} color={color}>
        {t('Redeem')}
      </TextWrapper>
    </AppHeaderButtonContainer>
  );
};

const AppHeaderButtonTextMobileHidden = styled(AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
