import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button';
import { Padbox } from '../../../../Layouts/primitives/Padbox';
import { Stack } from '../../../../Layouts/primitives/Stack';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../style/SIZES';
import styled from '../../../../style/styled';

type Props = {
  handleConfirm: () => void;
  handleCancel: () => void;
};

export const TourExitConfirmationPopupView = ({
  handleConfirm,
  handleCancel,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Padbox padding={[32, 16, 16, 16]}>
        <Stack gutter={16}>
          <Title>{t('Are you sure you want to exit the tour?')}</Title>

          <ButtonContainer>
            <Button small onClick={handleConfirm}>
              {t('Yes')}
            </Button>

            <Button small secondary onClick={handleCancel}>
              {t('No')}
            </Button>
          </ButtonContainer>
        </Stack>
      </Padbox>
    </Container>
  );
};

const Container = styled.div`
  /* this centers the content inside the modal */
  margin: auto;
  color: ${(props) => props.theme.palette.common.black};

  @media only screen and (min-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    max-width: 300px;
  }
`;

const Title = styled.span`
  display: block;
  color: #000;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;
