/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppType {
  ANCIENT_WORLD = "ANCIENT_WORLD",
  ANCIENT_WORLD_STAGING = "ANCIENT_WORLD_STAGING",
  ANCIENT_WORLD_WHITE_LABEL = "ANCIENT_WORLD_WHITE_LABEL",
  ANCIENT_WORLD_WHITE_LABEL_STAGING = "ANCIENT_WORLD_WHITE_LABEL_STAGING",
  EXPEDITION = "EXPEDITION",
  UNITY_BESPOKE = "UNITY_BESPOKE",
  UNITY_INTERNAL = "UNITY_INTERNAL",
}

export enum ContentRestriction {
  AUTHENTICATED = "AUTHENTICATED",
  FREE_FOR_APPS_ONLY = "FREE_FOR_APPS_ONLY",
  NONE = "NONE",
  OWNER = "OWNER",
  PAID_USER = "PAID_USER",
}

export enum CurrencyCode {
  AUD = "AUD",
  BRL = "BRL",
  CAD = "CAD",
  CHF = "CHF",
  CNY = "CNY",
  CZK = "CZK",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  HUF = "HUF",
  ILS = "ILS",
  JPY = "JPY",
  MXN = "MXN",
  MYR = "MYR",
  NOK = "NOK",
  NZD = "NZD",
  PHP = "PHP",
  PLN = "PLN",
  RUB = "RUB",
  SEK = "SEK",
  SGD = "SGD",
  THB = "THB",
  TWD = "TWD",
  USD = "USD",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNSPECIFIED = "UNSPECIFIED",
}

export enum GeoJSONFileType {
  BOUNDARY = "BOUNDARY",
  BUILDINGS = "BUILDINGS",
  EXTERNAL_WALLS = "EXTERNAL_WALLS",
  INTERNAL_WALLS = "INTERNAL_WALLS",
  PARK = "PARK",
  ROADS = "ROADS",
  SITE_AREA = "SITE_AREA",
  TRAIL = "TRAIL",
  WATER = "WATER",
}

export enum GeoJSONType {
  LineString = "LineString",
  MultiLineString = "MultiLineString",
  MultiPoint = "MultiPoint",
  MultiPolygon = "MultiPolygon",
  Point = "Point",
  Polygon = "Polygon",
}

export enum LanguageCode {
  ar = "ar",
  da_DK = "da_DK",
  de_DE = "de_DE",
  el_GR = "el_GR",
  en_AU = "en_AU",
  en_GB = "en_GB",
  en_US = "en_US",
  es_ES = "es_ES",
  fr_CA = "fr_CA",
  fr_FR = "fr_FR",
  he_IL = "he_IL",
  hi_IN = "hi_IN",
  it_IT = "it_IT",
  ja_JP = "ja_JP",
  ko_KR = "ko_KR",
  nb_NO = "nb_NO",
  nl_NL = "nl_NL",
  pl_PL = "pl_PL",
  pt_BR = "pt_BR",
  pt_PT = "pt_PT",
  ru_RU = "ru_RU",
  sk_SK = "sk_SK",
  sv_SE = "sv_SE",
  tr_TR = "tr_TR",
  uk_UA = "uk_UA",
  zh = "zh",
}

export enum NarrationSource {
  GOOGLE_CLOUD_TEXT_TO_SPEECH = "GOOGLE_CLOUD_TEXT_TO_SPEECH",
  PROFESSIONALLY_RECORDED = "PROFESSIONALLY_RECORDED",
}

export enum PlaylistFeature {
  COMPASS_ORIENTATION = "COMPASS_ORIENTATION",
  CUSTOM_MAP = "CUSTOM_MAP",
  GOOGLE_MAP = "GOOGLE_MAP",
  SPLIT_SCREEN = "SPLIT_SCREEN",
  UNNUMBERED_VIEWPOINTS = "UNNUMBERED_VIEWPOINTS",
  USER_POSITION = "USER_POSITION",
  VIEWPOINT_PROXIMITY_SENSING = "VIEWPOINT_PROXIMITY_SENSING",
}

export enum ProductSKU {
  GIFT_CARD = "GIFT_CARD",
  PLAYLIST = "PLAYLIST",
  PLAYLIST_BUNDLE = "PLAYLIST_BUNDLE",
  VIEWPOINT = "VIEWPOINT",
}

export enum PurchaseMode {
  GIFT = "GIFT",
  PERSONAL = "PERSONAL",
}

export enum PurchaseOwnerType {
  CLIENT = "CLIENT",
  CONSUMER = "CONSUMER",
}

export enum SERVICE_STATUS {
  AVAILABLE = "AVAILABLE",
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  DISCONNECTED = "DISCONNECTED",
  DISCONNECTING = "DISCONNECTING",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum StripeProcessingStatus {
  COMMENCED = "COMMENCED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
}

export interface ActivateProductInput {
  redeemCode: string;
  recaptchaToken?: string | null;
  device?: NewDeviceInput | null;
}

export interface ActivateProductsInput {
  redeemCode: string;
  recaptchaToken?: string | null;
  products: ProductInput[];
  device?: NewDeviceInput | null;
}

export interface AnalyticEventInput {
  appID?: string | null;
  appVersion?: string | null;
  data?: (LooseAnalyticsMetadataInput | null)[] | null;
  deviceID?: string | null;
  eventID: string;
  location?: CoordinatesInput | null;
  name: string;
  sessionID?: string | null;
  timestamp: any;
  timezone?: string | null;
  userID?: string | null;
}

export interface AuthAsAnonymousInput {
  recaptchaToken?: string | null;
  affiliateCode?: string | null;
  aoUserID?: string | null;
  fingerPrintVisitorID?: string | null;
  redeemCode?: string | null;
}

export interface AuthWithFacebookInput {
  accessToken?: string | null;
  code?: string | null;
  appID: string;
  redirectURL: string;
  affiliateCode?: string | null;
  referralAppID?: string | null;
}

export interface AuthWithGoogleInput {
  accessToken: string;
  clientID: string;
  affiliateCode?: string | null;
  referralAppID?: string | null;
}

export interface AuthWithGoogleUsingCodeInput {
  code: string;
  clientID: string;
  affiliateCode?: string | null;
  referralAppID?: string | null;
}

export interface ChangePasswordInput {
  confirmPassword: string;
  password: string;
  resetPasswordSecret?: string | null;
}

export interface ConvertAnonymousAccountInput {
  email: string;
  name: string;
  password: string;
  recaptchaToken?: string | null;
}

export interface CoordinatesInput {
  longitude: number;
  latitude: number;
}

export interface CreateContactFormMessageInput {
  fromName: string;
  fromEmail: string;
  fromExpertise?: string | null;
  message: string;
  recaptchaToken?: string | null;
  subject?: string | null;
}

export interface CreateReviewInput {
  tourID: string;
  rating?: number | null;
  review?: string | null;
}

export interface CreateStripePaymentIntentInput {
  currencyCode: CurrencyCode;
  products: (ProductWithCountInput | null)[];
  purchaseMode: PurchaseMode;
  ownerType?: PurchaseOwnerType | null;
  promoCodeID?: string | null;
  isSandbox: boolean;
}

export interface DeleteAccountInput {
  accountDeletionSecret: string;
}

export interface GenerateJWTFromRefreshTokenInput {
  refreshToken: string;
}

export interface GetAncientWorldRecommendedToursInput {
  skipPlaylistIDs?: string[] | null;
  skipPlaylistBundleIDs?: string[] | null;
  limit?: number | null;
}

export interface GetAppInput {
  appID: string;
}

export interface GetOrderInput {
  orderID: string;
}

export interface GetPlaylistBundleByInternalReferenceInput {
  internalReference: string;
}

export interface GetPromoCodeByCodeInput {
  code: string;
}

export interface GetTourByInternalReferenceInput {
  internalReference: string;
}

export interface LogInWithEmailInput {
  email: string;
  password: string;
  verificationURL?: string | null;
  recaptchaToken?: string | null;
}

export interface LooseAnalyticsMetadataInput {
  key?: string | null;
  value?: string | null;
}

export interface NewDeviceInput {
  installationID?: string | null;
  name?: string | null;
  os?: string | null;
}

export interface ProductInput {
  productSKU?: ProductSKU | null;
  productID?: string | null;
}

export interface ProductWithCountInput {
  productSKU: string;
  productID: string;
  count: number;
}

export interface SendAccountDeletionVerificationLinkInput {
  email: string;
  accountDeletionURL: string;
  recaptchaToken?: string | null;
}

export interface SendPostTourSurveyInput {
  tourID: string;
  recaptchaToken?: string | null;
}

export interface SendResetPasswordLinkInput {
  email: string;
  passwordResetURL?: string | null;
  recaptchaToken?: string | null;
}

export interface SignUpWithEmailInput {
  email: string;
  name?: string | null;
  password: string;
  affiliateCode?: string | null;
  ageMin?: number | null;
  ageMax?: number | null;
  countryID?: string | null;
  gender?: Gender | null;
  howDidYouHearAboutUs?: string | null;
  interests?: string[] | null;
  verificationURL?: string | null;
  skipVerification?: boolean | null;
  recaptchaToken?: string | null;
  referralAppID?: string | null;
}

export interface SubmitAnalyticEventsInput {
  events?: (AnalyticEventInput | null)[] | null;
}

export interface SubmitPayPalPurchaseMultiWithCountsAndMultiCurrenciesInput {
  currencyCode: CurrencyCode;
  orderID: string;
  products: (ProductWithCountInput | null)[];
  productURL: string;
  isSandbox: boolean;
  purchaseMode: PurchaseMode;
  ownerType?: PurchaseOwnerType | null;
  promoCodeID?: string | null;
}

export interface SubmitStripePurchaseInput {
  clientSecret: string;
}

export interface SubscribeToNewsletterUnauthedInput {
  email: string;
  name?: string | null;
  recaptchaToken?: string | null;
  productURL: string;
}

export interface VerifyAccountInput {
  verificationSecret: string;
}

export interface VerifyOperationInput {
  secret: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
