import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import RegularShape from 'ol/style/RegularShape';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import { SelectedFeature } from './../../types';
import Point from 'ol/geom/Point';

export function createMarkerFeature(
  { position, id, idx, type, thumbnailURL, name, description }: SelectedFeature,
  selectedID?: string | null
) {
  const adjustedPosition = fromLonLat(position);

  const feature = new Feature({
    geometry: new Point(adjustedPosition),
  });

  feature.setProperties({
    id,
    type,
    name,
    idx,
    description,
    thumbnailURL,
    latitude: adjustedPosition[0],
    longitude: adjustedPosition[1],
  });

  const triangle = new Style({
    image: new RegularShape({
      fill: new Fill({
        color: selectedID === id ? '#000' : '#ff0000',
      }),
      points: 3,
      radius: 14,
      rotation: Math.PI,
      displacement: [0, -5],
    }),
    // zIndex does not seem to get applied to a group, so have to set them individually
    zIndex: idx * 3,
  });

  const circle = new Style({
    image: new CircleStyle({
      fill: new Fill({ color: '#fff' }),
      // fill: new Fill({ color: "#ff0000" }),
      stroke: new Stroke({
        width: 3,
        color: selectedID === id ? '#000' : '#ff0000',
      }),
      radius: 12,
      displacement: [0, 16],
    }),
    // zIndex does not seem to get applied to a group, so have to set them individually
    zIndex: idx * 3 + 1,
  });

  const text = new Style({
    text: new Text({
      text: (parseInt(idx.toString()) + 1).toString(),
      scale: 1.25,
      fill: new Fill({ color: '#000' }),
      // fill: new Fill({ color: "#fff" }),

      offsetY: -15,
      // stroke: new Stroke({ color: "#000" }),
    }),
    // zIndex does not seem to get applied to a group, so have to set them individually
    zIndex: idx * 3 + 2,
  });

  feature.setStyle([circle, triangle, text]);

  return feature;
}
