import React, { useState } from 'react';
import { Howl, Howler } from 'howler';
import { mdiPause } from '@mdi/js';
import { mdiPlay } from '@mdi/js';
import styled from '../../../../style/styled';
import { Section } from './styledComponents/Section';
import { EXPLORE_WEB_GetTourByInternalReference_result_introNarrations } from '../../../../graphql/queries/__generated__/EXPLORE_WEB_GetTourByInternalReference';
import { IconButton } from '../../../IconButton';
import { COLORS } from '../../../../style/colors';
import { Lottie } from '../../../../components/Lottie';
import animationData from '../../../../animations/genericLoading.json';
import { DEFAULT_LANGUAGE, languages } from '../../../../consts';
import { LanguageCode } from '../../../../graphql/globalTypes';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/app/selectors';

enum AudioState {
  paused,
  playing,
  completed,
  systemPaused,
  loading,
}

interface Props {
  introNarrations: EXPLORE_WEB_GetTourByInternalReference_result_introNarrations[];
  guildeAvatarURI?: string | null;
}

export const TourInfoIntroNarrationSection: React.FC<Props> = ({
  introNarrations,
  guildeAvatarURI,
}) => {
  const language = useSelector(selectLanguage);

  const narration = getIntroNarration(introNarrations, language);

  if (!narration) {
    return null;
  }

  const trackURI = narration.voiceTrack.uri;
  const duration = narration.voiceTrackLengthInSeconds;

  return (
    <IntroNarrationSection
      trackURI={trackURI}
      duration={duration}
      guildeAvatarURI={guildeAvatarURI}
    />
  );
};

export function getIntroNarration(
  introNarrations: EXPLORE_WEB_GetTourByInternalReference_result_introNarrations[],
  language: LanguageCode
):
  | EXPLORE_WEB_GetTourByInternalReference_result_introNarrations
  | null
  | undefined {
  let narration = introNarrations.find(
    (n) => n.language.code === languages[language]?.code
  );

  if (!narration) {
    narration = introNarrations.find(
      (n) => n.language.code === languages[DEFAULT_LANGUAGE]?.code
    );
  }

  return narration;
}

interface IntroNarrationSectionProps {
  trackURI: string;
  guildeAvatarURI?: string | null;
  duration: number;
}

interface IntroNarrationSectionState {
  seekPos: number;
  playingState: AudioState;
  length: number;
}

class IntroNarrationSection extends React.Component<
  IntroNarrationSectionProps,
  IntroNarrationSectionState
> {
  state = {
    seekPos: 0,
    playingState: AudioState.loading,
    length: 0,
  };

  api: Howl | null = null;
  _timerID: number = -1;

  componentDidMount() {
    this.api = new Howl({
      src: [this.props.trackURI],
      volume: 1,
      loop: false,
      html5: false,
    });

    this.api.on('end', () => {
      this.setState({ playingState: AudioState.completed, seekPos: 0 });
    });

    this.api.on('load', () => {
      if (this.api) {
        let duration = this.api.duration();

        if (duration === Infinity) {
          duration = this.props.duration;
        }

        this.setState({
          playingState: AudioState.systemPaused,
          length: Math.floor(duration),
        });
      }
    });

    this.api.on('pause', () => {
      this.setState({ playingState: AudioState.paused });
    });

    this.api.on('play', () => {
      if (this.api) {
        this.setState({ playingState: AudioState.playing });

        window.clearTimeout(this._timerID);

        this._timerID = window.setTimeout(() => {
          this.step();
        }, 500);
      }
    });
  }

  componentWillUnmount() {
    if (this.api) {
      this.api.off();
      this.api.unload();
    }

    window.clearTimeout(this._timerID);
  }

  step = () => {
    if (!this.api) {
      return;
    }

    const seekPos = this.api.seek();

    if (typeof seekPos === 'number') {
      this.setState({ seekPos });
    }

    const isPlaying = this.api.playing();

    if (isPlaying) {
      // continue stepping
      requestAnimationFrame(this.step);
    }
  };

  pause = () => {
    if (this.api) {
      this.api.pause();
    }
  };

  play = () => {
    if (this.api) {
      if (Howler.ctx) {
        Howler.ctx.resume();
      }

      const isPlaying = this.api.playing();

      if (!isPlaying) {
        this.api.play();
      }
    }
  };

  seek = (value: number) => {
    if (!this.api) {
      return;
    }

    this.api.seek(value);

    this.setState({ seekPos: value });
  };

  render() {
    const { playingState, seekPos, length } = this.state;
    const { guildeAvatarURI } = this.props;

    return (
      <Section>
        <Container>
          {guildeAvatarURI && (
            <AvatarContainer>
              <img alt="" src={guildeAvatarURI} width="100%" height="100%" />
            </AvatarContainer>
          )}

          <PlayingStatus>
            {playingState === AudioState.loading && (
              <Lottie
                height={42}
                width={42}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            )}

            {playingState === AudioState.playing && (
              <IconButton
                path={mdiPause}
                onClick={this.pause}
                iconSize={28}
                iconBgColor={COLORS.darkTextColor}
              />
            )}

            {(playingState === AudioState.paused ||
              playingState === AudioState.systemPaused) && (
              <IconButton
                path={mdiPlay}
                onClick={this.play}
                iconSize={28}
                iconBgColor={COLORS.darkTextColor}
              />
            )}
          </PlayingStatus>

          <AudioSliderContainer>
            <AudioSlider
              seek={this.seek}
              seekPos={seekPos}
              length={length}
              playingState={playingState}
              pause={this.pause}
              play={this.play}
            />
          </AudioSliderContainer>
        </Container>
      </Section>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
`;

const PlayingStatus = styled.div`
  margin-right: 16px;
`;

const AudioSliderContainer = styled.div`
  flex-grow: 1;
`;

interface AudioSliderProps {
  seek: (value: number) => void;
  seekPos: number;
  length: number;
  playingState: AudioState;
  pause: () => void;
  play: () => void;
}

const AudioSlider: React.FC<AudioSliderProps> = ({
  seek,
  seekPos,
  length,
  playingState,
  pause,
  play,
}) => {
  const [
    previousPlayingState,
    setPreviousPlayingState,
  ] = useState<AudioState | null>();

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);

    seek(value * 0.1);
  };

  // if playing, pause the narration until mouse up
  // but store the previous playing state
  const onMouseDown = () => {
    setPreviousPlayingState(playingState);

    if (playingState === AudioState.playing) {
      pause();
    }
  };

  const onMouseUp = () => {
    // if audio was playing when mouse down was pressed, ]
    // resume playback
    if (previousPlayingState === AudioState.playing) {
      play();
    }
  };

  const position = length === 0 ? 0 : (seekPos * 100) / length;

  return (
    <Slider
      type="range"
      id="points"
      name="points"
      min="0"
      // this adds a bit of resolution when sliding, but doesn't affect the resolution of playtiume progress.
      max={length * 10}
      value={seekPos * 10}
      onChange={handleClick}
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onMouseUp}
      position={position}
      interactive={length > 0}
    />
  );
};

const Slider = styled.input.attrs<{
  position: number;
}>((props) => ({
  style: {
    background: `linear-gradient(to right,${COLORS.darkTextColor} 0%, ${COLORS.darkTextColor} ${props.position}%, #D1D1D1 ${props.position}%, #D1D1D1 100%)`,
  },
}))<{ position: number; interactive: boolean }>`
  outline: none;
  align-items: center;
  appearance: none;
  background: #d1d1d1;
  cursor: pointer;
  display: flex;
  height: 4px;
  width: 100%;
  pointer-events: ${(props) => (props.interactive ? 'all' : 'none')};

  &::-webkit-slider-thumb {
    border: 1px solid ${COLORS.darkTextColor};
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${COLORS.lightTextColor};
    cursor: pointer;
    appearance: none;
    margin-top: -6px;
  }

  &::-webkit-slider-runnable-track {
    height: 4px;
    content: '';
    outline: none;
  }

  &::-moz-range-thumb {
    border: 1px solid ${COLORS.darkTextColor};
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${COLORS.lightTextColor};
    cursor: pointer;
    position: relative;
    outline: none;
  }

  &::-moz-range-progress {
    height: 4px;
    background: ${COLORS.darkTextColor};
    border: 0;
    margin-top: 0;
    outline: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    background: #d1d1d1;
    outline: none;
  }

  &::-ms-track {
    background: transparent;
    border: 0;
    border-color: transparent;
    border-radius: 0;
    border-width: 0;
    color: transparent;
    height: 4px;
    margin-top: 10px;
    width: 100%;
  }

  &::-ms-thumb {
    border: 1px solid ${COLORS.darkTextColor};
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: ${COLORS.lightTextColor};
    cursor: pointer;
  }

  &::-ms-tooltip {
    display: none;
  }

  &::-ms-fill-lower {
    background: ${COLORS.darkTextColor};
    border-radius: 0;
  }

  &::-ms-fill-upper {
    background: #d1d1d1;
    border-radius: 0;
  }
`;
