import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import AppHeader from '../../components/appHeader/AppHeader';
import AppHeaderMarketing from '../../components/appHeader/AppHeaderMarketing';
import { Footer } from '../../Layouts/Footer/Footer';
import FacebookRedirectHandler from '../Auth/login/FacebookRedirectHandler';
import { UnauthedRoute } from '../components/UnauthedRoute';
import { AuthedRoute } from '../components/AuthedRoute';
import { ROUTES } from '../routes';
import styled from '../../style/styled';
import { COLORS } from '../../style/colors';
import { NAVBAR_HEIGHT } from '../../consts';
import { env } from '../../App/config/env';
import { FullScreenLoadingOverlay } from '../../App/components/FullScreenLoadingOverlay';
import { getSearchQueryParam } from '../../utils/getSearchQueryParam';

const HomeScreen = React.lazy(() => import('../Home/HomeScreen'));
const ItineraryScreen = React.lazy(
  () => import('../Itinerary/ItineraryScreen')
);
const BundleItineraryScreen = React.lazy(() => import('../BundleItinerary'));
const DevScreen = React.lazy(() => import('../Dev/DevScreen'));
const VerificationRoutes = React.lazy(
  () => import('../Verification/VerificationRoutesView')
);
const AuthRoutes = React.lazy(() => import('../Auth/AuthRoutes'));
const AccountRoutes = React.lazy(() => import('../Account/AccountRoutes'));
const LibraryScreen = React.lazy(() => import('../Library/LibraryScreen'));

const LicensesScreen = React.lazy(() => import('../Licenses/LicensesScreen'));
const GuidesScreen = React.lazy(() => import('../Guides/GuidesScreen'));
const ShopRoutes = React.lazy(() => import('../Shop/ShopRoutes'));
const DeleteAccountRoutes = React.lazy(
  () => import('../DataDeletion/DeleteAccountRoutes')
);
const MerchScreen = React.lazy(() => import('../Merch/MerchScreen'));
const FAQScreen = React.lazy(() => import('../FAQ/FAQScreen'));
const TermsConditionsScreen = React.lazy(
  () => import('../TermsConditions/TermsConditionsScreen')
);
const PrivacyPolicyScreen = React.lazy(
  () => import('../PrivacyPolicy/PrivacyPolicyScreen')
);
const SetupScreen = React.lazy(() => import('../Setup/SetupScreen'));

const isFromProductLink = (searchStr: string) =>
  getSearchQueryParam(searchStr, 'utm_source') === 'app_header' &&
  getSearchQueryParam(searchStr, 'utm_medium') === 'product_link';

const RegularPageRoutes = () => {
  const {
    banner: { title },
  } = env.APP_ENV;

  const MyMain =
    title && (title === 'Fatima' || title === 'A Journey Through Faith')
      ? NewMain
      : Main;

  const location = useLocation();

  return (
    <PageContainer>
      <FullScreenLoadingOverlay />

      {/* If we are on the index page and env.ENABLE_MARKETING_HOME_SCREEN is true, then show a different AppHeader */}
      {(location.pathname === ROUTES.index ||
        isFromProductLink(location.search)) &&
      env.ENABLE_MARKETING_HOME_SCREEN ? (
        <AppHeaderMarketing />
      ) : (
        <AppHeader />
      )}

      <MyMain>
        <Switch>
          {/* authed routes */}
          <AuthedRoute path={ROUTES.account} component={AccountRoutes} />

          {/* only allowed when unauthed */}
          <UnauthedRoute path={ROUTES.auth} component={AuthRoutes} />

          {/* routes below have no auth check */}
          <Route path={ROUTES.library} component={LibraryScreen} />

          <Route
            exact
            path={ROUTES.facebookRedirectHandler}
            component={FacebookRedirectHandler}
          />

          <Route exact path={ROUTES.dev} component={DevScreen} />

          <Route exact path={ROUTES.setup} component={SetupScreen} />

          <Route exact path={ROUTES.merch} component={MerchScreen} />

          <Route exact path={ROUTES.yourGuides} component={GuidesScreen} />

          <Route exact path={ROUTES.licenses} component={LicensesScreen} />

          <Route
            exact
            path={ROUTES.privacyPolicy}
            component={PrivacyPolicyScreen}
          />

          <Route path={ROUTES.deleteAccount} component={DeleteAccountRoutes} />

          <Route path={ROUTES.verify} component={VerificationRoutes} />

          <Route path={ROUTES.shop} component={ShopRoutes} />

          <Route
            exact
            path={ROUTES.termsConditions}
            component={TermsConditionsScreen}
          />

          <Route exact path={ROUTES.faq} component={FAQScreen} />

          <Route path={ROUTES.itinerary} component={ItineraryScreen} />
          <Route
            path={ROUTES.bundle_itinerary}
            component={BundleItineraryScreen}
          />

          {/* Do not set exact here because that will break loading whitelabel apps */}
          <Route path={ROUTES.index} component={HomeScreen} />

          <Redirect to={ROUTES.index} />
        </Switch>
      </MyMain>

      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </PageContainer>
  );
};

export default RegularPageRoutes;

const PageContainer = styled.main`
  background: ${COLORS.primaryBackgroundColor};
  color: ${COLORS.primaryFontColor};
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Main = styled.main`
  /* this needs to be exactly the same height as the header, otherwise there would be a gap in tourinfo page between the header and the cover image for example */
  padding-top: ${NAVBAR_HEIGHT}px;
  width: 100%;
  /* done to push footer to the bottom of the page in case there isn't much content. */
  flex-grow: 1;
`;

const NewMain = styled.main`
  padding-top: 0;

  width: 100%;
  /* done to push footer to the bottom of the page in case there isn't much content. */
  flex-grow: 1;
`;
