import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from './Asset';
import { I18NSTRING } from './I18nString';

export const PRE_TOUR_VIEWPOINT_FRAGMENT = gql`
  fragment PreTourViewpointFragment on Viewpoint {
    id
    internalReference
    nameI18n {
      ...I18nStringFragment
    }
    imagesCount
    timePeriodSpecific
    thumbnail {
      ...AssetFragment
    }
    location {
      type
      coordinates {
        longitude
        latitude
      }
    }
    streetViewLocation {
      type
      coordinates {
        longitude
        latitude
      }
    }
    streetViewHeading
  }

  ${ASSET_FRAGMENT}
  ${I18NSTRING}
`;
