import styled from '../../../style/styled';
import { useTranslation } from 'react-i18next';
import { reloadApp } from '../../../utils/reloadApp';
import { COLORS } from '../../../style/colors';
import { LinkLikeButton } from '../../../styledComponents/LinkLikeButton';
import { DataKeys, EventNames, captureEvent } from '../../analytics';
import { env } from '../../../App/config/env';

type Props = any;

// closeToast is injected by react-toastify
export const UpdateAvailableAlert = ({ closeToast }: Props) => {
  const { t } = useTranslation();

  return (
    <MessageContainer>
      <MessageText>{t('An update to the app is available')}</MessageText>

      <ButtonCotainer>
        <Button
          onClick={() => {
            captureEvent({
              name: EventNames.APP_UPDATE_INITIATED,
              data: [
                {
                  key: DataKeys.CURRENT_VERSION,
                  value: env.APP_VERSION,
                },
              ],
            }).then(() => reloadApp());
          }}
        >
          {t('Update now')}
        </Button>

        <Spacer />

        <Button onClick={closeToast}>{t('Update later')}</Button>
      </ButtonCotainer>
    </MessageContainer>
  );
};

const Button = styled(LinkLikeButton)`
  color: #3fc4ff;

  &:hover {
    color: ${COLORS.white};
  }
`;

const ButtonCotainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Spacer = styled.div`
  width: 8px;
`;

const MessageContainer = styled.div``;

const MessageText = styled.p`
  color: ${COLORS.white};
  margin-bottom: 16px;
`;
