import { useContext, useEffect } from 'react';
import Zoom from 'ol/control/Zoom';
import MapContext from '../MapContext';

const ZoomControl = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    let zoomControl: Zoom;

    if (map) {
      zoomControl = new Zoom();

      // @ts-ignore
      map.controls.push(zoomControl);
    }

    return () => {
      if (map && zoomControl) {
        // @ts-ignore
        map.controls.remove(zoomControl);
      }
    };
  }, [map]);

  return null;
};

export default ZoomControl;
