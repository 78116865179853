import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setModalType } from '../../../../store/modal/actions';
import { ModalType } from '../../../../store/modal/types';
import { closeViewer } from '../../../../store/viewer/actions';
import { captureButtonPress } from '../../../../features/analytics';
import { ROUTES } from '../../../../Routes/routes';
import { env } from '../../../config/env';
import { getRedirectionURLOnTourExit } from '../../../../utils/getRedirectionURLOnTourExit';
import { TourExitConfirmationPopupView } from './TourExitConfirmationPopupView';

interface Props {
  tourID: string;
  viewpointID: string;
  isTrialTour: boolean;
}

// Confirmation when trying to exit a tour
const TourExitConfirmationPopup = ({
  tourID,
  viewpointID,
  isTrialTour,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Tour Close Confirmed',
      tourID,
      viewpointID,
    });

    // redirection logic
    const externalURL = getRedirectionURLOnTourExit(env.APP_ENV);

    if (externalURL) {
      window.location.href = externalURL;
      return;
    } else if (isTrialTour) {
      history.push(ROUTES.index);
    } else {
      history.push(ROUTES.index);
    }

    // clean up
    dispatch(closeViewer());
    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));
  };

  const handleCancel = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Tour Close Cancelled',
      tourID,
      viewpointID,
    });

    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));
  };

  return (
    <TourExitConfirmationPopupView
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
    />
  );
};

export default TourExitConfirmationPopup;
