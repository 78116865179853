import React, { PropsWithChildren, useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { captureButtonPress } from '../features/analytics';

interface Props extends LinkProps {
  buttonName: string;
}

// TODO: [AW-752] there is an issue with iOS where you need to double click a link to activate :S
// TODO: write tests
export default function LinkWithCapture({
  children,
  buttonName,
  ...linkProps
}: PropsWithChildren<Props>) {
  const onClick = useCallback(() => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName,
    });
  }, [buttonName]);

  return (
    <Link onClick={onClick} {...linkProps}>
      {children}
    </Link>
  );
}
