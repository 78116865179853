import { useState } from 'react';
import styled from '../style/styled';
import * as Sentry from '@sentry/react';
import { SCREEN_WIDTH_BREAK_SMALL } from '../style/SIZES';
import EmailIcon from 'mdi-react/EmailIcon';
import { useMutation } from '@apollo/client';
import {
  Data,
  Variables,
  SUBSCRIBE_TO_NEWSLETTER_UNAUTHED,
} from '../graphql/mutations/SubscribeToNewsletterUnauthed';
import { Modal } from './Modal';
import { ErrorView } from './ErrorView';
import { captureButtonPress } from '../features/analytics';
import Alert from './Alert';
import isEmail from '../utils/isEmail';
import { ErrorText } from '../styledComponents/ErrorText';
import { Paragraph } from '../styledComponents/Paragraph';
import { Input } from '../styledComponents/Input';
import { captureEvent, DataKeys, EventNames } from '../features/analytics';
import { UNKNOWN_ERROR } from '../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../App/config/reporting/captureInSentry';
import { getVerificationURL } from '../utils/getVerificationURL';

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();

  const verificationURL = getVerificationURL();

  const [subscribeMutation, { loading }] = useMutation<Data, Variables>(
    SUBSCRIBE_TO_NEWSLETTER_UNAUTHED,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        setError(error);
        setShowModal(true);
      },
      onCompleted: ({ result }) => {
        const { subscribed, error } = result;

        if (subscribed) {
          setEmail('');
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          setError(new Error(errorMessage));
        }

        setShowModal(true);
      },
    }
  );

  if (!verificationURL) {
    return null;
  }

  const handleSubmit = (recaptchaToken: string | null) => {
    setError(null);

    const trimmedEmail = email.trim();

    if (!isEmail(trimmedEmail)) {
      setError(new Error('Please enter a valid email'));
    } else {
      const variables: Variables = {
        input: {
          email: trimmedEmail,
          productURL: verificationURL,
        },
      };

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      subscribeMutation({ variables });
    }
  };

  const onKeyDown = () => {};

  return (
    <>
      <Container>
        <CallToAction>
          {t('Keep me up-to-date with the latest news from Ancient World.')}
        </CallToAction>

        <label>
          <Grid>
            <Input
              name="email"
              type="email"
              placeholder={t('please enter your email')}
              bgColor="#fff"
              value={email}
              disabled={loading}
              onKeyDown={onKeyDown}
              onFocus={() => setError(null)}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <Button
              type="button"
              aria-label="subscribe to newsletter"
              disabled={loading}
              onClick={() => {
                captureButtonPress({
                  buttonName: 'SubscribeToNewsletter',
                  page: window.location.pathname,
                });

                handleSubmit(null);
              }}
            >
              <ButtonText>
                {loading ? t('Please Wait...') : t('Subscribe')}
              </ButtonText>
              <div>
                <EmailIcon />
              </div>
            </Button>
          </Grid>
        </label>

        {!showModal && error && (
          <ErrorText>{t(error.message || UNKNOWN_ERROR)}</ErrorText>
        )}
      </Container>

      <Modal
        name="Subscribe manual"
        isOpen={showModal}
        lockBackground
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Subscribe manual',
              },
            ],
          });

          setShowModal(false);
          setError(null);
        }}
        clickOutsideToClose={true}
      >
        {error && <ErrorView error={error} />}
        {!error && (
          <Alert title="THANK YOU!">
            <Paragraph>
              You've subscribed to Ancient World communications. You will
              receive an email confirmation of your subscription. Please add{' '}
              <strong>seeker@ancient-world.co</strong> to your address book to
              avoid Ancient World communications going to your spam box.
            </Paragraph>
          </Alert>
        )}
      </Modal>
    </>
  );
};

const CallToAction = styled.p`
  margin-bottom: 8px;
`;

const ButtonText = styled.span`
  margin-right: 4px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-right: 0;
    display: none;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr fit-content(100px);
`;

const Container = styled.div`
  width: 100%;

  p {
    user-select: none;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Button = styled.button`
  background-color: ${(props) => props.theme.palette.primary.main};
  border: none;
  color: ${(props) => props.theme.palette.primary.contrastColor};
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;
