import { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { selectLocationRequested } from '../../store/location/selectors';
import {
  updateUserPosition,
  setLocationRequested,
} from '../../store/location/actions';
import { env } from '../../App/config/env';
import { GPSKalmanFilter } from '../geolocation/gpsKalmanFilter';
import { usePrevious } from '../../hooks/usePrevious';
import shouldRequestLocationWhenAppStart from '../whitelabeling/branding/utils/shouldRequestLocationWhenAppStart';
import LocationPermissionsDeniedMessage from './LocationPermissionsDeniedMessage';
import { logMessage } from '../logging/logMessage';

const kalmanFilter = new GPSKalmanFilter();

// TODO: [LVR-3132] As a developer, I have figured out how to reliably resume location monitoring, so that I can disable location monitoring outside a tour to save power
export const CurrentLocationWatcher = () => {
  const dispatch = useDispatch();
  const locationRequested = useSelector(selectLocationRequested);
  const locationRequestedPrevious = usePrevious(locationRequested);
  const watchID = useRef<number>(-1);
  const refAutomatic = useRef<boolean>(false);

  const startWatchPosition = useCallback(() => {
    logMessage('Starting watch position');

    if (watchID.current !== -1) {
      // clear the watchID if it's already set
      navigator.geolocation.clearWatch(watchID.current);
    }

    watchID.current = navigator.geolocation.watchPosition(
      (currentLocation) => {
        // denoise gps
        if (currentLocation && env.ENABLE_GPS_FILTERING_FOR_GOOGLE_MAPS) {
          const { coords, timestamp } = currentLocation;

          // denoise gps reading using a kalmanFilter (TODO: does not seem to work, see if the implementation is correct)
          const [longitude, latitude] = kalmanFilter.process(
            coords.latitude,
            coords.longitude,
            coords.accuracy,
            timestamp
          );

          // TODO: investigate why spreading doesn't work
          // update the currentLocation with the denoised lat,lng values
          const smoothedLocation: GeolocationPosition = {
            coords: {
              latitude,
              longitude,
              altitude: coords.altitude,
              accuracy: coords.accuracy,
              altitudeAccuracy: coords.altitudeAccuracy,
              speed: coords.speed,
              heading: coords.heading,
            },
            timestamp,
          };

          dispatch(updateUserPosition({ currentLocation: smoothedLocation }));
        } else if (
          // no denoising
          currentLocation &&
          !env.ENABLE_GPS_FILTERING_FOR_GOOGLE_MAPS
        ) {
          dispatch(updateUserPosition({ currentLocation }));
        }
      },
      (error) => {
        logMessage('Error getting location', error);

        // TODO: handle POSITION_UNAVAILABLE and TIMEOUT errors
        if (
          !refAutomatic.current &&
          error.code === GeolocationPositionError.PERMISSION_DENIED
        ) {
          toast.error(<LocationPermissionsDeniedMessage />, {
            autoClose: 10000,
            pauseOnHover: true,
            hideProgressBar: true,
          });
        }

        refAutomatic.current = false;

        dispatch(setLocationRequested({ requested: false }));
      },
      {
        enableHighAccuracy: true,
        maximumAge: 10000,
      }
    );
  }, [dispatch]);

  const clearWatchPosition = useCallback(() => {
    logMessage('Clearing watch position');

    kalmanFilter.reset();
    navigator.geolocation.clearWatch(watchID.current);
    watchID.current = -1;
  }, []);

  // Start watching location when locationRequested is true
  useEffect(() => {
    const requestChanged = locationRequested !== locationRequestedPrevious;

    logMessage(
      `Location requested changed from ${locationRequestedPrevious} to ${locationRequested}`
    );

    if (
      watchID.current === -1 &&
      requestChanged &&
      locationRequested &&
      navigator.geolocation
    ) {
      logMessage('Starting watch position');

      startWatchPosition();
    } else if (
      watchID.current !== -1 &&
      requestChanged &&
      !locationRequested &&
      navigator.geolocation
    ) {
      logMessage('Clearing watch position');

      clearWatchPosition();
    }
  }, [
    locationRequested,
    locationRequestedPrevious,
    dispatch,
    startWatchPosition,
    clearWatchPosition,
  ]);

  // Stop watching location when app is not visible.
  // Resume watching location when app is visible.
  useEffect(() => {
    function handleVisibilityChange() {
      if (
        document.visibilityState === 'visible' &&
        navigator.geolocation &&
        locationRequested
      ) {
        logMessage('Resuming watch position');

        if (watchID.current !== -1) {
          clearWatchPosition();
        }

        startWatchPosition();
      } else if (
        document.visibilityState === 'hidden' &&
        watchID.current !== -1 &&
        navigator.geolocation
      ) {
        logMessage('Pausing watch position');

        clearWatchPosition();
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [startWatchPosition, clearWatchPosition, locationRequested]);

  useEffect(() => {
    // Request location when app starts if the feature is enabled
    if (shouldRequestLocationWhenAppStart()) {
      logMessage('Requesting location when app starts');

      // A trigger to prevent the error message from showing up if the user has denied location permissions
      // when location is turned on automatically
      refAutomatic.current = true;

      dispatch(setLocationRequested({ requested: true }));
    }

    return () => {
      // clean up the watchID when unmounting
      if (watchID.current !== -1) {
        kalmanFilter.reset();
        navigator.geolocation.clearWatch(watchID.current);
      }
    };
  }, [dispatch]);

  return null;
};
