import React, { AnchorHTMLAttributes, useCallback } from 'react';
import { captureButtonPress } from '../features/analytics';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  buttonName: string;
  onMouseOver?: () => void;
}

// TODO: [AW-752] there is an issue with iOS where you need to double click a link to activate :S
// TODO: write tests
export default function ExternalLinkWithCapture({
  children,
  buttonName,
  onMouseOver = () => {},
  ...linkProps
}: Props) {
  const onClick = useCallback(() => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName,
    });
  }, [buttonName]);

  return (
    <a onClick={onClick} onMouseOver={onMouseOver} {...linkProps}>
      {children}
    </a>
  );
}
