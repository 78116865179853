import { gql } from '@apollo/client';
import { PURCHASEABLE_TOUR_FRAGMENT } from './PurchaseableTour';
import { TOUR_VIEWPOINT_FRAGMENT } from './TourViewpoint';

export const TOUR_FRAGMENT = gql`
  fragment TourFragment on Playlist {
    ...PurchaseableTourFragment

    narrationConfig {
      gender
      languageCode
      narrationSource
    }

    map {
      id
      projectionCode
      extXMin
      extYMin
      extXMax
      extYMax
      defaultViewBoxXMin
      defaultViewBoxYMin
      defaultViewBoxXMax
      defaultViewBoxYMax
      geoJSONFiles {
        id
        asset {
          id
          uri
          contentLength
        }
        geoJSONFileType
      }
    }

    viewpoints {
      ...TourViewpointFragment
    }
  }

  ${PURCHASEABLE_TOUR_FRAGMENT}
  ${TOUR_VIEWPOINT_FRAGMENT}
`;
