import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/app/selectors';
import { ROUTES } from '../routes';

type Props = RouteProps & {
  component: any;
};

export const TourRoute = ({ component: Component, ...restProps }: Props) => {
  const isAuthed = useSelector(selectIsAuthenticated);
  const allowAccess = // @ts-ignore TODO: fix later and do it properly for trial tour
    restProps.location?.state?.playlistID === '5ee83853cc7ee6000776bbfe' ||
    isAuthed;

  const renderFn = (props: RouteProps) =>
    allowAccess ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: ROUTES.auth }} />
    );

  return <Route {...restProps} render={renderFn} />;
};
