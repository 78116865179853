import * as icons from '@mdi/js';
import MdiIcon from './MdiIcon';

type Props = {
  iconName: string;
  color?: string;
  size?: number | string;
  horizontal?: boolean;
  vertical?: boolean;
  rotate?: number;
  spin?: number | boolean;
};

type IconsType = typeof icons & { [key: string]: string };

// Function to convert hyphenated icon names to camelCase with 'mdi' prefix
const toMdiIconName = (name: string): string => {
  if (name.startsWith('mdi')) {
    return name;
  }

  return (
    'mdi' +
    name
      .replace(/-([a-z])/g, (g) => g[1].toUpperCase())
      .replace(/^[a-z]/, (g) => g.toUpperCase())
  );
};

const DynamicMdiReactIcon = ({
  iconName,
  color = '#000',
  size = '20px',
  horizontal = false,
  vertical = false,
  rotate = 0,
  spin,
}: Props) => {
  let path = (icons as IconsType)[toMdiIconName(iconName)];

  // If the icon is not found, use the help circle icon as the fallback icon.
  if (!path) {
    path = icons.mdiHelpCircle;
  }

  return (
    <MdiIcon
      path={path}
      color={color}
      size={size}
      horizontal={horizontal}
      vertical={vertical}
      rotate={rotate}
      spin={spin}
    />
  );
};

export default DynamicMdiReactIcon;
