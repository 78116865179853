import React from 'react';

type Value = {
  fingerprint: string | null;
  fingerprintError: any;
};

export const DeviceFingerprintContext = React.createContext<Value>({
  fingerprint: null,
  fingerprintError: null,
});
