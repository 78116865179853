import Cookies from 'js-cookie';
import { AFFILIATE_CODE_KEY } from '../../App/config/enums';

export function removeStoredAffiliateCode() {
  const affiliateCode = window.localStorage
    ? localStorage.removeItem(AFFILIATE_CODE_KEY)
    : Cookies.remove(AFFILIATE_CODE_KEY);

  return affiliateCode;
}
