import { PlaylistFeature } from '../graphql/globalTypes';

export interface Tour {
  features: PlaylistFeature[];
}

export function tourSupportsFeature(
  tour: Tour,
  feature: PlaylistFeature
): boolean {
  const featureStr = feature.toString();

  return tour.features.map((f) => f.toString()).includes(featureStr);
}
