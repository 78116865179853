import { useHistory } from 'react-router-dom';
import styled from '../../style/styled';
import Alert from '../../components/Alert';
import { Button } from '../../components/Button';
import { ButtonContainer } from '../../styledComponents/ButtonContainer';
import { ROUTES } from '../routes';

export const SuccessMessage = () => {
  const history = useHistory();

  return (
    <Alert title="THANK YOU!">
      <BodyText>
        You're awesome, and we're excited to work with you! We'll be in touch
        very soon!
      </BodyText>

      <ButtonContainer>
        <Button onClick={() => history.push(ROUTES.business)}>
          Go back to Business Page
        </Button>
      </ButtonContainer>
    </Alert>
  );
};

const BodyText = styled.p`
  margin-bottom: 20px;
`;
