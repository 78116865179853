import { Link } from 'react-router-dom';
import styled from '../style/styled';

const ButtonLikeLink = styled(Link)<{
  bgColor?: string;
  secondary?: boolean;
  highlight?: boolean;
  width?: string;
  padding?: string;
  uppercase?: boolean;
  small?: boolean;
  boldText?: boolean;
  isAK?: boolean;
  isAO?: boolean;
  fontSize?: string;
  disabled?: boolean;
}>`
  font-family: ${({ isAK, isAO }) =>
    isAK ? 'Lato Regular' : isAO ? 'Titillium Web Bold' : 'inherit'};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ bgColor, secondary, highlight, theme }) => {
    if (bgColor) {
      return bgColor;
    } else if (secondary) {
      return theme.palette.secondary.main;
    } else if (highlight) {
      return theme.palette.highlight.main;
    }

    return theme.palette.primary.main;
  }};
  color: ${({ color, secondary, highlight, theme }) => {
    if (color) {
      return color;
    } else if (secondary) {
      return theme.palette.secondary.contrastColor;
    } else if (highlight) {
      return theme.palette.highlight.contrastColor;
    }

    return theme.palette.primary.contrastColor;
  }};
  width: ${({ width }) => width};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  outline: none;
  padding: ${({ padding }) => (padding ? padding : '8px')};
  user-select: none;

  border: ${({ bgColor, secondary, highlight, theme }) => {
    if (bgColor) {
      return `1px solid ${bgColor}`;
    } else if (secondary) {
      return `1px solid ${theme.palette.secondary.contrastColor}`;
    } else if (highlight) {
      return `1px solid ${theme.palette.highlight.main}`;
    }

    return `1px solid ${theme.palette.primary.main}`;
  }};

  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'auto')};
  font-weight: ${({ boldText }) => (boldText ? 'bold' : 'inherit')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 'inherit')};
  text-decoration: none;

  appearance: none;

  -webkit-tap-highlight-color: transparent;
  transition: box-shadow 0.2s ease-in-out;
  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled ? 'none' : '0px 0px 10px -1px #000000aa'};

    text-decoration: none;
    color: ${({ color, secondary, highlight, theme }) => {
      if (color) {
        return color;
      } else if (secondary) {
        return theme.palette.secondary.contrastColor;
      } else if (highlight) {
        return theme.palette.highlight.contrastColor;
      }

      return theme.palette.primary.contrastColor;
    }};
  }

  @media (hover: none) and (pointer: coarse) {
    padding: ${(props) => (props.small ? 8 : 15)}px;
  }
`;

export default ButtonLikeLink;
