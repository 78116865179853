import * as Sentry from '@sentry/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthButtonWithRecaptcha3 } from '../../../../components/AuthButtonWithRecaptcha3';
import { ErrorText } from '../../../../styledComponents/ErrorText';
import {
  ACTIVATE_PRODUCTS_MUTATION,
  Data,
  Variables,
} from '../../../../graphql/mutations/ActivateProductsMutation';
import { Container, Content, Title } from './styledComponents';
import { SelectMultupleToursToActivate } from './SelectMultupleToursToActivate';
import { UseADifferentCodeButton } from './UseADifferentCodeButton';
import CodeInput from './CodeInput';
import { UNKNOWN_ERROR } from '../../../../consts';
import { nonNullable } from '../../../../utils/nonNullable';
import { captureInSentry } from '../../../config/reporting/captureInSentry';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { captureButtonPress } from '../../../../features/analytics';
import { ProductSKU } from '../../../../graphql/globalTypes';
import { getDeviceID } from '../../../../globals/deviceID';
import { osName, osVersion } from 'react-device-detect';
import { useDeepCompareEffect } from 'react-use';

const getDevice = () => ({
  installationID: getDeviceID(),
  name: osName,
  os: osVersion,
});

const RECAPTCHA_CONTEXT = 'ActivateProducts';

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

type Props = {
  code: string;
  clearCode: () => void;
  redeemableTours: Array<{ productName: string; productID: string }>;
  redeemableToursCount: number;
  onSuccess: (data: {
    redeemCodeUsed: string;
    activatedTourIDs: Array<string>;
    expiryDate: number;
  }) => void;
};

const ActivateMultipleProductView = ({
  clearCode,
  code,
  redeemableTours,
  onSuccess,
  redeemableToursCount,
}: Props) => {
  const { t } = useTranslation();

  const [error, setError] = useState<Error | null>(null);

  const [selectedTours, setSelectedTours] = useState<
    Array<{ label: string; value: string }>
  >([]);

  // mutation to handle codes that support activate multiple products
  const [activateProducts, { loading }] = useMutation<Data, Variables>(
    ACTIVATE_PRODUCTS_MUTATION,
    {
      fetchPolicy: 'no-cache',
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        setError(error);

        const errorMessage = error?.message || UNKNOWN_ERROR;

        toast.error(errorMessage, {
          autoClose: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          toastId: errorMessage,
        });
      },
      onCompleted: ({ result }) => {
        const { purchases, error } = result;

        if (error) {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          setError(new Error(errorMessage));
        } else {
          // FIXME: support tour bundles
          const activatedProductIDs = purchases
            .map((p) => p.product?.id)
            .filter(nonNullable);

          onSuccess({
            redeemCodeUsed: code,
            activatedTourIDs: activatedProductIDs,
            expiryDate:
              typeof purchases[0]?.expiresAt === 'string'
                ? new Date(purchases[0]?.expiresAt).getTime()
                : // 1 month from now - it's a cache. that should be enough.
                  new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
          });
        }
      },
    }
  );

  // Automatically select all tours if they are less than or equal to the redeemableToursCount,
  // and attempt to automatically activate.
  useDeepCompareEffect(() => {
    // if redeemableToursCount is more than zero and more than or equal to redeemableTours.length,
    // automatically activate all redeemable tours
    if (
      redeemableToursCount > 0 &&
      redeemableTours &&
      redeemableToursCount >= redeemableTours.length
    ) {
      const allSelectedTours = redeemableTours.map((t) => ({
        label: t.productName,
        value: t.productID,
      }));

      setSelectedTours(allSelectedTours);

      captureButtonPress({
        page: window.location.pathname,
        buttonName: 'ActivateTours',
      });

      const variables: Variables = {
        input: {
          redeemCode: code.replace(/-/g, '').trim(),
          products: allSelectedTours.map((t) => ({
            productSKU: ProductSKU.PLAYLIST,
            productID: t.value,
          })),
        },
      };

      setVariables(variables);

      activateProducts({ variables });
    }
  }, [redeemableToursCount, redeemableTours, activateProducts]);

  const handleSubmit = useCallback(() => {
    setError(null);

    // remove all hyphens and trim the code (clients might add hyphens for readability)
    const redeemCode = code.replace('-', '').trim();

    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'ActivateTours',
    });

    const variables: Variables = {
      input: {
        redeemCode,
        products: selectedTours.map((t) => ({
          productSKU: ProductSKU.PLAYLIST,
          productID: t.value,
        })),
        device: getDevice(),
      },
    };

    setVariables(variables);

    activateProducts({ variables });
  }, [activateProducts, code, selectedTours]);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleSubmit();
  };

  const handleToursSelectChange = useCallback(
    (tours: Array<{ label: string; value: string }>) => {
      if (tours.length > redeemableToursCount) {
        // Move this to UI, not a toast
        const errorMessage = t(
          'You have reached the limit of tours you can activate with this redemption code.'
        );

        toast.error(errorMessage, {
          autoClose: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          toastId: errorMessage,
        });
      } else {
        setSelectedTours(tours);
      }
    },
    [redeemableToursCount, t]
  );

  let tourActivationText = '';

  if (redeemableToursCount === 1 && redeemableTours.length === 1) {
    tourActivationText = t('Yay! This code can activate the following tour.');
  } else if (redeemableToursCount === redeemableTours.length) {
    tourActivationText = t(
      'Yay! This code can activate all of the following tours.'
    );
  } else {
    // TODO: [LVR-2521] i18n see how to use this without splitting
    tourActivationText = `${t(
      'Yay! This code can activate any '
    )}${redeemableToursCount}${t(' of the following tours.')}`;
  }

  return (
    <Container>
      <form onSubmit={submitForm}>
        <Content>
          <Title>{t('Redeem Code')}</Title>

          <p>{t('Got a redeem code for a tour? Great!')}</p>

          <p>
            <strong>{t('Note')}</strong>:{' '}
            {t('Redeemed tours are valid for several months from redemption.')}
          </p>

          {/* At this point, we have already entered the code, so we just want this to be a readonly field */}
          <CodeInput
            code={code}
            setCode={() => {}}
            setError={() => {}}
            disabled
            hasError={false}
            setScanningQRCode={() => {}}
          />

          <SelectMultupleToursToActivate
            tourActivationText={tourActivationText}
            selectedTours={selectedTours}
            isDisabled={loading}
            handleToursSelectChange={handleToursSelectChange}
            redeemableTours={redeemableTours}
          />

          {error?.message ? (
            <ErrorText data-testid="activation-error-message">
              {error.message}
            </ErrorText>
          ) : null}

          <AuthButtonWithRecaptcha3
            uppercase
            context={RECAPTCHA_CONTEXT}
            disabled={loading || code.trim().length === 0}
            buttonName="ActivateProductsButton"
          >
            {t('Redeem selected tours')}
          </AuthButtonWithRecaptcha3>

          {/* Ability to change the code */}
          {!loading && <UseADifferentCodeButton onClick={clearCode} />}
        </Content>
      </form>
    </Container>
  );
};

export default ActivateMultipleProductView;
