import React from 'react';
import styled from '../../../../style/styled';
import { Star } from './Star';

interface Props {
  rating: number;
  setRating: (rating: number) => void;
  disabled: boolean;
}

export const Stars: React.FC<Props> = ({ rating, setRating, disabled }) => {
  const ratings = [];

  for (let i = 1; i <= 5; i++) {
    ratings.push(i <= rating);
  }

  return (
    <StarsContainer>
      {ratings.map((r, idx) => (
        <Star
          key={idx}
          onClick={() => setRating(idx + 1)}
          filled={r}
          disabled={disabled}
        />
      ))}
    </StarsContainer>
  );
};

const StarsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;
  margin: 0 auto;
`;
