import Icon from 'mdi-react/BriefcaseVariantIcon';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_MEDIUM,
} from '../../style/SIZES';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../Routes/routes';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  showTextInMobile?: boolean;
}

const OurWorkLink = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Header / Our work',
    });

    history.push(ROUTES.ourWork);
  };

  const TextWrapper = props.showTextInMobile
    ? AppHeaderButtonText
    : AppHeaderButtonTextMobileHidden;

  return (
    <AppHeaderButtonContainer
      fullWidth={fullWidth}
      onClick={handleClick}
      borderColor={location.pathname === ROUTES.ourWork ? color : ''}
    >
      <Icon color={color} size={size * 1.4} />
      <TextWrapper size={size} color={color}>
        {t('Our work')}
      </TextWrapper>
    </AppHeaderButtonContainer>
  );
};

export default OurWorkLink;

const AppHeaderButtonTextMobileHidden = styled(AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    display: none;
  }
`;
