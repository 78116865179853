import React from 'react';
import { useDispatch } from 'react-redux';
import Icon from 'mdi-react/LogoutIcon';
import { logoutRequest } from '../../store/app/actions';
import { captureButtonPress } from '../../features/analytics';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { useUser } from '../../hooks/useUser';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import { REASON_ID } from '../../App/components/Modals/ConvertGuestAccount';
interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const SignOutLink: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const { tourPurchasesMap, tourBundlePurchasesMap, isGuestUser } = useUser();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'LogOut',
    });

    // if the user has made any purchases,
    // show him the create user popup
    const hasPurchases =
      Object.keys(tourPurchasesMap).length > 0 ||
      Object.keys(tourBundlePurchasesMap).length > 0;

    if (isGuestUser && hasPurchases) {
      dispatch(
        setModalType({
          modalType: ModalType.CONVERT_GUEST_ACCOUNT,
          modalData: {
            reasonID: REASON_ID.NO_ACCESS_TO_TOURS,
          },
        })
      );
    } else {
      // otherwise log him out
      dispatch(logoutRequest({}));
    }
  };

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleClick}>
      <Icon color={color} size={size * 1.4} />
      <AppHeaderButtonText size={size} color={color}>
        {t('Log out')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};
