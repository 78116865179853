import {
  SCREEN_WIDTH_BREAK_MEDIUM,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import styled from '../../style/styled';

export const Padbox = styled.div<{
  padding?: number[];
  paddingMobile?: number[];
  paddingTablet?: number[];
}>`
  padding: ${({ padding }) => (padding ?? [10]).map((p) => `${p}px`).join(' ')};

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding: ${({ padding, paddingMobile }) =>
      (paddingMobile ?? padding ?? [10]).map((p) => `${p}px`).join(' ')};
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    padding: ${({ padding, paddingTablet }) =>
      (paddingTablet ?? padding ?? [10]).map((p) => `${p}px`).join(' ')};
  }
`;
