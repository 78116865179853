import {
  ALPHA_STOCK_URL,
  BETA_STOCK_URL,
  RTM_STOCK_URL,
  DEV_URL,
  RTM_STOCK_AK_URL,
  RTM_STOCK_AO_URL,
} from '../consts';
import { env } from '../App/config/env';
import { ROUTES } from '../Routes/routes';
import urlJoin from './urlJoin';

export function getTourShareURL(internalReference: string): string {
  if (env.IS_STOCK) {
    let hostname = RTM_STOCK_URL;
    const pageRoute = ROUTES.itinerary.replace(
      ':internalReference',
      internalReference
    );
    if (env.IS_AK) {
      hostname = RTM_STOCK_AK_URL;
    } else if (env.IS_AO) {
      hostname = RTM_STOCK_AO_URL;
    } else if (env.IS_ALPHA) {
      hostname = ALPHA_STOCK_URL;
    } else if (env.IS_BETA) {
      hostname = BETA_STOCK_URL;
    } else if (env.isDevelopment) {
      hostname = DEV_URL;
    }

    return urlJoin(hostname, pageRoute);
  }

  // TODO: support whitelabel alpha site as well?
  const hostname = 'https://guide.lithodomos.com';

  // if we are on the itinerary page, use the internalReference
  if (window.location.href.includes('itinerary')) {
    return urlJoin(
      hostname,
      ROUTES.itinerary.replace(':internalReference', internalReference)
    );
  }

  // use the index route with the appID appended
  return urlJoin(hostname, env.APP_ID);
}
