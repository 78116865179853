import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../../features/analytics';
import {
  Data,
  Variables,
  RedeemableProduct,
  ACTIVATE_PRODUCT_MUTATION,
} from '../../../../graphql/mutations/ActivateProductMutation';
import { ErrorText } from '../../../../styledComponents/ErrorText';
import { AuthButtonWithRecaptcha3 } from '../../../../components/AuthButtonWithRecaptcha3';
import { osName, osVersion } from 'react-device-detect';
import { UNKNOWN_ERROR } from '../../../../consts';
import { captureInSentry } from '../../../config/reporting/captureInSentry';
import { env } from '../../../config/env';
import { EXPLORE_WEB_ActivateProductMutation_result_purchase_tour } from '../../../../graphql/mutations/__generated__/EXPLORE_WEB_ActivateProductMutation';
import { getDeviceID } from '../../../../globals/deviceID';
import { UseADifferentCodeButton } from './UseADifferentCodeButton';
import CodeInput from './CodeInput';
import QrReader from '../../../../components/QRCodeScanner/QRCodeScanner';
import { CodeKeys } from '../../../../types';
import { logMessage } from '../../../../features/logging/logMessage';
import { Container, Content, Title } from './styledComponents';
import ActivateMultipleProductView from './ActivateMultipleProductView';

const RECAPTCHA_CONTEXT = 'ActivateProduct';

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export type OnSuccessArgs = {
  redeemCodeUsed: string;
  activatedTourIDs: string[];
  activatedSingleTour?: EXPLORE_WEB_ActivateProductMutation_result_purchase_tour;
  expiryDate: number;
};

interface Props {
  onSuccess: (args: OnSuccessArgs) => Promise<void>;
  codeFromDeeplink?: string | null;
  setActivateErrorMessage: (errorMessage: string) => void;
}

const getDevice = () => ({
  installationID: getDeviceID(),
  name: osName,
  os: osVersion,
});

// TODO: need to refactor
export const ActivateProductView = ({
  onSuccess,
  codeFromDeeplink,
  setActivateErrorMessage,
}: Props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>(codeFromDeeplink || '');
  const [error, setError] = useState<Error | null>(null);
  const [postProcessing, setPostProcessing] = useState<boolean>(false);
  const [scanningQRCode, setScanningQRCode] = useState<boolean>(false);
  // locks
  const qrCodeScanHandling = useRef(false);
  const hasAutomaticActivationAttempted = useRef(false);

  const [
    redeemableTours,
    setRedeemableTours,
  ] = useState<Array<RedeemableProduct> | null>(null);

  const [redeemableToursCount, setRedeemableToursCount] = useState<number>(0);

  useEffect(() => {
    if (error) {
      setActivateErrorMessage(error?.message || UNKNOWN_ERROR);
    }
  }, [error, setActivateErrorMessage]);

  // first try to activate code.
  // if there is a second step, like user given the option to choose a specific set of tours,
  // we need the second mutation.
  const [activateProduct, { loading }] = useMutation<Data, Variables>(
    ACTIVATE_PRODUCT_MUTATION,
    {
      fetchPolicy: 'no-cache',
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        setError(error);

        const errorMessage = error?.message || UNKNOWN_ERROR;

        toast.error(errorMessage, {
          autoClose: 5000,
          pauseOnHover: true,
          hideProgressBar: true,
          toastId: errorMessage,
        });
      },
      onCompleted: async ({ result }) => {
        setPostProcessing(true);

        const {
          purchase,
          redeemableProducts,
          redeemableProductsCount,
          error,
        } = result;

        // if all tours have already been activated, then simply call onSuccess with empty array
        if (error?.type === 'AllRedeemableProductsAlreadyPurchased') {
          await onSuccess({
            redeemCodeUsed: code,
            activatedTourIDs: [],
            expiryDate:
              typeof purchase?.expiresAt === 'string'
                ? new Date(purchase?.expiresAt).getTime()
                : // 1 month from now - it's a cache. that should be enough.
                  new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
          });
        } else if (purchase?.product) {
          // if a tour has been activated, then call onSuccess with the tour
          const activatedTourID = purchase.product.id;
          const tour = purchase.tour;
          await onSuccess({
            redeemCodeUsed: code,
            activatedTourIDs: [activatedTourID],
            activatedSingleTour: tour as EXPLORE_WEB_ActivateProductMutation_result_purchase_tour,
            expiryDate:
              typeof purchase?.expiresAt === 'string'
                ? new Date(purchase?.expiresAt).getTime()
                : // 1 month from now - it's a cache. that should be enough.
                  new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
          });
        } else if (
          // if it returns a list of redeemable products, then we need to show the user a list of tours to choose from
          Array.isArray(redeemableProducts) &&
          redeemableProducts.length > 0
        ) {
          setRedeemableTours(redeemableProducts);
          setRedeemableToursCount(
            typeof redeemableProductsCount === 'number'
              ? redeemableProductsCount
              : redeemableProducts.length
          );
        } else if (
          Array.isArray(redeemableProducts) &&
          redeemableProducts.length === 0
        ) {
          const errorMessage = t(
            'Sorry, this code cannot activate any more tours.'
          );

          setError(new Error(errorMessage));

          captureInSentry(errorMessage, { variables: getVariables() });
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          setError(new Error(errorMessage));
        }

        setPostProcessing(false);
      },
    }
  );

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // await if not the last step
    return handleSubmit(code);
  };

  const handleSubmit = async (inputCode: string) => {
    setError(null);

    // remove all hyphens and trim the code (clients might add hyphens for readability)
    const redeemCode = inputCode.replace(/-/g, '').trim();

    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'ActivateTour',
    });

    const variables: Variables = {
      input: {
        redeemCode,
        device: getDevice(),
      },
    };

    setVariables(variables);

    // await if not the last step
    return activateProduct({ variables });
  };

  // If there is a codeFromDeeplink, attempt to activate it
  // and set the tours if there are multiple tours redeemable.
  // We are placing a lock to prevent multiple attempts, in case.
  useEffect(() => {
    const init = async (redeemCode: string) => {
      const variables: Variables = {
        input: { redeemCode, device: getDevice() },
      };

      setVariables(variables);

      // await if not the last step
      return activateProduct({ variables });
    };

    if (hasAutomaticActivationAttempted.current) {
      Sentry.captureMessage(
        'Automatic activation attempted multiple times. but prevented'
      );

      return;
    }

    hasAutomaticActivationAttempted.current = true;

    if (codeFromDeeplink) {
      init(codeFromDeeplink);
    }
  }, [codeFromDeeplink, activateProduct]);

  const onQrCodeScan = async (urlFromQRCodeScanner: string) => {
    // Prevent multiple scans
    if (qrCodeScanHandling.current) {
      Sentry.captureMessage('Multiple QR code scans detected, but prevented');

      return;
    }

    qrCodeScanHandling.current = true;

    const urlParams = new URLSearchParams(new URL(urlFromQRCodeScanner).search);

    const rcode = urlParams.get(CodeKeys.REDEEM_CODE);

    if (rcode) {
      setCode(rcode);

      captureEvent({
        name: EventNames.QR_CODE_SCANNED,
        data: [{ key: DataKeys.REDEEM_CODE, value: rcode }],
      });

      logMessage('QR code scanned', rcode);

      await handleSubmit(rcode);
    } else {
      setError(new Error('Invalid QR code'));

      logMessage('Invalid QR code', urlFromQRCodeScanner);

      captureInSentry('Invalid QR code', {
        urlFromQRCodeScanner,
      });
    }

    setScanningQRCode(false);

    qrCodeScanHandling.current = false;
  };

  if (env.IS_AK && !error) {
    return null;
  }

  if (scanningQRCode) {
    return <QrReader onSuccess={onQrCodeScan} />;
  }

  if (Array.isArray(redeemableTours) && redeemableTours.length > 0) {
    return (
      <ActivateMultipleProductView
        code={code}
        clearCode={() => setRedeemableTours(null)}
        redeemableTours={redeemableTours}
        redeemableToursCount={redeemableToursCount}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <Container>
      <form onSubmit={submitForm}>
        <Content>
          <Title>{t('Redeem Code')}</Title>

          {!codeFromDeeplink ? (
            <>
              <p>{t('Got a redeem code for a tour? Great!')}</p>

              <p>
                {t(
                  'Enter the code below and click the REDEEM button to unlock your tour.'
                )}
              </p>
            </>
          ) : null}

          <p>
            <strong>{t('Note')}</strong>:{' '}
            {t('Redeemed tours are valid for several months from redemption.')}
          </p>

          <CodeInput
            code={code}
            setCode={setCode}
            setError={setError}
            disabled={Boolean(loading || postProcessing || redeemableTours)}
            hasError={Boolean(error)}
            setScanningQRCode={setScanningQRCode}
          />

          {error?.message ? (
            <ErrorText data-testid="activation-error-message">
              {error.message}
            </ErrorText>
          ) : null}

          <AuthButtonWithRecaptcha3
            uppercase
            context={RECAPTCHA_CONTEXT}
            disabled={loading || code.trim().length === 0}
            buttonName="ActivateProductButton"
          >
            {t(loading ? 'Processing...' : 'Redeem')}
          </AuthButtonWithRecaptcha3>

          {/* Ability to change the code */}
          {redeemableTours && !loading && (
            <UseADifferentCodeButton onClick={() => setRedeemableTours(null)} />
          )}
        </Content>
      </form>
    </Container>
  );
};
