export type Experiment = { experimentID: string; variant: string };

export type GoogleOptimizeState = {
  loading: boolean;
  experiments: Experiment[];
};

export const initialState: GoogleOptimizeState = {
  loading: true,
  experiments: [],
};
