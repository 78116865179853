import { isMobile } from 'react-device-detect';
import { Howl } from 'howler';
import { EMPTY_AUDIO_URL } from '../../consts';

// this is required to continue playing the narration on iOS
// Howler.autoSuspend = false;

// whether browser has determined that we can play audio
let _unlocked = !isMobile;

export function getAudioUnlocked(): boolean {
  return _unlocked;
}

export function setUnlocked() {
  _unlocked = true;
}

const api = new Howl({
  src: [EMPTY_AUDIO_URL],
  loop: false,
  html5: true,
  onunlock: setUnlocked,
  onend: () => {
    api.off();
    api.unload();
  },
});

let id: number | null = null;

export function unlock() {
  if (_unlocked) {
    return;
  }

  if (id) {
    api.play(id);
  } else {
    id = api.play();
  }
}
