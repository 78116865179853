import styled from 'styled-components';
import { useMemo, useContext, useEffect } from 'react';
import Feature from 'ol/Feature';
import { fromLonLat } from 'ol/proj';
import OLVectorLayer from 'ol/layer/Vector';
import OLVectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Point from 'ol/geom/Point';
import Enabled from 'mdi-react/LocationSearchingIcon';
import Recenter from 'mdi-react/GpsFixedIcon';
import Disabled from 'mdi-react/LocationOffIcon';
import MapContext from '../MapContext';
import { useDispatch, useSelector } from 'react-redux';
import { setLocationRequested } from '../../../../store/location/actions';
import { selectCurrentLocation } from '../../../../store/location/selectors';
import Circle from 'ol/geom/Circle';
import { Location } from '../../../../types';
import { Map } from 'ol';

const positionFeature = new Feature();
const accuracyFeature = new Feature();

positionFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#4593f7',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  })
);

accuracyFeature.setStyle(
  new Style({
    fill: new Fill({
      color: '#4593f744',
    }),
  })
);

let vectorLayer: OLVectorLayer<any> | null = null;

const UserPosition = () => {
  const { map } = useContext(MapContext);
  const dispatch = useDispatch();

  // TODO: [LVR-4730] As a developer, I have incorporated the geolocation logic from Google Maps with the offline maps, so we have all the improvements from the Venice tour in a tour which uses an offline map
  const currentLocation = useSelector(selectCurrentLocation);

  const { coordinates, accuracy } = useMemo(
    () => getCoordinatesAndAccuracy(currentLocation, map),
    [currentLocation, map]
  );

  useEffect(() => {
    if (map) {
      vectorLayer = new OLVectorLayer({
        map: map,
        source: new OLVectorSource({
          features: [accuracyFeature, positionFeature],
        }),
      });
    }

    return () => {
      if (vectorLayer) {
        vectorLayer.dispose();
      }
    };
  }, [map]);

  // update the position feature and the accuracy feature when the current location changes
  useEffect(() => {
    let newPositionGeometry;
    let newAccuracyGeometry;

    if (coordinates) {
      newPositionGeometry = new Point(coordinates);

      if (accuracy) {
        // accuracy geometry need some transformation
        newAccuracyGeometry = new Circle(coordinates, accuracy);
      }
    }

    positionFeature.setGeometry(newPositionGeometry);
    accuracyFeature.setGeometry(newAccuracyGeometry);
  }, [coordinates, accuracy]);

  const handleToggleLocation = () => {
    if (currentLocation) {
      dispatch(setLocationRequested({ requested: false }));
    } else {
      dispatch(setLocationRequested({ requested: true }));
    }
  };

  const handleRecenter = () => {
    if (map && coordinates) {
      map.getView().setCenter(coordinates);
    }
  };

  return (
    <Buttons className="ol-unselectable ol-control">
      {currentLocation && (
        <button onClick={handleRecenter}>
          <Recenter size={14} xlinkTitle="Locate me" />
        </button>
      )}

      <button onClick={handleToggleLocation}>
        {!currentLocation && (
          <Enabled size={14} xlinkTitle="Turn on location" />
        )}
        {currentLocation && (
          <Disabled size={14} xlinkTitle="Turn off location" />
        )}
      </button>
    </Buttons>
  );
};

export default UserPosition;

const Buttons = styled.div`
  position: absolute;
  top: 5em;
  left: 0.5em;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

function getCoordinatesAndAccuracy(
  currentLocation: Location | null,
  map: Map | null
) {
  let coordinates = null;
  let accuracy = null;

  if (map && currentLocation) {
    const projection = map.getView().getProjection();

    // need to convert coordinates to a unit the projection supports
    coordinates = fromLonLat(
      [currentLocation.coords.longitude, currentLocation.coords.latitude],
      projection
    );

    // this is specified in Map.tsx when initantiating the projection.
    // currently only set for EPSG:3857, so for other units, the accuracy will no be shown.
    const metersPerUnit = projection.getMetersPerUnit();

    if (metersPerUnit) {
      const accuracyInMeters = currentLocation.coords.accuracy;
      accuracy = accuracyInMeters / metersPerUnit;
    }
  }

  return { coordinates, accuracy };
}
