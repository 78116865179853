import { gql } from '@apollo/client';
import { createContext } from 'react';
import { ASSET_FRAGMENT } from '../graphql/fragments/Asset';
import { PRICE_FRAGMENT } from '../graphql/fragments/Price';
import { I18NSTRING } from '../graphql/fragments/I18nString';
import {
  EXPLORE_WEB_GetApp as Data,
  EXPLORE_WEB_GetAppVariables as Variables,
  EXPLORE_WEB_GetApp_result as AppData,
  EXPLORE_WEB_GetApp_result_curatedPlaylists as Tour,
  EXPLORE_WEB_GetApp_result_playlistBundles as TourBundle,
  EXPLORE_WEB_GetApp_result_trialTours as TrialTour,
} from './__generated__/EXPLORE_WEB_GetApp';

export type { Data, Variables, Tour, TourBundle, TrialTour };

export const GET_APP_QUERY = gql`
  query EXPLORE_WEB_GetApp($input: GetAppInput!) {
    result: getApp(input: $input) {
      id
      appID
      appType
      url
      stylesheet {
        ...AssetFragment
      }
      theme {
        ...AssetFragment
      }
      settings {
        ...AssetFragment
      }
      curatedPlaylists {
        id
        internalReference
        trial
        published
        descriptionI18n {
          ...I18nStringFragment
        }
        countries {
          id
          name
        }
        nameI18n {
          ...I18nStringFragment
        }
        viewpoints {
          imagesCount
        }
        validityPeriodMonths
        trailLength
        timePeriod
        audioDurationMinutes
        price {
          ...PriceFragment
        }
        cover {
          ...AssetFragment
        }
        pastThumbnail {
          ...AssetFragment
        }
        presentThumbnail {
          ...AssetFragment
        }
      }
      playlistBundles {
        id
        internalReference
        published
        nameI18n {
          ...I18nStringFragment
        }
        price {
          ...PriceFragment
        }
        cover {
          ...AssetFragment
        }
        thumbnail {
          ...AssetFragment
        }
        presentThumbnail {
          ...AssetFragment
        }
        playlists {
          id
        }
      }
      trialTours {
        id
        internalReference
        published
        trial
        nameI18n {
          ...I18nStringFragment
        }
        validityPeriodMonths
        trailLength
        timePeriod
        audioDurationMinutes
        price {
          ...PriceFragment
        }
        pastThumbnail {
          ...AssetFragment
        }
        presentThumbnail {
          ...AssetFragment
        }
        descriptionI18n {
          ...I18nStringFragment
        }
        countries {
          id
          name
        }
        viewpoints {
          imagesCount
        }
      }
    }
  }

  ${ASSET_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${I18NSTRING}
`;

export type RemoteAppContextValue = {
  appData: AppData | null | undefined;
};

const RemoteAppContext = createContext<RemoteAppContextValue>({
  appData: null,
});

export default RemoteAppContext;
