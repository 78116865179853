import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../Button';
import { captureButtonPress } from '../../../../features/analytics';
import { ROUTES } from '../../../../Routes/routes';
import { unlock } from '../../../../features/audio/howlerSetup';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../App/config/env';
import { selectPinnedTours } from '../../../../store/app/selectors';
import { useSelector } from 'react-redux';

const ANALYTICS_BUTTON_NAME = 'Itinerary_GoToGate';

interface Props {
  tourID: string;
}

export const TourInfoStartTourButton: React.FC<Props> = ({ tourID }) => {
  const pinnedTours = useSelector(selectPinnedTours);
  const { t } = useTranslation();
  const history = useHistory();
  const page = window.location.pathname;

  const tourIsPinned = Boolean(pinnedTours.find((t) => t.tourID === tourID));

  return (
    <Button
      highlight
      onClick={() => {
        unlock();

        captureButtonPress({
          buttonName: ANALYTICS_BUTTON_NAME,
          tourID,
          page,
        });

        history.push(ROUTES.tour, { playlistID: tourID });
      }}
    >
      {t(
        tourIsPinned
          ? env.APP_ENV.text.continueTourCaption
          : env.APP_ENV.text.startTourCaption
      )}
    </Button>
  );
};
