import { LanguageCode } from './../../../graphql/globalTypes';
import { GenericMarkerData, MarkerType } from './../types';
import { nonNullable } from '../../../utils/nonNullable';
import { getI18nFieldValue } from '../../i18n/getI18nFieldValue';
import { Asset, I18nInfoField, ViewpointLocation } from '../../../types';
import getGoogleMapMarkerImage from '../../whitelabeling/branding/utils/getGoogleMapMarkerImage';

type Viewpoint = {
  id: string;
  nameI18n: I18nInfoField;
  taglineI18n: I18nInfoField;
  location: ViewpointLocation | null;
  thumbnail: Asset | null;
};

type Tour = {
  id: string;
  viewpoints: Array<Viewpoint>;
};

export function getViewpointsMarkerData(
  tours: Array<Tour>,
  language: LanguageCode,
  currentViewpointID?: string
): Array<GenericMarkerData> {
  return tours
    .map((tour) => {
      const { id: tourID, viewpoints } = tour;

      return viewpoints.map((vp, idx) => {
        const {
          location,
          nameI18n,
          taglineI18n,
          thumbnail,
          id: viewpointID,
        } = vp;

        if (!location || !nameI18n) {
          return null;
        }

        const { longitude: lng, latitude: lat } = location.coordinates;

        const isCurrent = currentViewpointID === viewpointID || false;

        const icon = getGoogleMapMarkerImage(tourID, viewpointID);

        const markerData = {
          position: { lng, lat },
          name: getI18nFieldValue(nameI18n, language),
          description: getI18nFieldValue(taglineI18n, language),
          thumbnailURL: thumbnail?.uri || '',
          id: viewpointID,
          type: MarkerType.VIEWPOINT,
          isCurrent,
          idx,
          icon,
        };

        return markerData;
      });
    })
    .flat()
    .filter(nonNullable);
}
