import { createAction } from 'redux-actions';

export const ACTION_TYPES = {
  SET_UPDATE_AVAILABLE: 'setUpdateAvailable',
};

type SetUpdateAvailablePayload = { available: boolean };

export type SetUpdateAvailableAction = {
  type: typeof ACTION_TYPES.SET_UPDATE_AVAILABLE;
  payload: SetUpdateAvailablePayload;
};

export const setUpdateAvailable = createAction<SetUpdateAvailablePayload>(
  ACTION_TYPES.SET_UPDATE_AVAILABLE
);
