import React from 'react';
import Icon from 'mdi-react/AccountPlusIcon';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { captureButtonPress } from '../../features/analytics';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { useTheme } from '../../style/styled';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const ConvertGuestAccountLink: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'ConvertGuestAccount',
    });

    dispatch(
      setModalType({
        modalType: ModalType.CONVERT_GUEST_ACCOUNT,
      })
    );
  };

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleClick}>
      <Icon color={color} size={size * 1.4} />
      <AppHeaderButtonText size={size} color={color}>
        {t('Create account')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};
