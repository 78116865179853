import { LanguageCode } from '../../graphql/globalTypes';
import { differenceInSeconds } from 'date-fns';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { captureEvent } from './captureEvent';
import { DataKeys, EventNames } from './types';

let _viewpointID: string | null = null;
let _imageID: string | null = null;
let _tourID: string | null = null;

let _viewpointEnterTimestamp: Date | null = null;
let _imageEnterTimestamp: Date | null = null;
let _tourEnterTimestamp: Date | null = null;

export function tourStart(tourID: string): Promise<any> {
  _tourID = tourID;

  return captureEvent({
    name: EventNames.TOUR_START,
    data: [
      {
        key: DataKeys.TOUR_ID,
        value: _tourID,
      },
    ],
  }).then((event) => {
    _tourEnterTimestamp = new Date(event.timestamp);
  });
}

export function tourEnd(): Promise<any> | void {
  if (!_tourID || !_tourEnterTimestamp) {
    return;
  }

  const tourID = _tourID;

  if (_viewpointID) {
    viewpointEnd();
  }

  _tourID = null;

  return captureEvent({
    name: EventNames.TOUR_END,
    data: [
      {
        key: DataKeys.TOUR_ID,
        value: tourID,
      },
      {
        key: DataKeys.TOUR_DURATION,
        value: differenceInSeconds(new Date(), _tourEnterTimestamp).toString(),
      },
    ],
  });
}

export function viewpointStart(viewpointID: string): Promise<any> | void {
  if (!_tourID) {
    captureInSentry('tourID not set when calling viewpointStart', {
      viewpointID,
    });

    return;
  }

  // this will do nothing is the viewpointID is empty
  if (_viewpointID) {
    viewpointEnd();
  }

  _viewpointID = viewpointID;

  return captureEvent({
    name: EventNames.VIEWPOINT_START,
    data: [
      {
        key: DataKeys.VIEWPOINT_ID,
        value: _viewpointID,
      },
      {
        key: DataKeys.VIEWPOINT_VIEW_MODE,
        value: 'MagicWindow',
      },
      {
        key: DataKeys.TOUR_ID,
        value: _tourID,
      },
    ],
  }).then((event) => {
    _viewpointEnterTimestamp = new Date(event.timestamp);
  });
}

export function viewpointEnd(): Promise<any> | void {
  if (!_tourID || !_viewpointID || !_viewpointEnterTimestamp) {
    return;
  }

  const viewpointID = _viewpointID;

  if (_imageID) {
    imageEnd();
  }

  _viewpointID = null;

  return captureEvent({
    name: EventNames.VIEWPOINT_END,
    data: [
      {
        key: DataKeys.VIEWPOINT_ID,
        value: viewpointID,
      },
      {
        key: DataKeys.TOUR_ID,
        value: _tourID,
      },
      {
        key: DataKeys.VIEWPOINT_DURATION,
        value: differenceInSeconds(
          new Date(),
          _viewpointEnterTimestamp
        ).toString(),
      },
    ],
  });
}

export function imageStart(imageID: string) {
  if (!_tourID || !_viewpointID) {
    captureInSentry('tourID or viewpointID not set when calling imageStart', {
      imageID,
    });

    return;
  }

  if (_imageID === imageID) {
    return;
  }

  // this will do nothing is the viewpointID is empty
  if (_imageID) {
    imageEnd();
  }

  _imageID = imageID;

  return captureEvent({
    name: EventNames.IMAGE_START,
    data: [
      {
        key: DataKeys.IMAGE_ID,
        value: _imageID,
      },
      {
        key: DataKeys.TOUR_ID,
        value: _tourID,
      },
      {
        key: DataKeys.VIEWPOINT_ID,
        value: _viewpointID,
      },
    ],
  }).then((event) => {
    _imageEnterTimestamp = new Date(event.timestamp);
  });
}

export function imageEnd(): Promise<any> | void {
  if (!_imageID || !_viewpointID || !_tourID || !_imageEnterTimestamp) {
    return;
  }

  const imageID = _imageID;

  _imageID = null;

  return captureEvent({
    name: EventNames.IMAGE_END,
    data: [
      {
        key: DataKeys.IMAGE_ID,
        value: imageID,
      },
      {
        key: DataKeys.TOUR_ID,
        value: _tourID,
      },
      {
        key: DataKeys.VIEWPOINT_ID,
        value: _viewpointID,
      },
      {
        key: DataKeys.IMAGE_DURATION,
        value: differenceInSeconds(new Date(), _imageEnterTimestamp).toString(),
      },
    ],
  });
}

type CaptureInTourEventArgs = {
  name: EventNames;
  buttonName?: string;
  // toggle buttons
  action?: 'On' | 'Off';
  // app buttons
  app?: string;
  store?: string;
  // time slider
  audioPosition?: number;
  // subtitles
  languageCode?: LanguageCode;
  // panorama position
  longitude?: number;
  latitude?: number;
  // panorama zoom
  zoomLevel?: number;
};

export function captureInTourEvent(args: CaptureInTourEventArgs) {
  const data = [];

  if (args.buttonName) {
    data.push({
      key: DataKeys.BUTTON_NAME,
      value: args.buttonName,
    });
  }

  if (_tourID) {
    data.push({
      key: DataKeys.TOUR_ID,
      value: _tourID,
    });
  }

  if (_imageID) {
    data.push({
      key: DataKeys.IMAGE_ID,
      value: _imageID,
    });
  }

  if (_viewpointID) {
    data.push({
      key: DataKeys.VIEWPOINT_ID,
      value: _viewpointID,
    });
  }

  if (args.app) {
    data.push({
      key: DataKeys.APP,
      value: args.app,
    });
  }

  if (args.store) {
    data.push({
      key: DataKeys.STORE,
      value: args.store,
    });
  }

  if (args.audioPosition) {
    data.push({
      key: DataKeys.AUDIO_POSITION,
      value: args.audioPosition.toString(),
    });
  }

  if (args.longitude) {
    data.push({
      key: DataKeys.PANO_LONGITUDE,
      value: args.longitude.toString(),
    });
  }

  if (args.latitude) {
    data.push({
      key: DataKeys.PANO_LATITUDE,
      value: args.latitude.toString(),
    });
  }

  if (args.zoomLevel) {
    data.push({
      key: DataKeys.PANO_ZOOM_LEVEL,
      value: args.zoomLevel.toString(),
    });
  }

  captureEvent({ name: args.name, data });
}
