import { PropsWithChildren } from 'react';
import styled from '../style/styled';
import { COLORS } from '../style/colors';
import { ICON_BUTTON_SIZE } from '../style/SIZES';
import MdiIcon from './MdiIcon';

export type IconButtonProps = PropsWithChildren<{
  isActive?: boolean;
  iconSize?: number;
  iconFgColor?: string | null;
  iconBgColor?: string | null;
  labelText?: string;
  tooltipText?: string;
  accessbilityLabel?: string;
  circular?: boolean;
  disabled?: boolean;
  path: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  'data-testid'?: string;
}>;

export type IconToggleButtonProps = {
  isActive: boolean;
  onClick: () => any;
  labelText?: string;
  iconFgColor?: string | null;
  iconBgColor?: string | null;
  disabled?: boolean;
  circular?: boolean;
};

export const IconButton = ({
  path,
  labelText,
  children,
  onClick,
  'data-testid': dataTestID,
  ...restProps
}: IconButtonProps) => {
  const color = getIconColor(restProps);

  return (
    <ButtonContainerWithLabel
      onClick={onClick}
      title={labelText}
      disabled={restProps.disabled}
    >
      <ButtonStyled data-testid={dataTestID} {...restProps}>
        <MdiIcon path={path} size={restProps.iconSize} color={color} />
      </ButtonStyled>
    </ButtonContainerWithLabel>
  );
};

IconButton.defaultProps = {
  iconSize: ICON_BUTTON_SIZE,
  iconBgColor: COLORS.lightTextColor,
};

function getIconColor({
  isActive,
  circular,
  iconBgColor,
  iconFgColor,
}: Partial<IconToggleButtonProps>) {
  if (iconFgColor) {
    return iconFgColor;
  }

  if (circular) {
    return isActive ? iconBgColor : '#000';
  }

  return isActive ? '#000' : iconBgColor;
}

const ButtonContainerWithLabel = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export const ButtonStyled = styled.div<
  Partial<{
    size: number;
    disabled: boolean;
    iconFgColor?: string | null;
    iconBgColor?: string | null;
    circular?: boolean;
    isActive?: boolean;
  }>
>`
  width: ${(props) => props.size && `${props.size}px`};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  border-radius: ${(props) => (props.circular ? '50%' : 'auto')};
  background: ${({ circular, isActive, iconBgColor }) => {
    if (!circular) {
      return 'none';
    }

    return isActive ? '#000' : iconBgColor;
  }};
`;
