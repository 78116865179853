import styled from '../style/styled';
import ErrorIcon from 'mdi-react/AlertCircleIcon';
import { Button } from './Button';
import { COLORS } from '../style/colors';
import { reloadApp } from '../utils/reloadApp';

export const ChunkLoadErrorView = () => {
  return (
    <ErrorViewContainer>
      <ErrorIcon size={40} />
      <h2>Website updated</h2>
      <p>Please reload the page to continue.</p>
      <Button onClick={reloadApp}>Reload page</Button>
    </ErrorViewContainer>
  );
};

const ErrorViewContainer = styled.div`
  padding: 16px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${COLORS.offwhiteDarker};
  border-radius: 4px;
  background: ${COLORS.offwhite};
  max-width: 90%;
  text-align: center;

  > * {
    margin-bottom: 20px;
  }
`;
