import { gql } from '@apollo/client';
import { I18NSTRING } from '../fragments/I18nString';
import {
  EXPLORE_WEB_ActivateProductsMutation,
  EXPLORE_WEB_ActivateProductsMutationVariables,
} from './__generated__/EXPLORE_WEB_ActivateProductsMutation';

export type Data = EXPLORE_WEB_ActivateProductsMutation;
export type Variables = EXPLORE_WEB_ActivateProductsMutationVariables;

export const ACTIVATE_PRODUCTS_MUTATION = gql`
  mutation EXPLORE_WEB_ActivateProductsMutation(
    $input: ActivateProductsInput!
  ) {
    result: activateProducts(input: $input) {
      purchases {
        id
        product {
          id
          nameI18n {
            ...I18nStringFragment
          }
        }
        expiresAt
      }
      error {
        id
        message
        type
      }
    }
  }
  ${I18NSTRING}
`;
