import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import screenfull from 'screenfull';
import * as fullscreen from '../../utils/fullscreen';
import { setViewerOptions } from '../../store/viewer/actions';
import { captureInSentry } from '../config/reporting/captureInSentry';

export const FullscreenStateSynchroniser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // exit fullscreen if user exits fullscreen using browser functionality, not the button
    if (fullscreen.isSupported()) {
      screenfull.on('change', () => {
        dispatch(
          setViewerOptions({
            options: {
              isFullscreen: fullscreen.isFullscreen(),
            },
          })
        );
      });

      screenfull.on('error', (event) => {
        const errorMessage = 'Failed to enable fullscreen';

        console.error(errorMessage, event);

        captureInSentry(errorMessage, { event });
      });
    }
  }, [dispatch]);

  return null;
};
