import styled from '../../../style/styled';

export const AppHeaderButtonContainer = styled.div<{
  fullWidth?: boolean;
  disabled?: boolean;
  borderColor?: string;
}>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
  border-bottom: 2px solid ${(props) => props.borderColor || 'transparent'};
`;
