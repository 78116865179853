import React from 'react';
import styled from '../../style/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../style/SIZES';
import { Guide } from '../../graphql/queries/GetGuides';

interface Props {
  guide: Guide;
}

export const GuideWidget: React.FC<Props> = ({ guide }: Props) => {
  const { name, location, bio, avatar } = guide;

  return (
    <VerticalContainer>
      <AvatarContainer>
        <LazyLoadImage
          src={(avatar && avatar.uri) || ''}
          alt={`${name} ${location}`}
          width={120}
          height={120}
        />
      </AvatarContainer>

      <NameAndLocationContainer>
        <Name>{name}</Name>
        {location && <Location>{location}</Location>}
      </NameAndLocationContainer>

      <p>{bio}</p>
    </VerticalContainer>
  );
};

const NameAndLocationContainer = styled.div`
  margin-bottom: 20px;
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const AvatarContainer = styled.div`
  height: 120px;
  width: 120px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 20px;
  background: #ccc;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-bottom: 8px;
  }
`;

const Name = styled.p`
  text-transform: uppercase;
  font-weight: bold;
`;

const Location = styled(Name)`
  text-transform: capitalize;
  font-weight: normal;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-bottom: 8px;
  }
`;
