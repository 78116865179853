// @flow
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { logoutRequest } from '../../../../store/app/actions';
import { captureInSentry } from '../../reporting/captureInSentry';
import { getStore } from '../../../../store';

const HTTP_STATUS = {
  UNAUTHORIZED: 401,
  UPGRADE_REQUIRED: 426,
};

function handleApolloError({
  graphQLErrors,
  networkError,
  response,
}: ErrorResponse) {
  let ignoreError = false;

  const store = getStore();

  if (Array.isArray(graphQLErrors)) {
    const authError = graphQLErrors.find((err) => {
      const code = err.extensions?.exception?.code;

      return (
        code === HTTP_STATUS.UPGRADE_REQUIRED ||
        code === HTTP_STATUS.UNAUTHORIZED
      );
    });

    // if there was a 401 error, log out users.
    // this usually happens when the JWT expires.
    // we can use refresh tokens to improve the UX, but that's a lot of work.
    if (authError && store) {
      store.dispatch(logoutRequest({}));

      return;
    }

    const nonContentAccessErrors = graphQLErrors.filter(
      (err) =>
        err.message !==
        'Sorry, you do not have access to the requested resource.'
    );

    if (nonContentAccessErrors.length === 0) {
      // if all the graphql errors were content access errors, we ignore the error
      ignoreError = true;
    } else {
      captureInSentry('Unknown graphql error', { graphQLErrors });
    }
  }

  // if there was a 401 error, log out users.
  // this usually happens when the JWT expires.
  // we can use refresh tokens to improve the UX, but that's a lot of work.
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === HTTP_STATUS.UNAUTHORIZED &&
    store
  ) {
    store.dispatch(logoutRequest({ automatic: true }));

    return;
  } else if (ignoreError) {
    response.errors = null;
  }
}

export const handleApolloErrorLink = onError(handleApolloError);
