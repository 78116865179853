import React, { useEffect } from 'react';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';

interface Props {
  curPathname: string;
  prevPathname?: string | null;
}

// Capture path in analytics
export const RouteChangeCapturer: React.FC<Props> = ({
  curPathname,
  prevPathname,
}) => {
  useEffect(() => {
    if (curPathname !== prevPathname) {
      const data = [{ key: DataKeys.TO, value: curPathname }];

      if (prevPathname) {
        data.push({ key: DataKeys.FROM, value: prevPathname });
      }

      // analytics
      captureEvent({ name: EventNames.ROUTE_CHANGE, data });
    }
  }, [curPathname, prevPathname]);

  return null;
};
