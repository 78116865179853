import { point, featureCollection } from '@turf/helpers';
import { nonNullable } from '../../../utils/nonNullable';
import { Coordinate } from './../types';

export function getPointFeatures(positions: Array<Coordinate | null>) {
  const nonNullPositions = positions.filter(nonNullable);
  const turfPoints = nonNullPositions.map((pos) => point([pos.lng, pos.lat]));
  const features = featureCollection(turfPoints);

  return features;
}
