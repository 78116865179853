import { DEFAULT_CURRENCY_CODE } from '../../consts';
import { CurrencyCode } from '../../graphql/globalTypes';
import { Price } from '../../types';

interface Tour {
  price: Price;
}

type Args = {
  tour: Tour;
  currencyCode: CurrencyCode;
  overrideCurrencyCode?: boolean;
};

type Result = {
  currencyCode: CurrencyCode;
  price: number;
  priceInCents: boolean;
};

export function getTourPrice({
  tour,
  currencyCode,
  overrideCurrencyCode = false,
}: Args): Result {
  const { price } = tour;

  let value: number | null = null;
  let overriddenCurrencyCode = currencyCode;

  if (price && typeof price === 'object') {
    value = price[currencyCode];
  }

  // attempt default currencyCode
  if (overrideCurrencyCode && typeof value !== 'number' && price !== null) {
    value = price[DEFAULT_CURRENCY_CODE];
    overriddenCurrencyCode = DEFAULT_CURRENCY_CODE;
  }

  if (typeof value !== 'number') {
    value = 999;
  }

  return {
    price: value,
    priceInCents: true,
    currencyCode: overriddenCurrencyCode,
  };
}
