import React from 'react';
import styled from '../../../style/styled';

interface Props {
  imageURL: string;
}

// Display a single image in a modal
const SingleImageViewer: React.FC<Props> = ({ imageURL }) => {
  return (
    <Container>
      <Image src={imageURL} alt="" />
    </Container>
  );
};

export default SingleImageViewer;

const Container = styled.div`
  /* required to center the image vertically */
  margin: auto 0;
  /* without this there is a few pixel border appearing below the image. dunno why */
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
