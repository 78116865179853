import { isMobileOnly } from 'react-device-detect';
import styled from '../../../../style/styled';

export const Container = styled.div`
  width: ${isMobileOnly ? '100%' : '300px'};
  margin: 0 auto;
  padding: 10px;
`;

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 16px;
  }
`;

export const Title = styled.h4`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
`;
