import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlaylistIDsToResync } from '../../store/offline/selectors';
import {
  downloadPlaylistMetadata,
  removePlaylist,
  recheckPlaylist,
} from '../../store/offline/actions';

// If the user had cached tours,
// check if there are any updated tours and syncrhonise them.
export const OfflineAssetSynchroniser = () => {
  const dispatch = useDispatch();
  const { download, cleanup, recheck } = useSelector(selectPlaylistIDsToResync);

  const resyncPlaylists = () => {
    recheck.forEach((playlist) => {
      dispatch(
        recheckPlaylist({
          playlistID: playlist.id,
          automatic: playlist.automatic !== false,
        })
      );
    });

    download.forEach((playlist) => {
      dispatch(
        downloadPlaylistMetadata({
          playlistID: playlist.id,
          automatic: playlist.automatic !== false,
        })
      );
    });

    cleanup.forEach((playlist) => {
      dispatch(
        removePlaylist({
          playlistID: playlist.id,
          playlistName: playlist.name,
          automatic: playlist.automatic !== false,
        })
      );
    });
  };

  useEffect(
    () => {
      resyncPlaylists();
    },
    // we only need to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
};
