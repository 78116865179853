import { memo } from 'react';
import styled from '../../../../style/styled';
import { Section } from './styledComponents/Section';
import { SectionTitle } from './styledComponents/SectionTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/app/selectors';
import { getI18nFieldValue } from '../../../../features/i18n/getI18nFieldValue';
import { SCREEN_WIDTH_BREAK_MEDIUM } from '../../../../style/SIZES';
import { Asset, I18nInfoField, ViewpointLocation } from '../../../../types';

type Viewpoint = {
  id: string;
  nameI18n: I18nInfoField;
  imagesCount: number;
  timePeriodSpecific: string | null;
  thumbnail: Asset | null;
  location: ViewpointLocation | null;
};
interface Props {
  viewpoints: Array<Viewpoint>;
  displayTimePeriods: boolean;
}

export const TourInfoToSeeSection = memo(
  ({ viewpoints, displayTimePeriods }: Props) => {
    const { t } = useTranslation();
    const language = useSelector(selectLanguage);

    const toSee: Array<string> = viewpoints.map((vp) => {
      if (displayTimePeriods) {
        return `${getI18nFieldValue(vp.nameI18n, language)} (${
          vp.timePeriodSpecific
        })`;
      }

      return getI18nFieldValue(vp.nameI18n, language);
    });

    const cutOff = Math.max(Math.ceil(toSee.length / 2), 5);

    const toSeeLeft = toSee.slice(0, cutOff);
    const toSeeRight = toSee.slice(cutOff);

    return (
      <Section id="lots-to-see">
        <SectionTitle>{t('Lots to see...')}</SectionTitle>

        <ItemsContainer>
          <ol>
            {toSeeLeft.map((t, index) => (
              <li key={index + 1}>{t}</li>
            ))}
          </ol>
          <ol start={cutOff + 1}>
            {toSeeRight.map((t, index) => (
              <li key={cutOff + index + 1}>{t}</li>
            ))}
          </ol>
        </ItemsContainer>
      </Section>
    );
  }
);

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.palette.common.black};

  > ol {
    margin-left: 40px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    flex-direction: column;
  }
`;
