import { handleActions } from 'redux-actions';
import { ACTION_TYPES, SetVariantAction, SetVariantsAction } from './actions';
import { GoogleOptimizeState, initialState } from './types';

export const reducer = handleActions<GoogleOptimizeState, any>(
  {
    [ACTION_TYPES.SET_VARIANT]: (
      state: GoogleOptimizeState,
      { payload }: SetVariantAction
    ): GoogleOptimizeState => {
      const newExperiments = state.experiments
        // remove if the same experiment is available
        .filter((e) => e.experimentID !== payload.experimentID)
        // add the new experiment to the end
        .concat(payload);

      return {
        ...state,
        loading: false,
        experiments: newExperiments,
      };
    },

    [ACTION_TYPES.SET_VARIANTS]: (
      state: GoogleOptimizeState,
      { payload }: SetVariantsAction
    ): GoogleOptimizeState => {
      if (payload.length === 0) {
        return { ...state, loading: false };
      }

      // create a map of existing experiments
      let experimentsMap: { [keys: string]: string } = {};
      state.experiments.reduce((accum, experiment) => {
        accum[experiment.experimentID] = experiment.variant;

        return accum;
      }, experimentsMap);

      // loop new experiments
      payload.forEach((experiment) => {
        // overriding variant or adding a new entry
        experimentsMap[experiment.experimentID] = experiment.variant;
      });

      // transform map to array
      const newExperiments = Object.keys(experimentsMap).map(
        (experimentID) => ({
          experimentID,
          variant: experimentsMap[experimentID],
        })
      );

      return {
        ...state,
        loading: false,
        experiments: newExperiments,
      };
    },

    [ACTION_TYPES.SET_GOOGLE_OPTIMIZE_TIMED_OUT]: (
      state: GoogleOptimizeState
    ): GoogleOptimizeState => {
      return { ...state, loading: false };
    },
  },
  initialState
);
