import styled from '../../../style/styled';
import { MAX_WIDTH } from '../../../components/contentSections/toursSection/tourInfo/TourInfo';
import { ShopToursGrid } from '../../../components/ShopToursGrid';
import { env } from '../../config/env';
import { TrialTourEndCTAAO } from '../../../components/TrialTourEndCTAAO';

const TrialTourEndPopup = () => {
  return (
    <Container maxWidth={env.IS_AO ? 300 : MAX_WIDTH}>
      {env.IS_AO ? <TrialTourEndCTAAO /> : <ShopToursGrid />}
    </Container>
  );
};

export default TrialTourEndPopup;

// width should be same as max width
const Container = styled.div<{ maxWidth: number }>`
  padding: 20px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  margin: 0 auto;
`;
