import { z } from 'zod';
import * as Sentry from '@sentry/react';
import { env } from '../../../../App/config/env';

export default function isMapHiddenAfterChangingTheViewpoint() {
  // Define the schema for the env object
  const envSchema = z.object({
    APP_ENV: z.object({
      features: z
        .object({
          isMapHiddenAfterChangingTheViewpoint: z.boolean().optional(),
        })
        .optional(),
    }),
  });

  // Validate the env object
  const parsedEnv = envSchema.safeParse(env);

  if (!parsedEnv.success) {
    Sentry.captureException(parsedEnv.error);

    return false;
  }

  return !!parsedEnv.data.APP_ENV.features
    ?.isMapHiddenAfterChangingTheViewpoint;
}
