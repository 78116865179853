import * as Sentry from '@sentry/react';
import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga';
import UserContext, { CURRENT_USER_QUERY } from '../context/userContext';
import {
  EXPLORE_WEB_CurrentUserForUserContext as Data,
  EXPLORE_WEB_CurrentUserForUserContext_currentUser_purchases as Purchase,
} from '../context/__generated__/EXPLORE_WEB_CurrentUserForUserContext';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  selectIsAuthenticated,
  selectIsGuestUser,
} from '../store/app/selectors';
import { ProductSKU } from '../graphql/globalTypes';
import useOnAuthChanged from '../hooks/useOnAuthChanged';
import LogRocket from 'logrocket';
import { env } from '../App/config/env';
import { logMessage } from '../features/logging/logMessage';

type Props = {
  children: React.ReactNode;
};

const UserProvider = ({ children }: Props) => {
  const isAuthed = useSelector(selectIsAuthenticated);
  const isGuestUser = useSelector(selectIsGuestUser);

  const { data, refetch } = useQuery<Data, any>(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useOnAuthChanged(() => {
    logMessage('UserProvider: auth changed, refetching user');
    refetch();
  });

  const user = (isAuthed && data?.currentUser) || null;

  const { tourPurchasesMap, tourBundlePurchasesMap } = useMemo(() => {
    const purchases = user?.purchases ?? [];

    const tourPurchasesMap = purchases
      .filter((p) => p.productSKU === ProductSKU.PLAYLIST)
      .reduce((acc, p) => {
        acc[p.productID] = p;
        return acc;
      }, {} as Record<string, Purchase>);

    const tourBundlePurchasesMap = purchases
      .filter((p) => p.productSKU === ProductSKU.PLAYLIST_BUNDLE)
      .reduce((acc, p) => {
        acc[p.productID] = p;
        return acc;
      }, {} as Record<string, Purchase>);

    return { tourPurchasesMap, tourBundlePurchasesMap };
  }, [user]);

  const userID = user?.id;

  useEffect(() => {
    if (userID) {
      Sentry.setUser({ id: userID });

      if (env.ENABLE_LOG_ROCKET_REPORTING) {
        LogRocket.identify(userID);
      }

      ReactGA.set({ userId: userID });
    } else {
      Sentry.setUser(null);

      if (env.ENABLE_LOG_ROCKET_REPORTING) {
        LogRocket.identify('Anonymous');
      }

      ReactGA.set({ userId: null });
    }
  }, [userID]);

  return (
    <UserContext.Provider
      value={{
        user,
        refetch,
        tourPurchasesMap,
        tourBundlePurchasesMap,
        isGuestUser,
        isLoggedIn: isAuthed,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
