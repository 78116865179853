function isError(e: unknown): e is Error {
  return e instanceof Error;
}

function getErrorMessage(e: unknown): string {
  if (isError(e)) {
    return e.message;
  }

  if (e === null || e === undefined) {
    return String(e);
  }

  return e.toString();
}

export default getErrorMessage;
