import { RootState } from '../redux/types';
import {
  AudioState,
  ViewerOptionsState,
  ViewerState,
  AudioPlayingState,
  CompassSupport,
} from './types';
import { FullTour } from '../../graphql/queries/GetFullTourQuery';
import { LanguageCode } from '../../graphql/globalTypes';
import { getI18nFieldValue } from '../../features/i18n/getI18nFieldValue';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { Guide } from '../../graphql/queries/GetGuides';

export function selectViewer(state: RootState): ViewerState {
  return state.viewer;
}

export function selectViewerIsLoading(state: RootState): boolean {
  return state.viewer.isLoading;
}

export function selectViewerOptions(state: RootState): ViewerOptionsState {
  return state.viewer.viewerOptions;
}

export function selectViewerOptionIsImagesEnabled(state: RootState): boolean {
  return state.viewer.viewerOptions.isImagesEnabled;
}

export function selectViewpointOptionIsGuidePanelVisible(
  state: RootState
): boolean {
  return state.viewer.viewerOptions.isGuidePanelVisible;
}

export function selectViewerOptionsIsMetaDataWindowVisible(
  state: RootState
): boolean {
  return state.viewer.viewerOptions.isMetaDataWindowVisible;
}

export function selectViewerOptionIsMapsVisible(state: RootState): boolean {
  return (
    !selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.isMapsVisible
  );
}

export function selectViewerOptionIsSideBySideVisible(
  state: RootState
): boolean {
  return state.viewer.viewerOptions.isSideBySideVisible;
}

export function selectViewerOptionIsGyroEnabled(state: RootState): boolean {
  return state.viewer.viewerOptions.isGyroEnabled;
}

export function selectViewerOptionsIsFullscreen(state: RootState): boolean {
  return state.viewer.viewerOptions.isFullscreen;
}

export function selectTour(state: RootState): FullTour | null {
  return state.viewer.tour;
}

export function selectTourEnded(state: RootState): boolean {
  const isLastViewpoint = selectIsLastViewpoint(state);

  const audioCompleted =
    selectViewerAudioPlayingState(state) === AudioPlayingState.completed;

  return isLastViewpoint && audioCompleted;
}

export function selectIsLastViewpoint(state: RootState): boolean {
  const tour = selectTour(state);

  if (!tour) {
    return false;
  }

  const currentViewpointIndex = selectCurrentViewpointIndex(state);

  return tour.viewpoints.length <= currentViewpointIndex + 1;
}

export function selectCurrentViewpointIndex(state: RootState): number {
  return state.viewer.currentViewpointIndex;
}
export function selectPreviousViewpointIndex(state: RootState): number {
  return state.viewer.previousViewpointIndex;
}
export function selectCurrentViewpoint(state: RootState) {
  const tour = selectTour(state);

  if (!tour) {
    return null;
  }

  const currentViewpointIndex = selectCurrentViewpointIndex(state);

  const viewpoint = tour.viewpoints[currentViewpointIndex];

  if (!viewpoint) {
    return null;
  }
  return viewpoint;
}
export function selectUserSelectedAViewpoint(state: RootState): boolean {
  return state.viewer.userSelectedAViewpoint;
}

export function selectCurrentViewpointID(state: RootState): string | null {
  const tour = selectTour(state);

  if (!tour) {
    return null;
  }

  const currentViewpointIndex = selectCurrentViewpointIndex(state);

  const viewpoint = tour.viewpoints[currentViewpointIndex];

  if (!viewpoint) {
    return null;
  }

  return viewpoint.id;
}

export function selectCurrentImageIndex(state: RootState): number {
  return state.viewer.currentImageIndex;
}

export function selectImageCaption(state: RootState): string | null {
  const { tour, currentViewpointIndex, currentImageIndex } = state.viewer;
  const { language } = state.app;

  const currentViewpoint = (tour?.viewpoints || [])[currentViewpointIndex];

  if (!currentViewpoint) {
    captureInSentry('currentViewpoint is null in selectImageCaption', {
      tourID: tour?.id,
      currentViewpointIndex,
    });

    return null;
  }

  const { images } = currentViewpoint;

  const image = images[currentImageIndex];

  if (!image) {
    captureInSentry('Image is null in selectImageCaption', {
      tourID: tour?.id,
      currentViewpointIndex,
      currentImageIndex,
    });

    return null;
  }

  return getI18nFieldValue(image.nameI18n, language);
}

export function selectViewerAudio(state: RootState): AudioState {
  return state.viewer.audio;
}

export function selectShouldDisplaySubtitles(state: RootState): boolean {
  const {
    pausedByUser,
    pausedByTheSystem,
    playing,
    completed,
  } = state.viewer.audio;

  return playing || (!completed && (pausedByUser || pausedByTheSystem));
}

export function selectSubtitleTimestamp(state: RootState): number {
  if (selectShouldDisplaySubtitles(state)) {
    return selectViewerAudioSeekPos(state);
  }

  return -1;
}

export function selectViewerAudioIsPlaying(state: RootState): boolean {
  return state.viewer.audio.playing;
}

export function selectViewerAudioPlayingState(
  state: RootState
): AudioPlayingState | null {
  const {
    pausedByUser,
    pausedByTheSystem,
    loading,
    playing,
    completed,
  } = state.viewer.audio;

  if (loading) {
    return AudioPlayingState.loading;
  }

  // https://lithodomosvr.atlassian.net/browse/LVR-13292
  if (pausedByTheSystem && !completed) {
    return AudioPlayingState.systemPaused;
  }

  if (pausedByUser) {
    return AudioPlayingState.paused;
  }

  if (completed) {
    return AudioPlayingState.completed;
  }

  if (playing) {
    return AudioPlayingState.playing;
  }

  return null;
}

export function selectViewerAudioLoading(state: RootState): boolean {
  return state.viewer.audio.loading;
}

export function selectViewerAudioSeekPos(state: RootState): number {
  return state.viewer.audio.seekPos;
}

export function selectViewerAudioLength(state: RootState): number {
  return state.viewer.audio.length;
}

export function selectViewerAudioShowSubtitles(state: RootState): boolean {
  return state.viewer.audio.showSubtitles;
}

export function selectViewerAudioSubtitleLanguage(
  state: RootState
): LanguageCode {
  return state.viewer.audio.subtitlesLanguage;
}

export function selectViewerAudioNarrationLanguage(
  state: RootState
): LanguageCode {
  return state.viewer.audio.narrationLanguage;
}

export function selectViewerAudioMusicMuted(state: RootState): boolean {
  return state.viewer.audio.musicMuted;
}

export function selectGuide(state: RootState): Guide | null | undefined {
  return state.viewer.tour?.guide;
}

export function selectEndOfTourPopupClosed(state: RootState): boolean {
  return state.viewer.endOfTourPopupClosed;
}

export function selectCompassSupport(state: RootState): CompassSupport {
  return state.viewer.compassSupport;
}

export function selectIsTutorialVisible(state: RootState): boolean {
  return state.viewer.viewerOptions.isTutorialVisible;
}

export function selectTutorialDisplayed(state: RootState): boolean {
  return state.viewer.viewerOptions.tutorialDisplayed;
}

export function selectDisplayOnboardingTutorial({
  viewer: { viewerOptions },
}: RootState): boolean {
  return viewerOptions.isTutorialVisible || !viewerOptions.tutorialDisplayed;
}

export function selectViewerVisitedVPIndices(state: RootState): number[] {
  return state.viewer.visitedVPIndices;
}

export function selectIsPlaylistThumbnailsVisible(state: RootState): boolean {
  return state.viewer.viewerOptions.isPlaylistThumbnailsVisible;
}

export function selectUIVisible(state: RootState): boolean {
  return state.viewer.displayUI;
}

export function selectAudioSetChangingSlider(state: RootState): boolean {
  return Boolean(state.viewer.audio.changingSlider);
}

export function selectImagesButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialImagesButtonHighlighted
  );
}

export function selectVPGridButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialVPGridButtonHighlighted
  );
}

export function selectVPInfoButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialVPInfoButtonHighlighted
  );
}

export function selectSubtitlesButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialSubtitlesButtonHighlighted
  );
}

export function selectToggleMusicButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialToggleMusicButtonHighlighted
  );
}

export function selectNarrationPlayPauseButtonHighlighted(
  state: RootState
): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialNarrationPlayPauseButtonHighlighted
  );
}

export function selectGyroToggleButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialGyroToggleButtonHighlighted
  );
}

export function selectMapToggleButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialMapToggleButtonHighlighted
  );
}
export function selectSplitScreenButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialSplitScreenButtonHighlighted
  );
}

export function selectAudioPlaybackError(state: RootState): any {
  return state.viewer.audio.error;
}

export function selectIsFullscreenMapsVisible(state: RootState): boolean {
  return (
    selectViewerOptionIsMapsVisible(state) &&
    state.viewer.viewerOptions.isFullScreenMapVisible
  );
}
