import { useCallback } from 'react';
import styled from '../../../style/styled';
import { MarkerProps, MarkerType } from '../types';
import { GenericMarkerData } from '../types';

const VIEWPOINT_ICON_SRC = '/icons/Viewpoint_Tour_Icon_02.png';
const TOUR_ICON_SRC = '/icons/Tour_Icon_02.png';

function getIconSrc(type: MarkerType, id: string): string {
  switch (type) {
    case MarkerType.VIEWPOINT:
      return VIEWPOINT_ICON_SRC;
    case MarkerType.TOUR:
      return TOUR_ICON_SRC;
    default:
      return '';
  }
}

interface Props extends MarkerProps {
  data: GenericMarkerData;
  selected: boolean;
  setSelected: (placeID: string | null) => void;
  withNumbers?: boolean;
}

export const GenericMarker = ({
  data,
  selected,
  setSelected,
  withNumbers = true,
}: Props) => {
  const { id, idx, type, icon } = data;

  const handleClick = useCallback(() => {
    if (!id) return;

    setSelected(selected ? null : id);
  }, [setSelected, id, selected]);

  const withNumber = withNumbers && typeof idx === 'number';

  const iconSrc = icon || getIconSrc(type, id);

  return (
    <Container onClick={handleClick}>
      <Icon src={iconSrc} selected={selected} />

      {withNumber && (
        <MarkerNumberContainer>
          <MarkerNumber>{idx + 1}</MarkerNumber>
        </MarkerNumberContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

const Icon = styled.img<{ selected: boolean }>`
  position: absolute;
  width: 32px;
  height: 32px;
  left: -16px;
  top: -32px;
  filter: brightness(${({ selected }) => (selected ? 50 : 100)}%);
`;

const MarkerNumberContainer = styled.div`
  z-index: 2;
  position: absolute;
  width: 14px;
  height: 14px;
  left: -7px;
  top: -27px;
  background: ${(props) => props.theme.palette.common.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MarkerNumber = styled.p`
  color: ${(props) => props.theme.palette.common.black};
  font-weight: bold;
  font-size: 10px;
`;
