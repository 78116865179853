import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../../Routes/routes';
import { closeViewer } from '../../../store/viewer/actions';
import { exitFullscreen } from '../../../utils/fullscreen';
import { disableNoSleep } from '../../../utils/noSleep';

interface Props {
  curPathname: string;
  prevPathname?: string | null;
}

export const CloseTourIfNotOnFreeRoamTourRoute: React.FC<Props> = ({
  curPathname,
  prevPathname,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (curPathname !== prevPathname && curPathname !== ROUTES.freeRoamTour) {
      // this takes care of stopping audio when a user hits the back button while inside a tour
      dispatch(closeViewer());

      // this takes care of disabling nosleep when not inside a tour
      disableNoSleep();

      // exist fullscreen
      exitFullscreen();
    }
  }, [dispatch, curPathname, prevPathname]);

  return null;
};
