import ReactGA from 'react-ga';
import { AnalyticEventInput } from '../../graphql/globalTypes';
import { DataKeys, EventNames } from './types';

export function captureInGA(event: AnalyticEventInput) {
  const mapIntValue: { [key: string]: any } = {};

  const dataMap = (event.data || []).reduce((accum, item) => {
    if (item?.key && item?.value) {
      accum[item.key] = item.value;
    }

    return accum;
  }, mapIntValue);

  if (event.name === EventNames.ROUTE_CHANGE) {
    const page = dataMap[DataKeys.TO];

    if (page) {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
  } else if (event.name === EventNames.ACTION_BUTTON_PRESS) {
    const buttonName = dataMap[DataKeys.BUTTON_NAME];
    const page = dataMap[DataKeys.PAGE];

    if (buttonName && page) {
      // https://github.com/react-ga/react-ga#reactgaeventargs

      ReactGA.event({
        category: EventNames.ACTION_BUTTON_PRESS,
        label: `On page ${page}`,
        action: buttonName,
        // value: Number,
        nonInteraction: false,
      });
    }
  } else if (event.name === EventNames.VIEW_MODAL) {
    const modalName = dataMap[DataKeys.MODAL];

    if (modalName) {
      ReactGA.modalview(modalName);
    }
  } else if (event.name === EventNames.TOUR_START) {
    const tourID = dataMap[DataKeys.TOUR_ID];

    ReactGA.event({
      category: 'Tour',
      label: `TourID: ${tourID}`,
      action: 'Started Tour',
      // value: Number,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.TOUR_END) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const tourDuration = parseInt(dataMap[DataKeys.TOUR_DURATION] || '0');

    ReactGA.event({
      category: 'Tour',
      label: `TourID: ${tourID}`,
      action: 'Ended Tour',
      value: tourDuration,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.VIEWPOINT_START) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];

    ReactGA.event({
      category: 'Tour',
      label: `ViewpointID: ${viewpointID} (TourID: ${tourID})`,
      action: 'Started Viewpoint',
      // value: Number,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.VIEWPOINT_END) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];
    const viewpointDuration = parseInt(
      dataMap[DataKeys.VIEWPOINT_DURATION] || '0'
    );

    ReactGA.event({
      category: 'Tour',
      label: `ViewpointID: ${viewpointID} (TourID: ${tourID})`,
      action: 'Ended Viewpoint',
      value: viewpointDuration,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.IMAGE_START) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];
    const imageID = dataMap[DataKeys.IMAGE_ID];

    ReactGA.event({
      category: 'Tour',
      label: `ImageID: ${imageID} (TourID: ${tourID}, ViewpointID: ${viewpointID})`,
      action: 'Started Viewpoint',
      // value: Number,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.IMAGE_END) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];
    const imageID = dataMap[DataKeys.IMAGE_ID];
    const imageDuration = parseInt(dataMap[DataKeys.IMAGE_DURATION] || '0');

    ReactGA.event({
      category: 'Tour',
      label: `ImageID: ${imageID} (TourID: ${tourID}, ViewpointID: ${viewpointID})`,
      action: 'Ended Tour',
      value: imageDuration,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.RATED_TOUR) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const rating = (dataMap[DataKeys.RATING] || 0) * 5;

    ReactGA.event({
      category: 'Tour',
      label: `TourID: ${tourID}`,
      action: 'Rated Tour',
      value: rating,
      nonInteraction: false,
    });
  } else if (event.name === EventNames.LOGGED_IN) {
    const loginMethod = dataMap[DataKeys.LOGIN_METHOD];

    ReactGA.event({
      category: 'User',
      label: `LoginMethod: ${loginMethod}`,
      action: 'Logged In',
      // value: rating,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.USER_REGISTERED) {
    const signUpMethod = dataMap[DataKeys.SIGNUP_METHOD] || 'Email';

    ReactGA.event({
      category: 'User',
      label: `SignupMethod: ${signUpMethod}`,
      action: 'Sign Up',
      // value: rating,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.ATTEMPTED_COUPON_CODE) {
    const couponCode = dataMap[DataKeys.COUPON_CODE] || 'Unknown';

    ReactGA.event({
      category: 'Conversion',
      label: `CouponCode: ${couponCode}`,
      action: 'Attempted Coupon Code',
      // value: rating,
      nonInteraction: false,
    });
  } else if (event.name === EventNames.PURCHASED_PRODUCT) {
    const orderID = dataMap[DataKeys.ORDER_ID] || 'Unknown';

    ReactGA.event({
      category: 'Conversion',
      label: `OrderID: ${orderID}`,
      action: 'Purchased Product',
      // value: rating,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.REDEMPTION_CODE_EXTRACTED) {
    const redeemCode = dataMap[DataKeys.REDEEM_CODE] || 'Unknown';

    ReactGA.event({
      category: 'Conversion',
      label: `RedeemCode: ${redeemCode}`,
      action: 'Redeem Code Extracted from Link',
      nonInteraction: true,
    });
  } else if (event.name === EventNames.ACTIVATED_PRODUCT) {
    const redeemCode = dataMap[DataKeys.REDEEM_CODE] || 'Unknown';

    ReactGA.event({
      category: 'Conversion',
      label: `RedeemCode: ${redeemCode}`,
      action: 'Redeemed Product',
      // value: rating,
      nonInteraction: true,
    });
  } else if (event.name === EventNames.AFFILIATE_CODE_EXTRACTED) {
    const affiliateCode = dataMap[DataKeys.AFFILIATE_CODE] || 'Unknown';

    ReactGA.event({
      category: 'Conversion',
      label: `AffiliateCode: ${affiliateCode}`,
      action: 'Affiliate Code Extracted from Link',
      nonInteraction: true,
    });
  } else if (event.name === EventNames.PANORAMA_CLICK) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];

    ReactGA.event({
      category: 'Tour',
      label: `viewpointID: ${viewpointID} (TourID: ${tourID})`,
      action: 'Clicked 360 Image',
      nonInteraction: false,
    });
  } else if (event.name === EventNames.PANORAMA_ZOOM) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];
    const zoomLevel = dataMap[DataKeys.PANO_ZOOM_LEVEL];

    ReactGA.event({
      category: 'Tour',
      label: `viewpointID: ${viewpointID} (TourID: ${tourID})`,
      action: 'Zoomed 360 Image',
      value: zoomLevel,
      nonInteraction: false,
    });
  } else if (event.name === EventNames.PANORAMA_ROTATE) {
    const tourID = dataMap[DataKeys.TOUR_ID];
    const viewpointID = dataMap[DataKeys.VIEWPOINT_ID];

    ReactGA.event({
      category: 'Tour',
      label: `viewpointID: ${viewpointID} (TourID: ${tourID})`,
      action: 'Panned 360 Image',
      nonInteraction: false,
    });
  } else if (event.name === EventNames.ITINERARY_VIDEO_PLAYED) {
    const tourID = dataMap[DataKeys.TOUR_ID];

    ReactGA.event({
      category: 'Video',
      label: `TourID: ${tourID}`,
      action: 'Played Tour Itinerary Video',
      nonInteraction: false,
    });
  } else if (event.name === EventNames.LANGUAGE_CHANGED) {
    const languageCode = dataMap[DataKeys.LANGUAGE];

    ReactGA.event({
      category: 'I18n',
      label: languageCode,
      action: 'Changed language',
      nonInteraction: false,
    });
  }
}
