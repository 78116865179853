import { Stack } from '../primitives/Stack';
import { Version } from './Version';

export const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Stack gutter={8}>
      <p>©{currentYear} Lithodomos VR Pty Ltd</p>

      <Version />
    </Stack>
  );
};
