import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button';
import { captureButtonPress } from '../../../../features/analytics';

type Props = {
  onClick: () => void;
};

export const UseADifferentCodeButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      uppercase
      secondary
      onClick={() => {
        captureButtonPress({
          page: window.location.pathname,
          buttonName: 'UseADifferentCode',
        });

        onClick();
      }}
    >
      {t('Use a different code')}
    </Button>
  );
};
