import React from 'react';
import styled from 'styled-components';
import { mdiClose } from '@mdi/js';
import { mdiArrowRight } from '@mdi/js';
import { SelectedFeature } from '../../../features/offlineMap/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentViewpointIndex } from '../../../store/viewer/selectors';
import { setViewpointIndex } from '../../../store/viewer/actions';
import MdiIcon from '../../../components/MdiIcon';

interface Props {
  selectedFeature: SelectedFeature | null | undefined;
  onClose: () => void;
  withViewpointVisiting: boolean;
  withNumbers?: boolean;
  showGoButtonOnCurrentMarker?: boolean;
}

export const InfoPanel: React.FC<Props> = ({
  onClose,
  selectedFeature,
  withViewpointVisiting = true,
  withNumbers = true,
  showGoButtonOnCurrentMarker = false,
}: Props) => {
  const dispatch = useDispatch();
  const currentViewpointIndex = useSelector(selectCurrentViewpointIndex);

  const { idx, title, description, thumbnailURL } = getFeatureDetails(
    selectedFeature
  );

  return (
    <Panel visible={Boolean(selectedFeature)} thumbnailURL={thumbnailURL}>
      <Overlay />

      <PanelContent>
        <MetadataContent>
          <Title>
            {withNumbers && (
              <NumberCircleContainer>
                <NumberCircle>{idx + 1}</NumberCircle>
              </NumberCircleContainer>
            )}
            <TitleSpan>{title}</TitleSpan>
          </Title>
          {description && <Description>{description}</Description>}
        </MetadataContent>

        {withViewpointVisiting &&
          (showGoButtonOnCurrentMarker || idx !== currentViewpointIndex) && (
            <GoButtonContainer>
              <GoIconContainer
                onClick={() => {
                  dispatch(setViewpointIndex({ index: idx }));
                }}
              >
                <MdiIcon path={mdiArrowRight} size={24} color="#fff" />
              </GoIconContainer>
            </GoButtonContainer>
          )}
      </PanelContent>

      <CloseButtonContainer onClick={onClose} title="Close info panel">
        <MdiIcon path={mdiClose} color="#fff" />
      </CloseButtonContainer>
    </Panel>
  );
};

function getFeatureDetails(feature: SelectedFeature | null | undefined) {
  let idx = 0;
  let title = '';
  let description = '';
  let thumbnailURL = '';

  if (feature) {
    idx = feature.idx;
    title = feature.name;
    description = feature.description;
    thumbnailURL = feature.thumbnailURL;
  }

  return { idx, title, description, thumbnailURL };
}

const TitleSpan = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
`;

const MetadataContent = styled.div`
  flex-grow: 1;
`;

const GoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 8px;
`;

const NumberCircleContainer = styled.div`
  display: inline-block;
  margin-right: 8px;
`;

const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #fff;
  color: #000;
`;

const Title = styled.span`
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-bottom: 8px;
  margin-right: 20px;
`;

const Description = styled.span`
  font-size: 14px;
  color: #fff;
  display: block;
`;

const Panel = styled.div.attrs<{ visible: boolean; thumbnailURL: string }>(
  (props) => ({
    style: {
      top: props.visible ? 'auto' : '100%',
      bottom: props.visible ? '0' : 'auto',
      backgroundImage: `url(${props.thumbnailURL})`,
    },
  })
)<{ visible: boolean; thumbnailURL: string }>`
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;
  width: 100%;
  bottom: 0;
  z-index: 1;
  right: 0;
  color: #fff;
  border-radius: 12px 12px 0 0;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const PanelContent = styled.div`
  color: #fff;
  padding: 20px 8px;
  position: relative;
  width: 100%;
  z-index: 2;
  display: flex;
`;

const IconContainer = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 50%;
`;

const CloseButtonContainer = styled(IconContainer)`
  position: absolute;
  top: -16px;
  right: 8px;
  z-index: 3;
`;

const GoIconContainer = styled(IconContainer)`
  width: auto;
  height: auto;
  padding: 8px 16px;
  border-radius: 8px;
  background: #0066ff;
`;
