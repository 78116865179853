import { handleActions } from 'redux-actions';
import {
  ACTION_TYPES,
  SetContentIndexAction,
  OpenTourSuccessAction,
  SetViewerOptionsAction,
  SetViewerLoadingStateAction,
  UpdateNarrationLengthAction,
  UpdateNarrationSeekPosAction,
  SetImageCaptionAction,
  // PlayNarrationAction,
  SetSubtitleVisibilityAction,
  SetSubtitleLanguageAction,
  SetCompassSupportAction,
  SetNarrationLanguageAction,
  SetVPVisitedAction,
  OpenImageByIndexAction,
  SetChangingSliderAction,
  SetAudioStatePartialAction,
  SetClosestViewpointIndexAction,
} from './actions';
import {
  ACTION_TYPES as APP_ACTION_TYPES,
  SetLanguageAction,
} from '../app/actions';
import { initialState, ViewerState } from './types';

export const reducer = handleActions<ViewerState, any>(
  {
    [ACTION_TYPES.CLOSE_VIEWER]: (state: ViewerState): ViewerState => ({
      ...initialState,
      viewerOptions: {
        ...initialState.viewerOptions,
        isTutorialVisible: state.viewerOptions.isTutorialVisible,
        tutorialDisplayed: state.viewerOptions.tutorialDisplayed,
      },
      audio: {
        ...initialState.audio,
        narrationLanguage: state.audio.narrationLanguage,
        subtitlesLanguage: state.audio.subtitlesLanguage,
      },
    }),

    [ACTION_TYPES.OPEN_TOUR_SUCCESS]: (
      state: ViewerState,
      { payload }: OpenTourSuccessAction
    ): ViewerState => ({
      ...state,
      tour: payload.tour,
      currentViewpointIndex: payload.currentViewpointIndex,
      viewerOptions: {
        ...initialState.viewerOptions,
        isTutorialVisible: state.viewerOptions.isTutorialVisible,
        tutorialDisplayed: state.viewerOptions.tutorialDisplayed,
        ...payload.viewerOptions,
      },
      audio: {
        ...initialState.audio,
        narrationLanguage: state.audio.narrationLanguage,
        subtitlesLanguage: state.audio.subtitlesLanguage,
      },
    }),

    [ACTION_TYPES.INCREMENT_VIEWPOINT_INDEX]: (
      state: ViewerState
    ): ViewerState => {
      const { tour, currentViewpointIndex, visitedVPIndices } = state;

      if (!tour) {
        return state;
      }

      const { viewpoints } = tour;

      const nextViewpointIndex =
        currentViewpointIndex + 1 === viewpoints.length
          ? 0
          : currentViewpointIndex + 1;
      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(nextViewpointIndex)),
      ];

      return {
        ...state,
        currentViewpointIndex: nextViewpointIndex,
        previousViewpointIndex: currentViewpointIndex,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
        visitedVPIndices: nextVsitedVPIndices,
        // audio: {
        //   ...state.audio,
        //   playingState: null,
        // },
      };
    },

    [ACTION_TYPES.DECREMENT_VIEWPOINT_INDEX]: (
      state: ViewerState
    ): ViewerState => {
      const { currentViewpointIndex, visitedVPIndices, tour } = state;

      if (!tour) {
        return state;
      }

      const { viewpoints } = tour;

      const previousViewpointIndex =
        (currentViewpointIndex < 1
          ? viewpoints.length
          : currentViewpointIndex) - 1;
      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(previousViewpointIndex)),
      ];

      return {
        ...state,
        currentViewpointIndex: previousViewpointIndex,
        previousViewpointIndex: currentViewpointIndex,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
        visitedVPIndices: nextVsitedVPIndices,
        // audio: {
        //   ...state.audio,
        //   playingState: null,
        // },
      };
    },

    [ACTION_TYPES.SET_VP_VISITED]: (
      state: ViewerState,
      { payload }: SetVPVisitedAction
    ): ViewerState => {
      const { visitedVPIndices } = state;

      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(payload.index)),
      ];

      return {
        ...state,
        visitedVPIndices: nextVsitedVPIndices,
      };
    },

    [ACTION_TYPES.SET_VIEWPOINT_INDEX]: (
      state: ViewerState,
      action: SetContentIndexAction
    ): ViewerState => {
      const { currentViewpointIndex, visitedVPIndices, tour } = state;
      const indexRequested = action.payload.index;

      if (!tour || typeof indexRequested !== 'number') {
        return state;
      }

      const { viewpoints } = tour;

      // if it's the same vp or it's not available (unlikely)
      // don't do anything.
      if (
        indexRequested === currentViewpointIndex ||
        !viewpoints[indexRequested]
      ) {
        return {
          ...state,
          currentViewpointIndex: indexRequested,
          previousViewpointIndex: currentViewpointIndex,
          userSelectedAViewpoint: true,
        };
      }

      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(indexRequested)),
      ];

      return {
        ...state,
        currentViewpointIndex: indexRequested,
        previousViewpointIndex: currentViewpointIndex,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
        visitedVPIndices: nextVsitedVPIndices,
        userSelectedAViewpoint: true,
      };
    },

    [ACTION_TYPES.SET_CLOSEST_VIEWPOINT_INDEX]: (
      state: ViewerState,
      action: SetClosestViewpointIndexAction
    ) => {
      return {
        ...state,
        closestViewpointIndex: action.payload.index,
        closestViewpointDistance: action.payload.distance,
        closestViewpointDetectionMode: action.payload.mode,
      };
    },

    [ACTION_TYPES.INCREMENT_IMAGE_INDEX]: (state: ViewerState): ViewerState => {
      const { tour, currentViewpointIndex, currentImageIndex } = state;

      if (!tour) {
        return state;
      }

      const { viewpoints } = tour;

      const currentViewpoint = viewpoints[currentViewpointIndex];

      if (!currentViewpoint) {
        return state;
      }

      const images = currentViewpoint.images;

      // infinite scrolling
      const nextImageIndex =
        currentImageIndex + 1 === images.length ? 0 : currentImageIndex + 1;

      return {
        ...state,
        currentImageIndex: nextImageIndex,
        previousImageIndex: currentImageIndex,
      };
    },

    [ACTION_TYPES.DECREMENT_IMAGE_INDEX]: (state: ViewerState): ViewerState => {
      const { tour, currentViewpointIndex, currentImageIndex } = state;

      if (!tour) {
        return state;
      }

      const { viewpoints } = tour;

      const currentViewpoint = viewpoints[currentViewpointIndex];

      if (!currentViewpoint) {
        return state;
      }

      const images = currentViewpoint.images;

      // infinite scrolling
      const previousImageIndex =
        (currentImageIndex < 1 ? images.length : currentImageIndex) - 1;

      return {
        ...state,
        currentImageIndex: previousImageIndex,
        previousImageIndex: currentImageIndex,
      };
    },

    [ACTION_TYPES.SET_IMAGE_INDEX]: (
      state: ViewerState,
      action: OpenImageByIndexAction
    ): ViewerState => {
      const { currentViewpointIndex, currentImageIndex, tour } = state;
      const indexRequested = action.payload;

      if (!tour || typeof indexRequested !== 'number') {
        return state;
      }

      const { viewpoints } = tour;

      const currentViewpoint = viewpoints[currentViewpointIndex];

      if (!currentViewpoint) {
        return state;
      }

      const images = currentViewpoint.images;

      if (!images[indexRequested] || indexRequested === currentImageIndex) {
        return state;
      }

      return {
        ...state,
        currentImageIndex: indexRequested,
        previousImageIndex: currentImageIndex,
      };
    },

    [ACTION_TYPES.SET_VIEWER_OPTIONS]: (
      state: ViewerState,
      { payload }: SetViewerOptionsAction
    ): ViewerState => ({
      ...state,
      viewerOptions: {
        ...state.viewerOptions,
        ...payload.options,
      },
    }),

    [ACTION_TYPES.SET_VIEWER_LOADING_STATE]: (
      state: ViewerState,
      { payload }: SetViewerLoadingStateAction
    ): ViewerState => ({
      ...state,
      isLoading: payload.isLoading,
    }),

    [ACTION_TYPES.SET_CHANGING_SLIDER]: (
      state: ViewerState,
      { payload }: SetChangingSliderAction
    ) => {
      return {
        ...state,
        audio: {
          ...state.audio,
          changingSlider: payload,
        },
      };
    },

    [ACTION_TYPES.PLAY_NARRATION]: (state: ViewerState): ViewerState => {
      return {
        ...state,
        audio: {
          ...state.audio,
          playing: true,
          pausedByUser: false,
          pausedByTheSystem: false,
        },
      };
    },

    [ACTION_TYPES.PAUSE_NARRATION]: (state: ViewerState): ViewerState => {
      return {
        ...state,
        audio: {
          ...state.audio,
          playing: false,
          pausedByUser: true,
          pausedByTheSystem: false,
        },
      };
    },

    [ACTION_TYPES.SET_AUDIO_STATE_PARTIAL]: (
      state: ViewerState,
      { payload }: SetAudioStatePartialAction
    ) => {
      return {
        ...state,
        audio: {
          ...state.audio,
          ...payload,
        },
      };
    },

    [ACTION_TYPES.UPDATE_NARRATION_LENGTH]: (
      state: ViewerState,
      { payload }: UpdateNarrationLengthAction
    ) => {
      const { seconds } = payload;

      return {
        ...state,
        audio: {
          ...state.audio,
          length: seconds,
          // if length is zero or negative, reset the seekpos
          seekPos: seconds <= 0 ? 0 : state.audio.seekPos,
        },
      };
    },

    [ACTION_TYPES.UPDATE_NARRATION_SEEK_POS]: (
      state: ViewerState,
      { payload }: UpdateNarrationSeekPosAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        seekPos: payload.seek,
      },
    }),

    [ACTION_TYPES.SET_IMAGE_CAPTION]: (
      state: ViewerState,
      { payload }: SetImageCaptionAction
    ) => ({
      ...state,
      imageCaption: payload.caption,
    }),

    [ACTION_TYPES.TOGGLE_SUBTITLE_VISIBILITY]: (state: ViewerState) => ({
      ...state,
      audio: {
        ...state.audio,
        showSubtitles: !state.audio.showSubtitles,
      },
    }),

    [ACTION_TYPES.SET_SUBTITLE_VISIBILITY]: (
      state: ViewerState,
      { payload }: SetSubtitleVisibilityAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        showSubtitles: payload.visibility,
      },
    }),

    [APP_ACTION_TYPES.SET_LANGUAGE]: (
      state: ViewerState,
      { payload }: SetLanguageAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        narrationLanguage: payload,
      },
    }),

    [ACTION_TYPES.SET_SUBTITLE_LANGUAGE]: (
      state: ViewerState,
      { payload }: SetSubtitleLanguageAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        subtitlesLanguage: payload.languageCode,
        showSubtitles: true,
      },
    }),

    [ACTION_TYPES.SET_NARRATION_LANGUAGE]: (
      state: ViewerState,
      { payload }: SetNarrationLanguageAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        narrationLanguage: payload.languageCode,
      },
    }),

    [ACTION_TYPES.TOGGLE_MUTE_MUSIC]: (state: ViewerState) => ({
      ...state,
      audio: {
        ...state.audio,
        musicMuted: !state.audio.musicMuted,
      },
    }),

    [ACTION_TYPES.SET_END_OF_TOUR_POPUP_CLOSED]: (state: ViewerState) => ({
      ...state,
      endOfTourPopupClosed: true,
    }),

    [ACTION_TYPES.INCREMENT_RESYNC_WITH_COMPASS_COUNTER]: (
      state: ViewerState
    ) => ({
      ...state,
      resyncWithCompassCounter: (state.resyncWithCompassCounter || 0) + 1,
    }),

    [ACTION_TYPES.SET_COMPASS_SUPPORT]: (
      state: ViewerState,
      { payload }: SetCompassSupportAction
    ) => ({
      ...state,
      compassSupport: payload.compassSupport,
    }),

    [ACTION_TYPES.TOGGLE_UI]: (state: ViewerState) => ({
      ...state,
      displayUI: !state.displayUI,
    }),

    [ACTION_TYPES.TOGGLE_FULLSCREEN_MAP]: (state: ViewerState) => ({
      ...state,
      viewerOptions: {
        ...state.viewerOptions,
        isFullScreenMapVisible: !state.viewerOptions.isFullScreenMapVisible,
      },
    }),
  },
  initialState
);
