import { SagaIterator } from 'redux-saga';
import { all, spawn, call, takeEvery } from 'redux-saga/effects';
import { sagas as viewerSagas } from '../viewer/sagas';
import { sagas as appSagas } from '../app/sagas';
import { sagas as offlineSagas } from '../offline/sagas';
import { sagas as locationSagas } from '../location/sagas';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { ACTION_TYPES as APP_ACTION_TYPES } from '../app/actions';
import { ACTION_TYPES as VIEWER_ACTION_TYPES } from '../viewer/actions';
import { persistImmediately } from './createReduxStore';
import getErrorMessage from '../../utils/getErrorMessage';

function* commonSagas(): SagaIterator {
  yield takeEvery(
    [
      APP_ACTION_TYPES.LOGIN_SUCCESS,
      APP_ACTION_TYPES.LOGOUT_SUCCESS,
      APP_ACTION_TYPES.SET_LANGUAGE,
      APP_ACTION_TYPES.SET_CURRENCY_CODE,
      APP_ACTION_TYPES.SET_WHITELABEL_APP_SETTINGS,
      APP_ACTION_TYPES.ADD_SUCCESSFUL_ACTIVATION,
      APP_ACTION_TYPES.CLEAR_SUCCESSFUL_ACTIVATIONS,
      VIEWER_ACTION_TYPES.SET_SUBTITLE_LANGUAGE,
      VIEWER_ACTION_TYPES.SET_NARRATION_LANGUAGE,
    ],
    persistReduxStoreImmediatelySaga
  );
}

function* persistReduxStoreImmediatelySaga(): SagaIterator {
  // call persistImmediately
  yield call(persistImmediately);
}

export function* rootSaga(): SagaIterator {
  const sagas = [
    ...viewerSagas,
    ...appSagas,
    ...offlineSagas,
    ...locationSagas,
    commonSagas,
  ];

  // Boilerplate that allows for throws in sagas to be restarted
  // as per https://redux-saga.js.org/docs/advanced/RootSaga.html
  yield all(
    sagas.map((saga) =>
      spawn(function* spawned() {
        while (true) {
          try {
            yield call(saga);

            break;
          } catch (error: any) {
            captureInSentry(`rootSaga.ts error: ${getErrorMessage(error)}`);
          }
        }
      })
    )
  );
}
