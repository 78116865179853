import memoizeOne from 'memoize-one';
import React, { useCallback } from 'react';
import styled from '../../../../style/styled';
import { Button } from '../../../Button';
import { captureButtonPress } from '../../../../features/analytics';
import { JobStatus } from '../../../../store/offline/types';
import { useSelector, useDispatch } from 'react-redux';
import { selectOfflinePlaylist } from '../../../../store/offline/selectors';
import {
  downloadPlaylistMetadata,
  removePlaylist,
} from '../../../../store/offline/actions';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../App/config/env';

const ANALYTICS_BUTTON_CAPTION_1 = 'Itinerary_Download';
const ANALYTICS_BUTTON_CAPTION_2 = 'Itinerary_ClearCache';
interface Props {
  playlistID: string;
  playlistName: string;
}

export const DownloadWidget: React.FC<Props> = ({
  playlistID,
  playlistName,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    jobStatus,
    progress,
    // remainingSize,
    // totalSize,
    cachedSize,
  } = useSelector(selectOfflinePlaylist(playlistID));

  const helperText = memoizedGetHelperText(jobStatus);
  const downloadButtonVisible = Boolean(
    jobStatus === JobStatus.DOWNLOAD_ERROR || jobStatus === JobStatus.NONE
  );
  const downloadButtonText = t(
    jobStatus === JobStatus.DOWNLOAD_ERROR
      ? 'Resume downloading'
      : 'Download tour'
  );

  const download = useCallback(() => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: ANALYTICS_BUTTON_CAPTION_1,
    });

    dispatch(downloadPlaylistMetadata({ playlistID, automatic: false }));
  }, [dispatch, playlistID]);

  const cleanUp = useCallback(() => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: ANALYTICS_BUTTON_CAPTION_2,
    });

    dispatch(removePlaylist({ playlistID, playlistName, automatic: false }));
  }, [dispatch, playlistID, playlistName]);

  return (
    <div>
      <BodyText>{t(helperText)}</BodyText>

      <ButtonContainer>
        {downloadButtonVisible && (
          <ButtonWithMarginRightAndBottom onClick={download}>
            {downloadButtonText}
          </ButtonWithMarginRightAndBottom>
        )}

        {jobStatus === JobStatus.DOWNLOADING && (
          <ButtonWithMarginRightAndBottom disabled>
            {t('Downloading')} {progress}%
          </ButtonWithMarginRightAndBottom>
        )}
      </ButtonContainer>

      <ButtonContainer>
        {jobStatus === JobStatus.DOWNLOAD_SUCCESS && (
          <ButtonWithMarginRightAndBottom secondary onClick={cleanUp}>
            {t('Remove')} {cachedSize}MB
          </ButtonWithMarginRightAndBottom>
        )}

        {jobStatus === JobStatus.CLEANING_UP && (
          <ButtonWithMarginRightAndBottom secondary disabled>
            {t('Removing...')}
          </ButtonWithMarginRightAndBottom>
        )}
      </ButtonContainer>
    </div>
  );
};

const BodyText = styled.p`
  margin-bottom: 8px;
`;

const ButtonWithMarginRightAndBottom = styled(Button)`
  margin-right: 8px;
  margin-bottom: 8px;
  min-width: 200px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const memoizedGetHelperText = memoizeOne(getHelperText);

// text is i18n translated by the caller
function getHelperText(jobStatus: JobStatus): string {
  switch (jobStatus) {
    case JobStatus.DOWNLOADING: {
      return `The ${
        env.IS_STOCK ? 'tour' : 'guide'
      } data is being downloaded for the best experience. You can start the ${
        env.IS_STOCK ? 'tour' : 'guide'
      } while the download is in progress.`;
    }
    case JobStatus.DOWNLOAD_SUCCESS: {
      return `The ${
        env.IS_STOCK ? 'tour' : 'guide'
      } content is downloaded for the best experience.`;
    }
    case JobStatus.DOWNLOAD_ERROR: {
      return `Oops! We ran into an error while downloading the ${
        env.IS_STOCK ? 'tour' : 'guide'
      } data. Please try again.`;
    }
    case JobStatus.CLEANING_UP: {
      return `The ${
        env.IS_STOCK ? 'tour' : 'guide'
      } data is being removed to clear up storage space. You can always download the ${
        env.IS_STOCK ? 'tour' : 'guide'
      } data again.`;
    }

    case JobStatus.CLEANUP_ERROR: {
      return `Oops! We ran into an error while removing the ${
        env.IS_STOCK ? 'tour' : 'guide'
      } data. Please try again.`;
    }

    default: {
      return `Download the ${
        env.IS_STOCK ? 'tour' : 'guide'
      } data for the best experience.`;
    }
  }
}
