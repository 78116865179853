import { GeoJSONType } from './graphql/globalTypes';

// https://github.com/piotrwitek/utility-types
export type $PropertyType<T extends object, K extends keyof T> = T[K];

// google analytics/optimize
declare global {
  interface Window {
    google_optimize: any;
    dataLayer: any;
    gtag: any;
  }
}

export type Location = {
  coords: {
    longitude: number;
    latitude: number;
    accuracy: number;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    speed: number | null;
  };
  timestamp: number;
};

export enum CodeKeys {
  PROMO_CODE = 'promocode',
  REDEEM_CODE = 'rcode',
  AFFILIATE_CODE = 'affid',
  APP_NAME = 'app',
  REFRESH_TOKEN = 'refreshToken',
  MANUAL_REDEEM = 'redeem_popup',
}

export enum TourPurchaseState {
  PURCHASED,
  IN_CART,
  GIFT,
  NONE,
}
export type Country = {
  id: string;
  name: string;
};
export type Price = {
  AUD: number;
  BRL: number;
  CAD: number;
  CHF: number;
  CNY: number;
  CZK: number;
  DKK: number;
  EUR: number;
  GBP: number;
  HKD: number;
  HUF: number;
  ILS: number;
  JPY: number;
  MXN: number;
  MYR: number;
  NOK: number;
  NZD: number;
  PHP: number;
  PLN: number;
  RUB: number;
  SEK: number;
  SGD: number;
  THB: number;
  TWD: number;
  USD: number;
};

export type I18nInfoField = {
  ar: string | null;
  de_DE: string | null;
  el_GR: string | null;
  en_US: string | null;
  es_ES: string | null;
  fr_FR: string | null;
  he_IL: string | null;
  hi_IN: string | null;
  it_IT: string | null;
  ja_JP: string | null;
  pl_PL: string | null;
  pt_PT: string | null;
  ru_RU: string | null;
  zh: string | null;
};

export type Asset = {
  id: string;
  uri: string;
  contentLength: number;
};

export type ViewpointLocation = {
  type: GeoJSONType;
  coordinates: {
    longitude: number;
    latitude: number;
  };
};

export type PromoCode = {
  id: string;
  code: string;
  discountRate: number;
  expired: boolean;
};
