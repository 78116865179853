import createIndexedDBStorage from 'redux-persist-indexeddb-storage';
import { env } from '../../App/config/env';
import { logMessage } from '../../features/logging/logMessage';
import { getOldReduxPersistStorage } from './getOldReduxPersistStorage';

export function getReduxPersistStorage() {
  if (env.REDUX_PERSIST_USE_INDEXEDDB) {
    const storage = createIndexedDBStorage('redux-persist:root');

    if (!env.REDUX_PERSIST_ENABLE_DEBUGGING) {
      return storage;
    }

    const wrappedStorage = {
      ...storage,
      setItem: async (key: string, item: any) => {
        logMessage('Persisting state');
        return storage.setItem(key, item);
      },
    };

    return wrappedStorage;
  }

  return getOldReduxPersistStorage();
}
