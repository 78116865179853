import bbox from '@turf/bbox';
import transformScale from '@turf/transform-scale';
import bboxPolygon from '@turf/bbox-polygon';
import { Coordinate } from '../types';
import { getPointFeatures } from './getPointFeatures';

export function calculateBoundOfPoints(
  positions: Array<Coordinate | null>,
  scale: number = 1
) {
  const features = getPointFeatures(positions);
  const turfBoxOriginal = bbox(features);

  const turfBox =
    transformScale(bboxPolygon(bbox(features)), scale).bbox || turfBoxOriginal;

  return [
    {
      lng: turfBox[0],
      lat: turfBox[1],
    },
    {
      lng: turfBox[2],
      lat: turfBox[3],
    },
  ];

  // let minLat = 90;
  // let maxLat = -90;
  // let minLng = 180;
  // let maxLng = -180;

  // for (let position of positions) {
  //   if (!position) {
  //     continue;
  //   }

  //   minLat = minLat < position.lat ? minLat : position.lat;
  //   maxLat = maxLat > position.lat ? maxLat : position.lat;
  //   minLng = minLng < position.lng ? minLng : position.lng;
  //   maxLng = maxLng > position.lng ? maxLng : position.lng;
  // }

  // return [
  //   {
  //     lng: minLng,
  //     lat: maxLat,
  //   },
  //   {
  //     lng: maxLng,
  //     lat: minLat,
  //   },
  // ];
}
