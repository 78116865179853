import React from 'react';
import Icon from 'mdi-react/CollectionIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { captureButtonPress } from '../../features/analytics';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { ROUTES } from '../../Routes/routes';
import { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { env } from '../../App/config/env';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const ToursLibraryLink: React.FC<Props> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  return (
    <AppHeaderButtonContainer
      fullWidth={fullWidth}
      onClick={() => {
        captureButtonPress({
          page: window.location.pathname,
          buttonName: 'ToursLibrary_Header',
        });

        if (env.ENABLE_MARKETING_HOME_SCREEN) {
          history.push(ROUTES.library);
        } else {
          history.push({ pathname: ROUTES.index, hash: '#tours_section' });
        }
      }}
    >
      <Icon color={color} size={size * 1.4} />
      <AppHeaderButtonText size={size} color={color}>
        {t('Tours library')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};
