import decodeJWT from 'jwt-decode';
import * as Sentry from '@sentry/react';
import getErrorMessage from './getErrorMessage';

interface JWTProps {
  u?: string;
  // legacy
  userId?: string;
}

export function getUserIDFromJWT(jwt: string): string | null {
  let userID = null;

  try {
    const { u, userId } = decodeJWT(jwt) as JWTProps;

    userID = u || userId || null;

    if (!userID) {
      Sentry.captureMessage(
        `getUserIDFromJWT.ts userID not foud in jwt: ${jwt}`
      );
    }
  } catch (error) {
    Sentry.captureMessage(
      `getUserIDFromJWT.ts error: ${getErrorMessage(error)}`
    );
  }

  return userID;
}
