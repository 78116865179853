import styled, { keyframes } from 'styled-components';

// Keyframes for the spinner animation
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled component for the spinner
const Spinner = styled.div<{ color: string; size: string }>`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${(props) => props.color || '#09f'};
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

type Props = {
  color?: string;
  size?: string;
};

const LoadingSpinnerSimple = ({ size = '14px', color = '#000' }: Props) => (
  <Spinner size={size} color={color} />
);

export default LoadingSpinnerSimple;
