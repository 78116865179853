import React from 'react';
import styled, { useTheme } from '../../style/styled';
import Icon from 'mdi-react/LoginIcon';
import { useDispatch } from 'react-redux';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import { captureButtonPress } from '../../features/analytics';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { useTranslation } from 'react-i18next';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  highlight?: boolean;
  showTextInMobile?: boolean;
}

export const SignInLink = ({
  highlight = true,
  showTextInMobile = false,
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const color = theme.palette.highlight.contrastColor;
  const size = rest.size || HEADER_BUTTON_SIZE;
  const fullWidth = rest.fullWidth || false;

  const Wrapper = highlight ? Container : AppHeaderButtonContainer;

  const TextWrapper = showTextInMobile
    ? AppHeaderButtonText
    : AppHeaderButtonTextMobileHidden;

  return (
    <Wrapper
      fullWidth={fullWidth}
      onClick={() => {
        captureButtonPress({
          page: window.location.pathname,
          buttonName: 'LogIn',
        });

        dispatch(
          setModalType({
            modalType: ModalType.AUTH_LOGIN,
            modalData: {
              redirectedHref: window.location.href,
              originalHref: window.location.href,
              reason: null,
            },
          })
        );
      }}
    >
      <Icon color={color} size={size * 1.4} />
      <TextWrapper size={size} color={color}>
        {t('Log in / Sign up')}
      </TextWrapper>
    </Wrapper>
  );
};

const Container = styled(AppHeaderButtonContainer)`
  background: ${(props) => props.theme.palette.highlight.main};
  padding: 4px 8px;
`;

const AppHeaderButtonTextMobileHidden = styled(AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
