import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import getErrorMessage from '../../utils/getErrorMessage';
import { readRemoteFileAsText } from '../../utils/readRemoteFileAsText';

export async function parseRemoteGeoJSONFile(url: string) {
  try {
    const text = await readRemoteFileAsText(url);

    const parsed = JSON.parse(text);

    return parsed;
  } catch (error) {
    captureInSentry(getErrorMessage(error), { url });
  }

  return null;
}
