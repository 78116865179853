import { createAction } from 'redux-actions';
import { ModalType } from './types';

export const ACTION_TYPES = {
  SET_MODAL_TYPE: 'setModalType',
};

export type SetModalTypePayload = { modalType: ModalType; modalData?: any };

export type SetModalTypeAction = {
  type: typeof ACTION_TYPES.SET_MODAL_TYPE;
  payload: SetModalTypePayload;
};

export const setModalType = createAction<SetModalTypePayload>(
  ACTION_TYPES.SET_MODAL_TYPE
);
