import { SagaIterator } from 'redux-saga';
import { call, getContext, takeEvery, select, put } from 'redux-saga/effects';
import { SagaContextKeys } from '../redux/types';
import {
  ACTION_TYPES,
  DownloadPlaylistAction,
  DownloadPlaylistMetadataAction,
  RemovePlaylistAction,
  downloadPlaylist,
  RecheckPlaylistAction,
  removeDeprecatedFiles,
  UpdatePlaylistStatsAction,
  updatePlaylistStats,
} from './actions';
import { CacheManagerForTourAssets } from '../../features/offline/CacheManagerForTourAssets';
import { OfflineState, Asset, JobStatus } from './types';
import { fetchTour } from '../../features/offline/utils/fetchTour';
import { getAssetIDsToDelete } from '../../features/offline/utils/getAssetIDsToDelete';
import { findAllAssets } from '../../features/offline/utils/findAllAssets';
import { selectOfflineState } from './selectors';
import { toast } from 'react-toastify';
import { LanguageCode } from '../../graphql/globalTypes';
import { selectLanguage } from '../app/selectors';
import { getI18nFieldValue } from '../../features/i18n/getI18nFieldValue';
import i18next from 'i18next';
import { DataKeys, EventNames, captureEvent } from '../../features/analytics';
import { env } from '../../App/config/env';
import { logMessage } from '../../features/logging/logMessage';
// import {
//   LOADING_BLACK_PLANE,
//   LOADING_WHITE_PLANE,
// } from '../../Routes/components/LoadingScreen/LoadingIndicator';

export const sagas = [viewerSagas];

export function* viewerSagas(): any {
  yield takeEvery(
    ACTION_TYPES.DOWNLOAD_PLAYLIST_METADATA,
    downloadPlaylisMetadataSaga
  );
  yield takeEvery(ACTION_TYPES.DOWNLOAD_PLAYLIST, downloadPlaylistSaga);
  yield takeEvery(ACTION_TYPES.REMOVE_PLAYLIST, removePlaylistSaga);
  yield takeEvery(ACTION_TYPES.RECHECK_PLAYLIST, recheckPlaylistSaga);
  yield takeEvery(ACTION_TYPES.UPDATE_PLAYLIST_STATS, updatePlaylistStatsSaga);
}

// this isn't a generator function. just showing the alert if not automatic.
// TODO: [LVR-2522] i18n translate text
function updatePlaylistStatsSaga(action: UpdatePlaylistStatsAction) {
  const { playlistName, playlistID, status, automatic } = action.payload;

  if (!automatic && status === JobStatus.CLEANUP_SUCCESS) {
    const cleanUpSuccessMessage = `The downloaded data for ${playlistName} cleaned up successfully.`;

    toast.info(cleanUpSuccessMessage, {
      autoClose: 3000,
      pauseOnHover: true,
      hideProgressBar: true,
      position: 'bottom-right',
      toastId: cleanUpSuccessMessage,
    });
  } else if (!automatic && status === JobStatus.CLEANUP_ERROR) {
    const cleanUpErrorMessage = `Failed to remove some or all of downloaded data for ${playlistName}. Please try again.`;

    toast.error(cleanUpErrorMessage, {
      autoClose: 3000,
      pauseOnHover: true,
      hideProgressBar: true,
      position: 'bottom-right',
      toastId: cleanUpErrorMessage,
    });
  } else if (!automatic && status === JobStatus.DOWNLOAD_ERROR) {
    const downloadErrorMessage = `Failed to download ${playlistName}. Please try again.`;

    toast.error(downloadErrorMessage, {
      autoClose: 3000,
      pauseOnHover: true,
      hideProgressBar: true,
      position: 'bottom-right',
      toastId: downloadErrorMessage,
    });
  } else if (status === JobStatus.DOWNLOAD_SUCCESS) {
    if (!automatic) {
      const downloadSuccessMessage = `Successfully downloaded ${playlistName}.`;

      toast.info(downloadSuccessMessage, {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true,
        position: 'bottom-right',
        toastId: downloadSuccessMessage,
      });
    }

    captureEvent({
      name: EventNames.TOUR_DATA_CACHED,
      data: [
        {
          key: DataKeys.TOUR_ID,
          value: playlistID,
        },
      ],
    });
  }
}

function* recheckPlaylistSaga(action: RecheckPlaylistAction): SagaIterator {
  const { playlistID } = action.payload;
  const language: LanguageCode = yield select(selectLanguage);

  const tour = yield call(fetchTour, playlistID);
  const playlistName =
    getI18nFieldValue(tour.nameI18n, language) || i18next.t('Tour');

  const findTargetAssets = findAllAssets(tour);

  const assetsThatShouldBeCached = findTargetAssets.map((asset) => ({
    id: asset.id,
    uri: asset.uri,
    contentLength: asset.contentLength,
    playlistIDs: [playlistID],
  }));

  const { files }: OfflineState = yield select(selectOfflineState);

  // used for detecting the old system, to determine if we need to migrate
  const filesInStateBelongingToThisTour: Array<Asset> = [];

  // used for detecting if the asset is deprecated (no longer used by any tour that's available offline)
  const filesInStateBelongingToOnlyThisTour: Array<Asset> = [];

  Object.keys(files).forEach((assetID) => {
    const file = files[assetID];

    if (file) {
      const playlistIDs = file.playlistIDs || [];
      const dependent = playlistIDs.includes(playlistID);

      if (dependent) {
        if (playlistIDs.length === 1) {
          filesInStateBelongingToOnlyThisTour.push({ id: assetID, ...file });
        }

        filesInStateBelongingToThisTour.push({ id: assetID, ...file });
      }
    }
  });

  // if filesInStateBelongingToThisTour is empty, which means all the files were either in assets, or not in state at all.
  // we need to download it again to migrate to the new system. technically this doesn't require network fetches
  const needsMigration = filesInStateBelongingToThisTour.length === 0;

  // check if filesInStateBelongingToOnlyThisTour contains files that are not in assetsThatShouldBeCached
  const assetIDs = assetsThatShouldBeCached.map(({ id }) => id);

  const deprecatedAssets: Asset[] = filesInStateBelongingToOnlyThisTour.filter(
    ({ id }) => !assetIDs.includes(id)
  );

  const cacheManager: CacheManagerForTourAssets = yield getContext(
    SagaContextKeys.cacheManager
  );

  // if we need to clean up deprecated assets
  if (deprecatedAssets.length > 0) {
    // remove just the asset from the cache; do not dispatch any actions.
    yield call(cacheManager.removeAssets, deprecatedAssets);

    const deprecatedAssetIDs = deprecatedAssets.map(({ id }) => id);

    // dispatch action to clean up the files (also assets) from cache
    yield put(removeDeprecatedFiles({ playlistID, deprecatedAssetIDs }));
  }

  // get missing assets (i.e. if the tour has updated, the cache is out of sync with the tour)
  const missingAssetIDs = yield call(
    cacheManager.getMissingAssets,
    assetsThatShouldBeCached
  );

  // redownload the tour (it will go through all assets, assets in cache should not be downloaded again)
  if (needsMigration || missingAssetIDs.length > 0) {
    toast.info(
      `${playlistName} content has been updated. We are downloading new files.`,
      {
        autoClose: 3000,
        pauseOnHover: true,
        hideProgressBar: true,
        position: 'bottom-right',
      }
    );

    yield put(
      downloadPlaylist({
        playlistID,
        automatic: true,
        playlistName,
        assets: assetsThatShouldBeCached,
      })
    );
  } else {
    // The tour is already cached, so we'll send an event for this session.
    // Otherwise we will send it when the caching is complete. Check `updatePlaylistStatsSaga`.
    captureEvent({
      name: EventNames.TOUR_DATA_CACHED,
      data: [
        {
          key: DataKeys.TOUR_ID,
          value: playlistID,
        },
      ],
    });
  }
}

function* downloadPlaylisMetadataSaga(
  action: DownloadPlaylistMetadataAction
): SagaIterator {
  const { playlistID, automatic } = action.payload;
  const language: LanguageCode = yield select(selectLanguage);

  const tour = yield call(fetchTour, playlistID);

  // if an error occurred while fetching
  if (!tour) {
    yield put(
      updatePlaylistStats({
        playlistID,
        playlistName: 'the tour',
        totalSize: 0,
        cachedSize: 0,
        status: JobStatus.DOWNLOAD_ERROR,
        automatic,
      })
    );
  } else {
    const playlistName =
      getI18nFieldValue(tour.nameI18n, language) || i18next.t('Tour');

    const findTargetAssets = [
      ...findAllAssets(tour),
      // ...findAllAssets(LOADING_BLACK_PLANE),
      // ...findAllAssets(LOADING_WHITE_PLANE),
    ];

    const assets = findTargetAssets.map((asset) => ({
      id: asset.id,
      contentLength: asset.contentLength,
      uri: asset.uri,
      playlistIDs: [playlistID],
    }));

    yield put(
      downloadPlaylist({ playlistID, playlistName, assets, automatic })
    );
  }
}

function* downloadPlaylistSaga(action: DownloadPlaylistAction): SagaIterator {
  const { playlistID, playlistName, assets, automatic } = action.payload;

  if (
    env.ENABLE_SERVICE_WORKER_BACKGROUND_CACHING &&
    navigator.serviceWorker.controller
  ) {
    logMessage('DEBUG SW going to call postMessage');

    // navigator.serviceWorker.controller.postMessage({
    //   action: 'test',
    // });

    navigator.serviceWorker.controller.postMessage({
      action: 'cacheTourAssets',
      assets: assets.map(({ uri }) => uri),
    });
  } else {
    logMessage(
      'DEBUG SW navigator.serviceWorker.controller is ',
      navigator.serviceWorker.controller
    );
  }

  const cacheManager: CacheManagerForTourAssets = yield getContext(
    SagaContextKeys.cacheManager
  );

  yield call(
    cacheManager.cacheAssets,
    playlistID,
    playlistName,
    assets,
    automatic
  );
}

function* removePlaylistSaga(action: RemovePlaylistAction): SagaIterator {
  const { playlistID, playlistName, automatic } = action.payload;

  const assetIDsToDelete: Asset[] = yield call(getAssetIDsToDelete);

  const cacheManager: CacheManagerForTourAssets = yield getContext(
    SagaContextKeys.cacheManager
  );

  yield call(
    cacheManager.removePlaylistAssets,
    playlistID,
    playlistName,
    assetIDsToDelete,
    automatic
  );
}
