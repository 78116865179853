import React from 'react';
import Alert from '../../../components/Alert';
import { useTranslation } from 'react-i18next';

// Session expired alert
const SessionExpired = () => {
  const { t } = useTranslation();

  return (
    <Alert
      title={t('Your session has expired')}
      message={t(
        'Please log back in with your email and password to access your account and purchased tours.'
      )}
    />
  );
};

export default SessionExpired;
