import { handleActions } from 'redux-actions';
import { ACTION_TYPES, SetModalTypeAction } from './actions';
import { ModalState, initialState } from './types';

export const reducer = handleActions<ModalState, any>(
  {
    [ACTION_TYPES.SET_MODAL_TYPE]: (
      state: ModalState,
      action: SetModalTypeAction
    ): ModalState => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
