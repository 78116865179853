import React from 'react';
import styled from '../../../../style/styled';
import { Section } from './styledComponents/Section';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../style/SIZES';

interface Props {
  // i18n text
  name?: string | null;
  // i18n text
  description?: string | null;
}

export const TourInfoBasicDetailsSection: React.FC<Props> = ({
  name,
  description,
}) => {
  return (
    <Section>
      {name && <Title>{name}</Title>}

      {description && <p>{description}</p>}
    </Section>
  );
};

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 20px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin: 10px 0 10px;
  }
`;
