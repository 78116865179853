export function findAllAssetsInSettings(obj: any): string[] {
  let list: string[] = [];

  if (!obj) {
    return list;
  }

  if (obj instanceof Array) {
    for (var i in obj) {
      list = list.concat(findAllAssetsInSettings(obj[i]));
    }

    return list;
  }

  if (typeof obj == 'object' && obj !== null) {
    let children = Object.keys(obj);

    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        const value = obj[children[i]];

        if (typeof value === 'object') {
          list = list.concat(findAllAssetsInSettings(value));
        } else if (
          typeof value === 'string' &&
          value.includes('gaia.lithodomos.com/')
        ) {
          list = list.concat(value);
        }
      }
    }
  }

  return list;
}
