import { env } from '../../App/config/env';
import FooterStock from './FooterStock';
import FooterWhitelabeled from './FooterWhitelabeled';

export const Footer = () => {
  if (env.IS_STOCK) {
    return <FooterStock />;
  }

  return <FooterWhitelabeled />;
};
