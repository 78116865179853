import { getApolloClient } from '../../App/config/apollo/ApolloClient';
import {
  EXPLORE_WEB_SubmitAnalyticEvents as Data,
  EXPLORE_WEB_SubmitAnalyticEventsVariables as Variables,
} from './__generated__/EXPLORE_WEB_SubmitAnalyticEvents';
import { AnalyticEventInput } from '../../graphql/globalTypes';
import { gql } from '@apollo/client';

export const SUBMIT_ANALYTIC_EVENTS_MUTATION = gql`
  mutation EXPLORE_WEB_SubmitAnalyticEvents(
    $input: SubmitAnalyticEventsInput!
  ) {
    result: submitAnalyticEvents(input: $input) {
      events {
        eventID
      }
      error {
        id
        message
        type
      }
    }
  }
`;

export async function submitToBackend(
  events: Array<AnalyticEventInput>
): Promise<void> {
  const client = await getApolloClient();

  await client.mutate<Data, Variables>({
    fetchPolicy: 'no-cache',
    mutation: SUBMIT_ANALYTIC_EVENTS_MUTATION,
    variables: {
      input: { events },
    },
  });
}
