import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ScrollPosition } from 'react-lazy-load-image-component';
import {
  selectLanguage,
  selectPlaylistsMapInCart,
  selectPromoCodeDiscountRate,
} from '../../../../../store/app/selectors';
import { getExpiryInfo } from '../../getExpiryInfo';
import { getI18nFieldValue } from '../../../../../features/i18n/getI18nFieldValue';
import { Asset, I18nInfoField } from '../../../../../types';
import { CurrencyCode } from '../../../../../graphql/globalTypes';
import { TourWidgetView } from './TourWidgetView';
import { useUser } from '../../../../../hooks/useUser';

export type TourWidgetTour = {
  id: string;
  internalReference: string;
  published?: boolean;
  trial?: boolean;
  nameI18n: I18nInfoField;
  pastThumbnail: Asset | null;
  presentThumbnail: Asset | null;
  currencyCode?: CurrencyCode;
  unitPrice?: number;
  priceInCents?: boolean;
  pinned?: boolean;
};

export interface Props {
  buyTour: (tourID: string) => void;
  expiresAt?: string | null;
  isGift?: boolean;
  tour: TourWidgetTour;
  LeftButton?: React.ReactChild;
  RightButton?: React.ReactChild;
  scrollPosition?: ScrollPosition;
  opensItineraryPage?: boolean;
  withPriceHidden?: boolean;
}

export const TourWidget: React.FC<Props> = ({
  buyTour,
  expiresAt,
  tour,
  LeftButton,
  RightButton,
  isGift,
  scrollPosition,
  opensItineraryPage = false,
  withPriceHidden = false,
}) => {
  const [refreshKey, setRefreshKey] = React.useState(0);
  const { tourPurchasesMap } = useUser();
  const playlistsMapInCart = useSelector(selectPlaylistsMapInCart);
  const language = useSelector(selectLanguage);
  const discountRate = useSelector(selectPromoCodeDiscountRate);

  const { expiresIn, expired, expiresSoon } = getExpiryInfo(
    expiresAt,
    language
  );

  const isInCart = Boolean(playlistsMapInCart[tour.id]);

  const nonInteractiveImageURI = tour.presentThumbnail?.uri;
  const interactiveImageURI = tour.pastThumbnail?.uri;

  const hasToureenPurchased = !!tourPurchasesMap[tour.id] || tour.trial;

  const priceDisplayed = !withPriceHidden && (!hasToureenPurchased || isGift);

  const downloadable = (hasToureenPurchased || tour.trial) && !isGift;

  const tourName = getI18nFieldValue(tour.nameI18n, language);

  useEffect(() => {
    // refresh the widget every minute
    const interval = setInterval(() => {
      setRefreshKey((prev) => prev + 1);
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <TourWidgetView
      key={refreshKey}
      buyTour={buyTour}
      currencyCode={tour.currencyCode}
      discountRate={discountRate}
      downloadable={downloadable}
      expired={expired}
      expiresIn={expiresIn}
      expiresSoon={expiresSoon}
      id={tour.id}
      interactiveImageURI={interactiveImageURI}
      internalReference={tour.internalReference}
      isInCart={isInCart}
      LeftButton={LeftButton}
      nonInteractiveImageURI={nonInteractiveImageURI}
      opensItineraryPage={opensItineraryPage}
      priceDisplayed={priceDisplayed}
      priceInCents={tour.priceInCents}
      published={tour.published}
      purchased={hasToureenPurchased}
      RightButton={RightButton}
      scrollPosition={scrollPosition}
      tourName={tourName}
      unitPrice={tour.unitPrice}
      pinned={tour.pinned}
    />
  );
};
