import urlJoin from './urlJoin';

const CDN_URI = 'https://gaia.lithodomos.com';

export function getCDNURI(filename: string) {
  if (filename) {
    return urlJoin(CDN_URI, filename);
  }

  return '';
}
