import { Store } from 'redux';
import { History } from 'history';
import { AudioPlayer } from '../features/audio/AudioPlayer';
import { MusicPlayer } from '../features/audio/MusicPlayer';
import { CacheManagerForTourAssets } from '../features/offline/CacheManagerForTourAssets';
import { RootState, SagasContext } from './redux/types';
// import { RootState, SagasContext } from './redux/types';
import { createReduxStore } from './redux/createReduxStore';
import { noop } from '../utils/noop';
// import { DEFAULT_LANGUAGE } from '../consts';
// import { setLanguage } from '../features/i18n/i18n';

// chicken and egg problem
let _store: Store<RootState> | null = null;

const getDispatch = () => {
  if (!_store) {
    return noop;
  }

  return _store.dispatch;
};

export function createStore(history: History) {
  const audio = new AudioPlayer({ getDispatch });
  const music = new MusicPlayer();
  const cacheManager = new CacheManagerForTourAssets({ getDispatch });
  const context: SagasContext = {
    history,
    audio,
    music,
    cacheManager,
  };

  const { store, persistor } = createReduxStore(context);

  // This is reutrn from getStore fn below
  _store = store;

  return { store, persistor };
}

export function getStore(): Store<any> | null {
  if (!_store) {
    throw new Error('store not initialised');
  }

  return _store;
}
