import React from 'react';
import { TourPurchaseState } from '../../../../types';
import { TourInfoAddToCartButton } from './TourInfoAddToCartButton';
import { TourInfoViewCartButton } from './TourInfoViewCartButton';
import { TourInfoStartTourButton } from './TourInfoStartTourButton';
import { CurrencyCode } from '../../../../graphql/globalTypes';

interface Props {
  purchaseState: TourPurchaseState;
  id: string;
  price: number;
  currencyCode: CurrencyCode;
  priceInCents: boolean;
}

export const TourInfoPrice: React.FC<Props> = ({
  purchaseState,
  id,
  price,
  currencyCode,
  priceInCents,
}) => {
  if (purchaseState === TourPurchaseState.IN_CART) {
    return <TourInfoViewCartButton />;
  }

  if (purchaseState === TourPurchaseState.PURCHASED) {
    return <TourInfoStartTourButton tourID={id} />;
  }

  return (
    <TourInfoAddToCartButton
      id={id}
      price={price}
      currencyCode={currencyCode}
      priceInCents={priceInCents}
    />
  );
};
