import { point, feature } from '@turf/helpers';
import pointInPolygon from '@turf/boolean-point-in-polygon';
import { Coordinate } from '../features/map/types';
import { captureInSentry } from '../App/config/reporting/captureInSentry';
import getErrorMessage from './getErrorMessage';

export function checkIfPointIsInsidePolygonOrMultiPolygon(
  { lat, lng }: Coordinate,
  data: any
) {
  if (typeof data !== 'object') {
    return false;
  }

  try {
    const geometry = data?.features?.[0]?.geometry;

    if (!geometry) {
      return false;
    }

    const checkingFeature = feature(geometry);
    const checkingPoint = point([lng, lat]);

    return pointInPolygon(checkingPoint, checkingFeature);
  } catch (error) {
    // console.error(error);

    captureInSentry(getErrorMessage(error), { lat, lng, data });
  }

  return false;
}
