import { captureButtonPress } from '../../features/analytics';
import { Container } from './styledComponents/Container';

export const ContactUsLink = () => {
  return (
    <Container>
      <a
        href="mailto:seeker@ancient-world.co"
        onClick={() => {
          captureButtonPress({
            page: window.location.pathname,
            buttonName: 'ContactUs',
          });
        }}
      >
        seeker@ancient-world.co
      </a>
    </Container>
  );
};
