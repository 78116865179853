import * as Sentry from '@sentry/react';

export function captureInSentry(message: string, data?: Object) {
  if (!Sentry.isInitialized()) {
    return;
  }

  if (data) {
    Sentry.captureMessage(message, {
      extra: {
        data: JSON.stringify(data),
      },
    });
  }

  Sentry.captureMessage(message);
}
