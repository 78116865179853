import styled from '../style/styled';
import { COLORS } from '../style/colors';

export const LinkLikeButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  text-decoration: none;
  cursor: pointer;

  :hover {
    color: ${COLORS.activeColor};
  }
`;
