import React, { useState } from 'react';
import styled from '../../../style/styled';
import { LogInViewDesktop } from './LogInViewDesktop';
import { COLORS } from '../../../style/colors';
import { Button as _Button } from '../../../components/Button';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';

interface Props {
  reason?: string | null;
  redirectedHref?: string | null;
  originalHref?: string | null;
  errorMessage?: string;
  onFailure?: () => void;
  onSuccess?: () => void;
  onCreateAccount?: () => void;
  onForgotPassword?: () => void;
}

export const LogInScreen: React.FC<Props> = ({ errorMessage, ...rest }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(
    errorMessage ? new Error(errorMessage) : null
  );

  const handleFailure = (error: Error) => {
    setError(error);
  };

  return (
    <>
      <LoginScreenContainer>
        <LogInViewDesktop {...rest} onFailure={handleFailure} />
      </LoginScreenContainer>

      {error && (
        <ErrorContainer>
          <ErrorText>{error?.message || UNKNOWN_ERROR}</ErrorText>

          <Button onClick={() => setError(null)}>{t('Retry')}</Button>
        </ErrorContainer>
      )}
    </>
  );
};

const LoginScreenContainer = styled.div`
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const ErrorText = styled.p`
  color: ${COLORS.errorColor};
  margin-bottom: 16px;
  margin-top: 60px;
`;

const Button = styled(_Button)`
  width: 120px;
`;

const ErrorContainer = styled.div`
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  text-align: center;
`;
