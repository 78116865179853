import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ShowPasswordIcon from 'mdi-react/EyeIcon';
import HidePasswordIcon from 'mdi-react/EyeOffIcon';
import styled from '../../../style/styled';
import * as Sentry from '@sentry/react';
import { ROUTES } from '../../routes';
import {
  SIGNUP_MUTATION,
  Data,
  Variables,
} from '../../../graphql/mutations/SignUpMutation';
import { Input } from '../../../styledComponents/Input';
import { Label } from '../../../styledComponents/Label';
import { ErrorText } from '../../../styledComponents/ErrorText';
import { loginSuccess } from '../../../store/app/actions';
import { Grid } from '../../../Layouts/primitives/Grid';
import { AuthButtonWithRecaptcha3 } from '../../../components/AuthButtonWithRecaptcha3';
import { captureEvent, EventNames } from '../../../features/analytics';
import isEmail from '../../../utils/isEmail';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { toast } from 'react-toastify';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import { isMobile } from 'react-device-detect';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { env } from '../../../App/config/env';
import { SignUpTerms } from '../../../components/SignUpTerms';

type FormState = {
  email: string;
  name: string;
  password: string;
};

type ErrorState = {
  email: string;
  name: string;
  password: string;
};

function getInitialFormState() {
  return {
    email: '',
    name: '',
    password: '',
  };
}

function getInitialErrorState() {
  return {
    email: '',
    name: '',
    password: '',
  };
}

const RECAPTCHA_CONTEXT = 'SignUp';

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  onLogIn?: () => void;
  onForgotPassword?: () => void;
  redirectedHref: string | null | undefined;
}

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export const SignUpScreen: React.FC<Props> = ({
  onSuccess,
  onFailure,
  redirectedHref,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>(getInitialFormState());
  const [formError, setFormError] = useState<ErrorState>(
    getInitialErrorState()
  );
  const [error, setError] = useState<Error | null>(null);
  const [signUpMutation, { loading }] = useMutation<Data, Variables>(
    SIGNUP_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        onFailure ? onFailure(error) : setError(error);
      },
      onCompleted: ({ result }) => {
        const { jwt, error } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({ name: EventNames.LOGGED_IN });

          const toastSuccessMessage = t(
            'Sign up successful. You have been automatically logged in.'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables(), jwt });

          onFailure
            ? onFailure(new Error(errorMessage))
            : setError(new Error(errorMessage));
        }
      },
    }
  );

  const submitForm = async (e: any) => {
    e.preventDefault();

    let token: string | null = null;

    handleSubmit(token);
  };

  const handleSubmit = (recaptchaToken: string | null) => {
    setError(null);

    const email = formState.email.trim();
    const password = formState.password.trim();
    const name = formState.name.trim();

    const errorState = getInitialErrorState();

    let hasError = false;

    if (!name) {
      errorState.name = t('Please enter your Name.');
      hasError = true;
    }

    if (!isEmail(email)) {
      errorState.email = t('Please enter a valid Email.');
      hasError = true;
    }

    if (password.length < 6) {
      errorState.password = t('Please enter a 6 or more character Password.');
      hasError = true;
    }

    if (hasError) {
      setFormError(errorState);
    } else {
      const variables: Variables = {
        input: {
          email,
          password,
          name,
          verificationURL: `https://${window.location.hostname}${ROUTES.authVerify}`,
          skipVerification: true,
          referralAppID: env.APP_ID,
        },
      };

      const affiliateCode = getStoredAffiliateCode();

      if (affiliateCode) {
        variables.input.affiliateCode = affiliateCode;
      }

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      signUpMutation({ variables });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setError(null);
    setFormError({ ...formError, [name]: '' });

    setFormState({ ...formState, [name]: value });
  };

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={submitForm}>
        <Grid cols={1} gap={12} marginBottom={20}>
          <section>
            <Label htmlFor="name">{t('Name')}</Label>
            <Input
              autoFocus={!isMobile}
              id="name"
              name="name"
              type="text"
              disabled={loading}
              autoComplete="given-name"
              value={formState.name}
              onChange={handleInputChange}
              maxLength={50}
              data-testid="name-input-field"
            />
            <ErrorText>{formError?.name || ''}</ErrorText>
          </section>
          <section>
            <Label htmlFor="email">{t('Email address')}</Label>
            <Input
              id="email"
              name="email"
              type="email"
              disabled={loading}
              autoComplete="email"
              value={formState.email}
              onChange={handleInputChange}
              data-testid="email-input-field"
            />
            <ErrorText>{formError?.email || ''}</ErrorText>
          </section>
          <section>
            <Label htmlFor="password">{t('Password')}</Label>
            <PasswordInputContainer>
              <Input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                disabled={loading}
                autoComplete="new-password"
                value={formState.password}
                onChange={handleInputChange}
                data-testid="password-input-field"
              />

              <ShowPasswordButton
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword && <ShowPasswordIcon />}
                {showPassword && <HidePasswordIcon />}
              </ShowPasswordButton>
            </PasswordInputContainer>
            <ErrorText>{formError?.password || ''}</ErrorText>
          </section>
          <div>
            <ConsentContainer>
              <SignUpTerms />
            </ConsentContainer>
          </div>
          <ErrorText>{error?.message || ''}</ErrorText>
          <AuthButtonWithRecaptcha3
            context={RECAPTCHA_CONTEXT}
            disabled={loading}
            buttonName="SignUpButton"
          >
            {t(loading ? 'Creating account...' : 'Create account')}
          </AuthButtonWithRecaptcha3>
        </Grid>
      </form>
    </div>
  );
};

const ShowPasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
`;

const PasswordInputContainer = styled.div`
  position: relative;
`;

const ConsentContainer = styled.label`
  display: flex;
  align-items: flex-sart;
  user-select: none;
`;
