import { DEFAULT_LANGUAGE } from '../../consts';
import { LanguageCode } from '../../graphql/globalTypes';
import { FullTour } from '../../graphql/queries/GetFullTourQuery';
import { ClosestViewpointDetectionMode } from '../location/types';

export enum CompassSupport {
  SUPPORTED,
  UNSUPPORTED,
  ENABLED,
  QUERYING,
}

export type ViewerState = {
  tour: FullTour | null;

  // closest viewpoint
  closestViewpointIndex: number;
  closestViewpointDistance: number;
  closestViewpointDetectionMode: ClosestViewpointDetectionMode;

  currentViewpointIndex: number;
  previousViewpointIndex: number;
  currentImageIndex: number;
  previousImageIndex: number;
  viewerOptions: ViewerOptionsState;
  visitedVPIndices: number[];

  // the following states aren't persisted. they are removed from the outbound transform (transforms.ts)
  isLoading: boolean;
  audio: AudioState;
  imageCaption: string | null;
  // DEPRECATED
  endOfTourPopupClosed: boolean;
  resyncWithCompassCounter: number;
  // we use this state to determine if we can enable gyro when starting the tour or not.
  // it is set from the TourViewCompassToggle component
  // and read from FreeRoamScreen
  compassSupport: CompassSupport;

  displayUI: boolean;

  userSelectedAViewpoint: boolean;
};

export enum TOUR_TYPE {
  NONE,
  FREE_ROAM,
  GUIDED,
}

export type ViewerOptionsState = {
  isOptionsMenuVisible: boolean;
  isStreetViewEnabled: boolean;
  isMapsVisible: boolean;
  isSideBySideVisible: boolean;
  isImagesEnabled: boolean;
  isImageCaptionsVisible: boolean;
  isDistractionFree: boolean;
  isPlaylistThumbnailsVisible: boolean;
  isMetaDataWindowVisible: boolean;
  isGuidePanelVisible: boolean;
  isFullscreen: boolean;
  isStereoMode: boolean;
  isAutoRotating: boolean;
  isMediaControlsVisible: boolean;
  isGyroEnabled: boolean;
  tourType: TOUR_TYPE;
  isTutorialVisible: boolean;
  tutorialDisplayed: boolean;
  tutorialImagesButtonHighlighted: boolean;
  tutorialVPGridButtonHighlighted: boolean;
  tutorialVPInfoButtonHighlighted: boolean;
  tutorialSubtitlesButtonHighlighted: boolean;
  tutorialToggleMusicButtonHighlighted: boolean;
  tutorialNarrationPlayPauseButtonHighlighted: boolean;
  tutorialGyroToggleButtonHighlighted: boolean;
  tutorialMapToggleButtonHighlighted: boolean;
  tutorialSplitScreenButtonHighlighted: boolean;
  isFullScreenMapVisible: boolean;
};

export const initialViewerOptions: ViewerOptionsState = {
  isOptionsMenuVisible: true,
  isStreetViewEnabled: false,
  isMapsVisible: false,
  isSideBySideVisible: false,
  isImagesEnabled: false,
  isImageCaptionsVisible: true,
  isDistractionFree: false,
  isPlaylistThumbnailsVisible: false,
  isGuidePanelVisible: false,
  isMetaDataWindowVisible: false,
  isFullscreen: false,
  isStereoMode: false,
  isAutoRotating: false,
  isMediaControlsVisible: false,
  isGyroEnabled: false,
  tourType: TOUR_TYPE.NONE,
  isTutorialVisible: true,
  tutorialDisplayed: false,
  tutorialImagesButtonHighlighted: false,
  tutorialVPGridButtonHighlighted: false,
  tutorialVPInfoButtonHighlighted: false,
  tutorialSubtitlesButtonHighlighted: false,
  tutorialToggleMusicButtonHighlighted: false,
  tutorialNarrationPlayPauseButtonHighlighted: false,
  tutorialGyroToggleButtonHighlighted: false,
  tutorialMapToggleButtonHighlighted: false,
  tutorialSplitScreenButtonHighlighted: false,
  isFullScreenMapVisible: false,
};

export enum AudioPlayingState {
  paused,
  playing,
  completed,
  systemPaused,
  loading,
}

export type AudioState = {
  showSubtitles: boolean;
  subtitlesLanguage: LanguageCode;
  musicMuted: boolean;
  narrationLanguage: LanguageCode;
  changingSlider: boolean;
  seekPos: number;
  length: number;
  pausedByUser: boolean;
  pausedByTheSystem: boolean;
  error: unknown | Error | null;
  loading: boolean;
  ready: boolean;
  playing: boolean;
  started: boolean;
  completed: boolean;
  unlocked: boolean;
};

export const initialAudioState: AudioState = {
  seekPos: 0,
  length: 0,
  showSubtitles: false,
  subtitlesLanguage: DEFAULT_LANGUAGE,
  musicMuted: false,
  narrationLanguage: DEFAULT_LANGUAGE,
  changingSlider: false,
  pausedByUser: false,
  pausedByTheSystem: false,
  error: null,
  loading: false,
  ready: false,
  playing: false,
  started: false,
  completed: false,
  unlocked: false,
};

export type Video = {
  id: string;
  playlistFile: {
    id: string;
    uri: string;
  };
};

export const initialState: ViewerState = {
  tour: null,
  currentViewpointIndex: 0,
  closestViewpointDistance: Infinity,
  closestViewpointDetectionMode: ClosestViewpointDetectionMode.RADIUS,
  previousViewpointIndex: -1,
  closestViewpointIndex: -1,
  currentImageIndex: 0,
  previousImageIndex: -1,
  isLoading: false,
  audio: initialAudioState,
  viewerOptions: initialViewerOptions,
  imageCaption: null,
  endOfTourPopupClosed: false,
  resyncWithCompassCounter: 0,
  compassSupport: CompassSupport.QUERYING,
  visitedVPIndices: [],
  displayUI: true,
  userSelectedAViewpoint: false,
};
