import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import { ErrorBoundary } from './App/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
// import './features/audio/howlerSetup';
import { env } from './App/config/env';
import { getApolloClient } from './App/config/apollo/ApolloClient';
import AppWithDefaultConfig from './App/AppWithDefaultConfig';
import AppWithRemoteConfig from './App/AppWithRemoteConfig';
import { getAppID } from './App/config/getAppID';
import { loadEventQueueFromStorage } from './features/analytics/eventQueue';
import { setInitialLanguage } from './features/i18n/setInitialLanguage';
import { logMessage } from './features/logging/logMessage';
import DeviceFingerprintProvider from './providers/deviceFingerprintProvider';
import UserProvider from './providers/userProvider';
import { createStore } from './store';
import { migratePersistedStateToNewStorage } from './store/redux/migratePersistedStateToNewStorage';
import { setUpdateAvailable } from './store/sw/actions';
import { createDeviceID } from './utils/createDeviceID';
import { createOrGetSession } from './features/analytics/createOrGetSession';
import getErrorMessage from './utils/getErrorMessage';
import generateDeviceFingerprint from './utils/generateDeviceFingerprint';
import './globals/stripeScript';
import { configureSentry } from './features/logging/configureSentry';

const history = createBrowserHistory();

configureSentry(history);

// Generate a new device ID or restore from storage
createDeviceID();

// Generate a sessionID, but do not submit the event to the backend
createOrGetSession();

const App = () => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(
    null
  );
  const [store, setStore] = useState(null);
  const [persistor, setPersistor] = useState(null);

  const [fingerprint, setFingerprint] = useState<string | null>(null);
  const [fingerprintError, setFingerprintError] = useState<string | null>(null);
  const [appID, setAppID] = useState<string | null>(null);

  useEffect(() => {
    const initializeApp = async () => {
      logMessage('Initializing app');

      const { appID } = getAppID(
        env.IS_STOCK,
        env.IS_RTM,
        env.IS_AK,
        env.IS_AO
      );

      setAppID(appID);

      // Generate a fingerprint
      try {
        const fingerprint = await generateDeviceFingerprint();
        setFingerprint(fingerprint);
        logMessage('Fingerprint hash', fingerprint);
      } catch (error) {
        setFingerprintError(getErrorMessage(error));
        logMessage('Error setting fingerprint hash', error);
      }

      // Load analytic event queue from storage
      try {
        await loadEventQueueFromStorage();

        logMessage('Analytic event queue loaded from storage');
      } catch (error) {
        logMessage(
          'Error loading analytic event queue from storage',
          getErrorMessage(error)
        );
      }

      // Should be before store initialises
      if (env.REDUX_PERSIST_USE_INDEXEDDB) {
        try {
          await migratePersistedStateToNewStorage();

          logMessage('Persisted state migrated to indexedDB');
        } catch (error) {
          logMessage(
            'Error migrating persisted state to indexedDB',
            getErrorMessage(error)
          );
        }
      }

      // Redux store initialisation
      const { store, persistor } = createStore(history);
      setStore(store);
      setPersistor(persistor);

      try {
        const client = await getApolloClient();

        setApolloClient(client);

        logMessage('Apollo client initialised');
      } catch (error) {
        logMessage('Error initializing apollo client', getErrorMessage(error));
      }

      if (store) {
        // Should be after the redux store initialises.
        // Set initial language read from redux store.
        try {
          await setInitialLanguage();

          logMessage('Initial language set');
        } catch (error) {
          logMessage('Error setting initial language', getErrorMessage(error));
        }

        // Should be after the store initialises.
        // Register service worker if enabled.
        if (env.ENABLE_SERVICE_WORKER) {
          serviceWorker.register({
            onUpdate: (registration) => {
              logMessage('Service worker: update available.');
              store.dispatch(setUpdateAvailable({ available: true }));
            },
            onSuccess: (registration) => {
              logMessage('Service worker: successfully registered.');
            },
          });
        } else {
          serviceWorker.unregister();
        }
      }
    };

    initializeApp();
  }, []);

  if (
    !appID ||
    !apolloClient ||
    !store ||
    !persistor ||
    !(fingerprint || fingerprintError)
  ) {
    return null;
  }

  return (
    <Router history={history}>
      <ReduxProvider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={() => logMessage('Store rehydrated')}
        >
          <ApolloProvider client={apolloClient}>
            <UserProvider>
              <DeviceFingerprintProvider
                fingerprint={fingerprint}
                fingerprintError={fingerprintError}
              >
                {env.IS_STOCK ? (
                  <AppWithDefaultConfig />
                ) : (
                  <AppWithRemoteConfig appID={appID} />
                )}
              </DeviceFingerprintProvider>
            </UserProvider>
          </ApolloProvider>
        </PersistGate>
      </ReduxProvider>
    </Router>
  );
};

render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
