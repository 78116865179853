import { CSSProperties, RefObject, AriaAttributes } from 'react';
import Icon from '@mdi/react';

interface HTMLProps extends AriaAttributes {
  className?: string;
}

interface Props extends HTMLProps {
  id?: string;
  path: string;
  ref?: RefObject<SVGSVGElement>;
  title?: string | null;
  description?: string | null;
  size?: number | string | null;
  color?: string | null;
  horizontal?: boolean;
  vertical?: boolean;
  rotate?: number;
  spin?: boolean | number;
  style?: CSSProperties;
  inStack?: boolean;
}

const MdiIcon = ({
  id,
  path,
  ref,
  title,
  description,
  size = '24px',
  color = '#000',
  horizontal,
  vertical,
  rotate,
  spin,
  style,
  inStack,
}: Props) => {
  const sizeToString = typeof size === 'number' ? `${size}px` : size;

  const restProps = {
    id,
    path,
    ref,
    title,
    description,
    color,
    horizontal,
    vertical,
    rotate,
    spin,
    style,
    inStack,
  };

  return <Icon {...restProps} size={sizeToString} />;
};

MdiIcon.defaultProps = {
  color: '#000',
  horizontal: false,
  vertical: false,
  rotate: 0,
  spin: false,
  inStack: false,
  size: '24px',
};

export default MdiIcon;
