import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section } from '../styledComponents/Section';
import { SectionTitle } from '../styledComponents/SectionTitle';
import { BodyTextWithIcon } from '../styledComponents/BodyTextWithIcon';
import { IconWithTextContainer } from '../styledComponents/IconWithTextContainer';
import { Grid } from '../../../../../Layouts/primitives/Grid';
import { useTranslation } from 'react-i18next';

const TOUR_INFO_COUCH_ICON = '/4a00b93c5e53b4f9e68cd375eba8423c.png';
const TOUR_INFO_SNACKS_ICON = '/861ccff85209fcdfdc3fed5ba78c2832.png';
const TOUR_INFO_NO_PASSPORT_ICON = '/e46372615a52ca317e6278cdcbb7cc42.png';
const TOUR_INFO_AUDIO_DURATION_ICON = '/135bc1912315b799d31f01ee2a1b1c14.png';

export const TourInfoGeneralSection = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle>{t('A few little tips')}</SectionTitle>
      <Grid cols={2} colsSmall={1} gap={5}>
        <IconWithTextContainer>
          <LazyLoadImage width={32} src={TOUR_INFO_COUCH_ICON} />
          <BodyTextWithIcon>
            {t("If you're home, find a comfortable spot on the couch")}
          </BodyTextWithIcon>
        </IconWithTextContainer>

        <IconWithTextContainer>
          <LazyLoadImage width={32} src={TOUR_INFO_AUDIO_DURATION_ICON} />
          <BodyTextWithIcon>{t('Listen to the local guide')}</BodyTextWithIcon>
        </IconWithTextContainer>

        <IconWithTextContainer>
          <LazyLoadImage width={32} src={TOUR_INFO_SNACKS_ICON} />
          <BodyTextWithIcon>{t('Snacks within reach')}</BodyTextWithIcon>
        </IconWithTextContainer>

        <IconWithTextContainer>
          <LazyLoadImage width={32} src={TOUR_INFO_NO_PASSPORT_ICON} />
          <BodyTextWithIcon>{t('No passport required!')}</BodyTextWithIcon>
        </IconWithTextContainer>
      </Grid>
    </Section>
  );
});
