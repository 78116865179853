import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_GetGuides,
  EXPLORE_WEB_GetGuides_result,
} from './__generated__/EXPLORE_WEB_GetGuides';
import { ASSET_FRAGMENT } from '../fragments/Asset';

export type Data = EXPLORE_WEB_GetGuides;
export type Guide = EXPLORE_WEB_GetGuides_result;

export const GET_GUIDES_QUERY = gql`
  query EXPLORE_WEB_GetGuides {
    result: getGuides(input: {}) {
      id
      name
      location
      bio
      avatar {
        ...AssetFragment
      }
    }
  }

  ${ASSET_FRAGMENT}
`;
