import React from 'react';
import * as Sentry from '@sentry/react';

interface Props {
  children: any;
}

interface State {
  eventId: string | null;
  error: Error | null;
}

export class ErrorBoundaryMini extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { eventId: null, error: null };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    const nextState: State = {
      error,
      eventId: null,
    };

    try {
      nextState.eventId = Sentry.captureException(error, {
        extra: { errorInfo },
      });
    } catch (error) {
      // don't do anything
    }

    this.setState(nextState);
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return this.props.children;
  }
}
