import { PinnedTour } from '../../../../store/app/types';
import { nonNullable } from '../../../../utils/nonNullable';

interface Tour {
  id: string;
  internalReference: string;
}

export function getHomeScreenSortedTours<T extends Tour>(
  tours: Array<T>,
  pinnedTours: PinnedTour[] = []
): Array<T> {
  let athens;
  let rome;
  let jerusalem;

  const first: T[] = [];
  const rest: T[] = [];

  const pinnedToursMapInit: { [key: string]: PinnedTour } = {};

  const pinnedToursMap = pinnedTours.reduce((accum, pinnedTour) => {
    accum[pinnedTour.tourID] = pinnedTour;

    return accum;
  }, pinnedToursMapInit);

  // first add the pinned tours
  pinnedTours.forEach((pt) => {
    const tour = tours.find((t) => t.id === pt.tourID);

    if (tour) {
      first.push(tour);
    }
  });

  for (let i = 0; i < tours.length; i++) {
    const tour = tours[i];
    const { id, internalReference } = tour;

    if (pinnedToursMap[id]) {
      continue;
    } else if (internalReference === 'ancient_athens') {
      athens = tour;
    } else if (internalReference === 'ancient_rome') {
      rome = tour;
    } else if (internalReference === 'ancient_jerusalem') {
      jerusalem = tour;
    } else {
      rest.push(tour);
    }
  }

  return [...first, athens, rome, jerusalem, ...rest].filter(nonNullable);
}
