import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorView } from '../components/ErrorView';
import { ChunkLoadErrorView } from '../components/ChunkLoadErrorView';
import styled from '../style/styled';
import { AppGlobalStyle } from '../style/AppGlobalStyle';
import { getDecorationImageURL } from '../utils/getDecorationImageURL';
import { reloadApp } from '../utils/reloadApp';
interface Props {
  children: any;
}

interface State {
  eventId: string | null;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { eventId: null, error: null };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    const isChunkLoadError = error?.name === 'ChunkLoadError';

    if (isChunkLoadError) {
      reloadApp();
    } else {
      this.setState({ error });

      const eventId = Sentry.captureException(error, {
        extra: {
          errorInfo,
        },
      });

      this.setState({ eventId });
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      // Treat the error differently if it is a chunk load error.
      // Chunk load error happens when the index.html is cached by the service worker,
      // but the chunk has not been cached by the service worker
      // AND there has been a new version pushed.
      // So the chunk name the index.html points to is not in service worker cache, NOR
      // is available on Firebase (because that container is no longer active)
      // The only solution is to reload the page and get the new index.html.
      const isChunkLoadError = error?.name === 'ChunkLoadError';

      return (
        <>
          <AppGlobalStyle />

          <ErrorContainer>
            {isChunkLoadError && <ChunkLoadErrorView />}

            {!isChunkLoadError && (
              // Do not capture an error here. We've already done that in componentDidCatch
              <ErrorView />
            )}
          </ErrorContainer>
        </>
      );
    }

    return this.props.children;
  }
}

const ErrorContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto-Condensed, Roboto, sans-serif;

  background-image: url(${getDecorationImageURL()});
  background-size: auto 300px;
  background-position: center bottom;
  background-repeat: repeat-x;
`;
