import { createAction } from 'redux-actions';

export const ACTION_TYPES = {
  SET_GOOGLE_OPTIMIZE_TIMED_OUT: 'setGoogleOptimizeTimedOut',
  SET_VARIANT: 'setVariant',
  SET_VARIANTS: 'setVariants',
};

type SetVariantPayload = { experimentID: string; variant: string };

export type SetVariantAction = {
  type: typeof ACTION_TYPES.SET_VARIANT;
  payload: SetVariantPayload;
};

export const setVariant = createAction<SetVariantPayload>(
  ACTION_TYPES.SET_VARIANT
);

type SetVariantsPayload = Array<{ experimentID: string; variant: string }>;

export type SetVariantsAction = {
  type: typeof ACTION_TYPES.SET_VARIANTS;
  payload: SetVariantsPayload;
};

export const setVariants = createAction<SetVariantsPayload>(
  ACTION_TYPES.SET_VARIANTS
);

export const setGoogleOptimizeTimedOut = createAction(
  ACTION_TYPES.SET_GOOGLE_OPTIMIZE_TIMED_OUT
);
