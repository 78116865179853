import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_LogInWithEmail,
  EXPLORE_WEB_LogInWithEmailVariables,
} from './__generated__/EXPLORE_WEB_LogInWithEmail';

export type Data = EXPLORE_WEB_LogInWithEmail;
export type Variables = EXPLORE_WEB_LogInWithEmailVariables;

export const LOGIN_WITH_EMAIL_MUTATION = gql`
  mutation EXPLORE_WEB_LogInWithEmail($input: LogInWithEmailInput!) {
    result: logInWithEmail(input: $input) {
      jwt
      error {
        id
        message
        type
      }
    }
  }
`;
