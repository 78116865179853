import 'buffer';
import { captureInSentry } from '../App/config/reporting/captureInSentry';
import getErrorMessage from './getErrorMessage';

// read remote file as unencoded text. required for reading srt files.
export async function readRemoteFileAsText(
  url: string,
  encoding: string = 'utf-8'
) {
  try {
    const response = await fetch(url);

    const text = await response
      .arrayBuffer()
      .then((arrayBuf) => Buffer.from(arrayBuf))
      .then((buf) => {
        const decoder = new TextDecoder(encoding);

        return decoder.decode(buf);
      });

    return text;
  } catch (error) {
    captureInSentry(
      `readRemoteFileAsText.ts error: ${getErrorMessage(error)}`,
      { url, encoding }
    );
  }

  return '';
}
