import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { setDeviceID } from '../globals/deviceID';

export function createDeviceID(): string {
  let deviceID;

  if (window.localStorage) {
    deviceID = localStorage.getItem('deviceID');

    if (!deviceID) {
      deviceID = uuidv4();

      localStorage.setItem('deviceID', deviceID);
    }
  } else {
    deviceID = Cookies.get('deviceID');

    if (!deviceID) {
      deviceID = uuidv4();

      Cookies.set('deviceID', deviceID);
    }
  }

  setDeviceID(deviceID);

  return deviceID;
}
