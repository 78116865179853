import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_SendResetPasswordLink,
  EXPLORE_WEB_SendResetPasswordLinkVariables,
} from './__generated__/EXPLORE_WEB_SendResetPasswordLink';

export type Data = EXPLORE_WEB_SendResetPasswordLink;
export type Variables = EXPLORE_WEB_SendResetPasswordLinkVariables;

export const SEND_RESET_PASSWORD_LINK_MUTATION = gql`
  mutation EXPLORE_WEB_SendResetPasswordLink(
    $input: SendResetPasswordLinkInput!
  ) {
    result: sendResetPasswordLink(input: $input) {
      sent
      error {
        id
        message
        type
      }
    }
  }
`;
