import { LanguageCode, CurrencyCode, ProductSKU } from './graphql/globalTypes';
import { nonNullable } from './utils/nonNullable';

export const CDN_URL = 'https://gaia.lithodomos.com';
export const ALPHA_STOCK_URL = 'https://awa.lithodomos.com';
export const BETA_STOCK_URL = 'https://awb.lithodomos.com';
export const RTM_STOCK_URL = 'https://ancient-world.co';
export const RTM_STOCK_AK_URL =
  'https://ancient-world-abercrombiekent.web.app/';
export const RTM_STOCK_AO_URL =
  'https://ancient-world-ancient-origins.web.app/';
export const ALPHA_WHITELABEL = 'https://guidestaging.lithodomos.com';
export const RTM_WHITELABEL = 'https://guide.lithodomos.com';
export const DEV_URL = 'http://localhost:3100';

export const NAVBAR_HEIGHT = 50;

export const EMPTY_AUDIO_URL = '/1-second-of-silence.mp3'; // require('../../assets/1-second-of-silence.mp3');

export const UNKNOWN_ERROR = 'Unknown error';
// export const RECAPTCHA_ERROR = 'Recaptcha error';

export const DEFAULT_LANGUAGE = LanguageCode.en_US;
export const DEFAULT_CURRENCY_CODE = CurrencyCode.USD;

export const DEFAULT_MAPS_TRAIL_STROKE_WIDTH = 5;

// this is a copy of language definitions on the API
export const languages = {
  [LanguageCode.ar]: {
    code: 'ar',
    displayTextEnglish: 'Arabic',
    displayTextNative: 'اَلْعَرَبِيَّةُ‎',
    rtl: true,
  },
  [LanguageCode.da_DK]: {
    code: 'da-DK',
    displayTextEnglish: 'Danish',
    displayTextNative: 'dansk',
    rtl: false,
  },
  [LanguageCode.de_DE]: {
    code: 'de-DE',
    displayTextEnglish: 'German',
    displayTextNative: 'Deutsch',
    rtl: false,
  },
  [LanguageCode.el_GR]: {
    code: 'el-GR',
    displayTextEnglish: 'Greek',
    displayTextNative: 'ελληνικά',
    rtl: false,
  },
  [LanguageCode.en_AU]: {
    code: 'en-AU',
    displayTextEnglish: 'English (Australian)',
    displayTextNative: 'English (Australian)',
    rtl: false,
  },
  [LanguageCode.en_GB]: {
    code: 'en-GB',
    displayTextEnglish: 'English (British)',
    displayTextNative: 'English (British)',
    rtl: false,
  },
  [LanguageCode.en_US]: {
    code: 'en-US',
    displayTextEnglish: ' English',
    displayTextNative: 'English',
    rtl: false,
  },
  [LanguageCode.es_ES]: {
    code: 'es-ES',
    displayTextEnglish: 'Spanish',
    displayTextNative: 'español',
    rtl: false,
  },
  [LanguageCode.fr_CA]: {
    code: 'fr-CA',
    displayTextEnglish: 'French (Canadian)',
    displayTextNative: 'français canadien',
    rtl: false,
  },
  [LanguageCode.fr_FR]: {
    code: 'fr-FR',
    displayTextEnglish: 'French',
    displayTextNative: 'Français',
    rtl: false,
  },
  [LanguageCode.he_IL]: {
    code: 'he-IL',
    displayTextEnglish: 'Hebrew',
    displayTextNative: 'עברית',
    rtl: true,
  },
  [LanguageCode.hi_IN]: {
    code: 'hi-IN',
    displayTextEnglish: 'Hindi',
    displayTextNative: 'हिन्दी',
    rtl: false,
  },
  [LanguageCode.it_IT]: {
    code: 'it-IT',
    displayTextEnglish: 'Italian',
    displayTextNative: 'italiano',
    rtl: false,
  },
  [LanguageCode.ja_JP]: {
    code: 'ja-JP',
    displayTextEnglish: 'Japanese',
    displayTextNative: '日本語',
    rtl: false,
  },
  [LanguageCode.ko_KR]: {
    code: 'ko-KR',
    displayTextEnglish: 'Korean',
    displayTextNative: '한국어',
    rtl: false,
  },
  [LanguageCode.nb_NO]: {
    code: 'nb-NO',
    displayTextEnglish: 'Norwegian',
    displayTextNative: 'norsk',
    rtl: false,
  },
  [LanguageCode.nl_NL]: {
    code: 'nl-NL',
    displayTextEnglish: 'Dutch',
    displayTextNative: 'Nederlands',
    rtl: false,
  },
  [LanguageCode.pl_PL]: {
    code: 'pl-PL',
    displayTextEnglish: 'Polish',
    displayTextNative: 'polski',
    rtl: false,
  },
  [LanguageCode.pt_BR]: {
    code: 'pt-BR',
    displayTextEnglish: 'Portuguese (Brazilian)',
    displayTextNative: 'português brasileiro',
    rtl: false,
  },
  [LanguageCode.pt_PT]: {
    code: 'pt-PT',
    displayTextEnglish: 'Portuguese',
    displayTextNative: 'português',
    rtl: false,
  },
  [LanguageCode.ru_RU]: {
    code: 'ru-RU',
    displayTextEnglish: 'Russian',
    displayTextNative: 'Pусский',
    rtl: false,
  },
  [LanguageCode.sk_SK]: {
    code: 'sk-SK',
    displayTextEnglish: 'Slovak',
    displayTextNative: 'slovenský',
    rtl: false,
  },
  [LanguageCode.sv_SE]: {
    code: 'sv-SE',
    displayTextEnglish: 'Swedish',
    displayTextNative: 'svenska',
    rtl: false,
  },
  [LanguageCode.tr_TR]: {
    code: 'tr-TR',
    displayTextEnglish: 'Turkish',
    displayTextNative: 'Türkçe',
    rtl: false,
  },
  [LanguageCode.uk_UA]: {
    code: 'uk-UA',
    displayTextEnglish: 'Ukrainian',
    displayTextNative: 'українська',
    rtl: false,
  },
  [LanguageCode.zh]: {
    code: 'zh',
    displayTextEnglish: 'Mandarin',
    displayTextNative: '中文',
    rtl: false,
  },
};

export const reverseLanguageMap = {
  ar: LanguageCode.ar,
  'da-DK': LanguageCode.da_DK,
  'de-DE': LanguageCode.de_DE,
  'el-GR': LanguageCode.el_GR,
  'en-AU': LanguageCode.en_AU,
  'en-GB': LanguageCode.en_GB,
  'en-US': LanguageCode.en_US,
  'es-ES': LanguageCode.es_ES,
  'fr-CA': LanguageCode.fr_CA,
  'fr-FR': LanguageCode.fr_FR,
  'he-IL': LanguageCode.he_IL,
  'hi-IN': LanguageCode.hi_IN,
  'it-IT': LanguageCode.it_IT,
  'ja-JP': LanguageCode.ja_JP,
  'ko-KR': LanguageCode.ko_KR,
  'nb-NO': LanguageCode.nb_NO,
  'nl-NL': LanguageCode.nl_NL,
  'pl-PL': LanguageCode.pl_PL,
  'pt-BR': LanguageCode.pt_BR,
  'pt-PT': LanguageCode.pt_PT,
  'ru-RU': LanguageCode.ru_RU,
  'sk-SK': LanguageCode.sk_SK,
  'sv-SE': LanguageCode.sv_SE,
  'tr-TR': LanguageCode.tr_TR,
  'uk-UA': LanguageCode.uk_UA,
  zh: LanguageCode.zh,
};

// Note: Move the flags into the public folder when adding new languages
export const LANGUAGE_CONFIGS = [
  {
    label: languages[LanguageCode.ar].displayTextNative,
    value: LanguageCode.ar,
    display: 'AR',
    uri: '/flags/ar.png',
  },
  {
    label: languages[LanguageCode.en_US].displayTextNative,
    value: LanguageCode.en_US,
    display: 'EN',
    uri: '/flags/gb.png',
  },
  {
    label: languages[LanguageCode.fr_FR].displayTextNative,
    value: LanguageCode.fr_FR,
    display: 'FR',
    uri: '/flags/fr.png',
  },
  {
    label: languages[LanguageCode.el_GR].displayTextNative,
    value: LanguageCode.el_GR,
    display: 'GR',
    uri: '/flags/gr.png',
  },
  {
    label: languages[LanguageCode.de_DE].displayTextNative,
    value: LanguageCode.de_DE,
    display: 'DE',
    uri: '/flags/de.png',
  },
  {
    label: languages[LanguageCode.he_IL].displayTextNative,
    value: LanguageCode.he_IL,
    display: 'IL',
    uri: '/flags/il.png',
  },
  {
    label: languages[LanguageCode.hi_IN].displayTextNative,
    value: LanguageCode.hi_IN,
    display: 'IN',
    uri: '/flags/in.png',
  },
  {
    label: languages[LanguageCode.it_IT].displayTextNative,
    value: LanguageCode.it_IT,
    display: 'IT',
    uri: '/flags/it.png',
  },
  {
    label: languages[LanguageCode.ja_JP].displayTextNative,
    value: LanguageCode.ja_JP,
    display: 'JP',
    uri: '/flags/jp.png',
  },
  {
    label: languages[LanguageCode.zh].displayTextNative,
    value: LanguageCode.zh,
    display: 'ZH',
    uri: '/flags/cn.png',
  },
  {
    label: languages[LanguageCode.pl_PL].displayTextNative,
    value: LanguageCode.pl_PL,
    display: 'PL',
    uri: '/flags/pl.png',
  },
  {
    label: languages[LanguageCode.pt_PT].displayTextNative,
    value: LanguageCode.pt_PT,
    display: 'PT',
    uri: '/flags/pt.png',
  },
  {
    label: languages[LanguageCode.ru_RU].displayTextNative,
    value: LanguageCode.ru_RU,
    display: 'RU',
    uri: '/flags/ru.png',
  },
  {
    label: languages[LanguageCode.es_ES].displayTextNative,
    value: LanguageCode.es_ES,
    display: 'ES',
    uri: '/flags/es.png',
  },
];

const DEFAULT_SUPPORTED_LANGUAGES = [
  LanguageCode.en_US,
  LanguageCode.de_DE,
  LanguageCode.it_IT,
  LanguageCode.es_ES,
  // Removed because it was an experiment that didn't go as planned
  // LanguageCode.zh,
];

export function getDefaultSupportedLanguages() {
  return DEFAULT_SUPPORTED_LANGUAGES;
}

export function getSupportedLanguageConfigs(
  languageCodes?: Array<LanguageCode>
) {
  let list = DEFAULT_SUPPORTED_LANGUAGES;

  if (languageCodes && languageCodes?.length > 0) {
    list = languageCodes;
  }

  return list
    .map((code) => LANGUAGE_CONFIGS.find((lang) => lang.value === code))
    .filter(nonNullable);
}

export const PRODUCT_SKU_OVERRIDES_FOR_ANCIENT_WORLD = {
  [ProductSKU.PLAYLIST]: 'Ancient World Tour',
  [ProductSKU.VIEWPOINT]: 'Ancient Wourld Tour Viewpoint',
  [ProductSKU.GIFT_CARD]: 'Ancient World Gift Card',
  [ProductSKU.PLAYLIST_BUNDLE]: 'Ancient World Tour Bundle',
};

export const PRODUCT_SKU_OVERRIDES_FOR_WHITELABELED_APP = {
  [ProductSKU.PLAYLIST]: 'Audio-Visual Guide',
  [ProductSKU.VIEWPOINT]: 'Viewpoint',
  [ProductSKU.GIFT_CARD]: 'Gift Card',
  [ProductSKU.PLAYLIST_BUNDLE]: 'Audio-Visual Guide Bundle',
};

export const STRIPE_CURRENCY_CODES = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SHP',
  'SLE',
  'SOS',
  'SRD',
  'STD',
  'SZL',
  'THB',
  'TJS',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
];

export const ZERO_DECIMAL_CURRENCIES = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

export const THREE_DECIMAL_CURRENCIES = ['BHD', 'JOD', 'KWD', 'OMR', 'TND'];
