import { isURL } from 'validator';
import { env } from '../../../../App/config/env';

export default function getRedirectCartButtonToExternalURL(): string | null {
  const url = env.APP_ENV.header.redirectCartButtonToURL;

  if (!url) {
    return null;
  }

  if (!isURL(url, { require_protocol: true })) {
    return null;
  }

  return url;
}
