import center from '@turf/center';
import { GoogleMapsLatLngLiteral } from '../types';
import { getPointFeatures } from './getPointFeatures';

export function calculateCenterOfPoints(
  positions: Array<GoogleMapsLatLngLiteral | null>
): GoogleMapsLatLngLiteral | null {
  const features = getPointFeatures(positions);
  const centerTurfPoint = center(features);
  const centreCoords = centerTurfPoint.geometry?.coordinates;

  if (centreCoords) {
    return {
      lng: centreCoords[0],
      lat: centreCoords[1],
    };
  }

  return null;
}
