import { captureInSentry } from '../App/config/reporting/captureInSentry';
import getErrorMessage from './getErrorMessage';

export function getTimezone(): string | null {
  if (typeof window.Intl?.DateTimeFormat === 'function') {
    try {
      return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      captureInSentry(`getTimezone.ts error: ${getErrorMessage(error)}`);
    }
  }

  return null;
}
