import * as Sentry from '@sentry/react';
import { DocumentNode } from 'graphql';
import { getApolloClient } from './../App/config/apollo/ApolloClient';

// run a graphql query outside react component lifecycle.
// but this means we cannot use cache-and-network policy.
export async function runGraphQLQuery(query: DocumentNode, variables: any) {
  try {
    const client = await getApolloClient();

    await client.query({
      query,
      variables,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}
