export type Coordinate = {
  lat: number;
  lng: number;
};

export type GoogleMapsLatLngLiteral = {
  lat: number;
  lng: number;
};

export interface MarkerProps {
  lat: number;
  lng: number;
}

export enum MarkerType {
  VIEWPOINT = 'viewpoint',
  TOUR = 'tour',
  ATTRACTION = 'attraction',
  PLACE = 'place',
}

export interface GenericMarkerData {
  position: Coordinate;
  type: MarkerType;
  id: string;
  name: string;
  description: string;
  thumbnailURL: string;
  isCurrent: boolean;
  idx: number;
  icon?: string;
}

// export enum SygicTravelLevels {
//   continent = "continent",
//   country = "country",
//   state = "state",
//   region = "region",
//   county = "county",
//   city = "city",
//   town = "town",
//   village = "village",
//   settlement = "settlement",
//   locality = "locality",
//   neighbourhood = "neighbourhood",
//   archipelago = "archipelago",
//   island = "island",
//   poi = "poi",
// }

// export enum SygicTravelCategories {
//     discovering = "discovering",
//     eating = "eating",
//     going_out = "going_out",
//     hiking = "hiking",
//     playing = "playing",
//     relaxing = "relaxing",
//     shopping = "shopping",
//     sightseeing = "sightseeing",
//     sleeping = "sleeping",
//     doing_sports = "doing_sports",
//     traveling = "traveling",
// }

// export type SygicTravelPlace = {
//   id: string;
//   level: SygicTravelLevels;
//   categories: SygicTravelCategories;
//   tag_keys: Array<string>;
//   rating: number;
//   rating_local: number;
//   quadkey: string;
//   location: GoogleMapsLatLngLiteral;
//   bounding_box: {
//     south: number;
//     west: number;
//     north: number;
//     east: number;
//   }
//   name: string | null;
//   name_suffix: string | null;
//   name_local: string | null;
//   name_en: string | null;
//   name_translated: string | null;
//   perex: string | null;
//   url: string | null;
//   thumbnail_url: string | null;
//   marker: string;
//   class: {
//     slug: string;
//     name: string;
//   }
//   parents: Array<{
//     id: string;
//     name: string|null;
//     level: SygicTravelLevels;
//   }>;
//   hotel_star_rating: number | null;
//   hotel_star_rating_unofficial: number | null;
//   customer_rating: number | null;
//   duration_estimate: number | null;
// }
