import React, { useEffect, useState } from 'react';
import styled from '../style/styled';
import { LoadingIndicator } from '../Routes/components/LoadingScreen/LoadingIndicator';
import { COLORS } from '../style/colors';
import { useTranslation } from 'react-i18next';

type Props = {
  size?: number;
  timeout?: number;
  showText?: boolean;
  black?: boolean;
  text?: string;
};

// only render loading spinner after a second or specified time
export const LoadingView: React.FC<Props> = ({
  size = 100,
  timeout = 1000,
  showText = true,
  black = false,
  text = 'Loading...',
}) => {
  const [showSpinner, setShowSpinner] = useState<boolean>(timeout <= 0);
  const { t } = useTranslation();

  useEffect(() => {
    const visibilityStartTimerID = window.setTimeout(
      () => setShowSpinner(true),
      timeout
    );

    // cleanup
    return () => {
      window.clearTimeout(visibilityStartTimerID);
    };
  }, [timeout]);

  if (!showSpinner) {
    return null;
  }

  return (
    <Container>
      <LoadingIndicator height={size} width={size} black={black} />

      {showText && (
        <Text size={size} black={black}>
          {t(text)}
        </Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p<{ size: number; black?: boolean }>`
  font-size: ${({ size }) => size / 8}px;
  color: ${({ black }) =>
    black ? COLORS.darkTextColor : COLORS.lightTextColor};
`;
