import { DEFAULT_LANGUAGE } from '../../consts';
import { LanguageCode } from '../../graphql/globalTypes';
import { I18nInfoField } from '../../types';

export function getI18nFieldValue(
  field: I18nInfoField | null,
  languageCode: LanguageCode,
  fallback: string = ''
): string {
  if (!field) {
    return fallback;
  }

  // TypeScript knows the type of languageCode is a key of I18nInfoField,
  // so no ts-ignore needed.
  let value =
    field[languageCode as keyof I18nInfoField] ||
    field[DEFAULT_LANGUAGE as keyof I18nInfoField];

  return typeof value === 'string' ? value : fallback;
}
