import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ShowPasswordIcon from 'mdi-react/EyeIcon';
import HidePasswordIcon from 'mdi-react/EyeOffIcon';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from '../../../style/styled';
import { loginSuccess } from '../../../store/app/actions';
import { ROUTES } from '../../routes';
import {
  Data,
  LOGIN_WITH_EMAIL_MUTATION,
  Variables,
} from '../../../graphql/mutations/LogInWithEmailMutation';
import { Input } from '../../../styledComponents/Input';
// import { RightAlignedContainer } from '../../../components/RightAlignedContainer';
import { AuthButtonWithRecaptcha3 } from '../../../components/AuthButtonWithRecaptcha3';
import { Label } from '../../../styledComponents/Label';
// import { ButtonContainer } from '../../../components/ButtonContainer';
// import { ButtonGroupContainer } from '../../../components/ButtonGroupContainer';
import { ErrorText } from '../../../styledComponents/ErrorText';
import { Grid } from '../../../Layouts/primitives/Grid';
// import { LinkLikeButton } from '../../../styledComponents/LinkLikeButton';
// import { AuthHelpTextCentered } from '../../../components/HelpText';
// import { ReCaptchaDisclaimer } from '../../../components/ReCaptchaDisclaimer';
import {
  // captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import isEmail from '../../../utils/isEmail';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import {
  // RECAPTCHA_ERROR,
  UNKNOWN_ERROR,
} from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';

const ErrorTextWithBottomMargin = styled(ErrorText)`
  margin-bottom: 10px;
`;

type FormState = {
  email: string;
  password: string;
};

type ErrorState = {
  email: string;
  password: string;
};

function getInitialFormState() {
  return { email: '', password: '' };
}

function getInitialErrorState() {
  return { email: '', password: '' };
}

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  onForgotPassword?: () => void;
  onCreateAccount?: () => void;
  onBackToOptions?: () => void;
  busy: boolean;
  setBusy: (busy: boolean) => void;
  redirectedHref: string | null | undefined;
}

const RECAPTCHA_CONTEXT = 'LogIn';

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export const LogInWithEmailForm: React.FC<Props> = ({
  onSuccess,
  onFailure,
  onForgotPassword,
  onCreateAccount,
  onBackToOptions,
  setBusy,
  busy,
  redirectedHref,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>(getInitialFormState());
  const [formError, setFormError] = useState<ErrorState>(
    getInitialErrorState()
  );
  const [error, setError] = useState<Error | null>(null);
  const [logInMutation, { loading }] = useMutation<Data, Variables>(
    LOGIN_WITH_EMAIL_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        onFailure ? onFailure(error) : setError(error);
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry(
            'LogInWithEmailForm.tsx result is null or undefined',
            { variables: getVariables() }
          );

          return;
        }

        const { jwt, error } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({
            name: EventNames.LOGGED_IN,
            data: [{ key: DataKeys.LOGIN_METHOD, value: 'Email' }],
          });

          const toastSuccessMessage = t(
            'You have successfully logged in with your Email'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables(), jwt });

          onFailure
            ? onFailure(new Error(errorMessage))
            : setError(new Error(errorMessage));
        }
      },
    }
  );

  useEffect(() => {
    setBusy(loading);
  }, [loading, setBusy]);

  // const onKeyDown = async (event: any) => {
  //   if (event.keyCode !== 13) {
  //     return;
  //   }

  //   let token: string | null = null;

  //   if (executeRecaptcha) {
  //     try {
  //       token = await executeRecaptcha(RECAPTCHA_CONTEXT);
  //     } catch (error) {
  //       // don't do anything
  //     }
  //   }

  //   handleOnInputSubmit(token);
  // };

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let token: string | null = null;

    // if (executeRecaptcha) {
    //   try {
    //     token = await executeRecaptcha(RECAPTCHA_CONTEXT);
    //   } catch (error) {
    //     captureInSentry(error?.message || RECAPTCHA_ERROR, {
    //       recaptchaContext: RECAPTCHA_CONTEXT,
    //     });
    //   }
    // }

    handleOnInputSubmit(token);
  };

  const handleOnInputSubmit = (recaptchaToken: string | null) => {
    setError(null);

    const email = formState.email.trim();

    const errorState = getInitialErrorState();

    let hasError = false;

    if (!isEmail(email)) {
      hasError = true;
      errorState.email = t('Please enter a valid Email.');
    }

    const password = formState.password.trim();

    if (password.length === 0) {
      hasError = true;
      errorState.password = t('Please enter your password.');
    }

    if (hasError) {
      setFormError(errorState);
    } else {
      const variables: Variables = {
        input: {
          email,
          password,
          verificationURL: `https://${window.location.hostname}${ROUTES.authVerify}`,
        },
      };

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      logInMutation({ variables });
    }
  };

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;

    setError(null);
    setFormError({ ...formError, [id]: '' });
    setFormState({ ...formState, [id]: value });
  };

  return (
    <Form onSubmit={submitForm}>
      <Grid cols={1} gap={12}>
        <section>
          {!isMobileOnly && <Label htmlFor="email">{t('Email address')}</Label>}
          <Input
            autoFocus={!isMobile}
            id="email"
            name="email"
            type="email"
            autoComplete="on"
            value={formState.email}
            onChange={handleInputChange}
            data-testid="email-input-field"
            disabled={busy}
            hasError={Boolean(formError.email)}
            placeholder={isMobileOnly ? t('Enter your email') : ''}
            // onKeyDown={onKeyDown}
          />
          <ErrorText>{formError.email || ''}</ErrorText>
        </section>

        <section>
          {!isMobileOnly && <Label htmlFor="password">{t('Password')}</Label>}
          <PasswordInputContainer>
            <Input
              id="password"
              name="current-password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="on"
              value={formState.password}
              onChange={handleInputChange}
              data-testid="password-input-field"
              disabled={busy}
              hasError={Boolean(formError.password)}
              placeholder={isMobileOnly ? t('Enter your password') : ''}
              // onKeyDown={onKeyDown}
            />

            <ShowPasswordButton
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword && <ShowPasswordIcon />}
              {showPassword && <HidePasswordIcon />}
            </ShowPasswordButton>
          </PasswordInputContainer>
          <ErrorText>{formError.password ? formError.password : ''}</ErrorText>
        </section>

        {error && (
          <ErrorTextWithBottomMargin>
            {error?.message || UNKNOWN_ERROR}
          </ErrorTextWithBottomMargin>
        )}

        <ButtonContainer>
          <AuthButtonWithRecaptcha3
            context={RECAPTCHA_CONTEXT}
            disabled={busy}
            buttonName="LogInButton"
          >
            {t(loading ? 'Logging in...' : 'Log in')}
          </AuthButtonWithRecaptcha3>

          {onBackToOptions && (
            <BackToOptionsButton
              secondary
              onClick={onBackToOptions}
              disabled={busy}
            >
              {t('Other options')}
            </BackToOptionsButton>
          )}
        </ButtonContainer>

        {/* <ReCaptchaDisclaimer /> */}
      </Grid>
    </Form>
  );
};

const Form = styled.form`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;

const BackToOptionsButton = styled(Button)``;

const ShowPasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
`;

const PasswordInputContainer = styled.div`
  position: relative;
`;
