export const AUTH_TOKEN_KEY = 'token';
export const AFFILIATE_CODE_KEY = 'affiliateCode';
export const COUPON_CODE_KEY = 'couponCode';
export const APP_ID_KEY = 'appID';

// Taken from @lvr/http-core
export enum LITHODOMOS_HEADERS {
  APP_ID = 'x-lvr-app-id',
  APP_VERSION = 'x-lvr-app-version',
  CORRELATION_ID = 'x-lvr-correlation-id',
  DEVICE_ID = 'x-lvr-device-id',
  TIMEZONE = 'x-lvr-timezone',
  LOCALE = 'x-lvr-locale',
}

export enum APP_ID {
  ANCIENT_WORLD = 'ancient-world',
  ANCIENT_WORLD_STAGING = 'viewer-lithodomos-com',
  ANCIENT_WORLD_WHITELABEL = 'ancient-world-whitelabel',
  ABERCROMBIEKENT = 'abercrombiekent',
  ANCIENT_ORIGINS = 'ancient-origins',
  OSTIA = 'ostia',
  NOT_FAIR = 'notfair',
  NOT_FAIR_STAGING = 'notfair-staging',
  HELLENIC_MUSEUM = 'hellenicmuseum',
  HELLENIC_MUSEUM_STAGING = 'hellenicmuseum-staging',
  ANCIENT_WORLD_CAMBRIDGE = 'cambridge',
  ANCIENT_WORLD_CAMBRIDGE_STAGING = 'cambridge-staging',
}
