import { env } from '../../../App/config/env';
import styled from '../../../style/styled';

export const AppHeaderButtonText = styled.p<{
  color: string;
  size: number;
  bold?: boolean;
  isAK?: boolean;
  isAO?: boolean;
}>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size}px;
  margin-left: 4px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-family: ${({ isAK, isAO }) =>
    isAK ? 'Lato Regular' : isAO ? 'Titillium Web Bold' : 'inherit'};
  user-select: none;
  text-transform: uppercase;
  display: flex;
`;

AppHeaderButtonText.defaultProps = {
  isAK: env.IS_AK,
};
