import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/Button';
import { setModalType } from '../../../store/modal/actions';
import { ModalType } from '../../../store/modal/types';
import styled from '../../../style/styled';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  discountRate: number;
}

// Show an alert with a discount for users from World History Encyclopedia.
// Works in conjunction with AffiliateCodeExtractor component.
const WHEPromoCode: React.FC<Props> = ({ discountRate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      setModalType({
        modalType: ModalType.NONE,
        modalData: null,
      })
    );
  };

  return (
    <Container>
      <Title>{t('Welcome World History Encyclopedia Guest')}</Title>

      <Bar width={100} height={3} color="#000" />

      <Message>
        <Trans
          // @ts-ignore
          count={discountRate}
        >
          {
            'Enjoy your exclusive {{count}}% discount on all tours when you check out!'
          }
        </Trans>
      </Message>

      <Button width="200px" uppercase boldText onClick={handleClick}>
        <Trans
          // @ts-ignore
          count={discountRate}
        >
          {'Get {{count}}% off'}
        </Trans>
      </Button>
    </Container>
  );
};

export default WHEPromoCode;

const Bar = styled.div<{ width: number; height: number; color: string }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  background-color: ${({ color }) => color};
`;

const Message = styled.p`
  margin: 30px auto;
`;

const Container = styled.div`
  padding: 40px 32px 20px;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  user-select: none;
`;

const Title = styled.h2`
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 20px;
`;
