import React from 'react';
import styled from '../style/styled';
import { captureButtonPress } from '../features/analytics';

interface Props {
  handleCheckChange: (checked: boolean) => void;
  selected: boolean;
  toggleSwitchName: string;
}

// TODO: write tests
export const ToggleSwitch: React.FC<Props> = ({
  toggleSwitchName,
  selected,
  handleCheckChange,
}) => {
  const onClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    captureButtonPress({
      page: window.location.pathname,
      buttonName: toggleSwitchName,
    });

    handleCheckChange(!selected);
  };

  return (
    <Switch>
      <input type="checkbox" checked={selected} onClick={onClick} />
      <Slider />
    </Switch>
  );
};

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} {
      background-color: #2196f3;
    }

    &:focus + ${Slider} {
      box-shadow: 0 0 1px #2196f3;
    }

    /* stylelint-disable selector-type-no-unknown */
    &:checked + ${Slider}:before {
      /* stylelint-enable selector-type-no-unknown */
      transform: translateX(13px);
    }
  }
`;
