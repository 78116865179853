import { IEnv } from '../../features/whitelabeling/branding/types';
import packageJson from '../../../package.json';
import {
  isIOS,
  isIOS13,
  isIPod13,
  isIPhone13,
  isIPad13,
  isMobileSafari,
  isAndroid,
  isMobile,
  isTablet,
  isMobileOnly,
  isDesktop,
} from 'react-device-detect';
import { getAppID } from './getAppID';
import { APP_ID } from './enums';
import { DEFAULT_APP_CONFIGURATION } from '../../features/whitelabeling/branding/consts';

enum Environment {
  DEV = 'dev',
  ALPHA = 'alpha',
  BETA = 'beta',
  RTM = 'rtm',
}

const isDevelopment = process.env.NODE_ENV === 'development';

// const mobileReg = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
// const tabletReg = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i;
// const iPhoneReg = /iPhone/i;
// const iPadReg = /iPad/i;

// const isTablet = tabletReg.test(navigator.userAgent);
// const isIPhone = iPhoneReg.test(navigator.userAgent);
// const isIPad = iPadReg.test(navigator.userAgent);
// const isMobileCustom = mobileReg.test(navigator.userAgent);

const useServiceWorker = 'serviceWorker' in navigator && true;
const environment = (process.env.REACT_APP_ENVIRONMENT ||
  Environment.DEV) as Environment;
const githash = process.env.REACT_APP_SENTRY_RELEASE || '00000000';
const isStockApp = Boolean(process.env.REACT_APP_STOCK);
const isAK = Boolean(process.env.REACT_APP_AK);
const isAO = Boolean(process.env.REACT_APP_AO);

const isRTM = environment === Environment.RTM;
const isBeta = environment === Environment.BETA;
const isAlpha = environment === Environment.ALPHA;
const isStaging = isBeta || isAlpha || isDevelopment;

const usePaypalSandbox = !isRTM;

const { appID } = getAppID(isStockApp, isRTM, isAK, isAO);

const isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
  (navigator && navigator.userAgent) || ''
);

const isLocalHost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

let apiURI = 'https://janus.lithodomos.com/graphql';

// guard against mistakenly pushing a build with incorrect API
if (!isRTM) {
  apiURI = 'https://test.lithodomos.com/graphql';
}

if (isDevelopment) {
  apiURI = 'https://test.lithodomos.com/graphql';
}

export type Env = {
  isBot: boolean;
  isDevelopment: boolean;
  isProduction: boolean;
  isIPhone: boolean;
  isIPad: boolean;
  isTablet: boolean;
  isMobile: boolean;
  API_URI: string;
  IS_STOCK: boolean;
  IS_AK: boolean;
  IS_AO: boolean;
  APP_ID: string;
  APP_VERSION: string;
  GOOGLE_MAPS_API_KEY: 'AIzaSyByuN9H0H55DSkogLlOcZBxTcU2QaYzsfw';
  REDUX_PERSIST_KEY: 'lvr-viewer-redux';
  REDUX_PERSIST_VERSION: 1;
  REDUX_PERSIST_USE_INDEXEDDB: boolean;
  REDUX_PERSIST_ENABLE_DEBUGGING: boolean;
  APOLLO_PERSIST_KEY: 'lvr-viewer-apollo';
  APOLLO_PERSIST_VERSION: '3';
  APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY: 'lvr-viewer-persist-version';
  ENABLE_APOLLO_PERSIST: boolean;
  APOLLO_PERSIST_ENABLE_DEBUGGING: boolean;
  FACEBOOK_APP_ID: '346161116681579' | '519586019448602';
  GOOGLE_CLIENT_ID:
    | '813573413130-3bbeib70ifkd3nge0t386a5mcg4ru9l5.apps.googleusercontent.com'
    | '1020876081065-fibtfmjmf7rrk2c9bqvrap7o7m0lsmfq.apps.googleusercontent.com';
  PAYPAL_CLIENT_ID:
    | 'AbhfpY7XefYH2Qq_by4yQmu6xk5Kv0jrB8JZtlZybTSarrrPuwRXVX2nvS4ToJnaZZUCFUYxJ3QqDLmG'
    | 'AcJjtf357Xyw5qY1cf0p9IqiYvBNr6eZ-Bg3qJC4QSr9EgdtLBYD64XybhkJVyzbDgDg7JYYy0vzEhT3';
  GOOGLE_ANALYTICS_TRACKING_ID_AK_AO_RTM: 'UA-82330519-2';
  GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_WORLD_RTM: 'UA-82330519-3';
  GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_WORLD_ALPHA_AND_BETA: 'UA-82330519-4';
  CAN_USE_AUTH_MODALS: boolean;
  RECAPTCHA_SITE_KEY: '6Lfue_kUAAAAAM_5uuc4Yfx-zg2xZ9vgUCkaGaET';
  ENABLE_SERVICE_WORKER: boolean;
  SENTRY_DSN:
    | 'https://0da606c4577848eaac30ef20db66305a@o95174.ingest.sentry.io/1771627'
    | 'https://09b215cff5704649a15c181f80e0d845@o95174.ingest.sentry.io/5737466';
  LOG_ROCKET_ID: 'ax2r62/ancient-world' | 'ax2r62/ancientworld-whitelabel';
  IS_LOCAL_HOST: boolean;
  REACT_APP_STRIPE_PUBLIC_KEY: string;
  ENVIRONMENT: Environment;
  GIT_HASH: string;
  IS_RTM: boolean;
  IS_BETA: boolean;
  IS_ALPHA: boolean;
  IS_STAGING_SITE: boolean;
  CONFIG_ENABLE_ORIENTATION: boolean;
  CONFIG_ENABLE_SPLITSCREEN: boolean;
  USE_PAYPAL_SANDBOX: boolean;
  BYPASS_CRAWLER_PROTECTION: boolean;
  BYPASS_COOKIE_DISCLAIMER: boolean;
  CAPTURE_EVENTS_IN_GOOGLE_ANALYTICS: boolean;
  CAPTURE_EVENTS_IN_LVR_API: boolean;
  ENABLE_SENTRY_REPORTING: boolean;
  ENABLE_LOG_ROCKET_REPORTING: boolean;
  ENABLE_DEV_MENU_ITEM: boolean;
  ENABLE_MOBILE_DEBUG: boolean;
  ENABLE_AUTO_ROTATE_TOGGLE_BUTTON: boolean;
  ENABLE_GYRO_TOGGLE_BUTTON: boolean;
  ENABLE_GUIDE_INFO_TOGGLE_BUTTON: boolean;
  ENABLE_GPS_FILTERING_FOR_GOOGLE_MAPS: boolean;
  ENABLE_NEW_IMAGE_VIEWER_TOUCH: boolean;
  ENABLE_ITINERARY_INTRO_NARRATIONS: boolean;
  ENABLE_RECOMMENDATIONS_ON_ITINERARY_PAGE: boolean;
  ENABLE_ITINERARY_PAGES: boolean;
  ENABLE_PLAYLIST_FEATURES_BASED_MAPS_ON_THE_ITINERARY: boolean;
  ENABLE_I18N: boolean;
  ENABLE_GUEST_LOGIN: boolean;
  ENABLE_REVIEWS: boolean;
  APP_ENV: IEnv;
  // GO_EXP_ID_FOR_NEW_BANNER_TEXT: string;
  // GO_EXP_ID_FOR_HIDING_I18N_FOR_ITALIAN_USERS: string;
  // GO_EXP_ID_FOR_NEW_HERO: string;
  GO_EXP_ID_FOR_LIBRARY_PAGE: string;
  GO_EXP_JUST_TEST: string;
  // GO_EXP_ID_FOR_GUEST_CHECKOUTS_ON_STOCK: string;
  ENABLE_NEW_GOOGLE_AUTH: boolean;
  ENABLE_PLAYLIST_BUNDLES: boolean;
  ENABLE_SERVICE_WORKER_BACKGROUND_CACHING: boolean;
  ENABLE_INTOUR_MAP_HEADING_INDICATOR: boolean;
  ENABLE_INTOUR_IMAGE_ZOOMING: boolean;
  ENABLE_OFFLINE_REDEMPTIONS: boolean;
  ENABLE_MARKETING_HOME_SCREEN: boolean;
  ENABLE_NATIVE_AUDIO_PLAYER: boolean;
};

export let env: Env = {
  // detect crawlers
  isBot,
  isDevelopment,
  isProduction: !isDevelopment,
  isIPhone: !isTablet && isIOS,
  isIPad: isIPad13 || (isTablet && isIOS),
  isTablet: isIPad13 || isTablet,
  isMobile: isMobileOnly,
  API_URI: apiURI,
  IS_STOCK: isStockApp,
  IS_AK: isAK,
  IS_AO: isAO,
  APP_ID: appID,
  APP_VERSION: packageJson.version,
  GOOGLE_MAPS_API_KEY: 'AIzaSyByuN9H0H55DSkogLlOcZBxTcU2QaYzsfw',
  REDUX_PERSIST_KEY: 'lvr-viewer-redux',
  REDUX_PERSIST_VERSION: 1,
  REDUX_PERSIST_ENABLE_DEBUGGING: !isRTM,
  REDUX_PERSIST_USE_INDEXEDDB: true,
  APOLLO_PERSIST_KEY: 'lvr-viewer-apollo',
  APOLLO_PERSIST_VERSION: '3',
  APOLLO_PERSIST_VERSION_LOCAL_STORAGE_KEY: 'lvr-viewer-persist-version',
  // react-snap condition (capture for all cases other than production && localhost)
  ENABLE_APOLLO_PERSIST: !(isRTM && isLocalHost),
  APOLLO_PERSIST_ENABLE_DEBUGGING: !isRTM,
  FACEBOOK_APP_ID: isStockApp ? '346161116681579' : '519586019448602',
  GOOGLE_CLIENT_ID: isStockApp
    ? '813573413130-3bbeib70ifkd3nge0t386a5mcg4ru9l5.apps.googleusercontent.com'
    : '1020876081065-fibtfmjmf7rrk2c9bqvrap7o7m0lsmfq.apps.googleusercontent.com',
  PAYPAL_CLIENT_ID: !usePaypalSandbox
    ? // live
      'AbhfpY7XefYH2Qq_by4yQmu6xk5Kv0jrB8JZtlZybTSarrrPuwRXVX2nvS4ToJnaZZUCFUYxJ3QqDLmG'
    : // sandbox
      'AcJjtf357Xyw5qY1cf0p9IqiYvBNr6eZ-Bg3qJC4QSr9EgdtLBYD64XybhkJVyzbDgDg7JYYy0vzEhT3',
  GOOGLE_ANALYTICS_TRACKING_ID_AK_AO_RTM: 'UA-82330519-2',
  GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_WORLD_RTM: 'UA-82330519-3',
  GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_WORLD_ALPHA_AND_BETA: 'UA-82330519-4',
  CAN_USE_AUTH_MODALS: !(
    isIOS ||
    isIOS13 ||
    isIPod13 ||
    isIPhone13 ||
    isIPad13 ||
    isMobileSafari ||
    isAndroid ||
    isMobile
  ),
  RECAPTCHA_SITE_KEY: '6Lfue_kUAAAAAM_5uuc4Yfx-zg2xZ9vgUCkaGaET',
  ENABLE_SERVICE_WORKER: useServiceWorker,
  SENTRY_DSN: isStockApp
    ? 'https://0da606c4577848eaac30ef20db66305a@o95174.ingest.sentry.io/1771627'
    : 'https://09b215cff5704649a15c181f80e0d845@o95174.ingest.sentry.io/5737466',
  LOG_ROCKET_ID: isStockApp
    ? 'ax2r62/ancient-world'
    : 'ax2r62/ancientworld-whitelabel',
  IS_LOCAL_HOST: isLocalHost,
  ENVIRONMENT: environment,
  GIT_HASH: githash,
  IS_RTM: isRTM,
  IS_BETA: isBeta,
  IS_ALPHA: isAlpha,
  IS_STAGING_SITE: isStaging,
  REACT_APP_STRIPE_PUBLIC_KEY: isRTM
    ? 'pk_live_ba1ciEBcnkUp9ib0MQ20AhcL'
    : 'pk_test_P33Ga0q5GJewuWAUhg027s3o',
  // config
  // TODO: improve compass orientation support
  // isMobile && (isSafari || (!isIOS && (isChrome || isChromium))),
  CONFIG_ENABLE_ORIENTATION: isMobile,
  CONFIG_ENABLE_SPLITSCREEN: isMobile || isDesktop,
  USE_PAYPAL_SANDBOX: usePaypalSandbox,
  // we do not want bots indexing the alpha and beta sites
  BYPASS_CRAWLER_PROTECTION: !(isAlpha || isBeta),
  // react-snap condition (show for every case other than when prerendering)
  BYPASS_COOKIE_DISCLAIMER:
    // prerendering
    (isRTM && isLocalHost) ||
    isAlpha ||
    // a hack for now - move this to app env
    appID === APP_ID.NOT_FAIR ||
    appID === APP_ID.NOT_FAIR_STAGING,
  // react-snap condition (capture for all cases other than (production && localhost))
  CAPTURE_EVENTS_IN_GOOGLE_ANALYTICS: !(!isDevelopment && isLocalHost),
  // same as Google Analytics + !isBot
  CAPTURE_EVENTS_IN_LVR_API: isRTM && !isDevelopment && !isLocalHost && !isBot,
  // in all production builds other than prerendering
  ENABLE_SENTRY_REPORTING: !isLocalHost && !isDevelopment,
  // in RTM production builds other than prerendering
  ENABLE_LOG_ROCKET_REPORTING: isRTM && !isLocalHost && !isDevelopment,
  // all non RTM (includes prerendering as well)
  ENABLE_DEV_MENU_ITEM: !isRTM,
  // mobile debugging is commented out normally to keep the bundle site smaller. this check is only there to disable it if we enabled it by mistake.
  ENABLE_MOBILE_DEBUG: isMobile && (isDevelopment || isAlpha),

  // tour features
  ENABLE_AUTO_ROTATE_TOGGLE_BUTTON: !isMobileOnly,
  ENABLE_GYRO_TOGGLE_BUTTON: isMobile,
  ENABLE_GUIDE_INFO_TOGGLE_BUTTON: !isMobileOnly,
  ENABLE_GPS_FILTERING_FOR_GOOGLE_MAPS: !isDevelopment,
  ENABLE_NEW_IMAGE_VIEWER_TOUCH: true,

  // app features
  // TODO: [LVR-2465] As a developer, I should be able to configure the app features from the backend so that I do not have to do a release to apply them.
  // reverted 2021-04-27 because it negatively affected bounce rate
  ENABLE_ITINERARY_INTRO_NARRATIONS: false,
  // reverted 2021-04-27 because it didn't make a difference
  ENABLE_RECOMMENDATIONS_ON_ITINERARY_PAGE: isStockApp && !isAK && !isAO,
  ENABLE_ITINERARY_PAGES: true,
  ENABLE_PLAYLIST_FEATURES_BASED_MAPS_ON_THE_ITINERARY: true,
  ENABLE_I18N: true,
  ENABLE_GUEST_LOGIN: true,
  ENABLE_NEW_GOOGLE_AUTH: true,
  ENABLE_REVIEWS: true,
  ENABLE_PLAYLIST_BUNDLES: !isStockApp,
  ENABLE_SERVICE_WORKER_BACKGROUND_CACHING: isStaging,
  ENABLE_INTOUR_MAP_HEADING_INDICATOR: true,
  ENABLE_INTOUR_IMAGE_ZOOMING: isStaging,
  ENABLE_OFFLINE_REDEMPTIONS: true,
  ENABLE_MARKETING_HOME_SCREEN: false,
  ENABLE_NATIVE_AUDIO_PLAYER: true,

  // This gets mutated when whitelabel settings are loaded. Maybe not the best way to do it, but it works.
  APP_ENV: DEFAULT_APP_CONFIGURATION.settings,

  GO_EXP_ID_FOR_LIBRARY_PAGE: isRTM
    ? // this needs a new experiment.
      's-AiHtq9SAm0r2cd0CC2Kg'
    : 'F5LRChXyQqagIJ4hFYwHlQ',
  GO_EXP_JUST_TEST: 'ntmmMkmVQTSR8nrTWZpZdQ',
};

// @ts-expect-error
window.APP_ENV = env.APP_ENV;

// @ts-expect-error
window.lvr = { env };
