import { RootState } from '../redux/types';
import { ModalType } from './types';

export function selectModalType(state: RootState): ModalType {
  return state.modals.modalType;
}

export function selectModalData(state: RootState): any {
  return state.modals.modalData;
}

export function selectIsModalOpen(state: RootState): boolean {
  return selectModalType(state) !== ModalType.NONE;
}
