import { matchPath } from 'react-router-dom';
import { ROUTES } from './../../Routes/routes';
import { getStoredAppID } from '../../utils/getStoredAppID';
import { APP_ID } from './enums';

export function getAppID(
  stock: boolean,
  rtm: boolean,
  isAK?: boolean,
  isAO?: boolean
): {
  isNew: boolean;
  appID: string;
} {
  const storedAppID = getStoredAppID();

  let newAppID = '';

  if (stock && rtm && isAK) {
    newAppID = APP_ID.ABERCROMBIEKENT;
  } else if (stock && rtm && isAO) {
    newAppID = APP_ID.ANCIENT_ORIGINS;
  } else if (stock && rtm) {
    newAppID = APP_ID.ANCIENT_WORLD;
  } else if (stock && !rtm) {
    newAppID = APP_ID.ANCIENT_WORLD_STAGING;
  }

  // treat the first segment of the path as the appID
  // eg: guide.lithodomos.com/hellenicmuseum/xxxx => hellenicmuseum is the appID
  else if (window.location.pathname !== '/') {
    // match with internal routes
    const matched = Object.values(ROUTES)
      .map((route) =>
        matchPath(window.location.pathname, { path: route, exact: true })
      )
      .filter(Boolean);

    // if not match, return appID
    if (matched.length === 0) {
      newAppID = window.location.pathname.split('/').filter(Boolean)[0];
    }
  }

  // if the appID isn't provided, check if there is a stored appID.
  // return it if available, so that for subsequent visits, you don't need to specify the appID in the URL.
  // eg: if the user first visited guide.lithodomos.com/hellenicmuseum => next time, it will load the hellenic museum config
  // even if the user visits guide.lithodomos.com.
  if (!newAppID && storedAppID) {
    newAppID = storedAppID;
  }

  return {
    isNew: !!(storedAppID && newAppID !== storedAppID),
    appID: newAppID,
  };
}
