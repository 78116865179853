import decodeJWT from 'jwt-decode';
import TrialTourEndPopup from '../App/components/Modals/TrialTourEndPopup';

type DecodedJWT = {
  d: string;
  exp: number;
  iat: number;
  iss: string;
  r: Array<string>;
  u: string;
};

const isTokenExpired = (token: string | null | undefined) => {
  if (!token) {
    return TrialTourEndPopup;
  }

  try {
    const { exp } = decodeJWT<DecodedJWT>(token);

    if (typeof exp !== 'number') {
      return true;
    }

    return exp * 1000 <= Date.now();
  } catch (e) {}

  return true;
};

export default isTokenExpired;
