import { Button } from './Button';
import { captureButtonPress } from '../features/analytics';

interface Props {
  context: string;
  handleClick?: (token: string | null) => any;
  children: any;
  disabled: boolean;
  buttonName: string;
  uppercase?: boolean;
  highlight?: boolean;
}

export const AuthButtonWithRecaptcha3 = ({
  children,
  context,
  handleClick,
  buttonName,
  ...rest
}: Props) => {
  const clickHandler = async () => {
    captureButtonPress({
      buttonName,
      page: window.location.pathname,
    });

    if (handleClick) {
      handleClick(null);
    }
  };

  return (
    <Button type="submit" onClick={clickHandler} {...rest}>
      {children}
    </Button>
  );
};
