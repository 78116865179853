import Cookies from 'js-cookie';
import { APP_ID_KEY } from '../App/config/enums';

// stores the whitelabel appID in localstorage or cookies
// this probably should be in state
export function storeAppID(appID: string) {
  if (window.localStorage) {
    localStorage.setItem(APP_ID_KEY, appID);
  } else {
    Cookies.set(APP_ID_KEY, appID);
  }
}
