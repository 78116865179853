import { useRef, useState, useEffect, useCallback } from 'react';
import memoizeOne from 'memoize-one';
import styled, { useTheme } from '../../style/styled';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { Z_INDEX } from '../../style/Z_INDEX';
import {
  selectHasCartItems,
  selectIsAuthenticated,
  selectIsGuestUser,
} from '../../store/app/selectors';
import { DevButton } from './DevButton';
import { MobileMenu } from './MobileMenu';
import { AppLogoAndText } from './AppLogoAndText';
import { nonNullable } from '../../utils/nonNullable';
import { ToursLibraryLink } from './ToursLibraryLink';
import { LanguageSelector } from './LanguageSelector';
import { NAVBAR_HEIGHT } from '../../consts';
import ProductLink from './ProductLink';
import OurWorkLink from './OurWorkLink';
import AboutUsLink from './AboutUsLink';
import GetInTouchLink from './GetInTouchLink';
import { ROUTES } from '../../Routes/routes';
import { env } from '../../App/config/env';
import { SignOutLink } from './SignOutLink';
import { ConvertGuestAccountLink } from './ConvertGuestAccountLink';
import { ActivateProductButton } from './ActivateProductButton';
import { SignInLink } from './SignInLink';
import { MyTripsButton } from './MyTripsButton';
import { CartButton } from './CartButton';

const AppHeaderMarketing = () => {
  const isAuthed = useSelector(selectIsAuthenticated);
  const isGuestUser = useSelector(selectIsGuestUser);
  const hasCartItems = useSelector(selectHasCartItems);

  const location = useLocation();
  const theme = useTheme();
  const headerRef = useRef<HTMLHeadElement>(null);
  const [atTop, setAtTop] = useState<boolean>(window.scrollY <= 0);

  const { pathname } = location;
  const color = theme.palette.appHeader.color;
  const bgColor = theme.palette.appHeader.background;
  const nonMenuCommonProps = { color, size: HEADER_BUTTON_SIZE };
  const inMenuCommonProps = { ...nonMenuCommonProps, fullWidth: true };

  const updateAtTop = useCallback(() => {
    const header = headerRef.current;

    if (header) {
      const isAtTop = window.scrollY < header.offsetTop + header.offsetHeight;

      if (isAtTop !== atTop) {
        setAtTop(isAtTop);
      }
    }
  }, [atTop]);

  // Scroll event listener.
  // Updates whether we've scrolled to the top or not, to be used for toggling the box shadow of the header.
  useEffect(() => {
    window.addEventListener('scroll', updateAtTop);

    return () => {
      window.removeEventListener('scroll', updateAtTop);
    };
  }, [updateAtTop]);

  const {
    library,
    convertGuestAccount,
    signOut,
    dev,
    signIn,
    redeem,
    myTrips,
  } = getButtonVisibilityMemoized(
    pathname,
    isAuthed,
    isGuestUser,
    hasCartItems
  );

  const menuItems = [
    signIn ? (
      <MenuItem key="signIn" bgColor={bgColor}>
        <SignInLink {...inMenuCommonProps} highlight={false} showTextInMobile />
      </MenuItem>
    ) : null,

    redeem ? (
      <MenuItem key="redeem" bgColor={bgColor}>
        <ActivateProductButton {...inMenuCommonProps} showTextInMobile />
      </MenuItem>
    ) : null,

    myTrips ? (
      <MenuItem key="myTrips" bgColor={bgColor}>
        <MyTripsButton {...inMenuCommonProps} showTextInMobile />
      </MenuItem>
    ) : null,

    library ? (
      <MenuItem key="toursLibrary" bgColor={bgColor}>
        <ToursLibraryLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    convertGuestAccount && !env.IS_AO ? (
      <MenuItem key="convertGuestAccount" bgColor={bgColor}>
        <ConvertGuestAccountLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    signOut && !env.IS_AO ? (
      <MenuItem key="signOut" bgColor={bgColor}>
        <SignOutLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    dev ? (
      <MenuItem key="dev" bgColor={bgColor}>
        <DevButton {...inMenuCommonProps} fullWidth />
      </MenuItem>
    ) : null,
  ].filter(nonNullable);

  const backgroundColor = '#000';

  return (
    <AppHeaderContainer
      ref={headerRef}
      id="header"
      bgColor={backgroundColor}
      atTop={atTop}
    >
      <NavBar>
        <Container>
          <AppLogoAndText size={18} />
          <ProductLink />
          <OurWorkLink />
          <AboutUsLink />
        </Container>

        <Container>
          <GetInTouckLinkContainer>
            <GetInTouchLink />
          </GetInTouckLinkContainer>

          <LanguageSelectorContainer>
            <LanguageSelector />
          </LanguageSelectorContainer>

          {hasCartItems && (
            <CartButton showTextInMobile={false} showTextOnDesktop={false} />
          )}

          {menuItems.length > 0 && (
            <MobileMenu isAuthed={isAuthed} items={menuItems} />
          )}
        </Container>
      </NavBar>
    </AppHeaderContainer>
  );
};

export default AppHeaderMarketing;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GetInTouckLinkContainer = styled.div`
  & > div {
    margin-right: 0;
  }
`;

const LanguageSelectorContainer = styled.div`
  & > div {
    margin: 0;
  }
`;

const AppHeaderContainer = styled.header.attrs<{
  atTop: boolean;
}>(({ atTop }) => ({
  style: {
    boxShadow: atTop ? 'none' : '0px 1px 20px 0px #000000',
  },
}))<{
  bgColor: string;
  atTop: boolean;
}>`
  background: ${({ bgColor }) => bgColor};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: ${Z_INDEX.HEADER};
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${NAVBAR_HEIGHT}px;
  padding: 10px;
  width: 100%;
`;

const getButtonVisibilityMemoized = memoizeOne(getButtonVisibility);

function getButtonVisibility(
  pathname: string,
  isAuthed: boolean,
  isGuestUser: boolean,
  hasCartItems: boolean
) {
  return {
    library: true,
    languageSelector: true,
    dev: env.ENABLE_DEV_MENU_ITEM,
    version: env.ENABLE_DEV_MENU_ITEM,
    myTrips: isAuthed,
    signOut: isAuthed,
    redeem: pathname !== ROUTES.tour && !pathname.startsWith(ROUTES.auth),
    signIn: !isAuthed && pathname !== ROUTES.authLogin,
    convertGuestAccount: isAuthed && isGuestUser,
    cart: hasCartItems,
  };
}

const MenuItem = styled.div<{ nonInteractive?: boolean; bgColor: string }>`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 10px;

  svg {
    transition: transform 0.2s;
  }

  p {
    transition: transform 0.2s;
  }
`;
