import {
  SCREEN_WIDTH_BREAK_MEDIUM,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import styled from '../../style/styled';

export const Stack = styled.div<{
  gutter?: number;
  gutterTablet?: number;
  gutterMobile?: number;
}>`
  > * {
    margin: ${({ gutter }) => gutter ?? 10}px 0;

    @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
      margin: ${({ gutter, gutterTablet }) => gutterTablet ?? gutter ?? 10}px 0;
    }

    @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
      margin: ${({ gutter, gutterMobile, gutterTablet }) =>
          gutterMobile ?? gutterTablet ?? gutter ?? 10}px
        0;
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
