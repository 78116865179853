import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../style/styled';
import {
  SCREEN_WIDTH_BREAK_SMALL,
  HEADER_BUTTON_SIZE,
} from '../../style/SIZES';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../Routes/routes';
import { captureButtonPress } from '../../features/analytics';
import { getLogo } from '../../features/whitelabeling/branding/utils/getLogo';
import { COLORS } from '../../style/colors';
import { env } from '../../App/config/env';

interface Props {
  size?: number;
  color?: string;
}

export const AppLogoAndText: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const size = props.size || HEADER_BUTTON_SIZE;
  const color = props.color || COLORS.lightTextColor;
  const invert = color === '#000' ? 1 : 0;
  const { url, text } = getLogo();

  const captureAnalytics = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'AncientWorldLogo',
    });
  };

  let logo = (
    <AncientWorldLogoImage
      id="logo"
      alt="AW Logo"
      src={url}
      size={size}
      invert={invert}
    />
  );

  if (env.IS_AK) {
    logo = (
      <AncientWorldLogoImage
        id="logo"
        alt="AK Logo"
        src="https://gaia.lithodomos.com/e7d8fb1ec6e697d67110e1197eae630c.png"
        size={10}
        invert={invert}
      />
    );
  } else if (env.IS_AO) {
    logo = (
      <AncientWorldLogoImage
        id="logo"
        alt="AK Logo"
        src="https://gaia.lithodomos.com/b7e42314204e5d3628d4dc5fa517c02f.png"
        size={20}
        invert={invert}
      />
    );
  }

  return (
    <Link to={ROUTES.index}>
      <AncientWorldLogoContainer onClick={captureAnalytics}>
        {logo}

        {env.APP_ENV.header.showAppNameInHeader && (
          <AncientWorldLogText size={size} color={color}>
            {t(text)}
          </AncientWorldLogText>
        )}
      </AncientWorldLogoContainer>
    </Link>
  );
};

const AncientWorldLogoContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
  user-select: none;
`;

const AncientWorldLogText = styled.p<{ size: number; color: string }>`
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-size: ${(props) => props.size}px;
  margin-left: 10px;
  user-select: none;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;

const AncientWorldLogoImage = styled.img<{ size: number; invert: number }>`
  filter: invert(${(props) => props.invert});
  height: ${(props) => props.size / 0.6}px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    height: ${(props) => props.size}px;
  }
`;
