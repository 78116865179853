import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../../store/app/selectors';

const useAuthing = () => {
  // Wonder why we needed this convoluted logic (i.e. involvement of viewpointIDToLaunchPostActivation) to check if we are authenticated.
  const authed = useSelector(selectIsAuthenticated);
  // this is how we show the auth modal. ModalContent.NONE the non-auth modals are shown.
  // in this case, it means, it will show the activate modal.
  const [authing, setAuthing] = useState<boolean>(!authed);

  // This is required for some reason.
  // When coming from Facebook Redirect page,
  // authed is false at the beginning, and then authed becomes true,
  // but modalContent doesn't change.
  useEffect(() => {
    if (authed) {
      setAuthing(false);
    }
  }, [authed]);

  return { authing, setAuthing };
};

export default useAuthing;
