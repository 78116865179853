import styled from '../../../../style/styled';
import {
  SCREEN_WIDTH_BREAK_EXTRA_SMALL,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../../../style/SIZES';
import { env } from '../../../config/env';
import { RatingAndReview } from './RatingAndReview';
import { Shop } from './Shop';
import { Share } from './Share';

interface Props {
  tourID: string;
  tourInternalReference: string;
  tourName: string;
}

// show a popup at the end of a tour
// to rate, shop redbubble products and share
const TourEndPopup = ({ tourID, tourName, tourInternalReference }: Props) => {
  return (
    <Container>
      <Layout>
        <RatingAndReview tourID={tourID} />

        {env.IS_STOCK && (
          <Share
            tourName={tourName}
            tourInternalReference={tourInternalReference}
          />
        )}

        {env.IS_STOCK && <Shop />}
      </Layout>
    </Container>
  );
};

export default TourEndPopup;

const Layout = styled.div`
  display: grid;
  grid-gap: 64px;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_EXTRA_SMALL}px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }
`;

const Container = styled.div`
  user-select: none;
  padding: 20px;
`;
