import {
  deviceType,
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import { logMessage } from '../features/logging/logMessage';

export default async function generateDeviceFingerprint() {
  //   const fp = await FingerprintJS.load();
  //   const { visitorId, confidence } = await fp.get();
  //   return visitorId;

  const data = [
    mobileVendor || 'unknown',
    mobileModel || 'unknown',
    deviceType || 'unknown',
    osName || 'unknown',
    osVersion || 'unknown',
    `${window.screen.height}`,
    `${window.screen.width}`,
    // Was different on Chrome and Brave
    // (navigator.languages || []).join(','),
  ];

  logMessage('Device fingerprint', data.join(','));

  return data.join(',');
}
