import React, { useCallback } from 'react';
import { NavLink, LinkProps } from 'react-router-dom';
import { captureButtonPress } from '../features/analytics';

interface Props extends LinkProps {
  children: any;
  buttonName: string;
  onMouseOver?: () => void;
}

// TODO: [AW-752] there is an issue with iOS where you need to double click a link to activate :S
// TODO: write tests
export default function NavLinkWithCapture({
  children,
  buttonName,
  onMouseOver = () => {},
  ...linkProps
}: Props) {
  const onClick = useCallback(() => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName,
    });
  }, [buttonName]);

  return (
    <NavLink
      onClick={onClick}
      onMouseOver={onMouseOver}
      activeClassName="navlinkSelected"
      {...linkProps}
    >
      {children}
    </NavLink>
  );
}
