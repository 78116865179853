import { handleActions } from 'redux-actions';
import {
  ACTION_TYPES,
  DownloadCompleteAction,
  RemoveAssetAction,
  RemovePlaylistAction,
  UpdatePlaylistStatsAction,
  SetQueueStatusAction,
  DownloadPlaylistAction,
  RemoveDeprecatedFilesAction,
} from './actions';
import {
  OfflineState,
  initialState,
  JobStatus,
  Playlist,
  Asset,
} from './types';

export const reducer = handleActions<OfflineState, any>(
  {
    [ACTION_TYPES.DOWNLOAD_COMPLETE]: (
      state: OfflineState,
      action: DownloadCompleteAction
    ): OfflineState => {
      const { playlistID, playlistName, asset, automatic } = action.payload;
      const { playlists, files, assets } = state;

      // find the playlist stats in state
      const playlist = playlists.find((playlist) => playlist.id === playlistID);

      // get the file state
      const file = files[asset.id];
      const ownedByPlaylistIDs = file?.playlistIDs || [];
      const playlistOwnsAsset = Boolean(
        playlist && ownedByPlaylistIDs.find((id) => id === playlistID)
      );

      const updatedPlaylistStats: Playlist = {
        jobStatus: JobStatus.DOWNLOADING,
        // fallback defaults
        id: playlistID,
        name: playlistName,
        totalSize: 0,
        cachedSize: 0,
        automatic,
        // technically this should be there already, because of the DOWNLOAD_PLAYLIST_METADATA action
        ...playlist,
      };

      if (!playlistOwnsAsset) {
        updatedPlaylistStats.cachedSize =
          updatedPlaylistStats.cachedSize + asset.contentLength;
      }

      const playlistsWithCachedSize = !playlist
        ? playlists
        : playlists
            // remove current playlist
            .filter((p) => p.id !== playlistID)
            // add it to the end, and make sure it is a new object
            .concat(updatedPlaylistStats);

      const updatedAssetWithPlaylists: Asset = {
        ...asset,
        playlistIDs: [...new Set(ownedByPlaylistIDs.concat(playlistID))],
      };

      const updatedFiles = {
        ...files,
        [asset.id]: updatedAssetWithPlaylists,
      };

      // remove entry from assets
      const netAssets = assets.filter(({ id }) => id !== asset.id);

      return {
        ...state,
        assets: netAssets,
        files: updatedFiles,
        playlists: playlistsWithCachedSize,
      };
    },

    [ACTION_TYPES.REMOVE_ASSET]: (
      state: OfflineState,
      action: RemoveAssetAction
    ): OfflineState => {
      const { asset } = action.payload;
      const files = { ...state.files };

      // this is safe to do because we only delete if it belongs to the playlist we are deleting
      delete files[asset.id];

      // remove entry from old assets
      const assets = state.assets.filter(({ id }) => id !== asset.id);

      return { ...state, assets, files };
    },

    [ACTION_TYPES.UPDATE_PLAYLIST_STATS]: (
      state: OfflineState,
      action: UpdatePlaylistStatsAction
    ): OfflineState => {
      const {
        playlistID,
        playlistName,
        totalSize,
        cachedSize,
        status,
        automatic,
      } = action.payload;

      // filter out the updated playlist
      let playlists = state.playlists.filter(({ id }) => id !== playlistID);

      // concat the updated playlist
      playlists = playlists.concat({
        id: playlistID,
        name: playlistName,
        totalSize,
        cachedSize,
        jobStatus: status,
        automatic,
      });

      return { ...state, playlists };
    },

    [ACTION_TYPES.REMOVE_PLAYLIST]: (
      state: OfflineState,
      action: RemovePlaylistAction
    ): OfflineState => {
      const { playlistID } = action.payload;

      const offlinePlaylist = state.playlists.find((p) => p.id === playlistID);

      const files = Object.keys(state.files).reduce((accum, assetID) => {
        const asset = state.files[assetID];

        if (asset) {
          const playlistIDs = asset.playlistIDs.filter(
            (id) => id !== playlistID
          );

          // @ts-ignore
          accum[assetID] = { ...asset, playlistIDs };
        }
        return accum;
      }, {});

      if (offlinePlaylist) {
        return {
          ...state,
          files,
          playlists: state.playlists
            // remove existing
            .filter((p) => p.id !== playlistID)
            // add the updated
            .concat({ ...offlinePlaylist, jobStatus: JobStatus.CLEANING_UP }),
        };
      }

      return state;
    },

    [ACTION_TYPES.DOWNLOAD_PLAYLIST_METADATA]: (
      state: OfflineState,
      action: RemovePlaylistAction
    ): OfflineState => {
      const { playlistID, playlistName, automatic } = action.payload;

      const offlinePlaylist = state.playlists.find((p) => p.id === playlistID);

      const playlists = state.playlists
        .filter((p) => p.id !== playlistID)
        .concat({
          id: playlistID,
          name: playlistName,
          // we don't know the total yet, we will know it when the downloadPlaylist action is dispatched with the assets
          totalSize: -1,
          cachedSize: 0,
          automatic,
          ...offlinePlaylist,
          jobStatus: JobStatus.DOWNLOADING,
        });

      return { ...state, playlists };
    },

    [ACTION_TYPES.DOWNLOAD_PLAYLIST]: (
      state: OfflineState,
      action: DownloadPlaylistAction
    ): OfflineState => {
      const { playlistID, playlistName, assets, automatic } = action.payload;

      const offlinePlaylist = state.playlists.find((p) => p.id === playlistID);

      const totalSize = assets.reduce(
        (accum, asset) => accum + asset.contentLength,
        0
      );

      return {
        ...state,
        playlists: state.playlists
          .filter((p) => p.id !== playlistID)
          .concat({
            id: playlistID,
            name: playlistName,
            cachedSize: 0,
            automatic,
            // cache size will be overridden by this
            ...offlinePlaylist,
            // we need to set the correct total size here
            totalSize,
            jobStatus: JobStatus.DOWNLOADING,
          }),
      };
    },

    [ACTION_TYPES.SET_QUEUE_STATUS]: (
      state: OfflineState,
      action: SetQueueStatusAction
    ): OfflineState => {
      const { status } = action.payload;

      return { ...state, queueStatus: status };
    },

    [ACTION_TYPES.REMOVE_DEPRECATED_FILES]: (
      state: OfflineState,
      action: RemoveDeprecatedFilesAction
    ): OfflineState => {
      const { playlistID, deprecatedAssetIDs } = action.payload;
      const { files, assets } = state;

      // remove playlistID from the assets
      const sanitisedFiles = { ...files };

      let newAssets = [...assets];

      deprecatedAssetIDs.forEach((assetID) => {
        const asset = files[assetID];
        const filteredPlaylistIDs = (asset?.playlistIDs || []).filter(
          (id) => id !== playlistID
        );

        if (filteredPlaylistIDs.length === 0) {
          // no playlist is using this asset, so completely remove it
          delete sanitisedFiles[assetID];
        } else {
          // just update the dependent playlists
          sanitisedFiles[assetID] = {
            ...asset,
            playlistIDs: filteredPlaylistIDs,
          };
        }

        // remove old assets entry
        newAssets = newAssets.filter(({ id }) => id !== assetID);
      });

      return { ...state, assets: newAssets, files: sanitisedFiles };
    },
  },
  initialState
);
