import { gql } from '@apollo/client';

export const PRICE_FRAGMENT = gql`
  fragment PriceFragment on Price {
    AUD
    BRL
    CAD
    CHF
    CNY
    CZK
    DKK
    EUR
    GBP
    HKD
    HUF
    ILS
    JPY
    MXN
    MYR
    NOK
    NZD
    PHP
    PLN
    RUB
    SEK
    SGD
    THB
    TWD
    USD
  }
`;
