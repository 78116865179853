import { handleActions } from 'redux-actions';
import {
  ACTION_TYPES,
  UpdateUserPositionAction,
  SetLocationRequestedAction,
} from './actions';
import { LocationState, initialState } from './types';

export const reducer = handleActions<LocationState, any>(
  {
    [ACTION_TYPES.UPDATE_USER_POSITION]: (
      state: LocationState,
      action: UpdateUserPositionAction
    ): LocationState => {
      return {
        ...state,
        currentLocation: action.payload.currentLocation,
      };
    },

    [ACTION_TYPES.SET_LOCATION_REQUESTED]: (
      state: LocationState,
      { payload }: SetLocationRequestedAction
    ): LocationState => {
      return {
        ...state,
        requested: payload.requested,
        currentLocation: payload.requested ? state.currentLocation : null,
      };
    },
  },
  initialState
);
