import React from 'react';
import styled from '../../../../style/styled';
import { Section } from './styledComponents/Section';
import { TourInfoShareButtons } from './TourInfoShareButtons';
import { TourInfoPrice } from './TourInfoPrice';
import { TourPurchaseState } from '../../../../types';
import { CurrencyCode } from '../../../../graphql/globalTypes';

interface Props {
  currencyCode: CurrencyCode;
  description: string;
  id: string;
  internalReference: string;
  name: string;
  price: number;
  priceInCents: boolean;
  purchaseState: TourPurchaseState;
  tags: string[];
}

export const TourInfoSharingAndPriceSection: React.FC<Props> = ({
  currencyCode,
  description,
  id,
  internalReference,
  name,
  price,
  priceInCents,
  purchaseState,
  tags,
}) => {
  return (
    <Section>
      <ShareAndPriceContainer>
        <TourInfoShareButtons
          name={name}
          internalReference={internalReference}
          description={description}
          tags={tags}
        />

        <TourInfoPrice
          id={id}
          price={price}
          currencyCode={currencyCode}
          priceInCents={priceInCents}
          purchaseState={purchaseState}
        />
      </ShareAndPriceContainer>
    </Section>
  );
};

const ShareAndPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
