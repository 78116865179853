import { v4 as uuidv4 } from 'uuid';
import { env } from '../../App/config/env';
import { getDeviceID } from '../../globals/deviceID';
import { getTimezone } from '../../utils/getTimezone';
import { AnalyticEventInput } from '../../graphql/globalTypes';
import { DataKeys, EventNames } from './types';
import { createOrGetSession } from './createOrGetSession';
import { submitToBackend } from './submitToBackend';
import { tourEnd } from './captureTourAnalytics';
import { captureInGA } from './captureInGA';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { getStore } from '../../store';
import { addToEventQueue } from './eventQueue';

type EventArg = {
  name: EventNames;
  data?: Array<{
    key: DataKeys;
    value: string;
  }>;
};

// this does not seem to work
window.onunload = function () {
  tourEnd();
};

export async function captureEvent({
  name,
  data = [],
}: EventArg): Promise<AnalyticEventInput> {
  const sessionID = createOrGetSession(true);
  const deviceID = getDeviceID();
  const timezone = getTimezone();

  const event: AnalyticEventInput = {
    name,
    data,
    appID: env.APP_ID,
    appVersion: env.APP_VERSION,
    deviceID,
    timestamp: new Date().toISOString(),
    eventID: uuidv4(),
    sessionID,
  };

  if (timezone) {
    event.timezone = timezone;
  }

  // get current location and userID
  const store = getStore();

  if (store) {
    const {
      app: { user },
      location: { currentLocation },
    } = store.getState();

    if (user) {
      event.userID = user.id;
    }

    if (currentLocation?.coords) {
      const { latitude, longitude } = currentLocation.coords;

      if (typeof latitude === 'number' && typeof longitude === 'number') {
        event.location = {
          latitude,
          longitude,
        };
      }
    }
  }

  if (env.CAPTURE_EVENTS_IN_GOOGLE_ANALYTICS) {
    captureInGA(event);
  }

  if (env.CAPTURE_EVENTS_IN_LVR_API) {
    try {
      await submitToBackend([event]);
    } catch (error) {
      addToEventQueue(event);
    }
  } else if (env.isBot) {
    const message = `Bot crawled the site. User agent: ${
      navigator?.userAgent || ''
    }`;

    // capture it in Sentry
    captureInSentry(message);
  }

  return event;
}
