import { gql } from '@apollo/client';
import { I18NSTRING } from '../fragments/I18nString';
import {
  EXPLORE_WEB_ActivateProductMutation,
  EXPLORE_WEB_ActivateProductMutationVariables,
  EXPLORE_WEB_ActivateProductMutation_result_redeemableProducts,
} from './__generated__/EXPLORE_WEB_ActivateProductMutation';

export type Data = EXPLORE_WEB_ActivateProductMutation;
export type Variables = EXPLORE_WEB_ActivateProductMutationVariables;
export type RedeemableProduct = EXPLORE_WEB_ActivateProductMutation_result_redeemableProducts;

export const ACTIVATE_PRODUCT_MUTATION = gql`
  mutation EXPLORE_WEB_ActivateProductMutation($input: ActivateProductInput!) {
    result: activateProduct(input: $input) {
      purchase {
        tour {
          id
          viewpoints {
            id
            internalReference
          }
        }
        id
        product {
          id
          nameI18n {
            ...I18nStringFragment
          }
        }
        expiresAt
      }
      redeemableProducts {
        productSKU
        productID
        productName
      }
      redeemableProductsCount
      error {
        id
        message
        type
      }
    }
  }
  ${I18NSTRING}
`;
