import { env } from '../App/config/env';

declare global {
  interface Window {
    Modernizr: any;
  }
}
export function getDecorationImageURL(): string {
  if (!env.APP_ENV.pageLayout.displayDecorationBanner) {
    return '';
  }

  return window.Modernizr?.webp
    ? '/decoration.webp'
    : '/1c03e0e1deec99ecf5372efe912bb4e9.jpg';
}
