import React from 'react';
import styled from '../../../../../style/styled';
import { MapType } from './types';
import { tourSupportsGoogleMap } from '../../../../../utils/tourSupportsGoogleMap';
import { tourSupportsCustomMap } from '../../../../../utils/tourSupportsCustomMap';
import { PlaylistFeature } from '../../../../../graphql/globalTypes';
import { nonNullable } from '../../../../../utils/nonNullable';
import { captureButtonPress } from '../../../../../features/analytics';
import { TourInfoWhenAndWhereSectionMapSwitcherButton } from './TourInfoWhenAndWhereSectionMapSwitcherButton';

interface Tour {
  features: PlaylistFeature[];
}

interface Props {
  selected: MapType;
  tour: Tour;
  changeMapType: (mapType: MapType) => void;
}

export const TourInfoWhenAndWhereSectionMapSwitcher: React.FC<Props> = ({
  selected,
  tour,
  changeMapType,
}) => {
  const options = getOptions(tour);

  // no options, and just one option means we don't need the switcher to render
  if (options.length < 2) {
    return null;
  }

  return (
    <Container>
      {options.map((o) => (
        <TourInfoWhenAndWhereSectionMapSwitcherButton
          key={o.mapType}
          selected={selected === o.mapType}
          iconURL={o.iconURL}
          selectedIconURL={o.selectedIconURL}
          caption={o.caption}
          onClick={() => {
            captureButtonPress({
              buttonName: `MapType ${o.mapType}`,
              page: window.location.pathname,
            });

            changeMapType(o.mapType);
          }}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
`;

function getOptions(tour: Tour) {
  return [
    tourSupportsGoogleMap(tour)
      ? {
          mapType: MapType.GOOGLE,
          // this needs to be in translations
          caption: 'Google map',
          iconURL: '/icons/google_map_icon_white.png',
          selectedIconURL: '/icons/google_map_icon_black.png',
        }
      : null,
    tourSupportsCustomMap(tour)
      ? {
          mapType: MapType.CUSTOM,
          // this needs to be in translations
          caption: 'Site map',
          iconURL: '/icons/site_map_icon_white.png',
          selectedIconURL: '/icons/site_map_icon_black.png',
        }
      : null,
  ].filter(nonNullable);
}
