import { CookieDisclaimer } from '../../components/CookieDisclaimer';
import { env } from '../config/env';
import { ErrorBoundaryMini } from '../../components/ErrorBoundaryMini';
import { RenderIfAuthed } from '../../components/RenderIfAuthed';
import { CurrentLocationWatcher } from '../../features/map/CurrentLocationWatcher';
import { DownloadAppAssets } from '../../features/offline/components/DownloadAppAssets';
import { DownloadAppAssetsSW } from '../../features/offline/components/DownloadAppAssetsSW';
import { UpdateNotification } from '../../features/offline/UpdateNotification';
import { FullscreenStateSynchroniser } from './FullscreenStateSynchroniser';
import { MobileDebug } from './MobileDebug/MobileDebug';
import { OfflineAssetSynchroniser } from './OfflineAssetSynchroniser';
import { SubmitQueuedAnayticsEvents } from './SubmitQueuedAnayticsEvents';

// Mount these components lazily
export const LazyComponents = () => {
  return (
    <ErrorBoundaryMini>
      {/* Alert users we are using cookies */}
      {!env.BYPASS_COOKIE_DISCLAIMER && <CookieDisclaimer />}

      {/* Alerts the user when an update to the website is available. 
      This works in conjunction with the service worker. */}
      <UpdateNotification />

      {/* Synchronise fullscreen redux state when the browser's 
      native fullscreen functionality is triggered */}
      <FullscreenStateSynchroniser />

      {/* Sync assets of tours which should be available offline, 
      in case there was an change to the tour assets */}
      <ErrorBoundaryMini>
        <OfflineAssetSynchroniser />
      </ErrorBoundaryMini>

      {/* Cache app assets if the user is logged in */}
      <RenderIfAuthed>
        <ErrorBoundaryMini>
          {env.ENABLE_SERVICE_WORKER_BACKGROUND_CACHING ? (
            <DownloadAppAssetsSW />
          ) : (
            <DownloadAppAssets />
          )}
        </ErrorBoundaryMini>

        {/* When the user requests to track location, we would listen 
        to the geolocation updates and store it in `location` state */}
        <ErrorBoundaryMini>
          <CurrentLocationWatcher />
        </ErrorBoundaryMini>
      </RenderIfAuthed>

      {/* Show eruda "mobile" console for mobile browsers for debugging */}
      {env.ENABLE_MOBILE_DEBUG && (
        <ErrorBoundaryMini>
          <MobileDebug />
        </ErrorBoundaryMini>
      )}

      <ErrorBoundaryMini>
        <SubmitQueuedAnayticsEvents />
      </ErrorBoundaryMini>
    </ErrorBoundaryMini>
  );
};
