import { Location } from './../../types';
import { createAction } from 'redux-actions';

export const ACTION_TYPES = {
  UPDATE_USER_POSITION: 'updateUserPosition',
  SET_LOCATION_REQUESTED: 'setLocationRequested',
};

type UpdateUserPositionPayload = { currentLocation: Location | null };

export type UpdateUserPositionAction = {
  type: typeof ACTION_TYPES.UPDATE_USER_POSITION;
  payload: UpdateUserPositionPayload;
};

type SetLocationRequestedPayload = { requested: boolean };

export type SetLocationRequestedAction = {
  type: typeof ACTION_TYPES.SET_LOCATION_REQUESTED;
  payload: SetLocationRequestedPayload;
};

export const updateUserPosition = createAction<UpdateUserPositionPayload>(
  ACTION_TYPES.UPDATE_USER_POSITION
);

export const setLocationRequested = createAction<SetLocationRequestedPayload>(
  ACTION_TYPES.SET_LOCATION_REQUESTED
);
