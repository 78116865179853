import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_GetFullTour,
  EXPLORE_WEB_GetFullTourVariables,
  EXPLORE_WEB_GetFullTour_result,
  EXPLORE_WEB_GetFullTour_result_viewpoints,
  EXPLORE_WEB_GetFullTour_result_viewpoints_images,
} from './__generated__/EXPLORE_WEB_GetFullTour';
import { TOUR_VIEWPOINT_FRAGMENT } from '../fragments/TourViewpoint';
import { TOUR_FRAGMENT } from '../fragments/Tour';

export type Variables = EXPLORE_WEB_GetFullTourVariables;
export type Data = EXPLORE_WEB_GetFullTour;
export type FullTour = EXPLORE_WEB_GetFullTour_result;
export type FullViewpoint = EXPLORE_WEB_GetFullTour_result_viewpoints;
export type FullImage = EXPLORE_WEB_GetFullTour_result_viewpoints_images;

// Important: If new asset fields are added, make sure they are added to
export const GET_FULL_TOUR_QUERY = gql`
  query EXPLORE_WEB_GetFullTour($tourID: ID!) {
    result: getTourForConsumerApp(input: { tourID: $tourID }) {
      ...TourFragment

      viewpoints {
        ...TourViewpointFragment
      }
    }
  }

  ${TOUR_FRAGMENT}
  ${TOUR_VIEWPOINT_FRAGMENT}
`;
