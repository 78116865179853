import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  to: string;
}

export const RedirectWithParams: React.FC<Props> = (props) => {
  return (
    <Redirect
      to={{
        pathname: props.to,
        search: props.location && props.location.search,
      }}
    />
  );
};
