import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../Routes/routes';
import { captureButtonPress } from '../../../../features/analytics';
import { Button } from '../../../Button';
import { unlock } from '../../../../features/audio/howlerSetup';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../App/config/env';

interface Props {
  tourID: string;
}

export const TourWidgetGoToGateButton: React.FC<Props> = ({ tourID }) => {
  const { text } = env.APP_ENV;

  const { t } = useTranslation();
  const history = useHistory();
  const page = window.location.pathname;

  const handleGoToGateButtonClick = useCallback(() => {
    unlock();

    captureButtonPress({
      buttonName: 'GoToGate',
      tourID,
      page,
    });

    history.push(ROUTES.tour, { playlistID: tourID });
  }, [tourID, history, page]);

  return (
    <Button width="100%" onClick={handleGoToGateButtonClick} highlight>
      {t(text.startTourCaption)}
    </Button>
  );
};
