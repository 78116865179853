import React, { useState, useEffect } from 'react';
import {
  LogEntry,
  clearLogMessagesForDate,
  getLogMessagesForDate,
  logGroupDateFormatter,
} from './logMessage';
import styled from '../../style/styled';
import { Button } from '../../components/Button';
import { Stack } from '../../Layouts/primitives/Stack';

const LogViewer = () => {
  const [logs, setLogs] = useState<Array<LogEntry>>([]);
  const [selectedDate, setSelectedDate] = useState(
    logGroupDateFormatter(new Date())
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLogs(selectedDate);
  }, [selectedDate]);

  const fetchLogs = async (date: string) => {
    setLoading(true);
    const logs = await getLogMessagesForDate(date);
    setLogs(logs);
    setLoading(false);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const handleClearLogs = async () => {
    await clearLogMessagesForDate(selectedDate);
    fetchLogs(selectedDate);
  };

  return (
    <Wrapper>
      <Stack gutter={10}>
        <Heading>Log Viewer</Heading>

        <div>
          <label htmlFor="date">Select Date: </label>
          <input
            type="date"
            id="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>

        <Button small onClick={handleClearLogs}>
          Clear Logs for Selected Date
        </Button>

        {loading ? (
          <MessageText>Loading logs...</MessageText>
        ) : (
          <div>
            {logs.length === 0 ? (
              <MessageText>No logs found for the selected date.</MessageText>
            ) : (
              <List>
                {logs.map((log, index) => (
                  <ListItem key={index}>
                    <ListItemText>
                      <strong>{log.timestamp}:</strong> {log.message}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        )}
      </Stack>
    </Wrapper>
  );
};

export default LogViewer;

const Wrapper = styled.div`
  padding: 10px;
`;

const Heading = styled.span`
  font-weight: bold;
  font-size: 16px;
  display: block;
`;

const MessageText = styled.span`
  font-size: 12px;
  text-align: center;
  display: block;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li``;

const ListItemText = styled.span`
  font-size: 10px;
  font-family: monospace;

  > * {
    font-family: monospace;
    font-size: 10px;
  }
`;
