import React from 'react';
import { mdiStar } from '@mdi/js';
import { mdiStarOutline } from '@mdi/js';
import { IconButton } from '../../../../components/IconButton';

interface Props {
  onClick: () => void;
  filled: boolean;
  disabled: boolean;
}

export const Star: React.FC<Props> = ({ onClick, filled, disabled }) => {
  const iconPath = filled ? mdiStar : mdiStarOutline;

  return (
    <IconButton
      iconBgColor="#000"
      path={iconPath}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      disabled={disabled}
    />
  );
};
