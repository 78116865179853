import {
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import styled from '../../../../style/styled';
import { getTourShareURL } from '../../../../utils/getTourShareURL';

interface Props {
  name: string;
  tags: string[];
  description: string;
  internalReference: string;
}

// TODO: [AW-738] use webshare API if available https://web.dev/web-share/
export const TourInfoShareButtons = ({
  name,
  tags,
  internalReference,
}: Props) => {
  const url = getTourShareURL(internalReference);

  return (
    <Container>
      <FacebookShareButton url={url} hashtag="ancientworld">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={name} hashtags={tags}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <RedditShareButton url={url} title={name}>
        <RedditIcon size={32} round />
      </RedditShareButton>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32px);
  grid-gap: 4px;
  margin-top: 8px;
`;
