import screenfull from 'screenfull';

export function isSupported(): boolean {
  return screenfull.isEnabled;
}

export function isFullscreen(): boolean {
  return screenfull.isEnabled && screenfull.isFullscreen;
}

export function enterFullScreen() {
  if (screenfull.isEnabled) {
    screenfull.request();
  }
}

export function exitFullscreen() {
  if (screenfull.isEnabled && screenfull.isFullscreen) {
    screenfull.exit();
  }
}

export function toggleFullscreen() {
  if (screenfull.isEnabled) {
    screenfull.toggle();
  }
}
