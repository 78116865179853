import { isIOS } from 'react-device-detect';
import { useEffect, useState } from 'react';
import screenfull from 'screenfull';

export enum Dim {
  WIDTH,
  HEIGHT,
  BOTH,
}

export type BothDims = {
  width: number;
  height: number;
};

// Note: the setTimeout is set because Safari on iPad (perhaps other browsers too)
// don't give the updated innerheight value (innerwidth too perhaps?) properly when fullscreening
// I guess it is because of the way fullscreen works? It's a Promise afaik.
const DELAY = isIOS ? 500 : 0;

export function useScreenSize() {
  const [size, setSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    let timerID: number;

    const resizeHandler = () => {
      if (timerID) {
        window.clearTimeout(timerID);
      }

      timerID = window.setTimeout(() => {
        setSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, DELAY);
    };

    // page resize
    window.addEventListener('resize', resizeHandler);

    // fullscreen change
    if (screenfull.isEnabled) {
      screenfull.on('change', resizeHandler);
    }

    return () => {
      // clean up
      window.removeEventListener('resize', resizeHandler);

      if (screenfull.isEnabled) {
        screenfull.off('change', resizeHandler);
      }

      if (timerID) {
        window.clearTimeout(timerID);
      }
    };
  }, []);

  return size;
}
