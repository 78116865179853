import { handleActions } from 'redux-actions';
import { ACTION_TYPES, SetUpdateAvailableAction } from './actions';
import { SWState, initialState } from './types';

export const reducer = handleActions<SWState, any>(
  {
    [ACTION_TYPES.SET_UPDATE_AVAILABLE]: (
      state: SWState,
      action: SetUpdateAvailableAction
    ): SWState => ({
      ...state,
      updateAvailable: action.payload.available,
    }),
  },
  initialState
);
