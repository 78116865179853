import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCodeIcon from 'mdi-react/QrcodeIcon';
import { Input } from '../../../../styledComponents/Input';
import styled from '../../../../style/styled';
import { captureButtonPress } from '../../../../features/analytics';

const InputContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const QRCodeIconContainer = styled.div<{ inputHeight: string }>`
  border: 1px solid #ddd;
  background-color: #efefef;
  width: ${({ inputHeight }) => inputHeight};
  height: ${({ inputHeight }) => inputHeight};
`;

type Props = {
  disabled: boolean;
  setError: (error: Error | null) => void;
  setCode: (code: string) => void;
  code: string;
  hasError: boolean;
  setScanningQRCode: (scanningQRCode: boolean) => void;
};

const CodeInput = ({
  disabled,
  setError,
  setCode,
  code,
  hasError,
  setScanningQRCode,
}: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputHeight, setInputHeight] = useState<string>();

  // Set the height of the QR code
  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(`${inputRef.current.offsetHeight}px`);
    }
  }, []);

  const onQRCodeButtonClick = () => {
    captureButtonPress({
      buttonName: 'QRCodeIconButtonClick',
      page: window.location.pathname,
    });
    setScanningQRCode(true);
  };

  return (
    <InputContainer>
      <Input
        ref={inputRef}
        autoFocus
        type="text"
        value={code}
        data-testid="activation-code-input"
        placeholder={t('please enter your code here')}
        disabled={disabled}
        hasError={hasError}
        onChange={(e) => {
          setError(null);
          setCode(e.target.value);
        }}
      />
      {inputHeight && (
        <QRCodeIconContainer
          inputHeight={inputHeight}
          role="button"
          onClick={onQRCodeButtonClick}
        >
          <QRCodeIcon size="100%" />
        </QRCodeIconContainer>
      )}
    </InputContainer>
  );
};

export default CodeInput;
