import { History } from 'history';
import { SagaIterator } from 'redux-saga';
import { toast } from 'react-toastify';
import {
  call,
  getContext,
  put,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { ROUTES } from '../../Routes/routes';
import { SagaContextKeys } from '../redux/types';
import {
  ACTION_TYPES,
  LogoutRequestAction,
  logoutSuccess,
  removeAllPinnedTours,
  SetLanguageAction,
  AddToCartAction,
  clearSuccessfulActivations,
} from './actions';
import { setModalType } from '../modal/actions';
import { ModalType } from '../modal/types';
import { setLanguage as setI18nLng } from '../../features/i18n/i18n';
import { CurrencyCode } from '../../graphql/globalTypes';
import { selectCart, selectCurrencyCode } from './selectors';
import { addToCartAllowed } from './actions';

export const sagas = [loginRequestsSaga];

function* loginRequestsSaga(): SagaIterator {
  yield takeLatest(ACTION_TYPES.LOGOUT_REQUEST, logoutRequestSaga);

  yield takeLatest(ACTION_TYPES.SET_LANGUAGE, setLanguageSaga);

  yield takeEvery(ACTION_TYPES.ADD_TO_CART, addToCartSaga);
}

function* addToCartSaga({ payload }: AddToCartAction): SagaIterator {
  // verify currency
  const cart = yield select(selectCart);
  const prevCurrencyCode: CurrencyCode = yield select(selectCurrencyCode);
  const nextCurrencyCode = payload.currencyCode || CurrencyCode.USD;
  const areItemsInCart = cart.length > 0;

  if (areItemsInCart && prevCurrencyCode !== nextCurrencyCode) {
    const message =
      'Purchasing items with different currencies is currently supported.';
    toast.error(message, { toastId: message });
  } else {
    yield put(addToCartAllowed(payload));
  }
}

function* setLanguageSaga({ payload }: SetLanguageAction): SagaIterator {
  yield call(setI18nLng, payload);
}

// TODO: [LVR-1815]  Investigate why the session expiry alert is not displayed.
function* logoutRequestSaga(action: LogoutRequestAction): SagaIterator {
  const history: History = yield getContext(SagaContextKeys.history);

  yield call(history.push, ROUTES.index as any);

  if (action.payload?.automatic) {
    // this is due to expired access token. we don't want to clear cache here because it can affect offline tours.
    yield put(setModalType({ modalType: ModalType.SESSION_EXPIRED_MODAL }));
  }

  yield put(clearSuccessfulActivations());
  yield put(removeAllPinnedTours());
  yield put(logoutSuccess());
}
