export enum ROUTES {
  index = '/',

  auth = '/auth',
  authQRCode = '/auth/qrcode',
  authCode = '/auth/code',
  authLogin = '/auth/login',
  authSignUp = '/auth/signup',
  authSignUpSuccess = '/auth/signup_success',
  authVerify = '/auth/verify',
  authVerifySuccess = '/auth/verify_success',
  authVerifyFailure = '/auth/verify_failure',
  authForgot = '/auth/forgot',
  authForgotSuccess = '/auth/forgot_success',
  authChangePassword = '/auth/change_password',
  authChangePasswordSuccess = '/auth/change_password_success',

  library = '/library',

  purchase = '/purchase',

  account = '/account',
  myTrips = '/account/my_trips',
  plans = '/account/plans',
  plan = '/account/plans/:id',
  myGiftPurchases = '/account/gift_purchases',
  security = '/account/security',

  licenses = '/licenses',

  trial = '/trial',
  tour = '/tour',
  freeRoamTour = '/tour/freeroam',

  shop = '/shop',
  cart = '/shop/cart',
  giftTours = '/shop/gift_tours',
  checkout = '/shop/checkout',
  order = '/shop/order/:orderID',
  stripeOrderConfimation = '/shop/stripe_order_confirmation',

  dev = '/lvr',
  setup = '/_setup',

  // marketing app header links
  product = '/product',

  // footer links
  privacyPolicy = '/privacy_policy',
  deleteAccount = '/delete_account',
  deleteAccountRequest = '/delete_account/request',
  deleteAccountConfirm = '/delete_account/confirm',
  deleteAccountConfirmSuccess = '/delete_account/confirm_success',
  deleteAccountConfirmFailure = '/delete_account/confirm_failure',
  termsConditions = '/terms_conditions',
  yourGuides = '/guides',
  faq = '/help',
  merch = '/merch',

  // IMPORTANT: Be careful when changing these because these links are used in Console and could be used elsewhere
  business = '/business',
  ourWork = '/business/our-work',
  aboutUs = '/business/about-us',
  // contract form screens
  businessPartnerContact = '/business/producers',
  businessAssociateContact = '/business/associate',
  businessAffiliateContact = '/business/affiliate',
  businessListYourTourContact = '/business/list_tour',
  businessHeritageContact = '/business/heritage',
  businessSightSeeingCoaches = '/business/sight-seeing-coaches',
  businessDaytripCoaches = '/business/day-trip-coaches',
  businessCruiseShips = '/business/cruise-ships',
  businessWalkingTours = '/business/walking-tours',
  businessStreetVendors = '/business/street-vendors',
  businessContact = '/business/contact',
  businessPartnetWithUs = '/business/partner-with-us',
  createTours = '/business/createTours',
  affiliateTerms = '/business/affiliate_terms',
  associateTerms = '/business/associate_terms',
  heritageClubTerms = '/business/heritage_club_terms',
  tourListingTerms = '/business/tour_listing_terms',

  itinerary = '/itinerary/:internalReference',
  bundle_itinerary = '/bundle_itinerary/:internalReference',

  redeemCodes = '/redeemCodes',

  // unused out until we revisit
  streetEscapes = '/street_escapes',
  streetEscapesPlaces = '/street_escapes/places',

  // facebook callback
  facebookRedirectHandler = '/facebook_redirect',

  comingSoon = '/coming_soon',

  verify = '/verify',
}
