import { useState } from 'react';
import { toast } from 'react-toastify';
import styled from '../../../style/styled';
import { gql, useMutation } from '@apollo/client';
import ShowPasswordIcon from 'mdi-react/EyeIcon';
import HidePasswordIcon from 'mdi-react/EyeOffIcon';
import * as Sentry from '@sentry/react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { captureEvent, EventNames } from '../../../features/analytics';
import { useDispatch } from 'react-redux';
// import { ModalContent, AuthModal } from '../../../components/AuthModal';
import { ErrorText } from '../../../styledComponents/ErrorText';
import { AuthButtonWithRecaptcha3 } from '../../../components/AuthButtonWithRecaptcha3';
import { Input } from '../../../styledComponents/Input';
import { isMobile } from 'react-device-detect';
import { loginSuccess, logoutRequest } from '../../../store/app/actions';
import {
  // RECAPTCHA_ERROR,
  UNKNOWN_ERROR,
} from '../../../consts';
import { captureInSentry } from '../../config/reporting/captureInSentry';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import isEmail from '../../../utils/isEmail';
import { Grid } from '../../../Layouts/primitives/Grid';
import { Label } from '../../../styledComponents/Label';
import { SignUpTerms } from '../../../components/SignUpTerms';
import {
  EXPLORE_WEB_ConvertGuestAccountMutation as Data,
  EXPLORE_WEB_ConvertGuestAccountMutationVariables as Variables,
} from './__generated__/EXPLORE_WEB_ConvertGuestAccountMutation';
import { setModalType } from '../../../store/modal/actions';
import { ModalType } from '../../../store/modal/types';
// import { ReCaptchaDisclaimer } from '../../../components/ReCaptchaDisclaimer';
import { AuthPageTitle as PageTitle } from '../../../styledComponents/Title';
import { Button } from '../../../components/Button';
import { FaExclamationTriangle, FaCheck } from 'react-icons/fa';

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

type FormState = {
  email: string;
  name: string;
  password: string;
};

type ErrorState = {
  email: string;
  name: string;
  password: string;
};

function getInitialFormState() {
  return {
    email: '',
    name: '',
    password: '',
  };
}

function getInitialErrorState() {
  return {
    email: '',
    name: '',
    password: '',
  };
}

const RECAPTCHA_CONTEXT = 'ConvertGuestAccount';

const CONVERT_ANONYMOUS_USER_MUTATION = gql`
  mutation EXPLORE_WEB_ConvertGuestAccountMutation(
    $input: ConvertAnonymousAccountInput!
  ) {
    result: convertAnonymousAccount(input: $input) {
      converted
      jwt
      error {
        id
        message
        type
      }
    }
  }
`;

export enum REASON_ID {
  NO_REASON = 'NO_REASON',
  NO_ACCESS_TO_TOURS = 'NO_ACCESS_TO_TOURS',
}

const REASONS = {
  [REASON_ID.NO_REASON]: [],
  [REASON_ID.NO_ACCESS_TO_TOURS]: [
    'You are currently logged in as a guest user.',
    'If you log out now, you will not be able to access the tours you have redeemed or purchased.',
    'You can convert your account to a real user to access your tours once you log out.',
  ],
};

type Props = {
  reasonID: REASON_ID;
};

// If the user is currently logged in as a guest user,
// he can convert his account to a real user.
// Refer to https://lithodomosvr.atlassian.net/wiki/spaces/LITHODOMOS/pages/1482850428/Anonymous+logins
// to find out what's a guest account.
export const ConvertGuestAccount = ({ reasonID }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>(getInitialFormState());
  const [formError, setFormError] = useState<ErrorState>(
    getInitialErrorState()
  );
  const [error, setError] = useState<Error | null>(null);
  const [infoVisible, setInfoVisible] = useState(
    reasonID !== REASON_ID.NO_REASON
  );

  const [convert, { loading }] = useMutation<Data, Variables>(
    CONVERT_ANONYMOUS_USER_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        setError(error);
      },
      onCompleted: ({ result }) => {
        const { jwt, error } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({ name: EventNames.USER_CONVERTED });

          const toastSuccessMessage = t('Account conversion successful.');

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables(), jwt });

          setError(new Error(errorMessage));
        }
      },
    }
  );

  const submitForm = async (e: any) => {
    e.preventDefault();

    let token: string | null = null;

    // if (executeRecaptcha) {
    //   try {
    //     token = await executeRecaptcha(RECAPTCHA_CONTEXT);
    //   } catch (error) {
    //     captureInSentry(error?.message || RECAPTCHA_ERROR, {
    //       recaptchaContext: RECAPTCHA_CONTEXT,
    //     });
    //   }
    // }

    handleSubmit(token);
  };

  const handleSubmit = (recaptchaToken: string | null) => {
    setError(null);

    const email = formState.email.trim();
    const password = formState.password.trim();
    const name = formState.name.trim();

    const errorState = getInitialErrorState();

    let hasError = false;

    if (!name) {
      errorState.name = t('Please enter your Name.');
      hasError = true;
    }

    if (!isEmail(email)) {
      errorState.email = t('Please enter a valid Email.');
      hasError = true;
    }

    if (password.length < 6) {
      errorState.password = t('Please enter a 6 or more character Password.');
      hasError = true;
    }

    if (hasError) {
      setFormError(errorState);
    } else {
      const variables: Variables = {
        input: {
          email,
          password,
          name,
        },
      };

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      convert({ variables });
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setError(null);
    setFormError({ ...formError, [name]: '' });

    setFormState({ ...formState, [name]: value });
  };

  const reasons = REASONS[reasonID];

  return (
    <Container>
      <div style={{ width: '100%' }}>
        {infoVisible ? (
          <>
            <PageTitle>
              <FaExclamationTriangle /> {t('Warning')}
            </PageTitle>

            {reasons.length > 0 &&
              reasons.map((text, index) => (
                <ReasonText key={index}>{t(text)}</ReasonText>
              ))}
            <ButtonContainer>
              <Button onClick={() => setInfoVisible(false)}>
                <ButtonContent>
                  <FaCheck /> {t('Convert to a real account')}
                </ButtonContent>
              </Button>
              <Button
                onClick={() =>
                  dispatch(
                    setModalType({ modalType: ModalType.NONE, modalData: null })
                  )
                }
              >
                <ButtonContent>
                  <FaCheck /> {t("I don't want to log out")}
                </ButtonContent>
              </Button>
              <Button
                secondary
                onClick={() => {
                  dispatch(
                    setModalType({ modalType: ModalType.NONE, modalData: null })
                  );

                  dispatch(logoutRequest({}));
                }}
              >
                <ButtonContent>
                  <FaExclamationTriangle /> {t('Log out anyway')}
                </ButtonContent>
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <PageTitle>{t('Create an account')}</PageTitle>

            <form onSubmit={submitForm}>
              <Grid cols={1} gap={12} marginBottom={20}>
                <section>
                  <Label htmlFor="name">{t('Name')}</Label>
                  <Input
                    autoFocus={!isMobile}
                    id="name"
                    name="name"
                    type="text"
                    disabled={loading}
                    autoComplete="given-name"
                    value={formState.name}
                    onChange={handleInputChange}
                    maxLength={50}
                    data-testid="name-input-field"
                  />
                  <ErrorText>{formError?.name || ''}</ErrorText>
                </section>

                <section>
                  <Label htmlFor="email">{t('Email address')}</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    disabled={loading}
                    autoComplete="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    data-testid="email-input-field"
                  />
                  <ErrorText>{formError?.email || ''}</ErrorText>
                </section>

                <section>
                  <Label htmlFor="password">{t('Password')}</Label>
                  <PasswordInputContainer>
                    <Input
                      id="password"
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      disabled={loading}
                      autoComplete="new-password"
                      value={formState.password}
                      onChange={handleInputChange}
                      data-testid="password-input-field"
                    />

                    <ShowPasswordButton
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword && <ShowPasswordIcon />}
                      {showPassword && <HidePasswordIcon />}
                    </ShowPasswordButton>
                  </PasswordInputContainer>
                  <ErrorText>{formError?.password || ''}</ErrorText>
                </section>

                <div>
                  <ConsentContainer>
                    <SignUpTerms />
                  </ConsentContainer>
                </div>

                <ErrorText>{error?.message || ''}</ErrorText>

                <AuthButtonWithRecaptcha3
                  context={RECAPTCHA_CONTEXT}
                  disabled={loading}
                  buttonName="ConvertAccountButton"
                >
                  {t(loading ? 'Creating account...' : 'Create account')}
                </AuthButtonWithRecaptcha3>
              </Grid>
            </form>
          </>
        )}
      </div>

      {/* <ReCaptchaDisclaimer /> */}
    </Container>
  );
};

const ShowPasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
`;

const PasswordInputContainer = styled.div`
  position: relative;
`;

const ConsentContainer = styled.label`
  display: flex;
  align-items: flex-sart;
  user-select: none;
`;

const Container = styled.div`
  width: 300px;
  max-width: 100%;
  margin: 0 auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px;
`;

const ReasonText = styled.p`
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
