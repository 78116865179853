import { createTransform } from 'redux-persist';
import { initialState as serviceWorkerInitialState } from '../sw/types';
import { initialState as locationInitialState } from '../location/types';
import { initialState as googleOptimizeInitialState } from '../googleOptimize/types';
import {
  initialState as modalsInitialState,
  ModalState,
  ModalType,
} from '../modal/types';
import {
  initialState as viewerInitialState,
  ViewerState,
} from '../viewer/types';
import { initialState as appInitialState, AppState } from '../app/types';
import {
  initialState as offlineInitialState,
  OfflineState,
} from '../offline/types';
import { isFuture } from 'date-fns';

const transform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return inboundState;
  },
  // transform state being rehydrated.
  // ideally we want to blacklist certain fields from the persist logic,
  // but I've found it to be too complicated. need to revisit at a later point.
  // for now, use filter them out when reading back.
  (outboundState, key) => {
    if (key === 'sw') {
      // we do not persist the entire sw state
      return serviceWorkerInitialState;
    }

    if (key === 'location') {
      // we do not persist the location sw state
      return locationInitialState;
    }

    if (key === 'googleOptimize') {
      return googleOptimizeInitialState;
    }

    if (key === 'modals') {
      const { modalType } = outboundState as ModalState;

      // specifically address the fact that Facebook login would work with redirection
      // meaning, the page will reload and would close the activation modal
      // as per RouteListener's effect #2 if we didn't persist it.
      if (modalType === ModalType.ACTIVATE_PRODUCT_MODAL) {
        return outboundState;
      }

      return modalsInitialState;
    }

    if (key === 'app') {
      const appState = outboundState as AppState;

      // MIGRATION:
      // successfulActivations: Record<string, Array<string>> to
      // successfulActivations: Record<string, ActivationCache>;
      // where ActivationCache is defined as:
      type ActivationCache = {
        tourIDs: Array<string>;
        expiryDate: number;
      };

      let successfulActivations =
        appState.successfulActivations || appInitialState.successfulActivations;

      if (appState.successfulActivations) {
        const newSuccessfulActivations: Record<string, ActivationCache> = {};

        for (const code in appState.successfulActivations) {
          const activationCacheForCode = appState.successfulActivations[code];

          // If good format, then keep it. Otherwise discard it.
          if (
            !Array.isArray(activationCacheForCode) &&
            typeof activationCacheForCode.expiryDate === 'number' &&
            isFuture(activationCacheForCode.expiryDate)
          ) {
            newSuccessfulActivations[code] = activationCacheForCode;
            continue;
          }
        }

        successfulActivations = newSuccessfulActivations;
      }

      return {
        ...appInitialState,
        ...appState,
        messages: appInitialState.messages,
        enableGoogleAnalytics: appInitialState.enableGoogleAnalytics,
        fulscreenLoadingOverlay: appInitialState.fulscreenLoadingOverlay,
        successfulActivations,
        activationStatus: appInitialState.activationStatus,
        activationError: appInitialState.activationError,
      };
    }

    if (key === 'viewer') {
      const viewerState = outboundState as ViewerState;

      const { narrationLanguage, subtitlesLanguage } = viewerState.audio;

      return {
        ...viewerState,
        isLoading: viewerInitialState.isLoading,
        endOfTourPopupClosed: viewerInitialState.endOfTourPopupClosed,
        audio: {
          ...viewerInitialState.audio,
          narrationLanguage,
          subtitlesLanguage,
        },
        resyncWithCompassCounter: viewerInitialState.resyncWithCompassCounter,
        compassSupport: viewerInitialState.compassSupport,
        userSelectedAViewpoint: viewerInitialState.userSelectedAViewpoint,
      };
    }

    if (key === 'offline') {
      const offlineState = outboundState as OfflineState;

      return {
        ...offlineState,
        queueStatus: offlineInitialState.queueStatus,
      };
    }

    return outboundState;
  }
);

export const transforms = [transform];
