import { appSupportsInTourNarrations } from './../../utils/appSupportsInTourNarrations';
import { isAndroid } from 'react-device-detect';
import { SagaIterator } from 'redux-saga';
import {
  call,
  getContext,
  takeLatest,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { AudioPlayer } from '../../features/audio/AudioPlayer';
import { MusicPlayer } from '../../features/audio/MusicPlayer';
import { SagaContextKeys } from '../redux/types';
import {
  ACTION_TYPES,
  OpenTourRequestAction,
  openTourSuccess,
  PlayNarrationAction,
  playNarration,
  OverrideNarrationSeekPosAction,
  SetViewerLoadingStateAction,
  ToggleMuteMusicAction,
  SetNarrationLanguageAction,
  SetViewerOptionsAction,
  setAudioStatePartial,
  setViewerOptions,
} from './actions';
import {
  selectIsFullscreenMapsVisible,
  selectViewer,
  selectViewerAudio,
  selectViewerOptions,
} from './selectors';
import {
  ViewerState,
  ViewerOptionsState,
  CompassSupport,
  AudioState,
} from './types';
import { resolvePreferredNarration } from './util/resolvePreferredNarration';
import {
  imageEnd,
  imageStart,
  tourStart,
  viewpointStart,
} from '../../features/analytics';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { env } from '../../App/config/env';
import isInTourMusicEnabled from '../../features/whitelabeling/branding/utils/isInTourMusicEnabled';
import isMapHiddenAfterChangingTheViewpoint from '../../features/whitelabeling/branding/utils/isMapHiddenAfterChangingTheViewpoint';

export const sagas = [viewerSagas];

export function* viewerSagas(): any {
  yield takeLatest(
    ACTION_TYPES.OPEN_TOUR_REQUEST,
    openCuratedPlaylistRequestSaga
  );

  yield takeLatest(ACTION_TYPES.PLAY_NARRATION, playNarrationSaga);

  yield takeLatest(ACTION_TYPES.RESTART_NARRATION, restartNarrationSaga);

  yield takeLatest(ACTION_TYPES.TOGGLE_MUTE_MUSIC, toggleMusicSaga);

  yield takeLatest(
    [ACTION_TYPES.STOP_NARRATION, ACTION_TYPES.CLOSE_VIEWER],
    stopNarrationSaga
  );

  yield takeLatest(ACTION_TYPES.PAUSE_NARRATION, pauseNarrationSaga);

  yield takeLatest(ACTION_TYPES.SYSTEM_PAUSE_NARRATION, systemPauseNarration);

  yield takeLatest(ACTION_TYPES.RESUME_NARRATION, resumeNarrationSaga);

  yield takeLatest(ACTION_TYPES.SYSTEM_RESUME_MUSIC, resumeMusicSaga);

  yield takeLatest(ACTION_TYPES.SYSTEM_PAUSE_MUSIC, pauseMusicSaga);

  yield takeLatest(ACTION_TYPES.SET_VIEWER_LOADING_STATE, viewpointLoadingSaga);

  yield takeLatest(
    [
      ACTION_TYPES.INCREMENT_VIEWPOINT_INDEX,
      ACTION_TYPES.SET_VIEWPOINT_INDEX,
      ACTION_TYPES.DECREMENT_VIEWPOINT_INDEX,
    ],
    changeViewpointIndexSaga
  );

  yield takeLatest(
    [
      ACTION_TYPES.INCREMENT_IMAGE_INDEX,
      ACTION_TYPES.SET_IMAGE_INDEX,
      ACTION_TYPES.DECREMENT_IMAGE_INDEX,
    ],
    changeImageIndexSaga
  );

  yield takeLatest(
    ACTION_TYPES.OVERRIDE_NARRATION_SEEK_POS,
    overrideNarrationSeekPosSaga
  );

  yield takeLatest(ACTION_TYPES.SET_NARRATION_LANGUAGE, changeNarrationSaga);

  yield takeEvery(ACTION_TYPES.SET_VIEWER_OPTIONS, setViewerOptionsSaga);
}

function* setViewerOptionsSaga(action: SetViewerOptionsAction): SagaIterator {
  const { options } = action.payload;

  if (
    // this "undefined" check is required because we are checking if this particular option
    // is available in the action payload. we don't care about any other options in the action payload.
    options.isTutorialVisible !== undefined ||
    options.isPlaylistThumbnailsVisible !== undefined ||
    options.isMapsVisible !== undefined
  ) {
    const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);

    const { pausedByUser, started, completed }: AudioState = yield select(
      selectViewerAudio
    );

    const tutorialOpen = options.isTutorialVisible;
    const thumbsGridOpen = options.isPlaylistThumbnailsVisible;
    const narrationWasPlaying = started && !completed && !pausedByUser;
    const isMapsVisible = options.isMapsVisible;

    const { isFullScreenMapVisible }: ViewerOptionsState = yield select(
      selectViewerOptions
    );

    const systemPauseNarration =
      tutorialOpen ||
      (isMapsVisible && isFullScreenMapVisible) ||
      thumbsGridOpen;

    if (systemPauseNarration && narrationWasPlaying) {
      yield call(audioPlayer.pauseNarration, true);
    } else if (!systemPauseNarration && narrationWasPlaying) {
      yield call(audioPlayer.resumeNarration);
    }
  }
}

function* openCuratedPlaylistRequestSaga({
  payload,
}: OpenTourRequestAction): SagaIterator {
  const { tour, currentViewpointIndex: curVPIndex } = payload;
  const viewerState: ViewerState = yield select(selectViewer);
  let currentViewpointIndex =
    curVPIndex && curVPIndex >= 0 && curVPIndex < tour.viewpoints.length
      ? curVPIndex
      : viewerState.currentViewpointIndex;
  let currentImageIndex = viewerState.currentImageIndex;

  let viewerOptions: Partial<ViewerOptionsState> = {
    ...viewerState.viewerOptions,
  };

  const existingTour = viewerState.tour;
  const existingTourID = existingTour?.id;

  const currentViewpoints = existingTour?.viewpoints || [];
  const currentViewpoint = currentViewpoints[currentViewpointIndex];

  const currentImages = currentViewpoint?.images || [];
  const currentImage = currentImages[currentImageIndex];

  // if the tourID is different (i.e. a different tour)
  if (existingTourID !== tour.id) {
    yield call(tourStart, tour.id);

    const enableGyro = viewerState.compassSupport === CompassSupport.ENABLED;

    const isFullScreenMapVisible = !!env.APP_ENV.features
      ?.isFullscreenMapDisplayedAtTourStart;

    viewerOptions = {
      isMediaControlsVisible: true,
      isGyroEnabled: enableGyro || isAndroid,
      // isAutoRotating: !enableGyro,
      isAutoRotating: false,
      isOptionsMenuVisible: false,
      isTutorialVisible: false,
      tutorialDisplayed: true,
      isPlaylistThumbnailsVisible: false,
      isMapsVisible: false,
      isSideBySideVisible: false,
      isFullScreenMapVisible,
    };

    if (isFullScreenMapVisible) {
      viewerOptions.isMapsVisible = true;
    }

    // reset the currentViewpointIndex and currentImageIndex
    if (!curVPIndex) {
      currentViewpointIndex = 0;
      currentImageIndex = 0;
    }
  }

  const newCurrentViewpoint = tour.viewpoints[currentViewpointIndex];

  // if a new viewpoint ID (i.e. perhaps we changed the order of the vps, or added a new vp)
  if (
    newCurrentViewpoint &&
    (existingTourID !== tour.id ||
      currentViewpoint.id !== newCurrentViewpoint.id)
  ) {
    yield call(viewpointStart, newCurrentViewpoint.id);

    // reset the currentImageIndex
    currentImageIndex = 0;
  }

  const newImages = newCurrentViewpoint?.images || [];
  const newCurrentImage = newImages[currentImageIndex];

  if (
    // if either the tour, viewpoint or image ID changed, and if viewing images,
    // capture analytics
    newCurrentImage &&
    (existingTourID !== tour.id ||
      currentViewpoint.id !== newCurrentViewpoint.id ||
      currentImage.id !== newCurrentImage.id) &&
    viewerState.viewerOptions.isImagesEnabled
  ) {
    yield call(imageStart, newCurrentImage.id);
  }

  yield put(
    openTourSuccess({
      tour,
      currentViewpointIndex,
      currentImageIndex,
      viewerOptions,
    })
  );
}

function* playNarrationSaga({ payload }: PlayNarrationAction): SagaIterator {
  const {
    currentViewpointIndex,
    tour,
    audio,
    viewerOptions,
    userSelectedAViewpoint,
  }: ViewerState = yield select(selectViewer);

  const currentViewpoint = tour?.viewpoints[currentViewpointIndex || 0];

  if (!currentViewpoint) {
    captureInSentry(
      'viewer saga.ts playNarrationSaga Cannot play narration because currentViewpoint is null or undefined'
    );
    return;
  }

  const { bySystem } = payload;

  const {
    isTutorialVisible,
    isPlaylistThumbnailsVisible,
    isMapsVisible,
  } = viewerOptions;

  // const audioUnlocked = getAudioUnlocked(); // yield call(getAudioUnlocked);
  const play =
    !bySystem ||
    Boolean(
      !audio.pausedByUser &&
        // this takes care of the undefined state
        // if isTutorialVisible is undefined (meaning, it's using an older persisted state), we need autoplay to be false.
        // if isTutorialVisible is true, autoplay should be false.
        isTutorialVisible === false &&
        !isPlaylistThumbnailsVisible &&
        currentViewpointIndex !== 0 &&
        (!isMapsVisible || (isMapsVisible && userSelectedAViewpoint))
    );

  // we might need to change this for future situations, but because we are playing audio automatically by default,
  // even when autoplay was blocked, we mark it as we were "supposed to" be playing audio.
  // wasPlaying = play !== false;

  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);

  const narrationToPlay = resolvePreferredNarration({
    viewpoint: currentViewpoint,
    overridingNarrationConfig: tour.narrationConfig,
    narrationLanguage: audio.narrationLanguage,
  });

  const musicUrl = currentViewpoint.music?.asset?.uri;
  const musicLength = currentViewpoint.music?.length || 0;
  const narrationUrl = narrationToPlay?.voiceTrack.uri;
  const narrationLength = narrationToPlay?.voiceTrackLengthInSeconds || 0;

  if (musicUrl && isInTourMusicEnabled()) {
    yield call(
      musicPlayer.playMusic,
      { url: musicUrl },
      {
        play: play !== false,
        muted: audio.musicMuted,
        length: musicLength,
      }
    );
  } else {
    yield call(musicPlayer.stop);
  }

  if (narrationUrl) {
    yield call(audioPlayer.playNarration, {
      sound: { url: narrationUrl },
      play,
      length: narrationLength,
    });
  } else {
    yield call(audioPlayer.stop);
  }
}

function* changeNarrationSaga(
  action: SetNarrationLanguageAction
): SagaIterator {
  const { currentViewpointIndex, tour, audio }: ViewerState = yield select(
    selectViewer
  );

  if (!tour) {
    captureInSentry(
      'viewer saga.ts changeNarrationSaga Cannot play narration because tour in viewer state is null or undefined'
    );
    return;
  }

  const currentViewpoint = tour.viewpoints[currentViewpointIndex || 0];

  if (!currentViewpoint) {
    captureInSentry(
      'viewer saga.ts changeNarrationSaga Cannot play narration because currentViewpoint is null or undefined'
    );
    return;
  }

  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);

  const narrationToPlay = resolvePreferredNarration({
    viewpoint: currentViewpoint,
    overridingNarrationConfig: tour.narrationConfig,
    narrationLanguage: audio.narrationLanguage,
  });

  const narrationUrl = narrationToPlay?.voiceTrack.uri;
  const length = narrationToPlay?.voiceTrackLengthInSeconds || 0;

  if (narrationUrl) {
    yield call(audioPlayer.playNarration, {
      sound: { url: narrationUrl },
      play: true,
      length,
    });
  } else {
    yield call(audioPlayer.stop);
  }
}

function* toggleMusicSaga(action: ToggleMuteMusicAction): SagaIterator {
  const { audio }: ViewerState = yield select(selectViewer);
  const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);
  yield call(musicPlayer.muteMusic, audio.musicMuted);
}

function* restartNarrationSaga(): SagaIterator {
  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  yield call(audioPlayer.restartNarration);
}

function* stopNarrationSaga(): SagaIterator {
  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);

  yield call(audioPlayer.stop);
  yield call(musicPlayer.stop);
}

function* pauseNarrationSaga(): SagaIterator {
  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  yield call(audioPlayer.pauseNarration);
}

function* systemPauseNarration(): SagaIterator {
  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  yield call(audioPlayer.pauseNarration, true);
}

function* resumeMusicSaga(): SagaIterator {
  const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);
  yield call(musicPlayer.resumeMusic);
}

function* pauseMusicSaga(): SagaIterator {
  const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);
  yield call(musicPlayer.pauseMusic);
}

function* resumeNarrationSaga(): SagaIterator {
  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  yield call(audioPlayer.resumeNarration);
}

function* overrideNarrationSeekPosSaga(
  action: OverrideNarrationSeekPosAction
): SagaIterator {
  const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
  yield call(audioPlayer.seek, { value: action.payload.value });
}

function* changeViewpointIndexSaga(): SagaIterator {
  // We need to call this because otherwise the audio will keep on playing for a bit after changing the narration,
  // before the correct track starts playing.

  const isFullScreenMapVisible = yield select(selectIsFullscreenMapsVisible);

  const nextViewerOptions: Partial<ViewerOptionsState> = {
    isSideBySideVisible: false,
  };

  // When we change the viewpoint, we want to hide the fullscreen map because otherwise we cannot see the controls.
  if (isFullScreenMapVisible) {
    nextViewerOptions.isFullScreenMapVisible = false;
  }

  if (isMapHiddenAfterChangingTheViewpoint()) {
    nextViewerOptions.isMapsVisible = false;
  }

  yield put(setViewerOptions({ options: nextViewerOptions }));

  yield put(setAudioStatePartial({ completed: false }));

  const {
    tour,
    currentViewpointIndex,
    previousViewpointIndex,
    currentImageIndex,
    viewerOptions,
  }: ViewerState = yield select(selectViewer);

  // stop the narration if we are changing the viewpoint index
  if (
    previousViewpointIndex !== currentViewpointIndex &&
    previousViewpointIndex !== -1
  ) {
    yield call(stopNarrationSaga);
  }

  const viewpoints = tour?.viewpoints || [];
  const currentViewpoint = viewpoints[currentViewpointIndex];
  const previousViewpoint = viewpoints[previousViewpointIndex];

  // A sanity check
  if (!currentViewpoint) {
    return;
  }

  // analytics
  if (viewerOptions.isImagesEnabled) {
    yield call(imageEnd);
  }

  // analytics
  yield call(viewpointStart, currentViewpoint.id);

  // analytics
  if (viewerOptions.isImagesEnabled) {
    const currentImageID = currentViewpoint.images[currentImageIndex]?.id;

    if (currentImageID) {
      yield call(imageStart, currentImageID);
    }
  }

  // if the vp doesn't change, it won't trigger the load event
  // for the audio to start loading, we have to handle this
  // special case here
  const indexChangedButVPIsSame =
    currentViewpointIndex !== previousViewpointIndex &&
    currentViewpoint.projectionLeft?.uri ===
      previousViewpoint?.projectionLeft?.uri;

  const narrationsSupported =
    !env.IS_AK && appSupportsInTourNarrations(env.APP_ENV);

  const shouldPlayNarration =
    narrationsSupported &&
    (viewerOptions.isStreetViewEnabled ||
      viewerOptions.isImagesEnabled ||
      indexChangedButVPIsSame ||
      isFullScreenMapVisible);

  // in the case of the 360 view, the play narration gets called on loading finish.
  if (shouldPlayNarration) {
    yield put(
      playNarration({
        viewpointID: currentViewpoint.id,
        // if isFullScreenMapVisible is true, then we will treat this as a user initiated one
        bySystem: !isFullScreenMapVisible,
      })
    );
  }
}

// just analytics for now
function* changeImageIndexSaga(): SagaIterator {
  const {
    tour,
    currentViewpointIndex,
    currentImageIndex,
  }: ViewerState = yield select(selectViewer);

  const currentViewpoint = tour?.viewpoints[currentViewpointIndex];

  // sanity check
  if (!currentViewpoint) {
    return;
  }

  // analytics
  const currentImageID = currentViewpoint.images[currentImageIndex]?.id;
  if (currentImageID) {
    yield call(imageStart, currentImageID);
  }
}

// this is the logic which makes audio autoplay
function* viewpointLoadingSaga(
  action: SetViewerLoadingStateAction
): SagaIterator {
  // we only care about loading completed state here
  const { isLoading } = action.payload;
  const { completed }: AudioState = yield select(selectViewerAudio);

  // solve LVR-4414 issues
  //As a user viewing the image viewer, I should not experience the app automatically replaying audio when I exit into the 360 viewer, so that I do not get confused
  if (isLoading || completed) {
    return;
  }

  const { tour, currentViewpointIndex }: ViewerState = yield select(
    selectViewer
  );

  if (!tour) {
    return;
  }

  const currentViewpoint = tour.viewpoints[currentViewpointIndex || 0];
  if (!currentViewpoint) {
    return;
  }

  if (!env.IS_AK && appSupportsInTourNarrations(env.APP_ENV)) {
    yield put(
      playNarration({
        viewpointID: currentViewpoint.id,
        // if the audio track was paused, load the track but don't play it
        bySystem: true,
      })
    );
  }
}
