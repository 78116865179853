import { lighten } from 'polished';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { env } from '../App/config/env';
import { ROUTES } from '../Routes/routes';
import { COLORS } from '../style/colors';
import styled from '../style/styled';

export const SignUpTerms = () => {
  return (
    <FinePrint>
      {env.IS_STOCK && (
        <Trans i18nKey="By signing up, I accept the Ancient World Terms of Service and Privacy Policy.">
          By signing up, I accept the Ancient World{' '}
          <Link
            to={ROUTES.termsConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            to={ROUTES.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </Trans>
      )}

      {!env.IS_STOCK && (
        <Trans i18nKey="By signing up, I accept the Lithodomos Audio-Visual Guide Terms of Service and Privacy Policy.">
          By signing up, I accept the Lithodomos Audio-Visual Guide{' '}
          <Link
            to={ROUTES.termsConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            to={ROUTES.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </Trans>
      )}
    </FinePrint>
  );
};

const FinePrint = styled.p`
  font-size: 12px;
  color: ${lighten(0.2, COLORS.darkTextColor)};

  a {
    font-weight: bold;
    font-size: 12px;
  }
`;
