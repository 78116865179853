import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { RouteListener } from './components/RouteListner/RouteListener';
import { AffiliateCodeExtractor } from '../Routes/components/AffiliateCodeExtractor';
import Modals from './components/Modals/Modals';
import { ScrollToAnchor } from './components/ScrollToAnchor';
import { AutoRedeemFromDeeplink } from './components/AutoRedeemFromDeeplink';

import { LoadingView } from '../components/LoadingView';
import { LoginFromRefreshToken } from './components/LoginFromRefreshToken';
import { LazyComponents } from './components/LazyComponents';
import { AppRoutes } from '../Routes/App/AppRoutes';
import { useBeforeFirstRender } from '../hooks/useBeforeFirstRender';
import { sessionStart } from '../features/analytics';
import { env } from './config/env';
import { AutoDisplayRedeemPopup } from './components/AutoDisplayRedeemPopup';

import AutoLoginAsAnonymous from './AutoLoginAsAnonymous';
import LanguageResetter from './LanguageResetter';
import { updateSessionEndLog } from '../features/logging/logMessage';

export const App = () => {
  // call this before app renders
  useBeforeFirstRender(() => {
    // create a session ID and send it to backend. also store the session ID for analytic events.
    sessionStart();
  });

  const { ready } = useTranslation();
  const [appMounted, setAppMounted] = useState<boolean>(false);

  useEffect(() => {
    window.setTimeout(() => {
      setAppMounted(true);
    }, 3000);

    const sessionEndUpdaterTimerID = window.setInterval(
      updateSessionEndLog,
      30000
    );

    return () => {
      window.clearInterval(sessionEndUpdaterTimerID);
    };
  }, []);

  return (
    <>
      {!ready && <LoadingView />}

      {ready && (
        <>
          {/* App routes!  */}
          <AppRoutes />

          {/* put any effect that want to be fired on Route changes. DO NOT REMOVE. Read notes inside. */}
          <Route component={RouteListener} />

          {/* React router doesn't support anchor tags out of the box. So we have to use our own component. */}
          <Route component={ScrollToAnchor} />

          {/* extract the affiliate code from the query string and store it in localstorage to be sent with a signup payload */}
          <AffiliateCodeExtractor />

          {/* extract the redeem code from the query string and call the activate dialog */}
          <AutoRedeemFromDeeplink />

          {/* display the activate dialog automatically via url but not fill a redeem code */}
          <AutoDisplayRedeemPopup />

          {/* extract the discount code and store it in localstorage */}
          {/* commented out because we don't use it anymore here; the user enters a coupon code manually on the checkout page */}
          {/* <CouponCodeExtractor /> */}

          {/* Modals container WIP */}
          <Modals />

          {/* Log in to a user using a refresh token.
              We currently use them with links in purchase confirmation emails of guest logins.
              https://lithodomosvr.atlassian.net/wiki/spaces/LITHODOMOS/pages/1482850428/Anonymous+guest+logins#If-you-have-made-a-purchase
          */}
          <LoginFromRefreshToken />

          {appMounted ? (
            //  processes that would only work after a page loads
            <LazyComponents />
          ) : null}
        </>
      )}

      <LanguageResetter />

      {env.IS_AK && <AutoLoginAsAnonymous />}
    </>
  );
};
