import { formatDistanceStrict, differenceInCalendarDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import itLocale from 'date-fns/locale/it';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import deLocale from 'date-fns/locale/de';
import ptLocale from 'date-fns/locale/pt';
import ruLocale from 'date-fns/locale/ru';
import plLocale from 'date-fns/locale/pl';
import elLocale from 'date-fns/locale/el';
import heLocale from 'date-fns/locale/he';
import hiLocale from 'date-fns/locale/hi';
import zhCNLocale from 'date-fns/locale/zh-CN';
import { LanguageCode } from '../../../graphql/globalTypes';

export function getExpiryInfo(
  expiresAt: string | null | undefined,
  language: LanguageCode
) {
  let expiresIn = null;
  let expiresSoon = false;
  let expired = false;

  if (expiresAt) {
    const expiryDate = new Date(expiresAt);
    const now = new Date();

    let locale = enLocale;

    if (language === LanguageCode.en_US) {
      locale = enLocale;
    } else if (language === LanguageCode.es_ES) {
      locale = esLocale;
    } else if (language === LanguageCode.fr_FR) {
      locale = frLocale;
    } else if (language === LanguageCode.de_DE) {
      locale = deLocale;
    } else if (language === LanguageCode.pt_PT) {
      locale = ptLocale;
    } else if (language === LanguageCode.ru_RU) {
      locale = ruLocale;
    } else if (language === LanguageCode.pl_PL) {
      locale = plLocale;
    } else if (language === LanguageCode.el_GR) {
      locale = elLocale;
    } else if (language === LanguageCode.he_IL) {
      locale = heLocale;
    } else if (language === LanguageCode.hi_IN) {
      locale = hiLocale;
    } else if (language === LanguageCode.it_IT) {
      locale = itLocale;
    } else if (language === LanguageCode.zh) {
      locale = zhCNLocale;
    } else if (language === LanguageCode.ar) {
      // Arabic is not supported by date-fns
      locale = enLocale;
    }

    expired = expiryDate < now;

    if (!expired) {
      expiresIn = formatDistanceStrict(expiryDate, now, {
        locale,
      });

      const daysBeforeExpiration = differenceInCalendarDays(expiryDate, now);
      expiresSoon = daysBeforeExpiration < 7;
    }
  }

  return { expiresIn, expiresSoon, expired };
}
