import { captureInSentry } from '../App/config/reporting/captureInSentry';
import { parseRemoteGeoJSONFile } from '../features/geolocation/parseRemoteGeoJSONFile';
import { GeoJSONType } from '../graphql/globalTypes';
import { ClosestViewpointDetectionMode } from '../store/location/types';
import { checkIfPointIsInsidePolygonOrMultiPolygon } from './checkIfPointIsInsidePolygonOrMultiPolygon';
import { getDistanceInMetersBetweenTwoCoords } from './getDistanceInMetersBetweenTwoCoords';
import getErrorMessage from './getErrorMessage';

type Coordinate = {
  lat: number;
  lng: number;
};

type BaseViewpoint = {
  id: string;
  internalReference: string;
  fallbackGeofenceRadius: number | null;
  geofence: {
    uri: string;
  } | null;
  location: {
    type: GeoJSONType;
    coordinates: {
      longitude: number;
      latitude: number;
    };
  } | null;
};

const GeofencesMap = new Map();

// Assumption: geojson geofences do not overlap,
// so if we find one within a geojson geofence, we pick it as the closest vp.

export async function getClosestViewpoint<T extends BaseViewpoint>(
  ref: Coordinate,
  viewpoints: T[],
  distanceThreshold: number
): Promise<{
  viewpoint: T | null;
  distance: number;
  mode: ClosestViewpointDetectionMode;
}> {
  let closestViewpoint: T | null = null;
  let closestDistance = Infinity;
  let mode = ClosestViewpointDetectionMode.RADIUS;
  let threshold = distanceThreshold;

  for (let i = 0; i < viewpoints.length; i++) {
    const vp = viewpoints[i];

    if (vp.geofence) {
      try {
        const json = await getGeofenceFromCacheOrNetwork(vp.geofence.uri);

        const isInside = checkIfPointIsInsidePolygonOrMultiPolygon(ref, json);

        if (isInside) {
          // TODO: need to come up with a fallback value
          closestDistance = 10;

          // attempt to get the closest distance
          if (vp.location) {
            const { latitude, longitude } = vp.location.coordinates;

            closestDistance = getDistanceInMetersBetweenTwoCoords(ref, {
              lat: latitude,
              lng: longitude,
            });
          }

          // early exit
          return {
            viewpoint: vp,
            distance: closestDistance,
            mode: ClosestViewpointDetectionMode.GEOFENCE,
          };
        }
      } catch (error) {
        captureInSentry(getErrorMessage(error), {
          ref,
          geofenceURI: vp.geofence.uri,
        });
      }
    }
    // if geofence is defined on a viewpoint, we do not use the radius method
    // irrespective of whether we ran into errors or if the ref is not within the geofence,
    // hence the else if
    else if (vp.location) {
      const { latitude, longitude } = vp.location.coordinates;

      const distance = getDistanceInMetersBetweenTwoCoords(ref, {
        lat: latitude,
        lng: longitude,
      });

      threshold = vp.fallbackGeofenceRadius || distanceThreshold;

      if (distance < closestDistance && distance < threshold) {
        closestDistance = distance;
        closestViewpoint = vp;
      }
    }
  }

  return { viewpoint: closestViewpoint, distance: closestDistance, mode };
}

async function getGeofenceFromCacheOrNetwork(uri: string) {
  const geofenceStrInCache = GeofencesMap.get(uri);

  if (geofenceStrInCache) {
    try {
      return JSON.parse(geofenceStrInCache);
    } catch (error) {
      captureInSentry(getErrorMessage(error), { uri });
    }
  }

  const data = await parseRemoteGeoJSONFile(uri);

  GeofencesMap.set(uri, JSON.stringify(data));

  return data;
}
