import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../style/styled';
import { Button } from './Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }
`;

export const TrialTourEndCTAAO = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href =
      'https://members.ancient-origins.net/ancient-origins-premium-membership-sales';
  };

  return (
    <Container>
      <h2>{t('You want more?')}</h2>

      <p>{t('Become a platinum member to access a new tour each month!')}</p>

      <Button onClick={handleClick}>Let's Go!</Button>
    </Container>
  );
};
