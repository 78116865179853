import React from 'react';
import { env } from '../env';

const GoogleAnalytics = React.lazy(() => import('./GoogleAnalytics'));

export default function Tracking() {
  return (
    <React.Suspense fallback={null}>
      {env.CAPTURE_EVENTS_IN_GOOGLE_ANALYTICS && <GoogleAnalytics />}
    </React.Suspense>
  );
}
