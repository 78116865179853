import React from 'react';
import { Lottie } from '../../../components/Lottie';
import styled from '../../../style/styled';
import animationDataBlack from '../../../animations/ruinsLoadingBlack.json';
import animationDataWhite from '../../../animations/ruinsLoadingWhite.json';

interface Props {
  black?: boolean;
  height?: number;
  width?: number;
}

export const LoadingIndicator = ({
  black = false,
  height = 100,
  width = 100,
}: Props) => {
  return (
    <Container height={height} width={width}>
      <Lottie
        height={height}
        width={width}
        options={{
          loop: true,
          autoplay: true,
          animationData: black ? animationDataBlack : animationDataWhite,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
    </Container>
  );
};

const Container = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;
