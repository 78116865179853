import styled from '../../../style/styled';

export const ContentSectionWidgetImageAspectRatioBoxContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #eee;
`;
