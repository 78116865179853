// // need to do this to be able to use the css prop. workaround link https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31245#issuecomment-463640878
// import * as types from 'styled-components/cssprop';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Z_INDEX } from './Z_INDEX';
import { createGlobalStyle } from './styled';
import { COLORS } from './colors';
import { SCREEN_WIDTH_BREAK_SMALL } from './SIZES';

// DO NOT USE THE THEME IN HERE.
export const AppGlobalStyle = createGlobalStyle`
  #VRButton {
    bottom:90px !important;

    @media only screen and (orientation: landscape) and (max-height: ${SCREEN_WIDTH_BREAK_SMALL}px) {
      bottom:55px !important;
    }
  }
html {
  color: ${COLORS.primaryFontColor};
}

html, body {
  width: 100%;
  height: 100%;
  background: ${COLORS.primaryBackgroundColor};
  -webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.grecaptcha-badge {
  visibility: hidden;
}

.Toastify__toast-container {
  z-index: ${Z_INDEX.REACT_TOAST_CONTAINER}
}

.react-select__control {
  height: 40px;

  @media (hover: none) and (pointer: coarse) {
    height: 50px;
  }
}

.navlinkSelected {
  font-weight: bold;
  text-decoration: underline;
}

.ol-popup {
  /* position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: 0px;
  min-width: 280px;
  z-index: 2; */
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.tour-info-images-carousal {
  /* cursor: pointer; */
  padding-left: 4px;
  padding-right: 4px;
  /* margin-bottom: 4px; */

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.tour-widget-item-carousal {
  cursor: pointer;
  padding: 0 10px;
  margin-bottom: 4px;
}

.map-current-location-button-loading {
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.carousel .slide img {
  pointer-events: all;
}

.update-notification {
  background-color: #202124;
}
`;
