import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getQueryVariable } from '../../utils/getQueryVariable';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import { CodeKeys } from '../../types';
import { unbox } from '../../utils/unbox';
import { captureEvent, DataKeys, EventNames } from '../../features/analytics';

// if there is a redeem code in the query string, extract it
// then open the modal, fill it and try to activate
export const AutoDisplayRedeemPopup = () => {
  const dispatch = useDispatch();

  useEffect(
    () => {
      const redeemPopup =
        unbox(getQueryVariable(CodeKeys.MANUAL_REDEEM)) || null;

      if (redeemPopup === 'y') {
        captureEvent({
          name: EventNames.MANUAL_REDEMTION_ACTIVATED,
          data: [
            {
              key: DataKeys.MANUAL_REDEMPTION,
              value: redeemPopup,
            },
          ],
        });

        dispatch(
          setModalType({
            modalType: ModalType.ACTIVATE_PRODUCT_MODAL,
            modalData: {
              code: null,
            },
          })
        );
      }
    },
    // we only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return null;
};
