import { Coordinate } from 'ol/coordinate';

export type SelectedFeature = {
  id: string;
  type: MarkerType;
  name: string;
  description: string;
  thumbnailURL: string;
  position: Coordinate;
  idx: number;
};

export enum MarkerType {
  VIEWPOINT = 'viewpoint',
  TOUR = 'tour',
  ATTRACTION = 'attraction',
  PLACE = 'place',
}
