import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLanguage,
  selectSupportedLanguages,
} from '../store/app/selectors';
import { LanguageCode } from '../graphql/globalTypes';
import { setLanguage } from '../store/app/actions';
import { setLanguage as setLng } from '../features/i18n/i18n';

// Perhaps this could be a hook, but this is used once and solely split into a separate file for clarity so this is fine.
const LanguageResetter = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const supportedLanguages = useSelector(selectSupportedLanguages);
  useDeepCompareEffect(() => {
    if (!supportedLanguages.includes(language)) {
      // pick the language to set from the supported languages
      const nextLanguageCode = supportedLanguages.includes(LanguageCode.en_US)
        ? LanguageCode.en_US
        : supportedLanguages[0];

      dispatch(setLanguage(nextLanguageCode));
    } else {
      setLng(language);
    }
  }, [language, supportedLanguages]);

  return null;
};

export default LanguageResetter;
