import { useHistory } from 'react-router-dom';
import styled from '../../../../style/styled';
import { captureButtonPress } from '../../../../features/analytics';
import { ROUTES } from '../../../../Routes/routes';
import { Button } from '../../../Button';
import { PurchaseTourContainer } from './styledComponents/PurchaseTourContainer';
import { useTranslation } from 'react-i18next';

const ANALYTICS_BUTTON_NAME = 'CheckIn_IteneraryPopup';

export const TourInfoViewCartButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PurchaseTourContainer>
      <PriceContainer>
        <p>{t('Added to cart')}</p>
      </PriceContainer>

      <Button
        black
        onClick={() => {
          captureButtonPress({
            page: window.location.pathname,
            buttonName: ANALYTICS_BUTTON_NAME,
          });

          history.push(ROUTES.cart);
        }}
      >
        {t('View in cart')}
      </Button>
    </PurchaseTourContainer>
  );
};

const PriceContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;
  }
`;
