import { z } from 'zod';
import * as Sentry from '@sentry/browser';
import { env } from '../../../../App/config/env';

export default function getGoogleMapMarkerImage(
  tourID: string,
  viewpointID: string
): string | undefined {
  // Define the Zod schema inside the function
  const iconSchema = z.object({
    viewpointID: z.string(),
    iconURL: z.string(),
  });

  const tourCustomIconsSchema = z.record(z.string(), z.array(iconSchema));
  const envSchema = z.object({
    APP_ENV: z
      .object({
        googleMaps: z
          .object({
            customIcons: tourCustomIconsSchema.optional(),
          })
          .optional(),
      })
      .optional(),
  });

  let parsedEnv;

  // Validate the environment configuration
  try {
    parsedEnv = envSchema.parse(env);
  } catch (error) {
    Sentry.captureException(error);
    return undefined;
  }

  const tourCustomIcons =
    parsedEnv.APP_ENV?.googleMaps?.customIcons?.[tourID] || [];

  // Use Array.prototype.find to locate the icon
  const icon = tourCustomIcons.find((icon) => icon.viewpointID === viewpointID);

  return icon?.iconURL;
}
