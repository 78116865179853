import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../../Routes/routes';
import { setModalType } from '../../../store/modal/actions';
import { selectModalType } from '../../../store/modal/selectors';
import { ModalType } from '../../../store/modal/types';

interface Props {
  curPathname: string;
  prevPathname?: string | null;
}

export const CloseModalManager: React.FC<Props> = ({
  curPathname,
  prevPathname,
}) => {
  const dispatch = useDispatch();
  const modalType = useSelector(selectModalType);

  const closeModal = useCallback(() => {
    if (
      modalType !== ModalType.ACTIVATE_PRODUCT_MODAL &&
      modalType !== ModalType.SESSION_EXPIRED_MODAL
    ) {
      dispatch(setModalType({ modalType: ModalType.NONE }));
    }
  }, [dispatch, modalType]);

  useEffect(
    () => {
      if (
        curPathname !== prevPathname &&
        prevPathname !== ROUTES.facebookRedirectHandler
      ) {
        closeModal();
      }
    },
    // it is important that we do not include anything else here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [curPathname, prevPathname]
  );

  return null;
};
