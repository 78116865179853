import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import styled from '../../../../style/styled';
import {
  SCREEN_WIDTH_BREAK_EXTRA_SMALL,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../../../style/SIZES';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../../features/analytics';
import { Stars } from './Stars';
import { Button } from '../../../../components/Button';
import {
  EXPLORE_WEB_CreateReview as Data,
  EXPLORE_WEB_CreateReviewVariables as Variables,
} from './__generated__/EXPLORE_WEB_CreateReview';
import { env } from '../../../config/env';
import { usePrevious } from '../../../../hooks/usePrevious';
import { nonNullable } from '../../../../utils/nonNullable';

const CREATE_REVIEW_MUTATION = gql`
  mutation EXPLORE_WEB_CreateReview($input: CreateReviewInput!) {
    result: createReview(input: $input) {
      review {
        id
      }
      error {
        message
      }
    }
  }
`;

interface Props {
  tourID: string;
}

export const RatingAndReview = ({ tourID }: Props) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [review, setReview] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const prevSubmitted = usePrevious(submitted);

  const [createReview] = useMutation<Data, Variables>(CREATE_REVIEW_MUTATION);

  useEffect(() => {
    if (submitted && !prevSubmitted) {
      captureEvent({
        name: EventNames.RATED_TOUR,
        data: [
          {
            key: DataKeys.RATING,
            value: (rating / 5).toString(),
          },
          env.ENABLE_REVIEWS
            ? {
                key: DataKeys.REVIEW,
                value: review,
              }
            : null,
          {
            key: DataKeys.TOUR_ID,
            value: tourID,
          },
        ].filter(nonNullable),
      });

      if (env.ENABLE_REVIEWS) {
        createReview({
          variables: {
            input: {
              tourID,
              rating: rating / 5,
              review,
            },
          },
        });
      }
    }
  }, [rating, review, tourID, createReview, submitted, prevSubmitted]);

  return (
    <StarRatingContainer>
      <h3>{t('How was that?')}</h3>

      {!env.ENABLE_REVIEWS ? (
        <Stars
          rating={rating}
          setRating={(rating: number) => {
            setRating(rating);
            setSubmitted(true);
          }}
          disabled={submitted}
        />
      ) : null}

      {env.ENABLE_REVIEWS && !submitted ? (
        <>
          <Stars rating={rating} setRating={setRating} disabled={submitted} />

          <TextArea
            rows={6}
            placeholder={t('Tell us more')}
            disabled={submitted}
            onChange={(e) => setReview(e.target.value)}
          />

          <Button
            disabled={(!rating && !review) || submitted}
            onClick={() => setSubmitted(true)}
          >
            Submit
          </Button>
        </>
      ) : null}

      {env.ENABLE_REVIEWS && submitted ? (
        <p>{t('Thanks for your feedback!')}</p>
      ) : null}
    </StarRatingContainer>
  );
};

const StarRatingContainer = styled.div`
  grid-column: 1/3;
  text-align: center;
  display: grid;
  grid-gap: 8px;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;

  h3 {
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    width: 100%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_EXTRA_SMALL}px) {
    grid-column: 1/2;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
`;
