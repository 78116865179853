import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import { selectIsAuthenticated } from '../store/app/selectors';
import { useEffect } from 'react';

const useOnAuthChanged = (callback: () => void) => {
  const authed = useSelector(selectIsAuthenticated);
  const wasAuthed = usePrevious(authed);

  // TODO: refetch if token is expired.

  useEffect(() => {
    if (authed !== wasAuthed && typeof wasAuthed === 'boolean') {
      callback();
    }
  }, [authed, wasAuthed, callback]);
};

export default useOnAuthChanged;
