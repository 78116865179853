import React, { useEffect } from 'react';

interface Props {
  curPathname: string;
  prevPathname?: string | null;
}

export const ScrollToPageTopManagerWithItineraryPages: React.FC<Props> = ({
  curPathname,
  prevPathname,
}) => {
  useEffect(() => {
    if (curPathname !== prevPathname) {
      window.scrollTo(0, 0);
    }
  }, [curPathname, prevPathname]);

  return null;
};
