import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_SubscribeToNewsletterUnauthedMutation,
  EXPLORE_WEB_SubscribeToNewsletterUnauthedMutationVariables,
} from './__generated__/EXPLORE_WEB_SubscribeToNewsletterUnauthedMutation';

export type Data = EXPLORE_WEB_SubscribeToNewsletterUnauthedMutation;
export type Variables = EXPLORE_WEB_SubscribeToNewsletterUnauthedMutationVariables;

export const SUBSCRIBE_TO_NEWSLETTER_UNAUTHED = gql`
  mutation EXPLORE_WEB_SubscribeToNewsletterUnauthedMutation(
    $input: SubscribeToNewsletterUnauthedInput!
  ) {
    result: subscribeToNewsletterUnauthed(input: $input) {
      subscribed
      error {
        id
        message
        type
      }
    }
  }
`;
