const ONBOARDING_TUTORIAL = 1000;
const NEARBY_VIEWPOINT_ALERT = 999;
const HEADER = 998;
const LOADING_SCREEN = 998;
const MENU = LOADING_SCREEN - 100;
const OVERLAY_UI = MENU - 100;

// inside tour
const STREET_VIEW = OVERLAY_UI - 300;
const IMAGE_VIEW = STREET_VIEW;
const PANORAMA = STREET_VIEW;

const PANORAMA_LOADING = LOADING_SCREEN;

const VIEWER = PANORAMA - 10;

const FULLSCREEN_VIDEO = PANORAMA;

const MODAL =
  Math.max(
    HEADER,
    OVERLAY_UI,
    STREET_VIEW,
    IMAGE_VIEW,
    PANORAMA,
    VIEWER,
    OVERLAY_UI,
    MENU,
    FULLSCREEN_VIDEO,
    ONBOARDING_TUTORIAL,
    NEARBY_VIEWPOINT_ALERT
  ) + 1;

const REACT_TOAST_CONTAINER = MODAL + 1;

// Need to revisit this. Z-indexes can cause a lot of pain
export const Z_INDEX = {
  OVERLAY_UI,
  VIEWER,
  PANORAMA,
  STREET_VIEW,
  IMAGE_VIEW,
  HEADER,
  LOADING_SCREEN,
  PANORAMA_LOADING,
  MENU,
  FULLSCREEN_VIDEO,
  MODAL,
  REACT_TOAST_CONTAINER,
  NEARBY_VIEWPOINT_ALERT,
  ONBOARDING_TUTORIAL,
};
