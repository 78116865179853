import { mdiCart } from '@mdi/js';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { captureButtonPress } from '../../features/analytics';
import { ROUTES } from '../../Routes/routes';
import { selectCartProductsCount } from '../../store/app/selectors';
import styled, { useTheme } from '../../style/styled';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText as _AppHeaderButtonText,
} from './styledComponents';
import getRedirectCartButtonToExternalURL from '../../features/whitelabeling/branding/utils/getRedirectCartButtonToExternalURL';
import redirectToUrl from '../../utils/redirectToUrl';
import MdiIcon from '../MdiIcon';
import getCartIcon from '../../features/whitelabeling/branding/utils/getCartIcon';
import DynamicMdiReactIcon from '../DynamicMdiReactIcon';

interface Props {
  color?: string;
  size?: number;
  showTextInMobile?: boolean;
  showTextOnDesktop?: boolean;
}

export const CartButton: React.FC<Props> = ({
  showTextInMobile = false,
  showTextOnDesktop = true,
  ...rest
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const count = useSelector(selectCartProductsCount);
  const theme = useTheme();

  const color = rest.color || theme.palette.appHeader.color;
  const size = rest.size || HEADER_BUTTON_SIZE;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Cart',
    });

    const redirectUrl = getRedirectCartButtonToExternalURL();
    if (redirectUrl) {
      redirectToUrl(redirectUrl);
    } else {
      history.push(ROUTES.cart);
    }
  };

  const customCartIcon = getCartIcon();

  return (
    <AppHeaderButtonContainer
      data-testid="header-cart-button"
      onClick={handleClick}
    >
      <CartButtonIconContainer count={count}>
        {customCartIcon ? (
          <DynamicMdiReactIcon
            iconName={customCartIcon}
            color={color}
            size={HEADER_BUTTON_SIZE * 1.4}
          />
        ) : (
          <MdiIcon
            path={mdiCart}
            color={color}
            size={HEADER_BUTTON_SIZE * 1.4}
          />
        )}

        {count > 0 && (
          <CartButtonCartCountContainer size={size}>
            <CartButtonCartCountText>{count}</CartButtonCartCountText>
          </CartButtonCartCountContainer>
        )}
      </CartButtonIconContainer>

      <AppHeaderButtonText
        color={color}
        bold={window.location.pathname === ROUTES.cart}
        size={size}
        showTextInMobile={showTextInMobile}
        showTextOnDesktop={showTextOnDesktop}
      >
        {t('Cart')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};

const CartButtonIconContainer = styled.div<{ count: number }>`
  position: relative;
  display: flex;
`;

const CartButtonCartCountContainer = styled.div<{
  size: number;
}>`
  right: -${(props) => props.size / 2}px;
  bottom: -${(props) => props.size / 2}px;
  position: absolute;
  background: #ff0000;
  padding: 2px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CartButtonCartCountText = styled.span`
  font-size: 66.66%;
  user-select: none;
  color: #fff;
  font-weight: bold;
`;

const AppHeaderButtonText = styled(_AppHeaderButtonText)<{
  showTextInMobile?: boolean;
  showTextOnDesktop?: boolean;
}>`
  display: ${(props) =>
    props.showTextInMobile ? 'inline-block' : 'none'} !important;

  @media only screen and (min-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: ${(props) =>
      props.showTextOnDesktop ? 'inline-block' : 'none'} !important;
  }
`;
