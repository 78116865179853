import NoSleep from 'nosleep.js';
import { isMobile, isIOS } from 'react-device-detect';
import { captureInSentry } from '../App/config/reporting/captureInSentry';
import getErrorMessage from './getErrorMessage';

const noSleep = new NoSleep();

let _enabled = false;

// perhaps now we can use isEnabled api function.
// haven't tested

// TODO: write tests
export function enableNoSleep() {
  // nosleep.js has issues with iOS volume controls hanging
  // the condition is added to enableNoSleep fn.
  if (isMobile && !isIOS && !_enabled) {
    try {
      noSleep.enable();

      // there were errors trying to disable without enabling and vise versa.
      _enabled = true;
    } catch (error) {
      captureInSentry(
        `noSleep.ts enableNoSleep() error: ${getErrorMessage(error)}`
      );
    }
  }
}

// TODO: write tests
export function disableNoSleep() {
  if (_enabled) {
    try {
      // there were errors trying to disable without enabling and vise versa.
      noSleep.disable();
      _enabled = false;
    } catch (error) {
      captureInSentry(
        `noSleep.ts disableNoSleep() error: ${getErrorMessage(error)}`
      );
    }
  }
}
