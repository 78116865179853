import { AnalyticEventInput } from '../../graphql/globalTypes';
import indexedDBStorage from '../../utils/indexedDBStorage';
import { submitToBackend } from './submitToBackend';

const EVENT_BATCH_SIZE = 10;
const EVENTS_QUEUE_KEY = 'lvr-viewer-analytic-events';

let eventQueue: Array<AnalyticEventInput> = [];

// A lock to prevent addToEventQueue from updating the eventQueue while processQueue is running
let queueUpdateLock = Promise.resolve();

export const addToEventQueue = async (event: AnalyticEventInput) => {
  queueUpdateLock = queueUpdateLock.then(async () => {
    eventQueue.push(event);

    try {
      await indexedDBStorage.setItem(EVENTS_QUEUE_KEY, eventQueue);
    } catch (error) {
      // do nothing
    }
  });
};

export const loadEventQueueFromStorage = async () => {
  try {
    const queue = await indexedDBStorage.getItem(EVENTS_QUEUE_KEY);

    if (Array.isArray(queue) && queue.length > 0) {
      eventQueue = queue;
    }
  } catch (error) {
    // do nothing
  }
};

// Flag to prevent multiple calls to processQueue
let processing = false;

export async function processQueue() {
  // We don't want to process the queue if we're already processing
  if (processing || eventQueue.length === 0) {
    return;
  }

  processing = true;

  // Move all the events from eventQueue to processingQueue making eventQueue empty
  let processingQueue = [...eventQueue.splice(0, eventQueue.length)];

  let i = 0;

  while (i < processingQueue.length) {
    // Get the first batch of events and remove from the processingQueue
    const batchEvents = processingQueue.splice(i, EVENT_BATCH_SIZE);

    try {
      // Submit the batch to the backend
      await submitToBackend(batchEvents);
    } catch (error) {
      // Put back the failed events to the front of the queue
      processingQueue = [...batchEvents, ...processingQueue];

      // Increment i by batchEvents.length to skip failed events for this call. We'll try again later.
      i += batchEvents.length;
    }
  }

  // If there are any remaining events, push them to the front of the queue
  if (processingQueue.length > 0) {
    eventQueue = [...processingQueue, ...eventQueue];
  }

  // Save the updated queue to IndexedDB
  queueUpdateLock = queueUpdateLock.then(async () => {
    try {
      await indexedDBStorage.setItem(EVENTS_QUEUE_KEY, eventQueue);
    } catch (idbError) {
      // Do nothing - there is no way to handle this case
    }
  });

  processing = false;
}
