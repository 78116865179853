import { AssetFragment } from '../../graphql/fragments/__generated__/AssetFragment';

export enum JobStatus {
  DOWNLOADING,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
  CLEANING_UP,
  CLEANUP_SUCCESS,
  CLEANUP_ERROR,
  NONE,
}

export type Playlist = {
  id: string;
  totalSize: number;
  cachedSize: number;
  name: string;
  jobStatus: JobStatus;
  automatic: boolean;
};

export enum QueueStatus {
  IDLE,
  BUSY,
}

export type Asset = {
  id: string;
  uri: string;
  contentLength: number;
  playlistIDs: string[];
};

export type OfflineState = {
  files: {
    [assetID: string]: {
      uri: string;
      contentLength: number;
      playlistIDs: string[];
    };
  };
  playlists: Array<Playlist>;
  // #DEPRECATED
  // TODO: remove using redux persist features
  assets: Array<AssetFragment>;

  // the following states aren't persisted. they are removed from the outbound transform (transforms.ts)
  queueStatus: QueueStatus;
};

export const initialState: OfflineState = {
  assets: [],
  playlists: [],
  queueStatus: QueueStatus.IDLE,
  files: {},
};
